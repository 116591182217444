// src/utils/authService.js

import { time } from '@tensorflow/tfjs';
import axios from 'axios';

export const refreshAccessToken = async () => {
  try {
    const response = await axios.post('https://api.stemverse.app/auth/refresh', {}, { withCredentials: true }); //'https://api.stemverse.app/auth/refresh'
    console.log("token refresh attempt")
    var currentdate = new Date(); 
    var datetime = "Last Sync: " + currentdate.getDate() + "/"
                + (currentdate.getMonth()+1)  + "/" 
                + currentdate.getFullYear() + " @ "  
                + currentdate.getHours() + ":"  
                + currentdate.getMinutes() + ":" 
                + currentdate.getSeconds();
    console.log(datetime);
    return response.data.accessToken;
  } catch (error) {
    console.error('Error refreshing token:', error);
    throw error;  // Trigger logout in `apiClient.js` if refresh fails
  }
};

export const logoutUser = async () => {
    try {
      await axios.post('http://api.stemverse.app/auth/logout', {}, { withCredentials: true }); //'http://api.stemverse.app/auth/logout'
      localStorage.removeItem('accessToken');  // Clear access token from local storage
    //   window.location.href = '/login';         // Redirect to login page
    } catch (error) {
      console.error('Error during logout:', error);
      // Still redirect to login page even if the server call fails
      localStorage.removeItem('accessToken');
    //   window.location.href = '/login';
    }
  };
