import VM from 'scratch-vm';
import EventEmitter from 'events';

// Add at the top of file
const KEY_CODES = {
    space: 32,
    'left arrow': 37,
    'up arrow': 38,
    'right arrow': 39,
    'down arrow': 40,
    enter: 13
};

export function createVMBridge() {
    let ws = null;
    let vmInstance = null;
    let sprites = new Map();

    const bridge = {
        initialize: function(vm) {
            vmInstance = vm;
            
            // Only set up event listeners after VM is initialized
            if (vmInstance && vmInstance.runtime) {
                vmInstance.runtime.on('ANSWER_VALUE', (data) => {
                    if (ws && ws.readyState === WebSocket.OPEN) {
                        ws.send(JSON.stringify(data));
                        console.log('Sent answer through bridge:', data);
                    }
                });
            }
            
            this.setupWebSocket();
        },

        setupWebSocket: function() {
            if (ws) ws.close();
            
            ws = new WebSocket('https://api.stemverse.app');
            
            ws.onmessage = (event) => {
                try {
                    const message = JSON.parse(event.data);
                    console.log("Received message:", message);
                    
                    switch (message.type) {
                        case 'create_sprite':
                        case 'scratch/create_sprite':
                            this.handleCreateSprite(message.data);
                            break;
                        case 'move_sprite':
                        case 'scratch/move_sprite':
                            this.handleMoveSprite(message.data);
                            break;
                        case 'rotate_sprite':
                        case 'scratch/rotate_sprite':
                            this.handleRotateSprite(message.data);
                            break;
                        case 'say_sprite':
                        case 'scratch/say_sprite':
                            this.handleSaySprite(message.data);
                            break;
                        case 'think_sprite':
                        case 'scratch/think_sprite':
                            this.handleThinkSprite(message.data);
                            break;
                        case 'move_steps':
                        case 'scratch/move_steps':
                            this.handleMoveSteps(message.data);
                            break;
                        case 'ask_sprite':
                        case 'scratch/ask_sprite':
                            this.handleAskSprite(message.data);
                            break;
                        case 'scratch/get_answer':
                            this.handleGetAnswer(message.data);
                            break;
                        case 'scratch/glide_to_xy':
                            try {
                                const target = sprites.get(message.data.id);
                                if (target) {
                                    // Get motion blocks instance
                                    const motionBlocks = new vmInstance.runtime._blockPackages.scratch3_motion(vmInstance.runtime);
                                    
                                    // Use glide block
                                    motionBlocks.glide({
                                        SECS: message.data.secs,
                                        X: message.data.x,
                                        Y: message.data.y
                                    }, {
                                        target: target,
                                        runtime: vmInstance.runtime
                                    });
                                    
                                    vmInstance.runtime.requestTargetsUpdate(target);
                                    vmInstance.runtime.requestRedraw();
                                }
                            } catch (error) {
                                console.error('Glide command error:', error);
                            }
                            break;
                        case 'scratch/bounce_on_edge':
                        case 'bounce_on_edge':
                            try {
                                const target = sprites.get(message.data.id);
                                if (target) {
                                    // Get motion blocks instance
                                    const motionBlocks = new vmInstance.runtime._blockPackages.scratch3_motion(vmInstance.runtime);
                                    
                                    // Use ifOnEdgeBounce block
                                    motionBlocks.ifOnEdgeBounce({}, {
                                        target: target,
                                        runtime: vmInstance.runtime
                                    });
                                    
                                    vmInstance.runtime.requestTargetsUpdate(target);
                                    vmInstance.runtime.requestRedraw();
                                }
                            } catch (error) {
                                console.error('Edge bounce error:', error);
                            }
                            break;
                        case 'scratch/set_rotation_style':
                        case 'set_rotation_style':
                            this.handleSetRotationStyle(message.data);
                            break;
                        case 'scratch/event_whenthisspriteclicked':
                            this.handleSpriteClick(message.data);
                            break;
                        case 'scratch/go_to_random':
                        case 'go_to_random':
                            try {
                                const target = sprites.get(message.data.id);
                                if (target) {
                                    target.setXY(message.data.x, message.data.y);
                                    vmInstance.runtime.requestTargetsUpdate(target);
                                    vmInstance.runtime.requestRedraw();
                                }
                            } catch (error) {
                                console.error('Go to random position error:', error);
                            }
                            break;
                        case 'scratch/go_to_mouse':
                        case 'go_to_mouse':
                            try {
                                const target = sprites.get(message.data.id);
                                if (target) {
                                    const mouseData = vmInstance.runtime.ioDevices.mouse;
                                    target.setXY(mouseData.getClientX(), mouseData.getClientY());
                                    vmInstance.runtime.requestTargetsUpdate(target);
                                    vmInstance.runtime.requestRedraw();
                                    
                                    // Set up continuous mouse following
                                    if (!target._mouseFollowLoop) {
                                        target._mouseFollowLoop = setInterval(() => {
                                            target.setXY(mouseData.getClientX(), mouseData.getClientY());
                                            vmInstance.runtime.requestTargetsUpdate(target);
                                            vmInstance.runtime.requestRedraw();
                                        }, 1000/30); // Update 30 times per second
                                    }
                                }
                            } catch (error) {
                                console.error('Go to mouse error:', error);
                            }
                            break;
                        case 'scratch/change_x_by':
                        case 'change_x_by':
                            try {
                                const target = sprites.get(message.data.id);
                                if (target) {
                                    // Use motion blocks changeX
                                    const motionBlocks = new vmInstance.runtime._blockPackages.scratch3_motion(vmInstance.runtime);
                                    motionBlocks.changeX({
                                        DX: message.data.dx
                                    }, {
                                        target: target,
                                        runtime: vmInstance.runtime
                                    });
                                    
                                    vmInstance.runtime.requestTargetsUpdate(target);
                                    vmInstance.runtime.requestRedraw();
                                }
                            } catch (error) {
                                console.error('Change x position error:', error);
                            }
                            break;

                        case 'scratch/change_y_by':
                        case 'change_y_by':
                            try {
                                const target = sprites.get(message.data.id);
                                if (target) {
                                    // Use motion blocks changeY
                                    const motionBlocks = new vmInstance.runtime._blockPackages.scratch3_motion(vmInstance.runtime);
                                    motionBlocks.changeY({
                                        DY: message.data.dy
                                    }, {
                                        target: target,
                                        runtime: vmInstance.runtime
                                    });
                                    
                                    vmInstance.runtime.requestTargetsUpdate(target);
                                    vmInstance.runtime.requestRedraw();
                                }
                            } catch (error) {
                                console.error('Change y position error:', error);
                            }
                            break;
                        case 'scratch/set_x':
                        case 'set_x':
                            try {
                                const target = sprites.get(message.data.id);
                                if (target) {
                                    // Use motion blocks setX
                                    const motionBlocks = new vmInstance.runtime._blockPackages.scratch3_motion(vmInstance.runtime);
                                    motionBlocks.setX({
                                        X: message.data.x
                                    }, {
                                        target: target,
                                        runtime: vmInstance.runtime
                                    });
                                    
                                    vmInstance.runtime.requestTargetsUpdate(target);
                                    vmInstance.runtime.requestRedraw();
                                }
                            } catch (error) {
                                console.error('Set x position error:', error);
                            }
                            break;

                        case 'scratch/set_y':
                        case 'set_y':
                            try {
                                const target = sprites.get(message.data.id);
                                if (target) {
                                    // Use motion blocks setY
                                    const motionBlocks = new vmInstance.runtime._blockPackages.scratch3_motion(vmInstance.runtime);
                                    motionBlocks.setY({
                                        Y: message.data.y
                                    }, {
                                        target: target,
                                        runtime: vmInstance.runtime
                                    });
                                    
                                    vmInstance.runtime.requestTargetsUpdate(target);
                                    vmInstance.runtime.requestRedraw();
                                }
                            } catch (error) {
                                console.error('Set y position error:', error);
                            }
                            break;
                        case 'scratch/goto_xy':
                        case 'goto_xy':
                            try {
                                const target = sprites.get(message.data.id);
                                if (target) {
                                    // Use motion blocks goToXY
                                    const motionBlocks = new vmInstance.runtime._blockPackages.scratch3_motion(vmInstance.runtime);
                                    motionBlocks.goToXY({
                                        X: message.data.x,
                                        Y: message.data.y
                                    }, {
                                        target: target,
                                        runtime: vmInstance.runtime
                                    });
                                    
                                    vmInstance.runtime.requestTargetsUpdate(target);
                                    vmInstance.runtime.requestRedraw();
                                }
                            } catch (error) {
                                console.error('Go to xy error:', error);
                            }
                            break;
                        case 'scratch/switch_costume':
                        case 'switch_costume':
                            try {
                                const target = sprites.get(message.data.id);
                                if (target) {
                                    // Get looks blocks instance
                                    const looksBlocks = new vmInstance.runtime._blockPackages.scratch3_looks(vmInstance.runtime);
                                    
                                    // Use switchCostume block
                                    looksBlocks.switchCostume({
                                        COSTUME: message.data.costume
                                    }, {
                                        target: target,
                                        runtime: vmInstance.runtime
                                    });
                                    
                                    vmInstance.runtime.requestTargetsUpdate(target);
                                    vmInstance.runtime.requestRedraw();
                                }
                            } catch (error) {
                                console.error('Switch costume error:', error);
                            }
                            break;
                        case 'scratch/switch_backdrop':
                        case 'switch_backdrop':
                            try {
                                const stage = vmInstance.runtime.getTargetForStage();
                                if (stage) {
                                    // Get looks blocks instance
                                    const looksBlocks = new vmInstance.runtime._blockPackages.scratch3_looks(vmInstance.runtime);
                                    
                                    // Use switchBackdrop block
                                    looksBlocks.switchBackdrop({
                                        BACKDROP: message.data.backdrop
                                    }, {
                                        target: stage,
                                        runtime: vmInstance.runtime
                                    });
                                    
                                    vmInstance.runtime.requestTargetsUpdate(stage);
                                    vmInstance.runtime.requestRedraw();
                                }
                            } catch (error) {
                                console.error('Switch backdrop error:', error);
                            }
                            break;

                        case 'scratch/next_backdrop':
                        case 'next_backdrop':
                            try {
                                const stage = vmInstance.runtime.getTargetForStage();
                                if (stage) {
                                    // Get looks blocks instance
                                    const looksBlocks = new vmInstance.runtime._blockPackages.scratch3_looks(vmInstance.runtime);
                                    
                                    // Use nextBackdrop block
                                    looksBlocks.nextBackdrop({}, {
                                        target: stage,
                                        runtime: vmInstance.runtime
                                    });
                                    
                                    vmInstance.runtime.requestTargetsUpdate(stage);
                                    vmInstance.runtime.requestRedraw();
                                }
                            } catch (error) {
                                console.error('Next backdrop error:', error);
                            }
                            break;
                        case 'scratch/change_size':
                        case 'change_size':
                            try {
                                const target = sprites.get(message.data.id);
                                if (target) {
                                    // Get looks blocks instance
                                    const looksBlocks = new vmInstance.runtime._blockPackages.scratch3_looks(vmInstance.runtime);
                                    
                                    // Use changeSize block
                                    looksBlocks.changeSize({
                                        CHANGE: message.data.change
                                    }, {
                                        target: target,
                                        runtime: vmInstance.runtime
                                    });
                                    
                                    vmInstance.runtime.requestTargetsUpdate(target);
                                    vmInstance.runtime.requestRedraw();
                                }
                            } catch (error) {
                                console.error('Change size error:', error);
                            }
                            break;

                        case 'scratch/set_size':
                        case 'set_size':
                            try {
                                const target = sprites.get(message.data.id);
                                if (target) {
                                    // Get looks blocks instance
                                    const looksBlocks = new vmInstance.runtime._blockPackages.scratch3_looks(vmInstance.runtime);
                                    
                                    // Use setSize block
                                    looksBlocks.setSize({
                                        SIZE: message.data.size
                                    }, {
                                        target: target,
                                        runtime: vmInstance.runtime
                                    });
                                    
                                    vmInstance.runtime.requestTargetsUpdate(target);
                                    vmInstance.runtime.requestRedraw();
                                }
                            } catch (error) {
                                console.error('Set size error:', error);
                            }
                            break;
                        case 'scratch/change_effect':
                        case 'change_effect':
                            try {
                                const target = sprites.get(message.data.id);
                                if (target) {
                                    // Get looks blocks instance
                                    const looksBlocks = new vmInstance.runtime._blockPackages.scratch3_looks(vmInstance.runtime);
                                    
                                    // Use changeEffect block
                                    looksBlocks.changeEffect({
                                        EFFECT: message.data.effect,
                                        CHANGE: message.data.change
                                    }, {
                                        target: target,
                                        runtime: vmInstance.runtime
                                    });
                                    
                                    vmInstance.runtime.requestTargetsUpdate(target);
                                    vmInstance.runtime.requestRedraw();
                                }
                            } catch (error) {
                                console.error('Change effect error:', error);
                            }
                            break;

                        case 'scratch/set_effect':
                        case 'set_effect':
                            try {
                                const target = sprites.get(message.data.id);
                                if (target) {
                                    // Get looks blocks instance
                                    const looksBlocks = new vmInstance.runtime._blockPackages.scratch3_looks(vmInstance.runtime);
                                    
                                    // Use setEffect block
                                    looksBlocks.setEffect({
                                        EFFECT: message.data.effect,
                                        VALUE: message.data.value
                                    }, {
                                        target: target,
                                        runtime: vmInstance.runtime
                                    });
                                    
                                    vmInstance.runtime.requestTargetsUpdate(target);
                                    vmInstance.runtime.requestRedraw();
                                }
                            } catch (error) {
                                console.error('Set effect error:', error);
                            }
                            break;

                        case 'scratch/clear_effects':
                        case 'clear_effects':
                            try {
                                const target = sprites.get(message.data.id);
                                if (target) {
                                    // Get looks blocks instance
                                    const looksBlocks = new vmInstance.runtime._blockPackages.scratch3_looks(vmInstance.runtime);
                                    
                                    // Use clearEffects block
                                    looksBlocks.clearEffects({}, {
                                        target: target,
                                        runtime: vmInstance.runtime
                                    });
                                    
                                    vmInstance.runtime.requestTargetsUpdate(target);
                                    vmInstance.runtime.requestRedraw();
                                }
                            } catch (error) {
                                console.error('Clear effects error:', error);
                            }
                            break;
                        case 'scratch/show':
                        case 'show':
                            try {
                                const target = sprites.get(message.data.id);
                                if (target) {
                                    // Get looks blocks instance
                                    const looksBlocks = new vmInstance.runtime._blockPackages.scratch3_looks(vmInstance.runtime);
                                    
                                    // Use show block
                                    looksBlocks.show({}, {
                                        target: target,
                                        runtime: vmInstance.runtime
                                    });
                                    
                                    vmInstance.runtime.requestTargetsUpdate(target);
                                    vmInstance.runtime.requestRedraw();
                                }
                            } catch (error) {
                                console.error('Show sprite error:', error);
                            }
                            break;

                        case 'scratch/hide':
                        case 'hide':
                            try {
                                const target = sprites.get(message.data.id);
                                if (target) {
                                    // Get looks blocks instance
                                    const looksBlocks = new vmInstance.runtime._blockPackages.scratch3_looks(vmInstance.runtime);
                                    
                                    // Use hide block
                                    looksBlocks.hide({}, {
                                        target: target,
                                        runtime: vmInstance.runtime
                                    });
                                    
                                    vmInstance.runtime.requestTargetsUpdate(target);
                                    vmInstance.runtime.requestRedraw();
                                }
                            } catch (error) {
                                console.error('Hide sprite error:', error);
                            }
                            break;

                        case 'scratch/go_to_front':
                        case 'go_to_front':
                            try {
                                const target = sprites.get(message.data.id);
                                if (target) {
                                    // Get looks blocks instance
                                    const looksBlocks = new vmInstance.runtime._blockPackages.scratch3_looks(vmInstance.runtime);
                                    
                                    // Use goToFrontBack block
                                    looksBlocks.goToFrontBack({
                                        FRONT_BACK: 'front'
                                    }, {
                                        target: target,
                                        runtime: vmInstance.runtime
                                    });
                                    
                                    vmInstance.runtime.requestTargetsUpdate(target);
                                    vmInstance.runtime.requestRedraw();
                                }
                            } catch (error) {
                                console.error('Go to front error:', error);
                            }
                            break;

                        case 'scratch/go_to_back':
                        case 'go_to_back':
                            try {
                                const target = sprites.get(message.data.id);
                                if (target) {
                                    // Get looks blocks instance
                                    const looksBlocks = new vmInstance.runtime._blockPackages.scratch3_looks(vmInstance.runtime);
                                    
                                    // Use goToFrontBack block
                                    looksBlocks.goToFrontBack({
                                        FRONT_BACK: 'back'
                                    }, {
                                        target: target,
                                        runtime: vmInstance.runtime
                                    });
                                    
                                    vmInstance.runtime.requestTargetsUpdate(target);
                                    vmInstance.runtime.requestRedraw();
                                }
                            } catch (error) {
                                console.error('Go to back error:', error);
                            }
                            break;

                        case 'scratch/change_layer':
                        case 'change_layer':
                            try {
                                const target = sprites.get(message.data.id);
                                if (target) {
                                    // Get looks blocks instance
                                    const looksBlocks = new vmInstance.runtime._blockPackages.scratch3_looks(vmInstance.runtime);
                                    
                                    // Use goForwardBackwardLayers block
                                    looksBlocks.goForwardBackwardLayers({
                                        FORWARD_BACKWARD: message.data.layers > 0 ? 'forward' : 'backward',
                                        NUM: Math.abs(message.data.layers)
                                    }, {
                                        target: target,
                                        runtime: vmInstance.runtime
                                    });
                                    
                                    vmInstance.runtime.requestTargetsUpdate(target);
                                    vmInstance.runtime.requestRedraw();
                                }
                            } catch (error) {
                                console.error('Change layer error:', error);
                            }
                            break;
                        case 'scratch/get_costume_info':
                            this.handleGetCostumeInfo(message.data);
                            break;

                        case 'scratch/get_backdrop_info':
                            this.handleGetBackdropInfo(message.data);
                            break;

                        case 'scratch/get_size':
                            this.handleGetSize(message.data);
                            break;
                        case 'scratch/sensing_touching':
                            try {
                                const target = sprites.get(message.data.id);
                                if (target && vmInstance) {
                                    let isTouching = false;
                                    const targetArg = message.data.target;
                                    
                                    if (targetArg === '_mouse_') {
                                        // Check mouse touching
                                        const mouseX = vmInstance.runtime.ioDevices.mouse.getScratchX();
                                        const mouseY = vmInstance.runtime.ioDevices.mouse.getScratchY();
                                        const bounds = target.getBounds();
                                        isTouching = bounds.contains(mouseX, mouseY);
                                    } else if (targetArg === '_edge_') {
                                        // Check edge touching
                                        const bounds = target.getBounds();
                                        const stageBounds = {left: -240, right: 240, top: 180, bottom: -180};
                                        isTouching = bounds.left <= stageBounds.left || 
                                                    bounds.right >= stageBounds.right ||
                                                    bounds.top >= stageBounds.top ||
                                                    bounds.bottom <= stageBounds.bottom;
                                    } else {
                                        // Check sprite touching sprite
                                        const otherTarget = vmInstance.runtime.getSpriteTargetByName(targetArg);
                                        if (otherTarget) {
                                            const bounds1 = target.getBounds();
                                            const bounds2 = otherTarget.getBounds();
                                            isTouching = bounds1.intersects(bounds2);
                                        }
                                    }
                                    
                                    // Send result back through WebSocket
                                    if (ws) {
                                        ws.send(JSON.stringify({
                                            type: 'sensing_touching_result',
                                            data: {
                                                id: message.data.id,
                                                touching: isTouching
                                            }
                                        }));
                                    }
                                }
                            } catch (error) {
                                console.error('Touching check error:', error);
                                // Send error result
                                if (ws) {
                                    ws.send(JSON.stringify({
                                        type: 'sensing_touching_result',
                                        data: {
                                            id: message.data.id,
                                            touching: false
                                        }
                                    }));
                                }
                            }
                            break;
                        case 'scratch/sensing_distanceto':
                            try {
                                const target = sprites.get(message.data.id);
                                if (target && vmInstance) {
                                    let distance = 10000;

                                    if (message.data.target === '_mouse_') {
                                        // Get mouse position
                                        const mouseX = vmInstance.runtime.ioDevices.mouse.getScratchX();
                                        const mouseY = vmInstance.runtime.ioDevices.mouse.getScratchY();
                                        
                                        // Calculate distance to mouse
                                        const dx = target.x - mouseX;
                                        const dy = target.y - mouseY;
                                        distance = Math.sqrt((dx * dx) + (dy * dy));
                                    } else {
                                        // Find target sprite
                                        const otherTarget = vmInstance.runtime.getSpriteTargetByName(message.data.target);
                                        if (otherTarget) {
                                            const dx = target.x - otherTarget.x;
                                            const dy = target.y - otherTarget.y;
                                            distance = Math.sqrt((dx * dx) + (dy * dy));
                                        }
                                    }
                                    
                                    // Send result back
                                    if (ws) {
                                        ws.send(JSON.stringify({
                                            type: 'sensing_distanceto_result',
                                            data: {
                                                id: message.data.id,
                                                distance: distance
                                            }
                                        }));
                                    }
                                }
                            } catch (error) {
                                console.error('Distance check error:', error);
                                if (ws) {
                                    ws.send(JSON.stringify({
                                        type: 'sensing_distanceto_result',
                                        data: {
                                            id: message.data.id,
                                            distance: 10000,
                                            error: error.message
                                        }
                                    }));
                                }
                            }
                            break;
                        case 'scratch/sensing_mousex':
                            try {
                                if (vmInstance && vmInstance.runtime) {
                                    const mouseX = vmInstance.runtime.ioDevices.mouse.getScratchX();
                                    
                                    if (ws) {
                                        ws.send(JSON.stringify({
                                            type: 'scratch/sensing_mousex_result',
                                            data: {
                                                id: message.data.id,
                                                x: mouseX
                                            }
                                        }));
                                    }
                                }
                            } catch (error) {
                                console.error('Mouse x position error:', error);
                                if (ws) {
                                    ws.send(JSON.stringify({
                                        type: 'scratch/sensing_mousex_result',
                                        data: {
                                            id: message.data.id,
                                            x: 0,
                                            error: error.message
                                        }
                                    }));
                                }
                            }
                            break;

                        case 'scratch/sensing_mousey':
                            try {
                                if (vmInstance && vmInstance.runtime) {
                                    const mouseY = vmInstance.runtime.ioDevices.mouse.getScratchY();
                                    
                                    if (ws) {
                                        ws.send(JSON.stringify({
                                            type: 'scratch/sensing_mousey_result',
                                            data: {
                                                id: message.data.id,
                                                y: mouseY
                                            }
                                        }));
                                    }
                                }
                            } catch (error) {
                                console.error('Mouse y position error:', error);
                                if (ws) {
                                    ws.send(JSON.stringify({
                                        type: 'scratch/sensing_mousey_result',
                                        data: {
                                            id: message.data.id,
                                            y: 0,
                                            error: error.message
                                        }
                                    }));
                                }
                            }
                            break;
                            


                            
                        
                        case 'scratch/play_sound':
                            this.handlePlaySound(message.data);
                            break;
                    }
                } catch (error) {
                    console.error('Message handling error:', error);
                }
            };
        },

        handleCreateSprite: function(data) {
            if (!vmInstance || !vmInstance.runtime) return;

            try {
                // Create new sprite from default
                const newSprite = {
                    name: data.name,
                    isStage: false,
                    x: data.x,
                    y: data.y,
                    visible: true,
                    size: 100,
                    direction: 90,
                    draggable: true,
                    currentCostume: 0,
                    costume: {
                        name: 'costume1',
                        bitmapResolution: 1,
                        rotationCenterX: 0,
                        rotationCenterY: 0
                    }
                };

                const target = vmInstance.runtime.makeSprite(newSprite, vmInstance.runtime.targets[0]);
                sprites.set(data.id, target);

                // Position sprite
                target.setXY(data.x, data.y);
                vmInstance.runtime.requestTargetsUpdate(target);
                vmInstance.runtime.requestRedraw();

                console.log('Created sprite:', target);
            } catch (error) {
                console.error('Sprite creation error:', error);
            }
        },

        handleMoveSprite: function(data) {
            if (!vmInstance || !vmInstance.runtime) return;

            try {
                const target = sprites.get(data.id);
                if (target) {
                    target.setXY(data.x, data.y);
                    vmInstance.runtime.requestTargetsUpdate(target);
                    vmInstance.runtime.requestRedraw();
                    console.log('Moved sprite:', data);
                }
            } catch (error) {
                console.error('Sprite movement error:', error);
            }
        },

        handleRotateSprite: function(data) {
            if (!vmInstance || !vmInstance.runtime) return;

            try {
                const target = sprites.get(data.id);
                if (target) {
                    target.setDirection(data.angle);
                    vmInstance.runtime.requestTargetsUpdate(target);
                    vmInstance.runtime.requestRedraw();
                    console.log('Rotated sprite:', data);
                }
            } catch (error) {
                console.error('Sprite rotation error:', error);
            }
        },

        handleSaySprite: function(data) {
            if (!vmInstance || !vmInstance.runtime) return;

            try {
                const target = sprites.get(data.id);
                if (target) {
                    if (data.seconds) {
                        // Say for specific duration
                        target.sayForSeconds(data.message, data.seconds);
                    } else {
                        // Say indefinitely
                        target.say(data.message);
                    }
                    vmInstance.runtime.requestTargetsUpdate(target);
                    console.log('Sprite saying:', data);
                }
            } catch (error) {
                console.error('Sprite say error:', error);
            }
        },

        handleThinkSprite: function(data) {
            if (!vmInstance || !vmInstance.runtime) return;

            try {
                const target = sprites.get(data.id);
                if (target) {
                    // Create bubble
                    vmInstance.runtime.emit('SAY', target, 'think', data.message);
                    
                    if (data.seconds) {
                        // Clear bubble after duration
                        setTimeout(() => {
                            vmInstance.runtime.emit('SAY', target, 'think', '');
                        }, data.seconds * 1000);
                    }
                    
                    vmInstance.runtime.requestTargetsUpdate(target);
                    console.log('Sprite thinking:', data);
                }
            } catch (error) {
                console.error('Sprite think error:', error);
            }
        },

        handleMoveSteps: function(data) {
            if (!vmInstance || !vmInstance.runtime) return;

            try {
                const target = sprites.get(data.id);
                if (target) {
                    // Get motion blocks instance
                    const motionBlocks = new vmInstance.runtime._blockPackages.scratch3_motion(vmInstance.runtime);
                    
                    // Use moveSteps block
                    motionBlocks.moveSteps({
                        STEPS: data.steps
                    }, {
                        target: target,
                        runtime: vmInstance.runtime
                    });
                    
                    vmInstance.runtime.requestTargetsUpdate(target);
                    vmInstance.runtime.requestRedraw();
                    console.log('Moved steps:', data);
                }
            } catch (error) {
                console.error('Move steps error:', error);
            }
        },

        handleAskSprite: function(data) {
            if (!vmInstance || !vmInstance.runtime) return;

            try {
                const target = sprites.get(data.id);
                if (target) {
                    // Show question
                    vmInstance.runtime.emit('QUESTION', data.question);
                    vmInstance.runtime.emit('SAY', target, 'say', data.question);
                    
                    // Set up answer handler
                    vmInstance.runtime.once('ANSWER', (answer) => {
                        if (answer !== null && answer !== undefined) {
                            // Send answer back in same format as other messages
                            if (ws && ws.readyState === WebSocket.OPEN) {
                                ws.send(JSON.stringify({
                                    type: 'scratch/answer_value',
                                    data: {
                                        id: data.id,
                                        answer: answer,
                                        status: 'success'
                                    }
                                }));
                            }
                            
                            // Clear question
                            vmInstance.runtime.emit('QUESTION', null);
                            vmInstance.runtime.emit('SAY', target, 'say', '');
                        }
                    });
                }
            } catch (error) {
                console.error('Sprite ask error:', error);
            }
        },

        handleGetAnswer: function(data) {
            if (!vmInstance || !vmInstance.runtime) return;

            try {
                // Get answer from runtime
                const answer = vmInstance.runtime._answer;
                
                // Send answer back in same format
                if (ws && ws.readyState === WebSocket.OPEN) {
                    ws.send(JSON.stringify({
                        type: 'scratch/answer_value',
                        data: {
                            id: data.id,
                            answer: answer,
                            status: 'success'
                        }
                    }));
                }
            } catch (error) {
                console.error('Get answer error:', error);
            }
        },

        handleSetRotationStyle: function(data) {
            if (!vmInstance || !vmInstance.runtime) return;

            try {
                const target = sprites.get(data.id);
                if (target) {
                    // Get motion blocks instance
                    const motionBlocks = new vmInstance.runtime._blockPackages.scratch3_motion(vmInstance.runtime);
                    
                    // Convert style string to Scratch constant
                    let rotationStyle = 'all around';
                    switch (data.style) {
                        case 'left-right':
                            rotationStyle = 'left-right';
                            break;
                        case 'don\'t rotate':
                            rotationStyle = 'none';
                            break;
                        default:
                            rotationStyle = 'all around';
                    }
                    
                    // Use setRotationStyle block
                    motionBlocks.setRotationStyle({
                        STYLE: rotationStyle
                    }, {
                        target: target,
                        runtime: vmInstance.runtime
                    });
                    
                    vmInstance.runtime.requestTargetsUpdate(target);
                    vmInstance.runtime.requestRedraw();
                    console.log('Set rotation style:', data);
                }
            } catch (error) {
                console.error('Set rotation style error:', error);
            }
        },

       


       
        handlePlaySound: function(data) {
            if (!vmInstance || !vmInstance.runtime) return;

            try {
                const target = sprites.get(data.id);
                if (target) {
                    // Get sound blocks instance
                    const soundBlocks = new vmInstance.runtime._blockPackages.scratch3_sound(vmInstance.runtime);
                    
                    // Play the sound
                    if (data.wait) {
                        // Use play sound until done block
                        soundBlocks.playSoundAndWait({
                            SOUND_MENU: data.sound
                        }, {
                            target: target,
                            runtime: vmInstance.runtime
                        });
                    } else {
                        // Use play sound block
                        soundBlocks.playSound({
                            SOUND_MENU: data.sound
                        }, {
                            target: target,
                            runtime: vmInstance.runtime
                        });
                    }
                    
                    console.log('Playing sound:', data);
                }
            } catch (error) {
                console.error('Play sound error:', error);
            }
        },

        cleanup: function() {
            if (vmInstance && vmInstance.runtime) {
                vmInstance.runtime.removeAllListeners('ANSWER_VALUE');
            }
            if (ws) {
                ws.close();
                ws = null;
            }
            sprites.clear();
            vmInstance = null;
        }
    };

    return bridge;
}

