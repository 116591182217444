import React from 'react';
import AvrgirlArduino from 'avrgirl-arduino';
import { ESPLoader, Transport } from 'esptool-js';
import { string } from "prop-types";
import apiClient from "../../utils/apiClient.js";

const Scratch3RoboticArmBlocks = require('../../../../stemblocks-VM/src/extensions/Robotic_Arm/index');
const Scratch3ArduinoUnoBlocks = require('../../../../stemblocks-VM/src/extensions/scratch3_arduino/index.js');
const Scratch3ArduinoRoboticCarBlocks = require('../../../../stemblocks-VM/src/extensions/scratch3_car/index.js');
const Scratch3RoboticSpiderBlocks = require('../../../../stemblocks-VM/src/extensions/Robotic_Spider/index.js');
const Scratch3SensorsBlocks = require('../../../../stemblocks-VM/src/extensions/sensors/index.js');
const Scratch3ActuatorsBlocks = require('../../../../stemblocks-VM/src/extensions/actuators/index.js');
const Scratch3DisplayBlocks = require('../../../../stemblocks-VM/src/extensions/display_modules/index.js');
const StemEngineBlocks = require('../../../../stemblocks-VM/src/extensions/stemengine/index.js');
const PythonImportsExtension = require('../../../../stemblocks-VM/src/extensions/python_blocks/imports.js');
const PythonRobo_car = require('../../../../stemblocks-VM/src/extensions/python_blocks/robotic_car.js');
const pythonRobo_arm = require('../../../../stemblocks-VM/src/extensions/python_blocks/robotic_arm.js');
const codeGeneration = require('../../../../stemblocks-VM/src/extensions/codeGeneration.js');

async function flashESP32(port, firmwareBuffer) {
    let transport = null;
    let espLoader = null;
    
    try {
        console.log("Starting ESP32 flash process...");
        
        // Convert buffer to required format
        const uint8Array = new Uint8Array(firmwareBuffer);
        const binaryString = Array.from(uint8Array, byte => String.fromCharCode(byte)).join('');
        
        // Initialize transport and loader
        transport = new Transport(port);
        espLoader = new ESPLoader(
            { transport },
            {
                baudrate: 115200,
                debug: true,
                timeout: 5000,
            }
        );

        console.log("ESPLoader initialized");

        // Let ESPLoader handle the connection
        let retries = 5;
        while (retries > 0) {
            try {
                await espLoader.detectChip();
                console.log("Connected to ESP32");
                break;
            } catch (error) {
                retries--;
                console.warn(`Chip detection failed. Retries left: ${retries}`, error);
                await new Promise(resolve => setTimeout(resolve, 1000));
                
                if (retries === 0) {
                    throw new Error("Failed to detect chip after multiple attempts");
                }
            }
        }

        console.log("Running stub...");
        await espLoader.runStub();

        console.log("Starting flash process...");
        await espLoader.writeFlash({
            fileArray: [
                {
                    data: binaryString,
                    address: 0x0000,
                }
            ],
            flashSize: "keep",
            flashMode: "QIO",
            flashFreq: "40MHz",
            eraseAll: false,
            compress: true
        });
        
        console.log("Firmware flashing completed successfully");
        
    } catch (error) {
        console.error("Complete flashing process failed:", error);
        throw error;
    } finally {
        try {
            // First do hard reset
            if (espLoader) {
                await espLoader.hardReset(true);
                console.log("Hard reset completed");
            }

            // Then disconnect transport
            if (transport) {
                await transport.disconnect();
                await transport.waitForUnlock(1500); // Wait for transport to unlock
                console.log("Transport disconnected successfully");
            }

            // Clear references
            transport = null;
            espLoader = null;
            alert("ESP32 flashed successfully");
        } catch (cleanupError) {
            console.warn("Error during cleanup:", cleanupError);
        }
    }
}

async function flashArduinoUno(firmwareBuffer) {
    console.log("Flashing Arduino Uno...");
    
    // Create new instance with correct constructor
    const avrgirl = new AvrgirlArduino({
        board: 'uno',
        // Optional: Add debug for more detailed logging
        debug: true,
        // Optional: Specify port if needed
        // port: '/dev/ttyUSB0' // Uncomment and modify if you need to specify port
    });

    return new Promise((resolve, reject) => {
        avrgirl.flash(firmwareBuffer, (error) => {
            if (error) {
                console.error('Error flashing Arduino board:', error);
                reject(error);
            } else {
                console.log('Code successfully burned to the Arduino board');
                resolve();
            }
        });
    });
}

function resetAllBlocks(...blocks) {
    blocks.forEach(block => block.full_reset && block.full_reset());
    codeGeneration.resetCode();
    console.log('Code has been reset');
}

async function generateAndUploadCode(serialport) {
    // Retrieve selected board from localStorage
    let selectedBoardObject = localStorage.getItem('selectedBoard');
    if (!selectedBoardObject) {
        alert("Please select a board before proceeding.");
        return;
    }
    selectedBoardObject = JSON.parse(selectedBoardObject);
    const selectedBoard = selectedBoardObject.name || '';
    const isESP32 = selectedBoard.toLowerCase() === 'esp32';
    const deviceType = isESP32 ? 'esp32' : 'arduino';
    const boardConfig = isESP32 ? { board: 'esp32:esp32:esp32' } : { board: 'arduino:avr:uno' };

    console.log("Selected Board:", selectedBoard);

    // Instantiate block classes
    const arduinoBlocks = new Scratch3ArduinoUnoBlocks();
    const armBlocks = new Scratch3RoboticArmBlocks();
    const carBlocks = new Scratch3ArduinoRoboticCarBlocks();
    const spiderBlocks = new Scratch3RoboticSpiderBlocks();
    const sensorBlock = new Scratch3SensorsBlocks();
    const actuatorBlock = new Scratch3ActuatorsBlocks();
    const displayBlock = new Scratch3DisplayBlocks();
    const stemEngine = new StemEngineBlocks();
    const pythonImports = new PythonImportsExtension();
    const pythonRoboCar = new PythonRobo_car();
    const pythonRoboArm = new pythonRobo_arm();

    const finalCode = codeGeneration.generateFinalCode();
    console.log('Generated Code:', finalCode);

    try {
        // Request the server to compile and prepare the firmware file for download
        const response = await apiClient.post('/iot/burnCode', {
            code: finalCode,
            deviceType,
            board: boardConfig.board
        }, {
            responseType: 'blob' // Important: Download file as blob
        });

        console.log("Firmware file received from server");

        // Convert Blob to ArrayBuffer
        const firmwareBuffer = await response.data.arrayBuffer();

        if (selectedBoard === 'Arduino Uno') {
            await flashArduinoUno(firmwareBuffer);
        } else if (isESP32) {
            if (serialport) {
                await flashESP32(serialport, firmwareBuffer);
            } else {
                alert("Failed to open serial port for ESP32");
            }
        } else {
            alert("Unsupported board type. Please select a valid board.");
        }
    } catch (error) {
        console.error('Error during flashing:', error);
        alert(`Error: ${error.message}`);
    } finally {
        resetAllBlocks(arduinoBlocks, armBlocks, carBlocks, spiderBlocks, sensorBlock, actuatorBlock, displayBlock, stemEngine, pythonImports, pythonRoboCar, pythonRoboArm);
    }
}


export default generateAndUploadCode;
