import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import tabStyles from 'react-tabs/style/react-tabs.css';
import VM from 'scratch-vm';
import Renderer from 'scratch-render';
import { LayoutTemplate, CircleXIcon } from 'lucide-react';

import PythonIDE from './code-editor.jsx';
import Blocks from '../../containers/blocks.jsx';
import CostumeTab from '../../containers/costume-tab.jsx';
import TargetPane from '../../containers/target-pane.jsx';
import SoundTab from '../../containers/sound-tab.jsx';
import StageWrapper from '../../containers/stage-wrapper.jsx';
import Loader from '../loader/loader.jsx';
import Box from '../box/box.jsx';
import MenuBar from '../menu-bar/menu-bar.jsx';
import CostumeLibrary from '../../containers/costume-library.jsx';
import BackdropLibrary from '../../containers/backdrop-library.jsx';
import Watermark from '../../containers/watermark.jsx';
import Backpack from '../../containers/backpack.jsx';
// import WebGlModal from '../../containers/webgl-modal.jsx';
import TipsLibrary from '../../containers/tips-library.jsx';
import Cards from '../../containers/cards.jsx';
import Alerts from '../../containers/alerts.jsx';
import DragLayer from '../../containers/drag-layer.jsx';
import ConnectionModal from '../../containers/connection-modal.jsx';
import TelemetryModal from '../telemetry-modal/telemetry-modal.jsx';

import layout, { STAGE_SIZE_MODES } from '../../lib/layout-constants';
import { resolveStageSize } from '../../lib/screen-utils';
import { themeMap } from '../../lib/themes';

import styles from './gui.css';
// import { Button } from '../../components/ui/button'
// import { Card } from '../../components/ui/card'
// import { Input } from '../../components/ui/input'
// import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../components/ui/tabs'
import addExtensionIcon from './icon--extensions.svg';
import codeIcon from './icon--code.svg';
import costumesIcon from './icon--costumes.svg';
import soundsIcon from './icon--sounds.svg';
import pythonIcon from './icon--python.svg';
import ResetPasswordForm from '../menu-bar/reset-password.jsx';

import LandingPage from './stemverse-home.jsx';
import LoginDropdown from '../../components/menu-bar/login-dropdown.jsx';
import MathUtil from '../../../../stemblocks-VM/src/util/math-util';

const messages = defineMessages({
    addExtension: {
        id: 'gui.gui.addExtension',
        description: 'Button to add an extension in the target pane',
        defaultMessage: 'Add Extension'
    }
});

// let isRendererSupported = null;

const KEY_CODES = {
    space: 32,
    'left arrow': 37,
    'up arrow': 38,
    'right arrow': 39,
    'down arrow': 40,
    enter: 13
};

const GUIComponent = props => {
    const {
        accountNavOpen,
        activeTabIndex,
        alertsVisible,
        authorId,
        authorThumbnailUrl,
        authorUsername,
        basePath,
        backdropLibraryVisible,
        backpackHost,
        backpackVisible,
        blocksId,
        blocksTabVisible,
        cardsVisible,
        canChangeLanguage,
        audioVideoSetting,
        canChangeTheme,
        canCreateNew,
        canEditTitle,
        canManageFiles,
        canRemix,
        canSave,
        canCreateCopy,
        canShare,
        canUseCloud,
        children,
        connectionModalVisible,
        costumeLibraryVisible,
        costumesTabVisible,
        enableCommunity,
        intl,
        isCreating,
        isFullScreen,
        isPlayerOnly,
        isRtl,
        isShared,
        isTelemetryEnabled,
        isTotallyNormal,
        loading,
        logo,
        renderLogin,
        onClickAbout,
        onClickAccountNav,
        onCloseAccountNav,
        onLogOut,
        onOpenRegistration,
        onToggleLoginOpen,
        onActivateCostumesTab,
        onActivateSoundsTab,
        onPythontab,
        onActivateTab,
        onClickLogo,
        onExtensionButtonClick,
        onProjectTelemetryEvent,
        onRequestCloseBackdropLibrary,
        onRequestCloseCostumeLibrary,
        onRequestCloseTelemetryModal,
        onSeeCommunity,
        onShare,
        onShowPrivacyPolicy,
        onStartSelectingFileUpload,
        onTelemetryModalCancel,
        onTelemetryModalOptIn,
        onTelemetryModalOptOut,
        showComingSoon,
        soundsTabVisible,
        pythonTabVisible,
        stageSizeMode,
        targetIsStage,
        telemetryModalVisible,
        theme,
        tipsLibraryVisible,
        vm,
        ...componentProps
    } = props;

    const [isModalOpen, setIsModalOpen] = useState(true);

    const [isRendererSupported, setIsRendererSupported] = useState(Renderer.isSupported());
    const [isSimulationOpen, setIsSimulationOpen] = useState(false);

    const handleToggleSimulation = () => {
        const newSimulationState = !isSimulationOpen;
        setIsSimulationOpen(newSimulationState);
        setIsRendererSupported(!newSimulationState);
    };
    const [isLoginOpen, setIsLoginOpen] = useState(false);

    // Function to close the main modal
    // const handleCloseModal = () => {
    //     setIsModalOpen(false);
    //     if (timeoutId) {
    //         clearTimeout(timeoutId);
    //     }
    // };
    // Function to handle option click in the modal
    const handleOptionClick = (option) => {
        onExtensionButtonClick();
        console.log('Option clicked:', option);

        // Close the main modal
        setIsModalOpen(false);

        // After closing the main modal, set a timeout to open the login modal after 2 seconds
        // setTimeout(() => {
        //     if(localStorage.getItem('isLoginModalOpen') === false || localStorage.getItem('isLoginModalOpen') === null){
        //         setIsLoginOpen(true);
        //         console.log("LoginDropdown should open now."); // Check if this logs
        //     }
        // }, 2000); // 2000 milliseconds = 2 seconds
    };

    // Class names for tabs styling
    const tabClassNames = {
        tabs: styles.tabs,
        tab: `${tabStyles.reactTabsTab} ${styles.tab}`,
        tabList: `${tabStyles.reactTabsTabList} ${styles.tabList}`,
        tabPanel: `${tabStyles.reactTabsTabPanel} ${styles.tabPanel}`,
        tabPanelSelected: `${tabStyles.reactTabsTabPanelSelected} ${styles.isSelected}`,
        tabSelected: `${tabStyles.reactTabsTabSelected} ${styles.isSelected}`
    };

    useEffect(() => {
        localStorage?.setItem('isLoginModalOpen', true);
    }, []);

    const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] = useState(false);
    const [resetToken, setResetToken] = useState(null);

    // Add this useEffect to handle reset password
    useEffect(() => {
        // Define the global handler
        window.handleResetLinkClick = (token) => {
            setResetToken(token);
            setIsResetPasswordModalOpen(true);
        };

        // Check URL for reset token
        const url = new URL(window.location.href);
        const urlToken = url.searchParams.get('reset_token');
        if (urlToken) {
            window.handleResetLinkClick(urlToken);
        }

        // Cleanup
        return () => {
            delete window.handleResetLinkClick;
        };
    }, []);

    if (isResetPasswordModalOpen) {
        return (
            <div
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <div className="w-full max-w-md bg-white/90 backdrop-blur-md shadow-xl rounded-lg p-6">
                    <div className="flex justify-between items-center mb-4">
                        <h2 className="text-2xl font-bold">Reset Password</h2>
                        {/* <button
                            onClick={() => setIsResetPasswordModalOpen(false)}
                            className="text-gray-500 hover:text-gray-700"
                        >
                            <CircleXIcon className="h-6 w-6" />
                        </button> */}
                    </div>
                    <ResetPasswordForm
                        token={resetToken}
                        onSuccess={() => {
                            setIsResetPasswordModalOpen(false);
                            alert('Password reset successfully! Please login with your new password.');
                        }}
                    />
                </div>
            </div>
        );
    }

    const [pythonFiles, setPythonFiles] = useState(() => {
        const savedFiles = localStorage.getItem('pythonFiles');
        if (savedFiles) {
            return JSON.parse(savedFiles);
        }
        return [{
            id: 'main',
            name: 'main.py',
            content: `# Online Python compiler (interpreter) to run Python online.
# Write Python 3 code in this online editor and run it.
print("Stem World")`,
            isDefault: true,
            type: 'file'
        }];
    });

    // Add function to handle file updates
    const handlePythonFilesChange = (newFiles) => {
        setPythonFiles(newFiles);
    };

    const [socket, setSocket] = useState(null);
    const [isConnected, setIsConnected] = useState(false);

    useEffect(() => {
        // Initialize WebSocket connection
        const ws = new WebSocket('wss://api.stemverse.app');//wss://api.stemverse.app

        ws.onopen = () => {
            console.log('WebSocket Connected');
            setIsConnected(true);
            setSocket(ws);
        };

        ws.onmessage = (event) => {
            // Handle incoming messages
            const message = JSON.parse(event.data);
            handleWebSocketMessage(message);
        };

        ws.onerror = (error) => {
            console.error('WebSocket Error:', error);
        };

        ws.onclose = () => {
            console.log('WebSocket Disconnected');
            setIsConnected(false);
        };

        // Cleanup on unmount
        return () => {
            if (ws) {
                ws.close();
            }
        };
    }, []);

    const handleWebSocketMessage = (message) => {
        try {
            switch (message.type) {
                case 'simulation':
                    props.vm.postIOData('simulation', message.data);
                    break;
                case 'control':
                    props.vm.postIOData('control', message.data);
                    break;
                case 'scratch/create_sprite':
                case 'create_sprite':
                    // Get stage target first
                    const stage = props.vm.runtime.getTargetForStage();
                    if (stage) {
                        const spriteTargets = props.vm.runtime.targets.filter(t => !t.isStage);
                        if (spriteTargets.length > 0) {
                            const sourceTarget = spriteTargets[0];
                            const newTarget = props.vm.runtime.cloneTarget(sourceTarget, stage);
                            if (newTarget) {
                                newTarget.setXY(message.data.x, message.data.y);
                                props.vm.runtime.requestTargetsUpdate(newTarget);
                            }
                        }
                    }
                    break;
                case 'scratch/move_sprite':
                case 'move_sprite':
                    const targets = props.vm.runtime.targets.filter(t => !t.isStage);
                    if (targets.length > 0) {
                        const target = targets[0];
                        target.setXY(message.data.x, message.data.y);
                        props.vm.runtime.requestTargetsUpdate(target);
                    }
                    break;
                case 'scratch/rotate_sprite':
                case 'rotate_sprite':
                    // Get first non-stage sprite
                    const rotateTargets = props.vm.runtime.targets.filter(t => !t.isStage);
                    if (rotateTargets.length > 0) {
                        const target = rotateTargets[0];
                        // Use turn block functionality
                        target.setDirection(message.data.angle);
                        props.vm.runtime.requestTargetsUpdate(target);
                    }
                    break;
                case 'scratch/ask_sprite':
                case 'ask_sprite':
                    const askTargets = props.vm.runtime.targets.filter(t => !t.isStage);
                    if (askTargets.length > 0) {
                        try {
                            const target = askTargets[0];

                            // Show question
                            props.vm.runtime.emit('QUESTION', message.data.question);
                            props.vm.runtime.emit('SAY', target, 'say', message.data.question);

                            // Create answer handler with direct WebSocket connection
                            const handleAnswer = answer => {
                                console.log('Answer received:', answer);

                                // Store answer in VM state
                                props.vm.runtime._answer = answer;

                                // Create new WebSocket connection for sending answer
                                const answerSocket = new WebSocket('wss://api.stemverse.app'); //wss://api.stemverse.app

                                answerSocket.onopen = () => {
                                    // Send answer once connected
                                    answerSocket.send(JSON.stringify({
                                        type: 'scratch/answer_value',
                                        data: {
                                            id: message.data.id,
                                            answer: answer
                                        }
                                    }));

                                    // Close socket after sending
                                    answerSocket.close();
                                };

                                // Clear question bubble
                                props.vm.runtime.emit('QUESTION', null);
                                props.vm.runtime.emit('SAY', target, 'say', '');

                                // Update VM
                                props.vm.runtime.requestTargetsUpdate(target);

                                // Remove this handler
                                props.vm.runtime.removeListener('ANSWER', handleAnswer);
                            };

                            // Setup answer handler
                            props.vm.runtime.removeAllListeners('ANSWER');
                            props.vm.runtime.once('ANSWER', handleAnswer);

                        } catch (error) {
                            console.error('Sprite ask error:', error);
                        }
                    }
                    break;
                case 'scratch/say_sprite':
                case 'say_sprite':
                    // Ignore any null message say_sprite requests
                    if (!message.data.message) return;

                    const sayTargets = props.vm.runtime.targets.filter(t => !t.isStage);
                    if (sayTargets.length > 0) {
                        const target = sayTargets[0];
                        props.vm.runtime.emit('SAY', target, 'say', message.data.message);
                        if (message.data.seconds) {
                            setTimeout(() => {
                                props.vm.runtime.emit('SAY', target, 'say', '');
                            }, message.data.seconds * 1000);
                        }
                    }
                    break;
                case 'scratch/think_sprite':
                case 'think_sprite':
                    const thinkTargets = props.vm.runtime.targets.filter(t => !t.isStage);
                    if (thinkTargets.length > 0) {
                        const target = thinkTargets[0];


                        // Create think bubble
                        props.vm.runtime.emit('SAY', target, 'think', message.data.message);


                        if (message.data.seconds) {
                            // Clear bubble after duration
                            setTimeout(() => {
                                props.vm.runtime.emit('SAY', target, 'think', '');
                            }, message.data.seconds * 1000);
                        }


                        props.vm.runtime.requestTargetsUpdate(target);
                    }
                    break;
                case 'scratch/move_steps':
                case 'move_steps':
                    const moveStepsTargets = props.vm.runtime.targets.filter(t => !t.isStage);
                    if (moveStepsTargets.length > 0) {
                        const target = moveStepsTargets[0];


                        // Calculate new position based on steps and direction
                        const steps = message.data.steps;
                        const direction = target.direction;

                        // Convert direction to radians and calculate position change
                        const radians = MathUtil.degToRad(90 - direction);
                        const dx = steps * Math.cos(radians);
                        const dy = steps * Math.sin(radians);

                        // Update position
                        target.setXY(target.x + dx, target.y + dy);

                        // Update rendering
                        target.updateAllDrawableProperties();
                        props.vm.runtime.requestTargetsUpdate(target);
                        props.vm.runtime.requestRedraw();
                    }
                    break;
                case 'scratch/glide_to_xy':
                case 'glide_to_xy':
                    const glideTargets = props.vm.runtime.targets.filter(t => !t.isStage);
                    if (glideTargets.length > 0) {
                        const target = glideTargets[0];

                        // Start glide animation
                        const startX = target.x;
                        const startY = target.y;
                        const endX = message.data.x;
                        const endY = message.data.y;
                        const duration = message.data.secs * 1000; // Convert to milliseconds
                        const startTime = Date.now();

                        const animate = () => {
                            const elapsed = Date.now() - startTime;
                            const progress = Math.min(elapsed / duration, 1);

                            // Calculate current position using linear interpolation
                            const currentX = startX + (endX - startX) * progress;
                            const currentY = startY + (endY - startY) * progress;

                            // Update sprite position
                            target.setXY(currentX, currentY);
                            props.vm.runtime.requestTargetsUpdate(target);
                            props.vm.runtime.requestRedraw();

                            // Continue animation if not complete
                            if (progress < 1) {
                                requestAnimationFrame(animate);
                            }
                        };

                        // Start animation
                        animate();
                    }
                    break;
                case 'scratch/next_costume':
                case 'next_costume':
                    const costumeTargets = props.vm.runtime.targets.filter(t => !t.isStage);
                    if (costumeTargets.length > 0) {
                        const target = costumeTargets[0];

                        // Get current costume index
                        const currentCostume = target.currentCostume;
                        const numCostumes = target.getCostumes().length;

                        // Set next costume (wrap around if at end)
                        const nextCostume = (currentCostume + 1) % numCostumes;
                        target.setCostume(nextCostume);

                        props.vm.runtime.requestTargetsUpdate(target);
                    }
                    break;
                case 'scratch/bounce_on_edge':
                case 'bounce_on_edge':
                    const bounceTargets = props.vm.runtime.targets.filter(t => !t.isStage);
                    if (bounceTargets.length > 0) {
                        const target = bounceTargets[0];

                        // Get stage size
                        const stageWidth = props.vm.runtime.constructor.STAGE_WIDTH;
                        const stageHeight = props.vm.runtime.constructor.STAGE_HEIGHT;

                        // Get sprite bounds
                        const bounds = target.getBounds();

                        // Check if sprite is touching any edge
                        const touchingEdge = {
                            left: target.x - (bounds.width / 2) <= -stageWidth / 2,
                            right: target.x + (bounds.width / 2) >= stageWidth / 2,
                            top: target.y + (bounds.height / 2) >= stageHeight / 2,
                            bottom: target.y - (bounds.height / 2) <= -stageHeight / 2
                        };

                        if (touchingEdge.left || touchingEdge.right || touchingEdge.top || touchingEdge.bottom) {
                            // Get current direction in radians
                            const currentDirection = MathUtil.degToRad(90 - target.direction);

                            // Calculate bounce direction
                            let dx = Math.cos(currentDirection);
                            let dy = Math.sin(currentDirection);

                            // Reverse direction based on which edge was hit
                            if (touchingEdge.left || touchingEdge.right) {
                                dx = -dx;
                            }
                            if (touchingEdge.top || touchingEdge.bottom) {
                                dy = -dy;
                            }

                            // Convert back to Scratch direction (degrees)
                            const newDirection = 90 - MathUtil.radToDeg(Math.atan2(dy, dx));
                            target.setDirection(newDirection);

                            // Move sprite slightly away from edge to prevent sticking
                            const bounceOffset = 1;
                            if (touchingEdge.left) {
                                target.setXY(-stageWidth / 2 + bounds.width / 2 + bounceOffset, target.y);
                            }
                            if (touchingEdge.right) {
                                target.setXY(stageWidth / 2 - bounds.width / 2 - bounceOffset, target.y);
                            }
                            if (touchingEdge.bottom) {
                                target.setXY(target.x, -stageHeight / 2 + bounds.height / 2 + bounceOffset);
                            }
                            if (touchingEdge.top) {
                                target.setXY(target.x, stageHeight / 2 - bounds.height / 2 - bounceOffset);
                            }

                            // Update sprite
                            props.vm.runtime.requestTargetsUpdate(target);
                            props.vm.runtime.requestRedraw();
                        }
                    }
                    break;
                case 'scratch/set_rotation_style':
                case 'set_rotation_style':
                    const rotationTargets = props.vm.runtime.targets.filter(t => !t.isStage);
                    if (rotationTargets.length > 0) {
                        const target = rotationTargets[0];

                        // Convert style string to Scratch constant
                        let rotationStyle = 'all around';
                        switch (message.data.style) {
                            case 'left-right':
                                rotationStyle = 'left-right';
                                break;
                            case 'don\'t rotate':
                                rotationStyle = 'don\'t rotate';
                                break;
                            default:
                                rotationStyle = 'all around';
                        }

                        // Set rotation style
                        target.setRotationStyle(rotationStyle);
                        props.vm.runtime.requestTargetsUpdate(target);
                    }
                    break;
                case 'scratch/go_to_random':
                case 'go_to_random': {
                    const randomTargets = props.vm.runtime.targets.filter(t => !t.isStage);
                    if (randomTargets.length > 0) {
                        const target = randomTargets[0];

                        // Get stage dimensions
                        const stageWidth = props.vm.runtime.constructor.STAGE_WIDTH;
                        const stageHeight = props.vm.runtime.constructor.STAGE_HEIGHT;

                        // Generate random coordinates within stage bounds
                        const randomX = message.data.x || (Math.random() * stageWidth - (stageWidth / 2));
                        const randomY = message.data.y || (Math.random() * stageHeight - (stageHeight / 2));

                        // Move sprite to random position
                        target.setXY(randomX, randomY);
                        props.vm.runtime.requestTargetsUpdate(target);
                        props.vm.runtime.requestRedraw();
                    }
                    break;
                }

                case 'scratch/go_to_mouse':
                case 'go_to_mouse': {
                    const mouseTargets = props.vm.runtime.targets.filter(t => !t.isStage);
                    if (mouseTargets.length > 0) {
                        const target = mouseTargets[0];

                        // Remove existing mouse follow if any
                        if (target._mouseFollowInterval) {
                            clearInterval(target._mouseFollowInterval);
                        }

                        // Set up mouse following
                        target._mouseFollowInterval = setInterval(() => {
                            const mouseData = props.vm.runtime.ioDevices.mouse;
                            if (mouseData && mouseData.getClientX && mouseData.getClientY) {
                                const mouseX = mouseData.getClientX();
                                const mouseY = mouseData.getClientY();

                                target.setXY(mouseX, mouseY);
                                props.vm.runtime.requestTargetsUpdate(target);
                                props.vm.runtime.requestRedraw();
                            }
                        }, 1000 / 30); // Update 30 times per second

                        // Store interval ID for cleanup
                        target.mouseFollowInterval = target._mouseFollowInterval;
                    }
                    break;
                }
                case 'scratch/change_x_by':
                case 'change_x_by': {
                    const positionTargets = props.vm.runtime.targets.filter(t => !t.isStage);
                    if (positionTargets.length > 0) {
                        const target = positionTargets[0];

                        // Get current position and add change
                        const currentX = target.x;
                        const newX = currentX + message.data.dx;

                        // Update position
                        target.setXY(newX, target.y);
                        props.vm.runtime.requestTargetsUpdate(target);
                        props.vm.runtime.requestRedraw();
                    }
                    break;
                }

                case 'scratch/change_y_by':
                case 'change_y_by': {
                    const positionTargets = props.vm.runtime.targets.filter(t => !t.isStage);
                    if (positionTargets.length > 0) {
                        const target = positionTargets[0];

                        // Get current position and add change
                        const currentY = target.y;
                        const newY = currentY + message.data.dy;

                        // Update position
                        target.setXY(target.x, newY);
                        props.vm.runtime.requestTargetsUpdate(target);
                        props.vm.runtime.requestRedraw();
                    }
                    break;
                }
                case 'scratch/set_x':
                case 'set_x': {
                    const positionTargets = props.vm.runtime.targets.filter(t => !t.isStage);
                    if (positionTargets.length > 0) {
                        const target = positionTargets[0];

                        // Set x position directly
                        target.setXY(message.data.x, target.y);
                        props.vm.runtime.requestTargetsUpdate(target);
                        props.vm.runtime.requestRedraw();
                    }
                    break;
                }

                case 'scratch/set_y':
                case 'set_y': {
                    const positionTargets = props.vm.runtime.targets.filter(t => !t.isStage);
                    if (positionTargets.length > 0) {
                        const target = positionTargets[0];

                        // Set y position directly
                        target.setXY(target.x, message.data.y);
                        props.vm.runtime.requestTargetsUpdate(target);
                        props.vm.runtime.requestRedraw();
                    }
                    break;
                }
                case 'scratch/goto_xy':
                case 'goto_xy': {
                    const positionTargets = props.vm.runtime.targets.filter(t => !t.isStage);
                    if (positionTargets.length > 0) {
                        const target = positionTargets[0];

                        // Move sprite directly to x,y coordinates
                        target.setXY(message.data.x, message.data.y);
                        props.vm.runtime.requestTargetsUpdate(target);
                        props.vm.runtime.requestRedraw();
                    }
                    break;
                }
                // Add this case to handleWebSocketMessage switch statement

                case 'scratch/switch_costume':
                case 'switch_costume': {
                    const costumeTargets = props.vm.runtime.targets.filter(t => !t.isStage);
                    if (costumeTargets.length > 0) {
                        const target = costumeTargets[0];

                        // Handle different types of costume inputs
                        const requestedCostume = message.data.costume;

                        if (typeof requestedCostume === 'number') {
                            // Handle costume number (1-based index)
                            target.setCostume(requestedCostume - 1);
                        } else {
                            // Handle costume name or special commands
                            const costumeIndex = target.getCostumeIndexByName(requestedCostume.toString());

                            if (costumeIndex !== -1) {
                                target.setCostume(costumeIndex);
                            } else if (requestedCostume === 'next costume') {
                                target.setCostume(target.currentCostume + 1);
                            } else if (requestedCostume === 'previous costume') {
                                target.setCostume(target.currentCostume - 1);
                            }
                        }

                        props.vm.runtime.requestTargetsUpdate(target);
                        props.vm.runtime.requestRedraw();
                    }
                    break;
                }
                case 'scratch/switch_backdrop':
                case 'switch_backdrop': {
                    const stage = props.vm.runtime.getTargetForStage();
                    if (stage) {
                        // Handle different types of backdrop inputs
                        const requestedBackdrop = message.data.backdrop;

                        if (typeof requestedBackdrop === 'number') {
                            // Numbers are treated as backdrop indices (1-based)
                            stage.setCostume(requestedBackdrop - 1);
                        } else {
                            // Try to find backdrop by name
                            const backdropIndex = stage.getCostumeIndexByName(requestedBackdrop.toString());

                            if (backdropIndex !== -1) {
                                stage.setCostume(backdropIndex);
                            } else if (requestedBackdrop === 'next backdrop') {
                                stage.setCostume(stage.currentCostume + 1);
                            } else if (requestedBackdrop === 'previous backdrop') {
                                stage.setCostume(stage.currentCostume - 1);
                            } else if (requestedBackdrop === 'random backdrop') {
                                const numBackdrops = stage.getCostumes().length;
                                if (numBackdrops > 1) {
                                    const random = Math.floor(Math.random() * numBackdrops);
                                    stage.setCostume(random);
                                }
                            }
                        }

                        props.vm.runtime.requestTargetsUpdate(stage);
                        props.vm.runtime.requestRedraw();
                    }
                    break;
                }

                case 'scratch/next_backdrop':
                case 'next_backdrop': {
                    const stage = props.vm.runtime.getTargetForStage();
                    if (stage) {
                        stage.setCostume(stage.currentCostume + 1);
                        props.vm.runtime.requestTargetsUpdate(stage);
                        props.vm.runtime.requestRedraw();
                    }
                    break;
                }
                // Add these cases to handleWebSocketMessage switch statement

                case 'scratch/change_size':
                case 'change_size': {
                    const targetSprites = props.vm.runtime.targets.filter(t => !t.isStage);
                    if (targetSprites.length > 0) {
                        const target = targetSprites[0];

                        // Change size by specified amount
                        target.setSize(target.size + message.data.change);
                        props.vm.runtime.requestTargetsUpdate(target);
                        props.vm.runtime.requestRedraw();
                    }
                    break;
                }

                case 'scratch/set_size':
                case 'set_size': {
                    const targetSprites = props.vm.runtime.targets.filter(t => !t.isStage);
                    if (targetSprites.length > 0) {
                        const target = targetSprites[0];

                        // Set size directly
                        target.setSize(message.data.size);
                        props.vm.runtime.requestTargetsUpdate(target);
                        props.vm.runtime.requestRedraw();
                    }
                    break;
                }
                // Add these cases to handleWebSocketMessage switch statement

                case 'scratch/change_effect':
                case 'change_effect': {
                    const targetSprites = props.vm.runtime.targets.filter(t => !t.isStage);
                    if (targetSprites.length > 0) {
                        const target = targetSprites[0];
                        const effect = message.data.effect.toLowerCase();

                        // Get current effect value and add change
                        let currentValue = target.effects[effect] || 0;
                        let newValue = currentValue + message.data.change;

                        // Apply effect limits
                        if (effect === 'ghost' || effect === 'brightness') {
                            newValue = Math.max(-100, Math.min(100, newValue));
                        }

                        // Set effect
                        target.setEffect(effect, newValue);
                        props.vm.runtime.requestTargetsUpdate(target);
                        props.vm.runtime.requestRedraw();
                    }
                    break;
                }

                case 'scratch/set_effect':
                case 'set_effect': {
                    const targetSprites = props.vm.runtime.targets.filter(t => !t.isStage);
                    if (targetSprites.length > 0) {
                        const target = targetSprites[0];
                        const effect = message.data.effect.toLowerCase();

                        // Apply effect limits
                        let value = message.data.value;
                        if (effect === 'ghost' || effect === 'brightness') {
                            value = Math.max(-100, Math.min(100, value));
                        }

                        // Set effect
                        target.setEffect(effect, value);
                        props.vm.runtime.requestTargetsUpdate(target);
                        props.vm.runtime.requestRedraw();
                    }
                    break;
                }

                case 'scratch/clear_effects':
                case 'clear_effects': {
                    const targetSprites = props.vm.runtime.targets.filter(t => !t.isStage);
                    if (targetSprites.length > 0) {
                        const target = targetSprites[0];
                        target.clearEffects();
                        props.vm.runtime.requestTargetsUpdate(target);
                        props.vm.runtime.requestRedraw();
                    }
                    break;
                }
                // Add these cases to handleWebSocketMessage switch statement

                case 'scratch/show':
                case 'show': {
                    const targetSprites = props.vm.runtime.targets.filter(t => !t.isStage);
                    if (targetSprites.length > 0) {
                        const target = targetSprites[0];
                        target.setVisible(true);
                        props.vm.runtime.requestTargetsUpdate(target);
                        props.vm.runtime.requestRedraw();
                    }
                    break;
                }

                case 'scratch/hide':
                case 'hide': {
                    const targetSprites = props.vm.runtime.targets.filter(t => !t.isStage);
                    if (targetSprites.length > 0) {
                        const target = targetSprites[0];
                        target.setVisible(false);
                        props.vm.runtime.requestTargetsUpdate(target);
                        props.vm.runtime.requestRedraw();
                    }
                    break;
                }

                case 'scratch/go_to_front':
                case 'go_to_front': {
                    const targetSprites = props.vm.runtime.targets.filter(t => !t.isStage);
                    if (targetSprites.length > 0) {
                        const target = targetSprites[0];
                        target.goToFront();
                        props.vm.runtime.requestTargetsUpdate(target);
                        props.vm.runtime.requestRedraw();
                    }
                    break;
                }

                case 'scratch/go_to_back':
                case 'go_to_back': {
                    const targetSprites = props.vm.runtime.targets.filter(t => !t.isStage);
                    if (targetSprites.length > 0) {
                        const target = targetSprites[0];
                        target.goToBack();
                        props.vm.runtime.requestTargetsUpdate(target);
                        props.vm.runtime.requestRedraw();
                    }
                    break;
                }

                case 'scratch/change_layer':
                case 'change_layer': {
                    const targetSprites = props.vm.runtime.targets.filter(t => !t.isStage);
                    if (targetSprites.length > 0) {
                        const target = targetSprites[0];
                        const layers = message.data.layers;

                        if (layers > 0) {
                            target.goForwardLayers(layers);
                        } else {
                            target.goBackwardLayers(Math.abs(layers));
                        }

                        props.vm.runtime.requestTargetsUpdate(target);
                        props.vm.runtime.requestRedraw();
                    }
                    break;
                }
                // Add these cases to handleWebSocketMessage switch statement

                case 'scratch/sensing_touching': {
                    const targetSprites = props.vm.runtime.targets.filter(t => !t.isStage);
                    if (targetSprites.length > 0) {
                        const target = targetSprites[0];
                        try {
                            let isTouching = false;
                            const targetArg = message.data.target;

                            if (targetArg === '_mouse_') {
                                // Check mouse touching
                                const mouseX = props.vm.runtime.ioDevices.mouse.getScratchX();
                                const mouseY = props.vm.runtime.ioDevices.mouse.getScratchY();
                                const bounds = target.getBounds();
                                isTouching = (
                                    mouseX >= bounds.left &&
                                    mouseX <= bounds.right &&
                                    mouseY >= bounds.bottom &&
                                    mouseY <= bounds.top
                                );
                            } else if (targetArg === '_edge_') {
                                // Check edge touching
                                const bounds = target.getBounds();
                                const stageBounds = { left: -240, right: 240, top: 180, bottom: -180 };
                                isTouching = bounds.left <= stageBounds.left ||
                                    bounds.right >= stageBounds.right ||
                                    bounds.top >= stageBounds.top ||
                                    bounds.bottom <= stageBounds.bottom;
                            } else {
                                // Check sprite touching sprite
                                const otherTarget = props.vm.runtime.getSpriteTargetByName(targetArg);
                                if (otherTarget) {
                                    const bounds1 = target.getBounds();
                                    const bounds2 = otherTarget.getBounds();
                                    isTouching = bounds1.intersects(bounds2);
                                }
                            }

                            console.log(`Touch check: ${targetArg} -> ${isTouching}`); // Debug log

                            const ansSocket = new WebSocket('wss://api.stemverse.app'); //wss://api.stemverse.app
                            // Send result back
                            // if (ansSocket && isConnected) {
                            ansSocket.onopen = () => {
                                ansSocket.send(JSON.stringify({
                                    type: 'sensing_touching_result',
                                    data: {
                                        id: message.data.id,
                                        touching: isTouching
                                    }
                                }));

                                ansSocket.close();
                            };
                            // }
                        } catch (error) {
                            console.error('Touching check error:', error);
                        }
                    }
                    break;
                }
               
                case 'scratch/answer_value':
                    try {
                        // Get sensing blocks instance
                        const answer = props.vm.runtime._answer;

                        console.log('Current answer value:', answer);

                        // Send answer back through WebSocket
                        if (socket && isConnected) {
                            socket.send(JSON.stringify({
                                type: 'scratch/answer_value',
                                data: {
                                    id: message.data.id,
                                    answer: answer
                                }
                            }));
                        }
                    } catch (error) {
                        console.error('Error getting answer:', error);
                    }
                    break;
                // Add to handleWebSocketMessage switch statement
                case 'scratch/sensing_distanceto': {
                    try {
                        const targetSprites = props.vm.runtime.targets.filter(t => !t.isStage);
                        if (targetSprites.length > 0) {
                            const target = targetSprites[0];
                            let distance = 10000;
                            const targetArg = message.data.target;

                            if (targetArg === '_mouse_') {
                                // Get mouse position
                                const mouseX = props.vm.runtime.ioDevices.mouse.getScratchX();
                                const mouseY = props.vm.runtime.ioDevices.mouse.getScratchY();
                                
                                // Calculate distance to mouse
                                const dx = target.x - mouseX;
                                const dy = target.y - mouseY;
                                distance = Math.sqrt((dx * dx) + (dy * dy));
                            } else {
                                // Find target sprite
                                const otherTarget = props.vm.runtime.getSpriteTargetByName(targetArg);
                                if (otherTarget) {
                                    const dx = target.x - otherTarget.x;
                                    const dy = target.y - otherTarget.y;
                                    distance = Math.sqrt((dx * dx) + (dy * dy));
                                }
                            }

                            console.log(`Distance check: ${targetArg} -> ${distance}`);

                            // Send result back
                            const ansSocket = new WebSocket('wss://api.stemverse.app'); // wss://api.stemverse.app
                            ansSocket.onopen = () => {
                                ansSocket.send(JSON.stringify({
                                    type: 'sensing_distanceto_result',
                                    data: {
                                        id: message.data.id,
                                        distance: distance
                                    }
                                }));
                                ansSocket.close();
                            };
                        }
                    } catch (error) {
                        console.error('Distance check error:', error);
                    }
                    break;
                }
                // Add to handleWebSocketMessage switch statement
                case 'scratch/sensing_mousex': {
                    try {
                        if (props.vm.runtime && props.vm.runtime.ioDevices) {
                            const mouseX = props.vm.runtime.ioDevices.mouse.getScratchX();
                            console.log(`Mouse X position: ${mouseX}`);
                            
                            const ansSocket = new WebSocket('wss://api.stemverse.app'); //wss://api.stemverse.app
                            ansSocket.onopen = () => {
                                ansSocket.send(JSON.stringify({
                                    type: 'scratch/sensing_mousex_result',
                                    data: {
                                        id: message.data.id,
                                        x: mouseX
                                    }
                                }));
                                ansSocket.close();
                            };
                        }
                    } catch (error) {
                        console.error('Mouse x position error:', error);
                    }
                    break;
                }

                case 'scratch/sensing_mousey': {
                    try {
                        if (props.vm.runtime && props.vm.runtime.ioDevices) {
                            const mouseY = props.vm.runtime.ioDevices.mouse.getScratchY();
                            console.log(`Mouse Y position: ${mouseY}`);

                            const ansSocket = new WebSocket('wss://api.stemverse.app'); // wss://api.stemverse.app
                            ansSocket.onopen = () => {
                                ansSocket.send(JSON.stringify({
                                    type: 'scratch/sensing_mousey_result',
                                    data: {
                                        id: message.data.id,
                                        y: mouseY
                                    }
                                }));
                                ansSocket.close();
                            };
                            
                        }
                    } catch (error) {
                        console.error('Mouse y position error:', error);
                    }
                    break;
                }

                // Add handlers for the result messages
                case 'scratch/sensing_mousex_result': {
                    console.log('Mouse X result:', message.data.x);
                    break;
                }

                case 'scratch/sensing_mousey_result': {
                    console.log('Mouse Y result:', message.data.y);
                    break;
                }
                case 'scratch/play_sound':
                case 'play_sound':
                    try {
                        const soundTargets = props.vm.runtime.targets.filter(t => !t.isStage);
                        if (soundTargets.length > 0) {
                            const target = soundTargets[0];

                            // Create sound data with default effect values
                            const soundState = {
                                effects: {
                                    pitch: 0,
                                    pan: 0
                                }
                            };

                            // Get the sound index from target's sounds
                            const soundIndex = target.sprite.sounds.findIndex(s => s.name === message.data.sound);
                            if (soundIndex !== -1) {
                                const soundId = target.sprite.sounds[soundIndex].soundId;

                                if (message.data.wait) {
                                    // Play sound and wait for completion
                                    target.sprite.soundBank.playSound(target, soundId).then(() => {
                                        // Send completion message back
                                        if (socket && isConnected) {
                                            socket.send(JSON.stringify({
                                                type: 'scratch/sound_done',
                                                data: {
                                                    id: message.data.id,
                                                    sound: message.data.sound,
                                                    status: 'complete'
                                                }
                                            }));
                                        }
                                    });
                                } else {
                                    // Play sound without waiting
                                    target.sprite.soundBank.playSound(target, soundId);
                                }

                                console.log('Playing sound:', message.data);
                            }
                        }
                    } catch (error) {
                        console.error('Play sound error:', error);
                    }
                    break;
                // Add this case in the handleWebSocketMessage switch statement
                case 'scratch/stop_all_sounds':
                    try {
                        const soundTargets = props.vm.runtime.targets.filter(t => !t.isStage);
                        if (soundTargets.length > 0) {
                            const target = soundTargets[0];

                            // Get target's sprite sound bank and stop all sounds
                            if (target.sprite && target.sprite.soundBank) {
                                target.sprite.soundBank.stopAllSounds(target);
                            }

                            props.vm.runtime.requestTargetsUpdate(target);
                            console.log('Stopped all sounds');
                        }
                    } catch (error) {
                        console.error('Stop all sounds error:', error);
                    }
                    break;
                // Add these cases in the handleWebSocketMessage switch statement
                case 'scratch/set_sound_effect':
                    try {
                        const soundTargets = props.vm.runtime.targets.filter(t => !t.isStage);
                        if (soundTargets.length > 0) {
                            const target = soundTargets[0];

                            // Get sound state from target directly
                            const soundState = target.getCustomState('Scratch.sound') || {
                                effects: {
                                    pitch: 0,
                                    pan: 0
                                }
                            };

                            // Set effect value within valid range
                            if (message.data.effect === 'pitch') {
                                soundState.effects.pitch = Math.max(-360, Math.min(360, message.data.value));
                            } else if (message.data.effect === 'pan') {
                                soundState.effects.pan = Math.max(-100, Math.min(100, message.data.value));
                            }

                            // Update sound state
                            target.setCustomState('Scratch.sound', soundState);
                            target.soundEffects = soundState.effects;

                            props.vm.runtime.requestTargetsUpdate(target);
                            console.log('Set sound effect:', message.data);
                        }
                    } catch (error) {
                        console.error('Set sound effect error:', error);
                    }
                    break;

                case 'scratch/change_sound_effect':
                    try {
                        const soundTargets = props.vm.runtime.targets.filter(t => !t.isStage);
                        if (soundTargets.length > 0) {
                            const target = soundTargets[0];

                            // Get current sound state
                            const soundState = target.getCustomState('Scratch.sound') || {
                                effects: {
                                    pitch: 0,
                                    pan: 0
                                }
                            };

                            // Change effect value within valid range
                            if (message.data.effect === 'pitch') {
                                soundState.effects.pitch = Math.max(-360, Math.min(360,
                                    soundState.effects.pitch + message.data.change));
                            } else if (message.data.effect === 'pan') {
                                soundState.effects.pan = Math.max(-100, Math.min(100,
                                    soundState.effects.pan + message.data.change));
                            }

                            // Update sound state
                            target.setCustomState('Scratch.sound', soundState);
                            target.soundEffects = soundState.effects;

                            props.vm.runtime.requestTargetsUpdate(target);
                            console.log('Changed sound effect:', message.data);
                        }
                    } catch (error) {
                        console.error('Change sound effect error:', error);
                    }
                    break;

                case 'scratch/clear_sound_effects':
                    try {
                        const soundTargets = props.vm.runtime.targets.filter(t => !t.isStage);
                        if (soundTargets.length > 0) {
                            const target = soundTargets[0];

                            // Reset sound state to defaults
                            const soundState = {
                                effects: {
                                    pitch: 0,
                                    pan: 0
                                }
                            };

                            // Update sound state
                            target.setCustomState('Scratch.sound', soundState);
                            target.soundEffects = soundState.effects;

                            props.vm.runtime.requestTargetsUpdate(target);
                            console.log('Cleared sound effects');
                        }
                    } catch (error) {
                        console.error('Clear sound effects error:', error);
                    }
                    break;
                // Add these cases to the handleWebSocketMessage switch statement
                case 'scratch/set_volume':
                    try {
                        const soundTargets = props.vm.runtime.targets.filter(t => !t.isStage);
                        if (soundTargets.length > 0) {
                            const target = soundTargets[0];

                            // Clamp volume between 0 and 100
                            const volume = Math.max(0, Math.min(100, message.data.volume));

                            // Set volume directly on target
                            target.volume = volume;

                            props.vm.runtime.requestTargetsUpdate(target);
                            // console.log('Set volume:', volume);
                        }
                    } catch (error) {
                        console.error('Set volume error:', error);
                    }
                    break;

                case 'scratch/change_volume':
                    try {
                        const soundTargets = props.vm.runtime.targets.filter(t => !t.isStage);
                        if (soundTargets.length > 0) {
                            const target = soundTargets[0];

                            // Get current volume and add change
                            const newVolume = Math.max(0, Math.min(100, target.volume + message.data.change));

                            // Set new volume
                            target.volume = newVolume;

                            props.vm.runtime.requestTargetsUpdate(target);
                            // console.log('Changed volume by:', message.data.change);
                        }
                    } catch (error) {
                        console.error('Change volume error:', error);
                    }
                    break;

                case 'scratch/get_volume':
                    try {
                        const soundTargets = props.vm.runtime.targets.filter(t => !t.isStage);
                        if (soundTargets.length > 0) {
                            const target = soundTargets[0];

                            const soundSocket = new WebSocket('wss://api.stemverse.app'); //wss://api.stemverse.app

                            soundSocket.onopen = () => {
                                // Send answer once connected
                                soundSocket.send(JSON.stringify({
                                    type: 'scratch/volume_value',
                                    data: {
                                        id: message.data.id,
                                        volume: target.volume
                                    }
                                }));

                                // Close socket after sending
                                soundSocket.close();
                            };

                            // console.log('Get volume:', target.volume);
                        }
                    } catch (error) {
                        console.error('Get volume error:', error);
                    }
                    break;
                // Add this case in the handleWebSocketMessage switch statement
                case 'scratch/volume_value':
                    try {
                        const soundTargets = props.vm.runtime.targets.filter(t => !t.isStage);
                        if (soundTargets.length > 0) {
                            const target = soundTargets[0];

                            // Send volume value back through WebSocket
                            if (socket && isConnected) {
                                socket.send(JSON.stringify({
                                    type: 'scratch/volume_value',
                                    data: {
                                        id: message.data.id,
                                        volume: target.volume
                                    }
                                }));
                            }

                            // console.log('Volume value:', target.volume);
                        }
                    } catch (error) {
                        console.error('Get volume value error:', error);
                    }
                    break;
                // Add to handleWebSocketMessage switch statement
                case 'scratch/get_key_pressed':
                    try {
                        const keyPressed = props.vm.runtime.ioDevices.keyboard.getKeyIsDown(message.data.key);

                        const keySocket = new WebSocket('wss://api.stemverse.app'); //wss://api.stemverse.app

                        keySocket.onopen = () => {
                            // Send answer once connected
                            keySocket.send(JSON.stringify({
                                type: 'scratch/key_pressed_value',
                                data: {
                                    id: message.data.id,
                                    key: message.data.key,
                                    pressed: keyPressed
                                }
                            }));

                            // Close socket after sending
                            keySocket.close();
                        };

                        // console.log('Key pressed check:', message.data.key, keyPressed);
                    } catch (error) {
                        console.error('Get key pressed error:', error);
                    }
                    break;
                // Add this case in the handleWebSocketMessage switch statement
                case 'scratch/key_pressed_value':
                    try {
                        const keyPressed = props.vm.runtime.ioDevices.keyboard.getKeyIsDown(message.data.key);

                        // Send key pressed state back through the same WebSocket connection
                        if (socket && isConnected) {
                            socket.send(JSON.stringify({
                                type: 'scratch/key_pressed_value',
                                data: {
                                    id: message.data.id,
                                    key: message.data.key,
                                    pressed: keyPressed
                                }
                            }));
                        }

                        // console.log('Key pressed value:', message.data.key, keyPressed);
                    } catch (error) {
                        console.error('Key pressed value error:', error);
                    }
                    break;

                // Add this case to the handleWebSocketMessage switch statement
                case 'scratch/get_days_since_2000':
                    try {
                        const msPerDay = 24 * 60 * 60 * 1000;
                        const start = new Date(2000, 0, 1); // Months are 0-indexed
                        const today = new Date();
                        const dstAdjust = today.getTimezoneOffset() - start.getTimezoneOffset();
                        let mSecsSinceStart = today.valueOf() - start.valueOf();
                        mSecsSinceStart += ((today.getTimezoneOffset() - dstAdjust) * 60 * 1000);
                        const days = mSecsSinceStart / msPerDay;

                        const daysSocket = new WebSocket('wss://api.stemverse.app'); //wss://api.stemverse.app

                        daysSocket.onopen = () => {
                            // Send answer once connected
                            daysSocket.send(JSON.stringify({
                                type: 'scratch/days_since_2000_value',
                                data: {
                                    id: message.data.id,
                                    days: days
                                }
                            }));

                            // Close socket after sending
                            daysSocket.close();
                        };

                        // console.log('Days since 2000:', days);
                    } catch (error) {
                        console.error('Get days since 2000 error:', error);
                    }
                    break;

                // Add this case in the handleWebSocketMessage switch statement
                case 'scratch/days_since_2000_value':
                    try {
                        const days = message.data.days;

                        // Send days value back through WebSocket
                        if (socket && isConnected) {
                            socket.send(JSON.stringify({
                                type: 'scratch/days_since_2000_value',
                                data: {
                                    id: message.data.id,
                                    days: days
                                }
                            }));
                        }

                        // console.log('Days since 2000 value:', days);
                    } catch (error) {
                        console.error('Days since 2000 value error:', error);
                    }
                    break;

                // Add this case to the handleWebSocketMessage switch statement
                case 'scratch/get_current':
                    try {
                        const menuOption = message.data.menu.toLowerCase();
                        const date = new Date();
                        let value = 0;

                        switch (menuOption) {
                            case 'year':
                                value = date.getFullYear();
                                break;
                            case 'month':
                                value = date.getMonth() + 1; // getMonth is zero-based
                                break;
                            case 'date':
                                value = date.getDate();
                                break;
                            case 'dayofweek':
                                value = date.getDay() + 1; // getDay is zero-based, Sun=0
                                break;
                            case 'hour':
                                value = date.getHours();
                                break;
                            case 'minute':
                                value = date.getMinutes();
                                break;
                            case 'second':
                                value = date.getSeconds();
                                break;
                        }

                        const dateSocket = new WebSocket('wss://api.stemverse.app'); //wss://api.stemverse.app

                        dateSocket.onopen = () => {
                            // Send answer once connected
                            dateSocket.send(JSON.stringify({
                                type: 'scratch/current_value',
                                data: {
                                    id: message.data.id,
                                    menu: message.data.menu,
                                    value: value
                                }
                            }));

                            // Close socket after sending
                            dateSocket.close();
                        };

                        // console.log('Current time value:', menuOption, value);
                    } catch (error) {
                        console.error('Get current value error:', error);
                    }
                    break;
                // Add this case in the handleWebSocketMessage switch statement
                case 'scratch/current_value':
                    try {
                        const currentValue = message.data.value;
                        
                        // Send current value back through WebSocket
                        if (socket && isConnected) {
                            socket.send(JSON.stringify({
                                type: 'scratch/current_value',
                                data: {
                                    id: message.data.id,
                                    menu: message.data.menu,
                                    value: currentValue
                                }
                            }));
                        }
                        
                        // console.log('Current value:', message.data.menu, currentValue);
                    } catch (error) {
                        console.error('Current value error:', error);
                    }
                    break;
                // Add these cases to the handleWebSocketMessage switch statement
                case 'scratch/get_timer':
                    try {
                        const timerValue = props.vm.runtime.ioDevices.clock.projectTimer();

                        const timerSocket = new WebSocket('wss://api.stemverse.app'); //wss://api.stemverse.app

                        timerSocket.onopen = () => {
                            // Send answer once connected
                            timerSocket.send(JSON.stringify({
                                type: 'scratch/timer_value',
                                data: {
                                    id: message.data.id,
                                    value: timerValue
                                }
                            }));

                            // Close socket after sending
                            timerSocket.close();
                        };

                        console.log('Timer value:', timerValue);
                    } catch (error) {
                        console.error('Get timer error:', error);
                    }
                    break;

                case 'scratch/reset_timer':
                    try {
                        props.vm.runtime.ioDevices.clock.resetProjectTimer();
                        console.log('Timer reset');
                    } catch (error) {
                        console.error('Reset timer error:', error);
                    }
                    break;

                case 'scratch/timer_value':
                    try {
                        const timerValue = message.data.value;
                        
                        // Send timer value back through WebSocket
                        if (socket && isConnected) {
                            socket.send(JSON.stringify({
                                type: 'scratch/timer_value',
                                data: {
                                    id: message.data.id,
                                    value: timerValue
                                }
                            }));
                        }
                        
                        console.log('Timer value:', timerValue);
                    } catch (error) {
                        console.error('Timer value error:', error);
                    }
                    break;
                default:
                    console.warn('Unknown message type:', message.type);
            }
        } catch (error) {
            console.error('WebSocket message handling error:', error);
        }
    };

    const sendWebSocketMessage = (type, data) => {
        if (socket && isConnected) {
            socket.send(JSON.stringify({
                type: type,
                data: data
            }));
        }
    };

    // Add these props to your children components that need WebSocket access
    const childProps = {
        ...props,
        onSendWebSocketMessage: sendWebSocketMessage,
        isWebSocketConnected: isConnected
    };

    return (
        <>
            {
                isModalOpen && (
                    <div
                        style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            zIndex: 9999,
                            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Add backdrop color
                        }}
                    >
                        <LandingPage handleTopicClick={handleOptionClick} />
                    </div>
                )}

            {/* LoginDropdown Modal */}
            {isLoginOpen && (
                <div
                    style={{
                        position: 'fixed',
                        // top: '50%',
                        // left: '50%',
                        // transform: 'translate(-50%, -50%)',
                        // backgroundColor: 'balck',
                        // padding: '20px',
                        // boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                        zIndex: 10000, // Ensure it's above everything else
                        width: '50%', // Adjust modal width
                        // borderRadius: '10px', // Add some border radius for styling
                        // pointerEvents: 'none' // Prevent interaction with background
                    }}
                >
                    <LoginDropdown
                        isOpen={isLoginOpen}
                        onClose={() => setIsLoginOpen(false)}
                    />
                </div>
            )
            }

            <MediaQuery minWidth={layout.fullSizeMinWidth}>
                {isFullSize => {
                    const stageSize = resolveStageSize(stageSizeMode, isFullSize);
                    return isPlayerOnly ? (
                        <StageWrapper
                            isFullScreen={isFullScreen}
                            isRendererSupported={isRendererSupported}
                            isRtl={isRtl}
                            loading={loading}
                            stageSize={STAGE_SIZE_MODES.large}
                            vm={vm}
                            onToggleSimulation={handleToggleSimulation}
                            isSimulationOpen={isSimulationOpen}
                        >
                            {alertsVisible ? (
                                <Alerts className={styles.alertsContainer} />
                            ) : null}
                        </StageWrapper>
                    ) : (
                        <Box
                            className={styles.pageWrapper}
                            dir={isRtl ? 'rtl' : 'ltr'}
                            {...componentProps}
                        >
                            {/* Render the LoginDropdown based on isLoginOpen state */}
                            {/* {isLoginOpen && (
                                <LoginDropdown
                                    isOpen={isLoginOpen}
                                    onClose={() => setIsLoginOpen(false)}
                                    className="bg-red-500"
                                />
                            )} */}

                            {/* Other content of your GUI */}
                            {telemetryModalVisible ? (
                                <TelemetryModal
                                    isRtl={isRtl}
                                    isTelemetryEnabled={isTelemetryEnabled}
                                    onCancel={onTelemetryModalCancel}
                                    onOptIn={onTelemetryModalOptIn}
                                    onOptOut={onTelemetryModalOptOut}
                                    onRequestClose={onRequestCloseTelemetryModal}
                                    onShowPrivacyPolicy={onShowPrivacyPolicy}
                                />
                            ) : null}
                            {loading ? (
                                <Loader />
                            ) : null}
                            {isCreating ? (
                                <Loader messageId="gui.loader.creating" />
                            ) : null}
                            {tipsLibraryVisible ? (
                                <TipsLibrary />
                            ) : null}
                            {cardsVisible ? (
                                <Cards />
                            ) : null}
                            {alertsVisible ? (
                                <Alerts className={styles.alertsContainer} />
                            ) : null}
                            {connectionModalVisible ? (
                                <ConnectionModal
                                    vm={vm}
                                />
                            ) : null}
                            {costumeLibraryVisible ? (
                                <CostumeLibrary
                                    vm={vm}
                                    onRequestClose={onRequestCloseCostumeLibrary}
                                />
                            ) : null}
                            {backdropLibraryVisible ? (
                                <BackdropLibrary
                                    vm={vm}
                                    onRequestClose={onRequestCloseBackdropLibrary}
                                />
                            ) : null}
                            <MenuBar
                                accountNavOpen={accountNavOpen}
                                authorId={authorId}
                                authorThumbnailUrl={authorThumbnailUrl}
                                authorUsername={authorUsername}
                                canChangeLanguage={canChangeLanguage}
                                audioVideoSetting={audioVideoSetting}
                                canChangeTheme={canChangeTheme}
                                canCreateCopy={canCreateCopy}
                                canCreateNew={canCreateNew}
                                canEditTitle={canEditTitle}
                                canManageFiles={canManageFiles}
                                canRemix={canRemix}
                                canSave={canSave}
                                canShare={canShare}
                                className={styles.menuBarPosition}
                                enableCommunity={enableCommunity}
                                isShared={isShared}
                                isTotallyNormal={isTotallyNormal}
                                logo={logo}
                                renderLogin={renderLogin}
                                showComingSoon={showComingSoon}
                                onClickAbout={onClickAbout}
                                onClickAccountNav={onClickAccountNav}
                                onClickLogo={onClickLogo}
                                onCloseAccountNav={onCloseAccountNav}
                                onLogOut={onLogOut}
                                onOpenRegistration={onOpenRegistration}
                                onProjectTelemetryEvent={onProjectTelemetryEvent}
                                onSeeCommunity={onSeeCommunity}
                                onShare={onShare}
                                onStartSelectingFileUpload={onStartSelectingFileUpload}
                                onToggleLoginOpen={onToggleLoginOpen}
                            />
                            <Box className={styles.bodyWrapper}>
                                <Box className={styles.flexWrapper}>
                                    <Box className={styles.editorWrapper}>
                                        <Tabs
                                            forceRenderTabPanel
                                            className={tabClassNames.tabs}
                                            selectedIndex={activeTabIndex}
                                            selectedTabClassName={tabClassNames.tabSelected}
                                            selectedTabPanelClassName={tabClassNames.tabPanelSelected}
                                            onSelect={onActivateTab}
                                        >
                                            <TabList className={tabClassNames.tabList}>
                                                <Tab
                                                    className={tabClassNames.tab}
                                                    style={{ marginRight: '10px' }}
                                                >
                                                    <img
                                                        draggable={false}
                                                        src={codeIcon}
                                                    />
                                                    <FormattedMessage
                                                        defaultMessage="Code"
                                                        description="Button to get to the code panel"
                                                        id="gui.gui.codeTab"
                                                    />
                                                </Tab>
                                                <Tab
                                                    className={tabClassNames.tab}
                                                    onClick={onPythontab}
                                                    style={{ marginRight: '10px' }}
                                                >
                                                    <img
                                                        draggable={false}
                                                        src={pythonIcon}
                                                    />
                                                    <FormattedMessage
                                                        defaultMessage='Python'
                                                        description='Button to upload python script from file in the editor tab'
                                                        id='gui.pythonTab.uploadPythonScript' // Ensure this line is correctly closed with a '

                                                    />
                                                </Tab>
                                                <Tab
                                                    className={tabClassNames.tab}
                                                    onClick={onActivateCostumesTab}
                                                    style={{ marginRight: '10px' }}
                                                >
                                                    <img
                                                        draggable={false}
                                                        src={costumesIcon}
                                                    />
                                                    {targetIsStage ? (
                                                        <FormattedMessage
                                                            defaultMessage="Backdrops"
                                                            description="Button to get to the backdrops panel"
                                                            id="gui.gui.backdropsTab"
                                                        />
                                                    ) : (
                                                        <FormattedMessage
                                                            defaultMessage="Costumes"
                                                            description="Button to get to the costumes panel"
                                                            id="gui.gui.costumesTab"
                                                        />
                                                    )}
                                                </Tab>
                                                <Tab
                                                    className={tabClassNames.tab}
                                                    onClick={onActivateSoundsTab}
                                                    style={{ marginRight: '10px' }}
                                                >
                                                    <img
                                                        draggable={false}
                                                        src={soundsIcon}
                                                    />
                                                    <FormattedMessage
                                                        defaultMessage="Sounds"
                                                        description="Button to get to the sounds panel"
                                                        id="gui.gui.soundsTab"
                                                    />
                                                </Tab>
                                            </TabList>
                                            <TabPanel className={tabClassNames.tabPanel}>
                                                <Box className={styles.blocksWrapper}>
                                                    <Blocks
                                                        key={`${blocksId}/${theme}`}
                                                        canUseCloud={canUseCloud}
                                                        grow={1}
                                                        isVisible={blocksTabVisible}
                                                        options={{
                                                            media: `${basePath}static/${themeMap[theme].blocksMediaFolder}/`
                                                        }}
                                                        stageSize={stageSize}
                                                        theme={theme}
                                                        vm={vm}
                                                    />
                                                </Box>
                                                <Box className={styles.extensionButtonContainer}>
                                                    <button
                                                        className="flex items-center justify-center p-2 bg-blue-600 hover:bg-blue-700 text-white font-semibold font-sm shadow-md border-none outline-none"
                                                        title={intl.formatMessage(messages.addExtension)}
                                                        onClick={onExtensionButtonClick}
                                                        style={{ width: '7.5rem', height: '2.3rem' }}
                                                    >
                                                        <LayoutTemplate className="w-6 h-6 mr-2" /> {/* Add the icon */}
                                                        <span>Extensions</span>
                                                    </button>
                                                </Box>
                                                <Box className={styles.watermark}>
                                                    <Watermark />
                                                </Box>
                                            </TabPanel>
                                            <TabPanel className={tabClassNames.tabPanel}>
                                                {pythonTabVisible ? (
                                                    <PythonIDE
                                                        vm={vm}
                                                        files={pythonFiles}
                                                        onFilesChange={handlePythonFilesChange}
                                                    />
                                                ) : null}
                                            </TabPanel>
                                            <TabPanel className={tabClassNames.tabPanel}>
                                                {costumesTabVisible ? <CostumeTab vm={vm} /> : null}
                                            </TabPanel>
                                            <TabPanel className={tabClassNames.tabPanel}>
                                                {soundsTabVisible ? <SoundTab vm={vm} /> : null}
                                            </TabPanel>
                                        </Tabs>
                                        {backpackVisible ? (
                                            <Backpack host={backpackHost} />
                                        ) : null}
                                    </Box>
                                    <Box className={`${styles.stageAndTargetWrapper} ${styles[stageSize]}`}>
                                        <StageWrapper
                                            isFullScreen={isFullScreen}
                                            isRendererSupported={isRendererSupported}
                                            isRtl={isRtl}
                                            stageSize={stageSize}
                                            vm={vm}
                                            onToggleSimulation={handleToggleSimulation}
                                            isSimulationOpen={isSimulationOpen}
                                        />
                                        {!isSimulationOpen ? (
                                            <Box className={styles.targetWrapper}>
                                                <TargetPane stageSize={stageSize} vm={vm} />
                                            </Box>
                                        ) : null}
                                    </Box>
                                </Box>
                            </Box>
                            <DragLayer />
                        </Box>
                    );
                }
                }
            </MediaQuery >
        </>
    );
};

GUIComponent.propTypes = {
    accountNavOpen: PropTypes.bool,
    activeTabIndex: PropTypes.number,
    authorId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    authorThumbnailUrl: PropTypes.string,
    authorUsername: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    backdropLibraryVisible: PropTypes.bool,
    backpackHost: PropTypes.string,
    backpackVisible: PropTypes.bool,
    basePath: PropTypes.string,
    blocksTabVisible: PropTypes.bool,
    blocksId: PropTypes.string,
    canChangeLanguage: PropTypes.bool,
    canChangeTheme: PropTypes.bool,
    audioVideoSetting: PropTypes.bool,
    canCreateCopy: PropTypes.bool,
    canCreateNew: PropTypes.bool,
    canEditTitle: PropTypes.bool,
    canManageFiles: PropTypes.bool,
    canRemix: PropTypes.bool,
    canSave: PropTypes.bool,
    canShare: PropTypes.bool,
    canUseCloud: PropTypes.bool,
    cardsVisible: PropTypes.bool,
    children: PropTypes.node,
    costumeLibraryVisible: PropTypes.bool,
    costumesTabVisible: PropTypes.bool,
    enableCommunity: PropTypes.bool,
    intl: intlShape.isRequired,
    isCreating: PropTypes.bool,
    isFullScreen: PropTypes.bool,
    isPlayerOnly: PropTypes.bool,
    isRtl: PropTypes.bool,
    isShared: PropTypes.bool,
    isTelemetryEnabled: PropTypes.bool,
    isTotallyNormal: PropTypes.bool,
    loading: PropTypes.bool,
    logo: PropTypes.string,
    onActivateCostumesTab: PropTypes.func,
    onActivateSoundsTab: PropTypes.func,
    onPythontab: PropTypes.func,
    onActivateTab: PropTypes.func,
    onClickAccountNav: PropTypes.func,
    onClickLogo: PropTypes.func,
    onCloseAccountNav: PropTypes.func,
    onExtensionButtonClick: PropTypes.func,
    onLogOut: PropTypes.func,
    onOpenRegistration: PropTypes.func,
    onRequestCloseBackdropLibrary: PropTypes.func,
    onRequestCloseCostumeLibrary: PropTypes.func,
    onRequestCloseTelemetryModal: PropTypes.func,
    onSeeCommunity: PropTypes.func,
    onShare: PropTypes.func,
    onShowPrivacyPolicy: PropTypes.func,
    onStartSelectingFileUpload: PropTypes.func,
    onTelemetryModalCancel: PropTypes.func,
    onTelemetryModalOptIn: PropTypes.func,
    onTelemetryModalOptOut: PropTypes.func,
    onToggleLoginOpen: PropTypes.func,
    renderLogin: PropTypes.func,
    showComingSoon: PropTypes.bool,
    soundsTabVisible: PropTypes.bool,
    pythonTabVisible: PropTypes.bool,
    stageSizeMode: PropTypes.oneOf(Object.keys(STAGE_SIZE_MODES)),
    targetIsStage: PropTypes.bool,
    telemetryModalVisible: PropTypes.bool,
    theme: PropTypes.string,
    tipsLibraryVisible: PropTypes.bool,
    vm: PropTypes.instanceOf(VM).isRequired
};

GUIComponent.defaultProps = {
    backpackHost: null,
    backpackVisible: false,
    basePath: './',
    blocksId: 'original',
    canChangeLanguage: true,
    canChangeTheme: true,
    audioVideoSetting: true,
    canCreateNew: false,
    canEditTitle: false,
    canManageFiles: true,
    canRemix: false,
    canSave: false,
    canCreateCopy: false,
    canShare: false,
    canUseCloud: false,
    enableCommunity: false,
    isCreating: false,
    isShared: false,
    isTotallyNormal: false,
    loading: false,
    showComingSoon: false,
    stageSizeMode: STAGE_SIZE_MODES.large
};

const mapStateToProps = state => ({
    blocksId: state.scratchGui.timeTravel.year.toString(),
    stageSizeMode: state.scratchGui.stageSize.stageSize,
    theme: state.scratchGui.theme.theme
});
export default injectIntl(connect(mapStateToProps)(GUIComponent));