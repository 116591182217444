// Import required modules
const BlockType = require('../../extension-support/block-type');
const ArgumentType = require('../../extension-support/argument-type');
const p5 = require('p5');

// Define the BalanceScaleExtension class
class BalanceScaleExtension {
    constructor(runtime) {
        this.runtime = runtime;
        this.leftPanBlocks = []; // Array to hold blocks for the left pan
        this.rightPanBlocks = []; // (Optional) If you want similar behavior for the right pan
        this.blockSize = 20; // Size of each block (can be adjusted)
        this.leftPanWeight = 0;
        this.rightPanWeight = 0;
        this.targetLeftWeight = 0;
        this.targetRightWeight = 0;
        this.colorLeftPan = '#FF5733';  // Color for left pan weight
        this.colorRightPan = '#33FF57'; // Color for right pan weight
        this.colorBeam = '#FFC300'; // Color for the balance beam
        this.colorText = '#000000'; // Text color
        this.isDiagramVisible = false; 
        this.canvasCleared = false;  // Track if canvas is cleared

        // Initialize p5.js instance directly
        this.p5Instance = null;
    }

    createSketch() {
        return (p) => {
            let canvas;
    
            // Variable to store the current question
            this.currentQuestion = '';
    
            p.setup = () => {
                canvas = p.createCanvas(480, 480);
                p.textSize(15);
                canvas.position(p.windowWidth - 490, 90);
            };
    
            p.draw = () => {

               

                if (!this.isDiagramVisible) return; // visible
                p.background(220);

                p.push();
                p.translate(-130, 0); // Shift everything 50 pixels to the left
                p.scale(1.3);
                const weightDifference = this.rightPanWeight - this.leftPanWeight;
                const maxTiltAngle = Math.PI / 6;
                const tiltAngle = p.constrain(weightDifference * 0.05, -maxTiltAngle, maxTiltAngle);
            
                p.push();
                p.translate(300, 200);
                p.rotate(tiltAngle);
                p.strokeWeight(4);
                p.stroke(this.colorBeam);
                p.line(-100, 0, 100, 0);
                p.pop();
            
                p.strokeWeight(4);
                p.line(300, 200, 300, 300);
            
                this.animatePanMovement();
            
                const leftPanY = 200 + 100 * Math.sin(-tiltAngle);
                p.fill('Black');
                p.rect(200, leftPanY, 50, 10);
            
                this.leftPanBlocks.forEach((weight, index) => {
                    const blockHeight = 10;
                    const blockY = leftPanY - blockHeight * (index + 1);
                    p.fill(this.colorLeftPan);
                    p.rect(200, blockY, 50, blockHeight);
                });
            
                const rightPanY = 200 + 100 * Math.sin(tiltAngle);
                p.fill('Black');
                p.rect(350, rightPanY, 50, 10);
            
                this.rightPanBlocks.forEach((weight, index) => {
                    const blockHeight = 10;
                    const blockY = rightPanY - blockHeight * (index + 1);
                    p.fill(this.colorRightPan);
                    p.rect(350, blockY, 50, blockHeight);
                });
            
                p.fill('#990099');
                p.text(`Left Pan: ${Math.round(this.leftPanWeight)}`, 130, 110);
                p.text(`Right Pan: ${Math.round(this.rightPanWeight)}`, 360, 110);
            
                p.textAlign(p.CENTER);
                // Set text color to red
                p.fill('red');
                if (this.leftPanWeight === this.rightPanWeight) {
                    p.text("Balanced!", p.width/1.75 , 30);
                } else {
                    p.text("Imbalanced!", p.width / 2, 50);
                }

                p.pop(); // Restore the previous drawing context
            
                // Only display the current question if it exists
                if (this.currentQuestion) {
                    this.displayQuestionOnCanvas(p, this.currentQuestion); // Call the function to display the question
                }

                if (this.canvasCleared) {
                    p.clear();  // Clear everything when canvasCleared is true
                }
            };
            
        };
    }
   
    

    // Draw Balance Scale Mode
    drawBalanceScale(p) {
        const weightDifference = this.rightPanWeight - this.leftPanWeight;
        const maxTiltAngle = Math.PI / 6; // Maximum tilt (30 degrees)
        const tiltAngle = p.constrain(weightDifference * 0.05, -maxTiltAngle, maxTiltAngle);
    }

     // Smoothly animate the pan weights (adjust slowly towards target weight)
     animatePanMovement() {
        if (this.leftPanWeight < this.targetLeftWeight) {
            this.leftPanWeight += 0.1;
        }
        if (this.rightPanWeight < this.targetRightWeight) {
            this.rightPanWeight += 0.1;
        }
    }

    // Method to add weight to the left pan
    addWeightToLeft(weight) {
        if (typeof this.leftPanBlocks === 'undefined') {
            this.leftPanBlocks = []; // Initialize the array if not already done
        }
    
        // Add weight to the array
        this.leftPanBlocks.push(weight);
    
        // Recalculate the total weight
        this.leftPanWeight = this.leftPanBlocks.reduce((sum, block) => sum + block, 0);
    
        // Update the target weight for smooth animations (if needed)
        this.targetLeftWeight = this.leftPanWeight;
    
        
    
        this.p5Instance.redraw(); // Trigger a redraw to update the visuals
    }
    // Method to add weight to the right pan
    addWeightToRight(weight) {
        if (typeof this.rightPanBlocks === 'undefined') {
            this.rightPanBlocks = []; // Initialize the array if not already done
        }
    
        // Add weight to the right pan blocks array
        this.rightPanBlocks.push(weight);
    
        // Recalculate the total weight on the right pan
        this.rightPanWeight = this.rightPanBlocks.reduce((sum, block) => sum + block, 0);
    
        // Update the target right weight for smooth animations (if needed)
        this.targetRightWeight = this.rightPanWeight;
    
        
    
        this.p5Instance.redraw(); // Trigger a redraw to update the visuals
    }

    

    resetScale() {
        // Reset weights and targets to zero
        this.leftPanWeight = 0;
        this.rightPanWeight = 0;
        this.targetLeftWeight = 0;
        this.targetRightWeight = 0;

        // Clear the blocks arrays
        this.leftPanBlocks = [];
        this.rightPanBlocks = [];

        // Force a redraw to clear the canvas
        this.p5Instance.redraw();
    }

    



    // Define Scratch blocks
    getInfo() {
        return {
            id: 'puzzles',
            name: 'Balance Scale',
            blocks: [
                {
                    opcode: 'startBalanceScale',
                    blockType: BlockType.COMMAND,
                    text: 'Start Balance Scale'
                },
                
               
                {
                    opcode: 'addToLeft',
                    blockType: BlockType.COMMAND,
                    text: 'Add [WEIGHT] to left pan',
                    arguments: {
                        WEIGHT: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 1
                        }
                    }
                },
                {
                    opcode: 'addToRight',
                    blockType: BlockType.COMMAND,
                    text: 'Add [WEIGHT] to right pan',
                    arguments: {
                        WEIGHT: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 1
                        }
                    }
                },
                {
                    opcode: 'setLeftPan',
                    blockType: BlockType.COMMAND,
                    text: 'Set left pan to [WEIGHT]',
                    arguments: {
                        WEIGHT: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 1
                        }
                    }
                },
                {
                    opcode: 'setRightPan',
                    blockType: BlockType.COMMAND,
                    text: 'Set right pan to [WEIGHT]',
                    arguments: {
                        WEIGHT: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 1
                        }
                    }
                },
                {
                    opcode: 'removeLeftPan',
                    blockType: BlockType.COMMAND,
                    text: 'Remove left pan [NUMBER] weights',
                    arguments: {
                        NUMBER: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 1
                        }
                    }
                },
                {
                    opcode: 'removeRightPan',
                    blockType: BlockType.COMMAND,
                    text: 'Remove right pan [NUMBER] weights',
                    arguments: {
                        NUMBER: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 1
                        }
                    }
                },
               
               
                {
                    opcode: 'resetScale',
                    blockType: BlockType.COMMAND,
                    text: 'Reset scale',
                    arguments: {}
                },
                {
                    opcode: 'isBalanced',
                    blockType: BlockType.BOOLEAN,
                    text: 'Is the scale balanced?',
                    arguments: {}
                },
                {
                    opcode: 'showBalanceStatus',
                    blockType: BlockType.COMMAND,
                    text: 'Show balance status',
                    arguments: {}
                }, 
                {
                    opcode: 'showQuestionDropdown',
                    blockType: BlockType.COMMAND,
                    text: 'Show question for [NUMBER]',
                    arguments: {
                        NUMBER: {
                            type: ArgumentType.NUMBER,
                            menu: 'questionMenu', // Referring to the dropdown menu
                            defaultValue: 1
                        }
                    }
                },
                {
                    opcode: 'clearCanvas',
                    blockType: BlockType.COMMAND,
                    text: 'Clear Canvas'
                }
               

            ],
            menus: {
                questionMenu: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'] // List of numbers 1 to 10 in the dropdown
            }
        };
    }

   
   // Start the balance scale by showing the diagram
   startBalanceScale() {
    if (!this.p5Instance) {
        this.p5Instance = new p5(this.createSketch());  // Initialize p5 instance and start drawing
    }
    this.isDiagramVisible = true;  // Set the flag to true to show the balance scale
    this.p5Instance.redraw();  // Redraw to show the balance scale
}
    

    // Scratch block implementations
    addToLeft(args) {
        const count = parseInt(args.WEIGHT, 10); // Parse the weight argument as an integer
    
        if (isNaN(count) || count <= 0) {
            console.error('Invalid weight count. Please provide a positive integer.');
            return;
        }
    
        // Add the specified number of weights
        for (let i = 0; i < count; i++) {
            this.addWeightToLeft(1); // Add a single unit weight each time
        }
    }
    
    

    addToRight(args) {
        const count = parseInt(args.WEIGHT, 10); // Parse the weight argument as an integer
    
        if (isNaN(count) || count <= 0) {
            console.error('Invalid weight count. Please provide a positive integer.');
            return;
        }
    
        // Add the specified number of weights
        for (let i = 0; i < count; i++) {
            this.addWeightToRight(1); // Add a single unit weight each time
        }
    }
    
    // Functionality for setting left pan
    setLeftPan(args) {
      const weight = parseInt(args.WEIGHT, 10);
       if (weight >= 0) {
        this.leftPanBlocks = Array(weight).fill(1); // Add specified number of weights
        this.targetLeftWeight = weight; // Update the target weight for smooth animation
        this.leftPanWeight = weight; // Update the actual weight
        this.p5Instance.redraw(); // Trigger a redraw
        }
   }

   setRightPan(args) {
    const weight = parseInt(args.WEIGHT, 10);
    if (weight >= 0) {
        this.rightPanBlocks = Array(weight).fill(1); // Add specified number of weights
        this.targetRightWeight = weight; // Update the target weight for smooth animation
        this.rightPanWeight = weight; // Update the actual weight
        this.p5Instance.redraw(); // Trigger a redraw
        }
    }
    // Functionality for removing left pan weights
    removeLeftPan(args) {
        const numberToRemove = parseInt(args.NUMBER, 10); // Parse the input
    
        if (isNaN(numberToRemove) || numberToRemove <= 0) {
            console.error('Invalid number to remove. Provide a positive integer.');
            return;
        }
    
        if (numberToRemove > this.leftPanBlocks.length) {
            console.error('Cannot remove more weights than available.');
            return;
        }
    
        // Remove the specified number of weights
        const removedWeights = this.leftPanBlocks.splice(-numberToRemove, numberToRemove);
    
        // Recalculate the total weight
        this.leftPanWeight = this.leftPanBlocks.reduce((sum, block) => sum + block, 0);
        this.targetLeftWeight = this.leftPanWeight; // Sync target weight
        this.p5Instance.redraw(); // Trigger canvas redraw
    }
    

   // Functionality for removing right pan weights
  removeRightPan(args) {
    const numberToRemove = parseInt(args.NUMBER, 10);
    if (numberToRemove > 0) {
        this.rightPanBlocks = this.rightPanBlocks.slice(0, -numberToRemove); // Remove specified number of weights
        this.rightPanWeight = this.rightPanBlocks.length; // Update the actual weight
        this.targetRightWeight = this.rightPanBlocks.length; // Update target weight for smooth animation
        this.p5Instance.redraw(); // Trigger a redraw
        }
   }

    isBalanced() {
        // Return true if weights are balanced
        return this.leftPanWeight === this.rightPanWeight;
    }
   

     
    showQuestionDropdown(args) {
        const selectedNumber = args.NUMBER;
    
        // Define questions based on the selected number
        let question = '';
        switch (parseInt(selectedNumber, 10)) {
            case 1:
                question = 'If the left pan has 6 weights and you add 4 more weights to it, what is the new total weight on the left pan?';
                break;
            case 2:
                question = 'What is 5 + 3?';
                break;
            case 3:
                question = 'If the left pan has 5 weights and the right pan has 3 weights, how many weights are needed in the right pan to balance the scale?';
                break;
            case 4:
                question = 'If you add 2 weights to the left pan, and the right pan has 6 weights, how many weights are needed in the left pan to balance the scale?';
                break;
            case 5:
                question = 'If each weight on the left pan weighs 2 grams and there are 4 weights, what is the total weight on the left pan?';
                break;
            case 6:
                question = 'If the right pan has 8 weights, and each weight is 1 gram, how many weights are needed in the left pan to balance the scale if each weight there is also 1 gram?';
                break;
            case 7:
                question = 'If the left pan has 10 weights and the right pan has 7 weights, how many weights must you remove from the left pan to balance the scale?';
                break;
            case 8:
                question = 'If the right pan has 15 weights and you remove 5 weights, how many weights are left on the right pan?';
                break;
            case 9:
                question = 'If the right pan has 10 weights,Left pan has 2 grms 5 weights will the scale be balanced?';
                break;
            case 10:
                question = 'If you double the weights on the left pan, and it originally had 5 weights, how many weights will the left pan have now?if the right pan has 10 weights,is the scale be balanced?';
                break;
            default:
                question = 'Invalid question number! Please select a valid number.';
        }
    
        // Set the current question
        this.currentQuestion = question;
                
    }
    

    showBalanceStatus() {
        if (this.leftPanWeight === this.rightPanWeight) {
         alert("The scale is balanced!");
         } else {
         alert("The scale is imbalanced!");
          }
     } 
     displayQuestionOnCanvas(p, question) {
        const lineHeight = 20; // Height between lines
        const margin = 20; // Margin from the edges
        const textMaxWidth = p.width - 2 * margin; // Maximum allowable text width
        
        // Prepare text wrapping logic
        const words = question.split(' ');
        let lines = [];
        let currentLine = '';
    
        // Loop through words and wrap them
        for (let i = 0; i < words.length; i++) {
            const word = words[i];
            const testLine = currentLine.length ? `${currentLine} ${word}` : word;
            const testWidth = p.textWidth(testLine);
            
            // If the line fits within the width, add the word
            if (testWidth < textMaxWidth) {
                currentLine = testLine;
            } else {
                lines.push(currentLine); // Save the current line
                currentLine = word; // Start a new line with the current word
            }
        }
        
        if (currentLine) {
            lines.push(currentLine); // Add the remaining line
        }
        
        // Calculate vertical positioning for the text
        const totalTextHeight = lines.length * lineHeight;
        let yStart = p.height - margin - totalTextHeight; // Start from bottom and move upward
        
        // Ensure it doesn't go off-screen
        if (yStart < margin) {
            yStart = margin;
        }
    
        // Draw each line of the question
        p.fill('black');
        p.textAlign(p.LEFT, p.TOP); // Align text from the top-left corner
        lines.forEach((line, index) => {
            const y = yStart + index * lineHeight;
            p.text(line, margin, y); // Draw each line
        });
    
        console.log("Question displayed on canvas:", question);
    }

    clearCanvas() {
        if (this.p5Instance) {
            this.p5Instance.clear();  // Clears everything from the canvas
            this.canvasCleared = true; // Mark canvas as cleared
        }
    }
   
    
    

    
    
    
}
module.exports = BalanceScaleExtension;



