
import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../components/ui/tabs";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../components/ui/card";
import { Button } from "../components/ui/button";
import { ChevronRight, ChevronLeft, X, AlertTriangle, Maximize2, Play, Squirrel } from "lucide-react";
import closeIcon from '../components/cards/icon--close.svg';
import arvrIcon from './curriculum_images/ar.jpg';
import arvrflower from './curriculum_images/ar_vr_flower.jpg';
import arvrfrog from './curriculum_images/ar_vr_frog.jpg';
import computer from './curriculum_images/computer.jpg';
import parts from './curriculum_images/parts.jpg';
import io from './curriculum_images/io.jpg';
import op from './curriculum_images/op.jpg';
import buttons from './curriculum_images/buttons.jpg';
import cake from './curriculum_images/cake.jpg';
import direction from './curriculum_images/direction.jpg';
import teeth from './curriculum_images/teeth.jpg';
import dailylife from './curriculum_images/dailylife.png';
import bread from './curriculum_images/bread.png';
import butter from './curriculum_images/butter.png';
import butter1 from './curriculum_images/butter1.jpg';
import breadhunt from './curriculum_images/breadhunt.jpg';
import cat from './curriculum_images/cat.jpg'
import blockscratch from './curriculum_images/blockscratch.png'
import control_block from './curriculum_images/control_block.png'
import chooseshape from './curriculum_images/chooseshape.png'
import create_thinking from './curriculum_images/create_thinking.png'
import custom_blocks from './curriculum_images/custom_blocks.png'
import event_block from './curriculum_images/event_block.png'
import event1 from './curriculum_images/event1.png'
import look_block from './curriculum_images/look_block.png'
import drag_blocks from './curriculum_images/drag_blocks.jpg'
import fosters_problem from './curriculum_images/fosters_problem.png'
import high_level_programming from './curriculum_images/high_level_programmong.jpg'
import kid_jr from './curriculum_images/kid_jr.jpg'
import kid_typeing from './curriculum_images/kid_typeing.jpg'
import stemversa1 from './curriculum_images/stemversa1.png'
import operations_block from './curriculum_images/operations_block.png'
import scratch3 from './curriculum_images/scratch3.png'
import scratchjr from './curriculum_images/scratchjr.png'
import senser_block from './curriculum_images/senser_block.png'
import sound_block from './curriculum_images/sound_block.png'
import variables_block from './curriculum_images/variables_block.png'
import chitte from './curriculum_images/chitte.png'
import moving_sprites from './curriculum_images/moving_sprites.jpg'
import squirrel from './curriculum_images/squirrel.jpg'
import jungle from './curriculum_images/jungle.jpg'
import topic41 from './curriculum_images/topic41.png'
import topic42 from './curriculum_images/topic42.png'
import topic413 from './curriculum_images/topic413.png'
import topic414 from './curriculum_images/topic414.jpg'
import topic416 from './curriculum_images/topic416.jpg'
import topic421 from './curriculum_images/topic421.jpg'
import topic422 from './curriculum_images/topic422.png'
import topic431 from './curriculum_images/topic431.png'
import topic432 from './curriculum_images/topic432.png'
import topic4551 from './curriculum_images/topic4551.png'
import topic4552 from './curriculum_images/topic4552.png'
import topic4553 from './curriculum_images/topic4553.png'
import debug from './curriculum_images/debug.png'
import fixerror from './curriculum_images/fixerror.jpg'
import yourcode from './curriculum_images/yourcode.png'
import storytelling from './curriculum_images/storytelling.png'
import plotimport from './curriculum_images/plotimport.jpg'
import roadmapstorie from './curriculum_images/roadmapstorie.jpg'
import reach_goal from './curriculum_images/reach_goal.jpg'
import twist from './curriculum_images/twist.jpg'
import flowchat from './curriculum_images/flowchat.jpg'
import animation from './curriculum_images/animation.jpg'
import charecter_design from './curriculum_images/charecter_design.png'
import text_speech from './curriculum_images/text_speech.png'
import lab51 from './curriculum_images/lab51.png'
import lab52 from './curriculum_images/lab52.png'
import lab53 from './curriculum_images/lab53.png'
import lab521 from './curriculum_images/lab521.png'
import lab522 from './curriculum_images/lab522.png'
import lab531 from './curriculum_images/lab531.png'
import topic61 from './curriculum_images/topic61.png'
import topic612 from './curriculum_images/topic612.png'
import topic613 from './curriculum_images/topic613.jpg'
import topic614 from './curriculum_images/topic614.png'
import topic615 from './curriculum_images/topic615.jpg'
import topic616 from './curriculum_images/topic616.jpg'
import topic617 from './curriculum_images/topic617.jpg'
import topic62 from './curriculum_images/topic62.jpg'
import topic621 from './curriculum_images/topic621.jpg'
import topic622 from './curriculum_images/topic622.jpg'
import topic623 from './curriculum_images/topic623.png'
import topic624 from './curriculum_images/topic624.png'
import topic6311 from './curriculum_images/topic6311.jpg'
import topic6322 from './curriculum_images/topic6322.png'
import topic6323 from './curriculum_images/topic6323.png'
import topic64 from './curriculum_images/topic64.jpg'
import topic641 from './curriculum_images/topic641.jpg'
import topic642 from './curriculum_images/topic642.jpg'
import topic71 from './curriculum_images/topic71.png'
import topic712 from './curriculum_images/topic712.png'
import topic713 from './curriculum_images/topic713.png'
// import pdfFile from'/Users/nares/scratch/stemblocks-GUI/src/components/library/ar_vr.pdf'




export function Arvrdocs({ isOpen, onClose }) {
  const [currentStep, setCurrentStep] = useState(1); // Keep track of the selected class
  const [selectedProject, setSelectedProject] = useState(null);

  const projects = {
    1: [
      {
        id: 1,
        title: 'PROJECT 1',
        // description: 'Learn about the foundational concepts of computers and technology.',
        details: [
          {
            sectionTitle: <span className="text-purple-600"> COMING SOON ....</span>,
            content: ``,
            // image: computer, // Replace with actual image path
            // imagePlacement: 'right', // Image on the right side
          }]
      },],

    2: [{
      id: 1,
      title: 'PROJECT 1',
      // description: 'Learn about the foundational concepts of computers and technology.',
      details: [
        {
          sectionTitle: <span className="text-purple-600">COMING SOON....</span>,
          content: ``
        },














      ]
    },
    ],
    3: [

      {
        id: 1,
        title: '1.Understanding Computers and Technology',
        // description: 'Learn about the foundational concepts of computers and technology.',
        details: [
          {
            sectionTitle: <span className="text-purple-600"> What is a Computer ?</span>,
            content: `<p>Imagine a computer as a really smart helper. It’s a machine that can do all sorts of things! Think of it like a magic box that can show you pictures, help you play games, watch funny videos, listen to songs, and even talk to friends or family who are far away.</p>
<p>A computer listens to you and does what you ask it to do, like a robot following commands. When you type or click on things, the computer understands and helps you finish tasks fast, like solving a puzzle or drawing a picture. Its like having a super-fast friend that never gets tired!</p>`,
            image: computer, // Replace with actual image path
            imagePlacement: 'right', // Image on the right side
          },
          {
            sectionTitle: <span className="text-purple-600"> Parts of a Computer</span>,
            content: `
            Just like we have hands, eyes, and a brain, a computer has its own special parts. Each part does something important to make the computer work:
 <ul class="list-disc pl-6">
    <li><strong>Monitor:</strong> This is the screen, like a TV, where you see everything. It shows you games, pictures, and words. You look at the monitor to see what’s happening.</li>
    <li><strong>Keyboard:</strong> This has lots of buttons with letters (like A, B, C) and numbers (1, 2, 3). When you press these buttons, it’s like talking to the computer. You use it to type your name, write words, or do math.</li>
    <li><strong>Mouse:</strong> The mouse is a little device that you hold and move with your hand. When you move the mouse, a pointer moves on the screen. It helps you click on things, like opening a game or choosing colors to draw with.</li>
    <li><strong>CPU (Central Processing Unit):</strong> The CPU is like the computer’s brain. It thinks really fast and makes all the other parts work together. If you tell the computer to start a game, the CPU makes sure it happens!</li>
    <li><strong>Speakers:</strong> These make sounds, like music or talking. If you’re watching a video, the speakers help you hear it.</li>
    <li><strong>Printer:</strong> If you want to take something from the computer, like a drawing or photo, and put it on paper, the printer does that. It’s like having your drawing magically appear on paper!</li>
  </ul>`,
            image: parts, // Replace with actual image path
            imagePlacement: 'left', // Image on the left side
          },
          {
            sectionTitle: <span className="text-purple-600">Basic Computer Operations</span>,
            content: `When you want to use a computer, you do a few simple things:
            <ul class="list-disc pl-6"> 
           <li> <strong>Turning On and Off:</strong> A computer has a power button, like a light switch. Press it to turn the computer on. When you’re finished, you need to turn it off so it can rest. This is called “shutting down.”</li>
<li> <strong>Opening Programs:</strong> Programs are like apps on a phone or a game. Each program helps you do something, like drawing or playing music. If you want to draw, you open a drawing program. If you want to watch a video, you open a video program.</li>
<li> <strong>Typing and Clicking:</strong> When you type on the keyboard or click with the mouse, it’s like telling the computer what to do. Typing letters makes words appear on the screen. Clicking the mouse lets you choose things on the screen, like opening a game</li>
.</ul>`,

            // No image property added here
          },


          {
            sectionTitle: <span className="text-purple-600">Input  Devices</span>,
            // layout: 'two-images', // New layout type for two images
            //leftImage: io, // Replace with the actual path
            //
            // rightImage: op, // Replace with the actual path
            content: `
              The computer has two types of parts: input devices and output devices.
              <ul class="list-disc pl-6">
               <li><strong>Input Devices:</strong> These are parts that help you put information into the computer. The keyboard and mouse are input devices. For example, when you type your name with the keyboard, that’s input. When you click on a picture with the mouse, that’s also input.</li>
               </ul>
            `,
            image: io, // Replace with actual image path
            imagePlacement: 'left', // Image on the left side
          },

          {
            sectionTitle: <span className="text-purple-600">Output Devices</span>,
            // layout: 'two-images', // New layout type for two images
            //leftImage: io, // Replace with the actual path
            //
            // rightImage: op, // Replace with the actual path
            content: `
              <ul class="list-disc pl-6">
               <li><strong>Output Devices:</strong> These show you what the computer is doing. The monitor and speakers are output devices. For example, the monitor shows you pictures and videos. The speakers play sounds, like music or the sound effects in a game.<p>Think of it like a magic show: input devices tell the computer what trick to do, and output devices show you the trick!</p></li>
               </ul>
            `,
            image: op, // Replace with actual image path
            imagePlacement: 'left', // Image on the left side
          },

          {
            sectionTitle: <span className="text-purple-600">Understanding Buttons and Icons</span>,
            content: `When you look at a computer screen, you see lots of little pictures and buttons. These pictures are called <b>icons</b>, and they help you find and open different programs.
            <ul class="list-disc pl-6">
           <li><strong>Icons:</strong> Each icon is like a shortcut. If you want to open a game, you might click on a picture that looks like a joystick or a soccer ball. If you want to see the photos you took, you might click an icon that looks like a camera or a picture.</li>
           <li><strong>Buttons:</strong> Buttons are like switches. When you click a button, something happens. For example, if you’re watching a video, there’s a button to “pause” or “play.” If you’re drawing, there might be a button to change colors or erase something.</li>
           </ul>
           <p>Icons and buttons make it easy to tell the computer what you want to do. Just click, and the computer knows exactly what to show you or do next!</p>
`,
            image: buttons, // Replace with actual image path
            imagePlacement: 'right', // Image on the left side
          },



          { sectionTitle: <span className="text-blue-600">Activity Session</span> },


          {
            sectionTitle: <span style={{ color: 'orange' }}>1.1 Exploring Different Types of Computers</span>,
            content: 'Students interact with pictures and devices to identify different types of computers.'
          }
          ,

          {
            sectionTitle: <span style={{ color: 'orange' }}>1.2 Identifying Computer Parts </span>,
            content: ' Match computer part pictures with their names and functions in a group activity.',
          },
          {
            sectionTitle: <span style={{ color: 'orange' }}>1.3 Mouse and Keyboard Practice </span>,
            content: 'Practice using a mouse to click and drag, and use a keyboard to type letters.',
          },
          {
            sectionTitle: <span style={{ color: 'orange' }}>1.4 Exploring Input and Output Devices </span>,
            content: ' Hands-on activity to explore different devices and identify their roles.',
          },
          {
            sectionTitle: <span style={{ color: 'orange' }}>1.5 Using Buttons and Icons on a Simple Interface </span>,
            content: 'Use an interactive app or software to explore buttons and icons.',
          },

        ]
      }, {
        id: 2,
        title: '2.Introduction to Sequencing and Algorithms',
        // description: 'Learn about the foundational concepts of computers and technology.',
        details: [
          {
            sectionTitle: <span className="text-purple-600"> What is a Sequencing ?</span>,
            content: `<p>Sequencing is all about putting things in the right order. Imagine you’re baking a cake! First, you get all the ingredients, then you mix them, then you bake the cake, and finally, you decorate it. You have to do each step in the right order, or the cake won’t turn out right.</p>
            <p>In computers, sequencing means giving instructions one by one in the right order so the computer can follow along. Just like following steps in a recipe or getting dressed (putting on socks before shoes), computers need clear steps to do things correctly.</p>`,
            image: cake, // Replace with actual image path
            imagePlacement: 'right', // Image on the right side
          },
          {
            sectionTitle: <span className="text-purple-600"> Understanding Algorithms </span>,
            content: `An algorithm is just a fancy word for a set of steps to solve a problem or complete a task. Imagine you’re teaching someone how to brush their teeth. You might tell them:
  
 <ul class="list-disc pl-6">
    <li>1.	Pick up the toothbrush.</li>
    <li>2.	Put toothpaste on it.</li>
    <li>3.	Brush your teeth for two minutes.</li>
    <li>4.	Rinse your mouth.</li>
  </ul>
  Each step is part of the algorithm for brushing teeth! In the same way, computers follow algorithms to do things, like solving math problems or playing a song. It’s like a set of instructions or directions to get a job done, and computers need us to tell them each step.
  `,
            image: teeth, // Replace with actual image path
            imagePlacement: 'left', // Image on the left side
          },
          {
            sectionTitle: <span className="text-purple-600">Introduction to Directions </span>,
            content: `Directions tell us where to go or how to move from one place to another. Imagine you’re playing a game, and you want to move your character to find a treasure. You might go:
            <ul class="list-disc pl-6"> 
           <li> <strong>Up</strong> to climb a hill,</li>
<li> <strong>Right</strong> to walk across a bridge,</li>
<li> <strong>Down</strong> to enter a cave</li>
<li> <strong>Left</strong> to reach the treasure chest!</li>
.</ul>
Just like you follow directions to find your way, computers need directions to know where to go or what to do next. When we give directions to a computer, we’re guiding it, step by step, to get to the final goal!
`,

            image: direction, // Replace with actual image path
            imagePlacement: 'right', // Image on the left side
          },


          {
            sectionTitle: <span className="text-purple-600">Daily Life Algorithms</span>,
            // layout: 'two-images', // New layout type for two images
            //leftImage: io, // Replace with the actual path
            //
            // rightImage: op, // Replace with the actual path
            content: `
              Believe it or not, we use algorithms every day without even thinking about it! Here are some examples:
              <ul class="list-disc pl-6">
              <b>Getting Ready For School:</b>
               <li>Wake up.</li>
               <li>Brush your teeth.</li>
               <li>Get dressed.</li>
               <li>Eat breakfast.</li>
               <li>Pack your bag.</li>
               <li>Go to school.</li>
               Here’s a fun story about making a sandwich to help kids understand the steps as part of a “sandwich-making algorithm”:
            
               </ul>
            `,
            image: dailylife, // Replace with actual image path
            imagePlacement: 'left', // Image on the left side
          },
          {
            // sectionTitle: <span className="text-purple-600"></span>,
            content: `<b>The Great Sandwich Adventure:</b>
            <ul class="list-disc pl-6"> 
        
               <li>One sunny afternoon, Leo the Little Chef was hungry. He wanted something yummy and easy to make, so he decided, <b>“I’ll make a sandwich!” </b>Leo thought making a sandwich would be a piece of cake… but it turned out to be more of an adventure!</li>
               <li><b>Step 1: The Bread Hunt </b>First, Leo ran to the kitchen and searched high and low for the bread. <b>“Ah-ha!”</b> he said, as he spotted the loaf of bread on the counter. He pulled out two slices of soft bread and placed them on the plate, side by side, like best friends getting ready for a big hug.</li>
               <li><b>Step 2: The Spread Surprise</b> Next, Leo thought, <b>“My sandwich needs something tasty to spread on the bread!” </b>He opened the fridge and found two jars—one with<b> butter </b>and one with <b>peanut butter.</b> He picked peanut butter, his favorite, and scooped some onto a spoon. Carefully, he spread it across one slice of bread, making sure every corner was covered.</li>
               <li><b>Step 3: The Cheese and Jam Mystery</b> Now it was time for something in the middle. <b>“Hmm, what goes well with peanut butter?” </b>Leo wondered. He opened the fridge again and saw his favorite <b>cheese slices.</b> He decided to place a piece of cheese right on top of the peanut butter. <b>“This is going to be the best sandwich ever!” </b>he said.</li>
               <li><b>Step 4: The Big Bread Hug</b> Leo was almost done. <b>“Now, I have to put the slices together,” </b>he thought. He carefully lifted one slice of bread and gently placed it on top of the other. <b>“Squish!”</b> went the sandwich, as the two slices of bread hugged each other tightly, with peanut butter and cheese happily sticking together in the middle.</li>
               <li><b>Step 5: The Great Cut</b> Leo looked at his sandwich and said,<b> “Hmm, I want it to look extra special.”</b> So, he picked up a plastic knife and carefully cut the sandwich into two triangles. <b>“Perfect!”</b> he said, smiling at his work.</li>
               <p>At last, Leo’s sandwich was ready! He took a big bite and felt like a real chef. “Yum!” he said, happily munching away.</p>
               <p>And that’s how Leo made his sandwich, step by step! Each part of the process helped him create something delicious. Leo learned that by following each step in the right order, he could make the perfect sandwich every time!</p></ul>`,

            image: bread, // Replace with actual image path
            imagePlacement: 'left', // Image on the left side
          },

          {
            sectionTitle: <span className="text-purple-600">Applying Sequencing to Problem Solving</span>,
            // layout: 'two-images', // New layout type for two images
            //leftImage: io, // Replace with the actual path
            //
            // rightImage: op, // Replace with the actual path
            content: `Sequencing helps us break down big problems into smaller steps. Let’s say you want to tidy up your room, which can feel like a big job. If you use sequencing, you can break it into smaller steps:
              <ul class="list-disc pl-6">
               <li>Pick up all your toys and put them in the toy box.</li>
               <li>Fold any clothes lying around.</li>
               <li>Put your books back on the shelf.</li>
               <li>Sweep the floor.</li>
               <p>By following these steps in the right order, you can solve the “problem” of a messy room one step at a time. This is what computers do too—they solve problems by following steps in the right order, so everything works smoothly.</p>
               </ul>
            `,
            //no image 
          },

          { sectionTitle: <span className="text-blue-600">Activity Session</span> },


          {
            sectionTitle: <span style={{ color: 'orange' }}>2.6	Sequencing in Everyday Tasks</span>,
            // layout: 'two-images', // New layout type for two images
            //leftImage: io, // Replace with the actual path
            //
            // rightImage: op, // Replace with the actual path
            content: `<b>Activity: </b>Arrange steps for tasks like getting dressed or making a sandwich.
                  <ul class="list-disc pl-6">
                  <b>Steps for Activity</b>
                   <li><b>Introduce the Concept of Sequencing</b>
                   </li>
                   <ol>
                   <li>Explain that sequencing means putting actions in the correct order to achieve a goal.</li>
                   <li>Give an example, like tying shoelaces, where each step must happen in the right order.</li>
                   </ol>
                   <li><b>Explain the Sandwich Task</b></li>
                    <ol>Show the students the materials or list of items involved in making a sandwich, such as:
                    <li>Bread</li>
                    <li>Butter or spread</li>
                    <li>Filling (like cheese or vegetables)</li>
                    <li>Knife for spreading</li></ol>
                   <li><b>Discuss Each Step in Order</b></li>
                   <ol>
                    <li><b>Step 1:</b> Take two slices of bread.</li>
                    <li><b>Step 2:</b> Spread butter or another spread on one or both slices of bread.</li>
                    <li><b>Step 3:</b> Add the filling (e.g., cheese or veggies) on top of one of the slices.</li>
                    <li><b>Step 4: </b>Place the second slice of bread on top to complete the sandwich.</li></ol>
                   </ul>
                `,
            // image: op, // Replace with actual image path
            // imagePlacement: 'left', // Image on the left side
          },
          {
            sectionTitle: <span style={{ color: 'orange' }}>2.7	Creating Simple Algorithms</span>,
            // layout: 'two-images', // New layout type for two images
            //leftImage: io, // Replace with the actual path
            //
            // rightImage: op, // Replace with the actual path
            content: `<b>Activity: </b>Build a simple sequence using cards or blocks for a specific task.
                  <ul class="list-disc pl-6">
                  <b>Steps for Activity</b>
                   <li><b>Explain the Concept of an Algorithm</b>
                   </li>
                   <ol>
                   <li>Define an algorithm as a set of step-by-step instructions to complete a task.</li>
                   <li>Give examples of everyday algorithms, like a recipe for cooking or the instructions for tying shoelaces.</li>
                   </ol>
                   <li><b>Introduce the Task: Getting Ready for School</b></li>
                    <ol>1.Explain that students will create an algorithm for getting ready in the morning.
                    2.List the typical steps involved:
                    <li>Wake up</li>
                    <li>Brush teeth</li>
                    <li>Eat breakfast</li>
                    <li>Pack school bag</li>
                    <li>Put on shoes</li>
                    </ol>
                   <li><b>Create Instruction Cards or Blocks</b></li>
                   <ol>
                    <li>Write each step on a separate card or use blocks with the steps written on them.</li>
                    <li>Shuffle the cards/blocks to allow students to arrange them in the correct order.</li>
                    </ol>
                    <li><b>Arrange the Steps in the Correct Sequence</b></li>
                   <ol>
                    <li>Ask students to organize the steps into a logical order.</li>
                    <li>Once they’ve arranged the steps, review the sequence together and discuss why each step comes in that particular order.</li>
                    </ol>
                     <li><b>Practice Creating Algorithms for Other Tasks</b></li>
                   <ol>
                    <li>Give students different tasks to build algorithms for, such as:</li>
                    <ul><b>Making a Bowl of Cereal:</b>
                    <li>* Get a bowl, pour cereal, add milk, use spoon to eat.</li></ul>
                    <ul><b>Planting a Seed:</b>
                    <li>* Dig a hole, plant the seed, cover with soil, water the plant.</li></ul>
                    <li>Have students create an algorithm for each task using their cards or blocks.</li>
                    </ol>
                   </ul>
                `,
            // image: op, // Replace with actual image path
            // imagePlacement: 'left', // Image on the left side
          },
          {
            sectionTitle: <span style={{ color: 'orange' }}>2.8	Following Directions in a Game</span>,
            // layout: 'two-images', // New layout type for two images
            //leftImage: io, // Replace with the actual path
            //
            // rightImage: op, // Replace with the actual path
            content: `<b>Activity: </b>Follow verbal or visual directions to reach a destination in a maze.
                  <ul class="list-disc pl-6">
                  <b>Steps for Activity</b>
                   <li><b>Introduce the Concept of Following Directions</b>
                   </li>
                   <ol>
                   <li>Explain that directions tell us how to get from one place to another.</li>
                   <li>Emphasize that following directions accurately is essential for reaching a goal, whether in a game, real life, or coding.</li>
                   </ol>
                   <li><b>Set Up the Maze</b></li>
                    <ol>
                    <li>Draw or print a simple maze on paper or create a large maze on the floor using tape.</li>
                    <li>Mark the <b>start</b> and <b>finish</b> points clearly.</li>
                    <li>Optionally, add obstacles or alternate paths to make the maze more challenging.</li>
                    </ol>
                   <li><b>Provide Verbal or Visual Directions</b></li>
                   <ol>Choose one of the following methods:
                    <li><b>Verbal Directions:</b> A partner or the instructor gives step-by-step directions out loud (e.g., “Move forward two steps,” “Turn left,” “Move forward one step”).</li>
                    <li><b>Visual Directions:</b> Provide students with a printed list of directions or arrows showing the path to follow.</li>
                    Allow students to attempt navigating the maze based on these instructions.</ol>
                    <li><b>Have Students Follow the Directions</b></li>
                    <ol>
                    <li>If using a paper maze, students can trace their path with a pencil or finger.</li>
                    <li>For a floor maze, students can walk through it step-by-step, following the directions precisely.</li>
                    </ol>
                   </ul>
                `,
            // image: op, // Replace with actual image path
            // imagePlacement: 'left', // Image on the left side
          },
          {
            sectionTitle: <span style={{ color: 'orange' }}>2.9	Acting Out Every Day Algorithms</span>,
            // layout: 'two-images', // New layout type for two images
            //leftImage: io, // Replace with the actual path
            //
            // rightImage: op, // Replace with the actual path
            content: `<b>Activity: </b>Role-play tasks with steps, like tying shoes or setting the table.
                  <ul class="list-disc pl-6">
                  <b>Steps for Activity</b>
                   <li><b>Introduce the Concept of Everday Algorithms</b>
                   </li>
                   <ol>
                   <li>Explain that an algorithm is simply a sequence of steps to achieve a specific outcome.</li>
                   <li>Give examples of everyday algorithms, such as instructions for making a sandwich or brushing teeth.</li>
                   </ol>
                   <li><b>Choose a Task to Act Out</b></li>
                    <ol>Select a task that has clear, sequential steps and is familiar to students. Suggested tasks:
                    <li><b>Setting the Table:</b></li>
                    <li>Step 1: Place the placemat on the table.</li>
                    <li>Step 2: Put a plate in the center of the placemat.</li>
                    <li>Step 3: Place a fork to the left of the plate.</li>
                    <li>Step 4: Place a knife and spoon to the right of the plate.</li>
                    <li>Step 5: Put a cup or glass above the plate.</il></ol>
                   <li><b>Role-Play the Task</b></li>
                   <ol>
                    <li>Assign each step to a student or group of students, or have a single student act out the entire task</li>
                    <li>As students perform each step, narrate or discuss the purpose of each step in achieving the goal.</li></ol>
                   </ul>
                `,
            // image: op, // Replace with actual image path
            // imagePlacement: 'left', // Image on the left side
          },
          {
            sectionTitle: <span style={{ color: 'orange' }}>2.10 Create Your Own Sequence</span>,
            // layout: 'two-images', // New layout type for two images
            //leftImage: io, // Replace with the actual path
            //
            // rightImage: op, // Replace with the actual path
            content: `<b>Activity: </b>Plan a simple sequence for an activity, like drawing a shape or playing a game.(By students)
    
              
            `,
            // image: op, // Replace with actual image path
            // imagePlacement: 'left', // Image on the left side
          },

        ]
      }, {
        id: 3,
        title: '3.Scratch Introduction  ',
        // description: 'Learn about the foundational concepts of computers and technology.',
        details: [
          {
            sectionTitle: <span className="text-purple-600"> Scratch Story Worlds</span>,
            content: `<p><b>Title: "The Adventures of Sprite and Script"</b></p>
            <p>Once upon a time, in a digital world called Scratchland, there lived a curious character named Sprite. Sprite was a colourful and lively character who loved to explore and create new things. However, Sprite often found themselves stuck in one place, unable to move or interact with the world around them.</p>
<p>One day, while wandering through Scratchland, Sprite stumbled upon a magical toolbox filled with colorful blocks. Each block seemed to hold a special power, and Sprite couldn't resist experimenting with them. As Sprite clicked and dragged the blocks together, they discovered that they could make themselves move, jump, and even change colors!</p>
<p>Excited by their newfound abilities, Sprite set off on an adventure to explore Scratchland like never before. Along the way, Sprite encountered other characters, each with their own unique abilities and challenges. There was Cat, who could meow and purr with delight; Dog, who could bark and wag their tail; and Bird, who could chirp and fly high in the sky.</p>
<p>Together, Sprite and their new friends embarked on a journey to solve puzzles, overcome obstacles, and unleash their creativity. With the help of the magical blocks, they built bridges, climbed mountains, and even created their own games and stories.</p>
<p>But their adventure was not without its challenges. Along the way, they encountered a mischievous character named Bug, who loved to cause trouble and chaos wherever they went. Bug would try to disrupt Sprite and their friends' creations, but with teamwork and determination, they always found a way to outsmart Bug and restore peace to Scratchland.</p>
<p>As their journey continued, Sprite and their friends discovered that the true magic of Scratchland was not just in the blocks or the characters, but in the endless possibilities of imagination and creativity. With Scratch, they could create anything they could dream of, from interactive stories to animated adventures.</p>
<p>And so, Sprite and their friends continued to explore and create, inspired by the limitless world of Scratchland and the power of programming to bring their ideas to life.
</p>`,
            image: cat, // Replace with actual image path
            imagePlacement: 'right', // Image on the right side
          },
          {
            sectionTitle: <span className="text-purple-600">SCRATCH</span>,
            content: `<p>Scratch is an innovative platform designed to introduce programming concepts in a playful and interactive manner, particularly catering to young learners. Its visual programming language employs a block-based interface, where users drag and drop colourful blocks representing different commands and functions to create scripts. This approach eliminates the need for writing complex code syntax, making it accessible even to those with no prior programming experience.</p>
<p>The philosophy behind Scratch is rooted in the idea of learning through exploration and creativity. Users can easily create animations, games, interactive stories, and more by combining various blocks to control characters, objects, and actions within their projects. This hands-on approach allows learners to experiment freely, fostering a deep understanding of fundamental programming concepts such as sequencing, loops, conditionals, and variables.</p>
<p>One of the defining features of Scratch is its vibrant online community, where users can share their projects, receive feedback, and collaborate with others. This collaborative aspect encourages peer learning and provides an opportunity for learners to gain inspiration from a diverse range of creations. Additionally, the platform offers extensive resources, tutorials, and challenges to support users in their coding journey.</p>
<p>Overall, Scratch serves as a gateway for individuals, especially children, to explore the world of computer science in a fun, engaging, and non-intimidating way. By combining creativity with computational thinking, Scratch empowers learners to express themselves through coding while developing valuable skills that are applicable across various domains.</p>
<p>In addition to the traditional Scratch platform, there are a few variations and extensions that offer different features and capabilities tailored to specific needs or age groups:</p>
 `,
            //               image: parts, // Replace with actual image path
            //             imagePlacement: 'left', // Image on the left side
          },
          {
            sectionTitle: <span className="text-purple-600">ScratchJr: </span>,
            content: `<p>This version of Scratch is designed for younger children (ages 5-7) and is available as a tablet app. It simplifies the Scratch interface and provides a more intuitive experience for young learners to create animations and simple interactive projects.</p>
            <p>This is the interface of ScratchJr, which is designed for younger children, simplifies the Scratch interface to make it more accessible and intuitive. Here are its main components: </p>
<p><b><u>Sprites:</u></b> Sprites are the characters, objects, or elements that users can program to interact with each other. In Scratch Jr, sprites are typically simple and colorful, making them appealing to young children.</p>
<p><b><u>Blocks:</u></b> Just like in the regular Scratch platform, Scratch Jr uses colourful blocks that represent different programming commands and actions. These blocks can be dragged and dropped to create scripts that control the behaviour of sprites.</p>
<p><b><u>Stage:</u></b> The stage is the area where the action of the program takes place. In Scratch Jr, the stage typically features a blank canvas where children can place sprites and create their scenes.</p>
<p><b><u>Scripts:</u></b> Scripts are sequences of blocks that determine the behavior of sprites. In Scratch Jr, users can assemble these blocks to create animations, stories, and simple interactive experiences.</p>
<p><b><u>Paint Editor: </u></b>Scratch Jr includes a simple paint editor that allows children to create and customize their own sprites. This feature encourages creativity and personalization in their projects.</p>
<p><b><u>Recording:</u></b> Scratch Jr also allows users to record their voices and sounds directly within the app, enabling them to add narration and sound effects to their creations.</p>`,

            image: scratchjr, // Replace with actual image path
            imagePlacement: 'left', // Image on the left side
          },


          {
            sectionTitle: <span className="text-purple-600">Scratch 3.0:</span>,
            // layout: 'two-images', // New layout type for two images
            //leftImage: io, // Replace with the actual path
            //
            // rightImage: op, // Replace with the actual path
            content: `
              <p>This is the latest version of Scratch as of my last update. It introduced several new features and improvements over Scratch 2.0, including an updated interface, new blocks, and enhanced functionality. Scratch 3.0 remains primarily web-based, but it offers improved compatibility with different devices and screen sizes.</p>
              <p>By looking at the picture we can find some parts as code, costumes and sound. The code part consists of number of blocks that can used to write a program, and the blocks are given by</p>
            `,

            image: blockscratch, // Replace with actual image path
            imagePlacement: 'left', // Image on the left side
          },
          {
            sectionTitle: <span style={{ color: 'orange' }}>Motion Blocks</span>,
            content: '<p>These blocks control the movement and positioning of sprites on the stage. They include blocks to move sprites forward, backward, left, or right, as well as blocks to change their direction, glide to a specific location, and more. </p>',
            image: look_block, // Replace with actual image path
            imagePlacement: 'left',
          }
          ,
          {
            sectionTitle: <span style={{ color: 'orange' }}>Looks Blocks</span>,
            content: '<p>Looks blocks determine the appearance and visual effects applied to sprites. They include blocks to change costumes, show or hide sprites, set their size, change their colour, and apply various graphical effects. </p>',
            image: sound_block, // Replace with actual image path
            imagePlacement: 'left',
          }
          ,
          {
            sectionTitle: <span style={{ color: 'orange' }}>Sound Blocks</span>,
            content: '<p>Sound blocks allow users to incorporate sounds and music into their projects. They include blocks to play sounds, stop sounds, change volume, and adjust other sound properties.</p>',
            image: event_block, // Replace with actual image path
            imagePlacement: 'left',
          }
          ,
          {
            sectionTitle: <span style={{ color: 'orange' }}> Event Blocks</span>,
            content: '<p>Events blocks trigger actions in response to certain events or conditions. They include blocks to start scripts when the green flag is clicked, when a sprite is clicked, when a key is pressed, and more.</p>',
            image: event1, // Replace with actual image path
            imagePlacement: 'left',
          }
          ,
          {
            sectionTitle: <span style={{ color: 'orange' }}> Control Blocks</span>,
            content: '<p>Control blocks determine the flow and execution of scripts. They include blocks to control loops (e.g., repeat loops and forever loops), conditionals (e.g., if-else statements), and other control structures.</p>',
            image: control_block, // Replace with actual image path
            imagePlacement: 'left',
          }
          ,

          {
            sectionTitle: <span style={{ color: 'orange' }}> Sensing Blocks</span>,
            content: '<p>Sensing blocks allow sprites to detect and respond to various inputs and conditions. They include blocks to detect user input (e.g., keyboard input and mouse input), detect sprite collisions, sense the stage boundaries, and more.</p>',
            image: senser_block, // Replace with actual image path
            imagePlacement: 'left',
          }
          , {
            sectionTitle: <span style={{ color: 'orange' }}> Operators Blocks</span>,
            content: '<p>Operators blocks perform mathematical and logical operations. They include blocks to perform arithmetic operations (e.g., addition, subtraction, multiplication, division), comparison operations (e.g., equal to, greater than), and logical operations (e.g., AND, OR, NOT). </p>',
            image: operations_block, // Replace with actual image path
            imagePlacement: 'left',
          }
          ,
          {
            sectionTitle: <span style={{ color: 'orange' }}> Variables Blocks</span>,
            content: '<p>Variables blocks allow users to create and manipulate variables within their projects. They include blocks to set variable values, change variable values, and display variable values on the stage.</p>',
            image: variables_block, // Replace with actual image path
            imagePlacement: 'left',
          }
          ,
          {
            sectionTitle: <span style={{ color: 'orange' }}> Custom Blocks</span>,
            content: `<p>In Scratch 3.0, users can create custom blocks by grouping together sequences of existing blocks. This allows users to define their own procedures and functions, making it easier to reuse and organize code.</p>
            <p>If we want to change the Sprite costume, colour or if we want to search for our own Sprite then we have to click on the button which is at the bottom right of the screen as shown below.</p>  
            <p>In this picture, when we look at the bottom right there is a cat symbol with a plus mark, in that we can able to change our sprite, the color of that sprite and we can even upload it. </p>
            <ul class="list-disc pl-6">
               <li>	This option allows you to browse a library of pre-made sprites and choose one to add to your project. You can select from a variety of characters, objects, animals, and more.</li>
<li>Clicking this option opens the paint editor, where you can create your own sprite from scratch using various drawing tools and colors. This is useful if you want to customize your sprite to fit your project's specific needs.</li>
<li>Use this option to upload an image file from your computer to use as a sprite in your project. This could be a picture of a character, object, or any other image you want to include.
</li>
               </ul>
            `,
            image: chooseshape, // Replace with actual image path
            imagePlacement: 'left',
          }
          ,
          {
            sectionTitle: <span className="text-purple-600">Benefits of Learning Scratch Programming for Kids</span>,
            // layout: 'two-images', // New layout type for two images
            //leftImage: io, // Replace with the actual path
            //
            // rightImage: op, // Replace with the actual path
            content: `<p>Learning Scratch programming helps kids foster the essential 21-st century skills while improving academic performance. Here are the five amazing benefits of learning Scratch programming for kids.</p>
            <p>Scratch is a visual programming language that lets children create a wide range of projects, including interactive stories, games, animations, and much more.</p>  
            <p>Scratch programs are made up of graphical coloured blocks. Kids can create programs by snapping different code blocks together.</p>
            <p>The blocks resemble puzzle pieces and can only be snapped together in ways that make sense. It prevents kids from using them in invalid combinations.</p>
            <p>In this way, Scratch enforces proper programming syntax and ensures that beginners learn the proper way to assemble and formulate programming logic.</p>
            <p>Encourages creative thinking</p>
            <p><b>Scratch<b>’s slogan is Imagine—Program—Share! It’s designed primarily for kids to encourage creativity by providing them with an easy-to-learn yet powerful programming platform.</p>
<p>It has a large library of predefined sprites and backgrounds, but it also lets kids draw their designs. With scratch code, kids will be able to create interactive stories with any character they can imagine.</p>
<p>Customizing sprites and creating interactive projects will improve their creativity and make them better at design, animation, and storytelling.</p>
<p>Scratch facilitates the development of projects using a mixture of media, graphics, and sound to create something new. It enables kids to unleash the power of their imagination.
</p>
            `,
            image: create_thinking, // Replace with actual image path
            imagePlacement: 'left', // Image on the left side
          },

          {
            sectionTitle: <span className="text-purple-600">Fosters problem-solving</span>,
            content: `The Scratch programming language is designed to support the constructionist approach to learning, which encourages problem-solving.
            <p>Scratch programming helps kids develop programmatic thinking. This involves kids breaking down problems into smaller sub-components by organizing code blocks and exploring multiple solutions to problems.</p>
            <p>To plan out the mechanics of their game or story, kids will need to figure out how multiple parts of their code or design will work together. Practising brainstorming in Scratch helps kids develop strong problem-solving and organizational skills transferable across multiple areas of life.</p> 
            
            `,
            image: fosters_problem, // Replace with actual image path
            imagePlacement: 'left', // Image on the left side
          },
          {
            sectionTitle: <span className="text-purple-600">Scratch programming is Fun and interactive</span>,
            content: `<p>Learning happens naturally when kids are having fun. Scratch programming language is designed primarily for kids with a visually appealing interface.</p>
<p>Kids need to drag blocks from the block palette into the coding workspace to start building programs. Kids can snap code blocks to make characters move, jump, dance, and sing. They can modify how their characters look, and design their backgrounds.</p>
<p>With Scratch, kids can add their voices and sounds and even photos of themselves and then use the programming blocks to bring their characters to life. This allows kids to create games, animations, and other interactive programs easily.</p>
<p>In this way, children explore coding concepts in a safe and fun environment.
</p>
`,
            image: drag_blocks, // Replace with actual image path
            imagePlacement: 'left', // Image on the left side
          },
          {
            sectionTitle: <span className="text-purple-600">Helps develop logical thinking skills</span>,
            content: `<p>With Scratch, kids can drag and drop code blocks. It involves dragging and dropping code blocks instead of writing code while children still learn coding logic.</p>
<p>They can see what they are building and the result of their actions right away. This helps kids to understand and analyse the logic behind the programs.</p>
<p>Logic with Scratch builds on foundational concepts such as variables, data types, lists, arrays, loops, and operators. These skills also translate to other languages when students advance from the popular block-based coding to text-based coding.</p>
`,
            image: kid_typeing, // Replace with actual image path
            imagePlacement: 'left', // Image on the left side
          }, {
            sectionTitle: <span className="text-purple-600">Stepping stone to high-level programming</span>,
            content: `<p>Scratch programming is one of the best ways for kids to learn to code.</p>
<p>It’s the perfect pathway for kids to learn programmatic thinking and get an enthusiastic head start into real-world coding skills.</p>
<p>Many other programming languages require kids to learn text commands and have strict rules about using them.</p>
<p>Scratch enables kids to focus their energy on the fun stuff, thinking of ideas for new programs, working out how to build them, designing them, and sharing them with friends.</p>
<p>Today, educators worldwide use Scratch programming to teach kids subjects like mathematics, music, art, design, and many more. Scratch is designed for exploration and experimentation, supporting many different learning styles.</p>
<p>Our Scratch classes are a fun and exciting way to build a foundation of skills that prepare kids for the future. Codingal motivates kids to bring their imagination to life by engaging in Scratch course activities individually and in groups.</p>


`,
            image: high_level_programming, // Replace with actual image path
            imagePlacement: 'left', // Image on the left side
          },

        ]
      }, {
        id: 4,
        title: '4. Introduction to stem verse and Animation Basics',
        // description: 'Learn about the foundational concepts of computers and technology.',
        details: [
          {
            sectionTitle: <span className="text-purple-600"> 4.1 Getting Started with Stem Verse</span>,
            content: `<p>Imagine a magical world where you can create your own stories, make characters dance, add fun sounds, and even build little games! This world is called Stem Verse. Think of <b>Stem Verse</b> as your very own <b>adventure playground</b> on the computer, where you can be the artist, storyteller, and game-maker all at once.</p>
<p>To get started, all you need is to open Stem Verse and explore! You’ll see lots of tools, colors, shapes, and characters that you can use to create whatever you want. It’s like having a big box of craft supplies for your imagination. Ready to make some magic? Let’s go!</p>`,
            image: stemversa1, // Replace with actual image path
            imagePlacement: 'left', // Image on the right side
          },
          {
            sectionTitle: <span className="text-purple-600"> 4.2 Understanding Sprites</span>,
            content: `<p>In Stem Verse, you’ll meet friendly little characters called <b>sprites</b>. Sprites are like actors in your story. They can look like animals, people, cars, or even silly creatures you invent yourself!</p>
            <p>Imagine sprites are like puppets, and <b>you’re the puppet master</b>. You can make them move, talk, change colors, and do all kinds of cool stuff. Want a cat to jump? Or a dragon to fly? With sprites, you can make anything happen. Each sprite is waiting for you to tell it what to do in your story or game.</p>
 `,
            image: chitte, // Replace with actual image path
            imagePlacement: 'left', // Image on the left side
          },
          {
            sectionTitle: <span className="text-purple-600">4.3 Moving Sprites with Sequencing</span>,
            content: `<p>Once you have your sprites, it’s time to make them <b>move!</b> But just like you can’t run before you take a step, sprites need to follow steps in the right order, too. This is called sequencing.</p>
            <p>Imagine you have a sprite that’s a cute bunny, and you want it to hop across the screen. You would tell the bunny to:</p>
            <ul class="list-disc pl-6"> 
           <li> <strong>1.Take a step forward,</strong></li>
<li> <strong>2.Jump up a little,</strong></li>
<li> <strong>3.Land back down,</strong></li>
<li> <strong>4.Take another step.</strong></li>
<p>When you put these steps in the right order, the bunny looks like it’s hopping! If you mix up the steps, the bunny might end up doing silly things like jumping backward or just spinning around. By using sequencing, you can guide your sprite to dance, run, or even fly across the screen like a superhero!</p>
.</ul>`,
            image: moving_sprites, // Replace with actual image path
            imagePlacement: 'left',
          },


          {
            sectionTitle: <span className="text-purple-600">4.4 Adding Sounds to Animations</span>,
            // layout: 'two-images', // New layout type for two images
            //leftImage: io, // Replace with the actual path
            //
            // rightImage: op, // Replace with the actual path
            content: `
              <p>What’s a story without sounds? In Stem Verse, you can make your animations even more fun by adding <b>sounds</b>. Imagine a lion roaring, a bell ringing, or a spaceship zooming by—each sound makes your story more exciting!</p>
              <p>Let’s say you have a sprite of a robot. When the robot moves, you can add a <b>beeping sound</b> every time it takes a step. Or if you have a sprite that’s a bird, you can add chirping sounds to make it feel like a real bird. You can even record your own voice to make characters say funny things!</p>
              <p>Adding sounds makes your stories come alive. It’s like adding music to a dance or laughter to a joke. Try adding a sound and see how it makes your animation more fun to watch!</p>
              
            `,
            // image: io, // Replace with actual image path
            // imagePlacement: 'left', // Image on the left side
          },

          {
            sectionTitle: <span className="text-purple-600">4.5 Creating Simple Animations</span>,
            // layout: 'two-images', // New layout type for two images
            //leftImage: io, // Replace with the actual path
            //
            // rightImage: op, // Replace with the actual path
            content: `
              Now that you know about sprites, sequencing, and sounds, let’s put it all together to make animations! Animation is just a fancy word for making pictures move.
Imagine you have a sprite that’s a dog, and you want to show it wagging its tail. You would:
 <ul class="list-disc pl-6"> 
           <li> <strong>1.1.	Make the tail move up a little.</strong></li>
<li> <strong>2.	Then make the tail move down a little.</strong></li>
<li> <strong>3.	Repeat these steps to show the tail wagging happily.</strong></li>
<p>Congratulations—you just created an animation! By changing tiny movements over and over, you make the sprite look like it’s moving smoothly. You can make a flower bloom, a car race across the screen, or even a superhero fly!
Animations are like making mini-movies, and each time you make a new one, you get to tell a part of your story in a cool way. So go ahead, start animating, and let your imagination run wild!
</p>
.</ul>
            `,
            // image: op, // Replace with actual image path
            // imagePlacement: 'left', // Image on the left side
          },

          //           {
          //             sectionTitle: <span className="text-purple-600">Understanding Buttons and Icons</span>,
          //             content: `When you look at a computer screen, you see lots of little pictures and buttons. These pictures are called <b>icons</b>, and they help you find and open different programs.
          //             <ul class="list-disc pl-6">
          //            <li><strong>Icons:</strong> Each icon is like a shortcut. If you want to open a game, you might click on a picture that looks like a joystick or a soccer ball. If you want to see the photos you took, you might click an icon that looks like a camera or a picture.</li>
          //            <li><strong>Buttons:</strong> Buttons are like switches. When you click a button, something happens. For example, if you’re watching a video, there’s a button to “pause” or “play.” If you’re drawing, there might be a button to change colors or erase something.</li>
          //            </ul>
          //            <p>Icons and buttons make it easy to tell the computer what you want to do. Just click, and the computer knows exactly what to show you or do next!</p>
          // `,
          // image: buttons, // Replace with actual image path
          // imagePlacement: 'right', // Image on the left side
          //           },



          { sectionTitle: <span className="text-blue-600">Activity Session</span> },


          {
            sectionTitle: <span style={{ color: 'orange' }}>4.6 Exploring the Stem Verse Platform</span>,
            content: 'Activity: Navigate through Stem Verse to become familiar with its features.'
          }
          ,

          {
            sectionTitle: <span style={{ color: 'orange' }}>4.7	Animating Sprite Movements </span>,
            content: '     Activity: Create a simple animation where a sprite moves across the screen.',
          },
          {
            sectionTitle: <span style={{ color: 'orange' }}>4.8	Building Patterns with Movement</span>,
            // layout: 'two-images', // New layout type for two images
            //leftImage: io, // Replace with the actual path
            //
            // rightImage: op, // Replace with the actual path
            content: `<b>Activity: </b>Create a movement pattern for a sprite using loops.
                    <ul class="list-disc pl-6">
                    <b>Step-by-Step Instructions:</b>
                     <li><b>1.	Choose a Sprite and Background</b>
                     </li>
                     <ol>
                     <li>Have students select a sprite to animate, such as a character or a simple shape.</li>
                     <li>Encourage them to pick a clear, contrasting background to make the movement pattern visible.</li>
                     </ol>
                     <li><b>2.	Introduction to Direction Changes</b></li>
                      <ol>
                      <li>o	Direction Blocks: Introduce blocks like "turn [15] degrees" and "point in direction [ ]" to help them control the direction of the sprite’s movement.</li>
                      </ol>
                     <li><b>3.	Create a Simple Pattern Using Loops</b></li>
                     <ol>Square Pattern (Example):
                      <li><b>Explain how moving in a square requires moving forward and then turning 90 degrees.</li>
                      <li><b>Walk them through the code:</b>	Use a "repeat [4]" block.<li>
                      
                      <li>Inside the loop, add "move [50] steps" and "turn [90] degrees".</li>
                      <li>This code will move the sprite in a square pattern</li>
                  
                     
                      </ol>
                     </ul>
                  `,
            // image: op, // Replace with actual image path
            // imagePlacement: 'upper', // Image on the left side
          },
          {
            sectionTitle: <span style={{ color: 'orange' }}>4.9 Adding Sound Effects to Animations </span>,
            content: `<b>Activity: </b>Create a movement pattern for a sprite using loops.
        <ul class="list-disc pl-6">
        <b>Step-by-Step Instructions:</b>
         <li><b>1.	Choose a Sprite and Background</b>
         </li>
         <ol>
         <li>Have students select a sprite to animate, such as a character or a simple shape.</li>
         <li>Encourage them to pick a clear, contrasting background to make the movement pattern visible.</li>
         </ol>
         <li><b>2.	Introduction to Direction Changes</b></li>
          <ol>
          <li>o	Direction Blocks: Introduce blocks like "turn [15] degrees" and "point in direction [ ]" to help them control the direction of the sprite’s movement.</li>
          </ol>
         <li><b>3.	Create a Simple Pattern Using Loops</b></li>
         <ol>Square Pattern (Example):
          <li><b>Explain how moving in a square requires moving forward and then turning 90 degrees.</li>
          <li><b>Walk them through the code:</b>	Use a "repeat [4]" block.<li>
          
          <li>Inside the loop, add "move [50] steps" and "turn [90] degrees".</li>
          <li>This code will move the sprite in a square pattern</li>
      
         
          </ol>
         </ul>
      `,
          },
          {
            sectionTitle: <span style={{ color: 'orange' }}>4.10	Showcasing Animations </span>,
            content: `<b>Activity: </b>Create a movement pattern for a sprite using loops.
        <ul class="list-disc pl-6">
        <b>Step-by-Step Instructions:</b>
         <li><b>1.	Choose a Sprite and Background</b>
         </li>
         <ol>
         <li>Have students select a sprite to animate, such as a character or a simple shape.</li>
         <li>Encourage them to pick a clear, contrasting background to make the movement pattern visible.</li>
         </ol>
         <li><b>2.	Introduction to Direction Changes</b></li>
          <ol>
          <li>o	Direction Blocks: Introduce blocks like "turn [15] degrees" and "point in direction [ ]" to help them control the direction of the sprite’s movement.</li>
          </ol>
         <li><b>3.	Create a Simple Pattern Using Loops</b></li>
         <ol>Square Pattern (Example):
          <li><b>Explain how moving in a square requires moving forward and then turning 90 degrees.</li>
          <li><b>Walk them through the code:</b>	Use a "repeat [4]" block.<li>
          
          <li>Inside the loop, add "move [50] steps" and "turn [90] degrees".</li>
          <li>This code will move the sprite in a square pattern</li>
      
         
          </ol>
         </ul>
      `,
          },

        ]
      }, {
        id: 5,
        title: '5. Interactive Storytelling with Sprites:',
        // description: 'Learn about the foundational concepts of computers and technology.',
        details: [
          {
            sectionTitle: <span className="text-purple-600">5.1 Storytelling with Sprites</span>,
            content: `<p>Imagine you’re the <b>director of your own cartoon show!</b> In Stem Verse, you can make up any story you want, using sprites as the characters. Sprites can be anyone you want—a friendly cat, a flying superhero, a talking robot, or even a dragon!</p>
<p>When you tell a story with sprites, it’s like putting on a play. You get to decide who the characters are, where they go, and what they do. Think of your sprites as the “actors” in your story. Each sprite can move, talk, and interact with other sprites in fun ways. Ready to make your story come alive? Let’s get to know the steps!
</p>`,
          },
          {
            sectionTitle: <span className="text-purple-600"> 5.2 Planning a Simple Story Sequence</span>,
            content: `<p>Every great story has a beginning, middle, and end. Let’s plan your story so you know what’s going to happen and in what order!</p>
<p>Imagine you want to tell the story of <b>Sammy the Super Squirrel </b>who finds a magic acorn. Start by asking yourself:
</p>

 <ul class="list-disc pl-6">
    <li><strong>Beginning:</strong> How does the story start? (Maybe Sammy is searching for food in the forest.)</li>
    <li><strong>	Middle: </strong> What’s the exciting part? (Sammy finds a glowing, magical acorn that gives him superpowers!)</li>
    <li><strong>End:</strong> How does it finish? (Sammy uses his new powers to save his friends from danger.)</li>
  </ul>
  Once you have your beginning, middle, and end, you’re ready to set up each part in Stem Verse. Just like a comic book or a movie, knowing what happens next will help you bring your story to life step by step!
`,
            image: squirrel, // Replace with actual image path
            imagePlacement: 'left', // Image on the left side
          },
          {
            sectionTitle: <span className="text-purple-600">5.3 Creating Characters and Settings</span>,
            content: `Every story needs characters (like Sammy the Super Squirrel) and settings (like the forest where he lives). In Stem Verse, you get to create both!

            <ul class="list-disc pl-6"> 
           <li> <strong>Characters: </strong> Think of who will be in your story. Maybe it’s not just Sammy—he might have a friend, like Bella the Brave Bird, or a wise old turtle. You can pick different sprites to be each character in your story, and even customize how they look.</li>
<li> 	Settings:</strong>  This is the place where your story happens. Is it a forest, a mountain, or maybe even outer space? In Stem Verse, you can create different backgrounds for each part of your story. Think about what your characters would see around them, and use those details to make the story feel real.</li>
By choosing fun characters and cool places for them to explore, you’re setting up the stage for an amazing adventure!
.</ul>`,
            image: jungle, // Replace with actual image path
            imagePlacement: 'left',
          },


          {
            sectionTitle: <span className="text-purple-600"> 5.4 Using Dialogue in Storytelling</span>,
            // layout: 'two-images', // New layout type for two images
            //leftImage: io, // Replace with the actual path
            //
            // rightImage: op, // Replace with the actual path
            content: `
              <p>Dialogue means making your characters <b>talk to each other.</b> This is where your story really comes to life because we get to hear what each character is thinking or feeling!</p>
<p>For example, if Sammy the Super Squirrel finds the magic acorn, he might say, “Wow! This acorn feels… powerful!” And if Bella the Brave Bird sees him, she might say, “Sammy, you’re glowing! Did you find something special?”</p>
</p>To add dialogue in Stem Verse, you can use speech bubbles or record your own voice. You get to decide what each character says, and that helps make the story more exciting and funnier! Remember to give each character their own personality so they sound unique.</p>

            `,
            // image: io, // Replace with actual image path
            // imagePlacement: 'left', // Image on the left side
          },

          {
            sectionTitle: <span className="text-purple-600"> 5.5 Finalizing Story Projects</span>,
            // layout: 'two-images', // New layout type for two images
            //leftImage: io, // Replace with the actual path
            //
            // rightImage: op, // Replace with the actual path
            content: `
            <p>You’re almost done! Now it’s time to put all the pieces together and <b>make sure your story flows smoothly</b>. Check if:</p>
<ul class="list-disc pl-6"> 
	<li>The sprites move and talk in the right order.</li>
<li>The backgrounds change when the story moves to a new place.</li>
<li>The characters are saying the right things and moving at the right times.</li>
<p>If everything looks good, give your story a title, like<b> "Sammy and the Magic Acorn Adventure!"</b> And maybe even add a little message at the end, like “Thanks for watching!” or “Stay tuned for more adventures!”</p>
<p>Now you can share your story with friends and family. They’ll be amazed at how you brought your characters and story to life with Stem Verse!</p>
</ul>
<p><b>Activity by children: </b>Now that you’ve read the story about Sammy, your task is to recreate it on the Stemverse platform. After finishing all the lab activities, which will be explained in upcoming classes, you can start working on it.</p>         
`,
            // image: op, // Replace with actual image path
            // imagePlacement: 'left', // Image on the left side
          },

          { sectionTitle: <span className="text-blue-600">Activity Session</span> },


          {
            sectionTitle: <span style={{ color: 'orange' }}>5.6 Creating a Story Using Spritess</span>,
            content: 'Students interact with pictures and devices to identify different types of computers.'
          }
          ,

          {
            sectionTitle: <span style={{ color: 'orange' }}>5.7	Designing Characters for the Story </span>,
            content: ' Match computer part pictures with their names and functions in a group activity.',
          },
          {
            sectionTitle: <span style={{ color: 'orange' }}>5.8	Building Scenes for the Story </span>,
            content: 'Practice using a mouse to click and drag, and use a keyboard to type letters.',
          },
          {
            sectionTitle: <span style={{ color: 'orange' }}>5.9	Incorporating Dialogue into the Animation </span>,
            content: ' Hands-on activity to explore different devices and identify their roles.',
          },
          {
            sectionTitle: <span style={{ color: 'orange' }}>5.10	Final Project Presentation</span>,
            content: 'Use an interactive app or software to explore buttons and icons.',
          },

        ]
      },
    ],
    4: [{
      id: 1,
      title: '1. Algorithms and Patterns',
      // description: 'Learn about the foundational concepts of computers and technology.',
      details: [
        {
          sectionTitle: <span className="text-purple-600"> Understanding Patterns in Algorithms</span>,
          content: `<p>What’s an Algorithm? Think of an algorithm as a secret code of steps that tells a computer or robot exactly what to do! Let’s make it more exciting imagine you’re about to become a robot! 🦾 Your job is to follow the instructions, or “algorithm,” to complete a task.</p>
<p><strong>Activity: The Sandwich-Making Algorithm</strong></p>
<ul class="list-disc pl-6">
  <li>Step 1: First, we’ll pretend you’re a “robot chef.”</li>
  <li>Step 2: I’ll give you a set of steps (an algorithm) to make a sandwich.</li>
  <ol>2.1 Pick up two slices of bread.</ol>
  <ol>2.2 Spread peanut butter on one slice.</ol>
  <ol>2.3 Spread jelly on the other.</ol>
  <ol>2.4 Put the slices together.</ol>
  <li>Step 3: Try switching the steps around—what happens? You get a funny, messy sandwich! Following steps in order is important for making things work right.</li>
  
</ul>
<p><strong>What’s a Pattern?</strong> A pattern is anything that repeats over and over, just like the colors of a rainbow scarf: red, orange, yellow, green, blue, purple, and then back to red again! Patterns help us know what’s coming next.
</p>`,
          image: topic41, // Replace with actual image path
          imagePlacement: 'left', // Image on the right side
        },
        {
          sectionTitle: <span className="text-orange-600">Activity: Color Pattern Game 🎨</span>,
          content: `
<ul class="list-disc pl-6">
  <li>Let’s make a color pattern with crayons:</li>
  <ol>Lay out the crayons in a line: Red, Blue, Red, Blue…</ol>
  <ol>Ask: What color comes next?</ol>
  <ol>Keep going and see if you can spot the pattern without peeking!</ol>
  
</ul>
<p><strong>How Do Patterns Help in Algorithms?</strong></p>
<p>Algorithms often have steps that repeat in a pattern. Spotting these patterns makes it easier to follow and even helps us make the algorithm faster.</p>`,
          image: topic42, // Replace with actual image path
          imagePlacement: 'right', // Image on the right side
        },
        {
          sectionTitle: <span className="text-orange-600">Activity: Square-Walking Robot 🤖</span>,
          content: `
<ul class="list-disc pl-6">
  <li>Let’s pretend you’re a robot again, but this time we’re giving you an algorithm to walk in a square.</li>
  <ol>Step forward.</ol>
  <ol>Turn right.</ol>
  <ol>Step forward.</ol>
  <ol>Turn right.</ol>
  <li>Keep going—do you notice anything? You’re repeating the same steps to make a square shape. That’s a pattern!Now, if someone asked, “How do you walk in a square?” you’d already know the answer because you spotted the repeating steps.
</li>
  
</ul>
<p><strong>Quick Quiz: Spot the Pattern! 🧩</strong></p>
<p>1. What comes next: Jump, clap, jump, clap, jump…?</p>
<p>2. What about: Walk, turn left, walk, turn left, walk…?</p>`,
          image: chitte, // Replace with actual image path
          imagePlacement: 'left', // Image on the right side
        },

        {
          sectionTitle: <span className="text-purple-600">Complex Algorithms: How They Work</span>,
          content: `<p><strong>What’s a Complex Algorithm?</strong>A complex algorithm is a little more tricky. Instead of just following one simple set of steps, a complex algorithm might have many steps, with choices and different ways to go.
</p>
<p><strong>Imagine It Like a Game Level:</strong> Think of a video game where you might go left or right, and each choice brings you to a different part of the game. Complex algorithms work the same way. They have steps that can change based on the choices made. For example, if you’re giving directions to go through a maze, your algorithm might say “If there’s a wall, turn right; if there’s no wall, go forward.”
</p>`,
          //   image: parts, // Replace with actual image path
          // imagePlacement: 'left', // Image on the left side
        },
        {
          sectionTitle: <span className="text-purple-600">Introduction to Loops: Repeating Actions</span>,
          content: `<p>What’s a Loop? Imagine you’re learning a cool dance move! To get really good at it, you’ll want to repeat the move a few times—maybe even 10 times! Instead of saying, “Do the move” over and over again, you just say, “Repeat this move 10 times!” That’s a loop!
</p>
<p>Loops in Coding: In coding, a loop lets us tell a computer to keep doing the same action over and over without typing the instructions again and again.
</p>
<p><strong>Activity: Dance Loop Challenge! </strong></p>
<p><strong>1. Pick a Dance Move:</strong>Let’s create a simple move, like clapping your hands or spinning in a circle.</p>
<p><strong>2. Try Without a Loop:</strong>Ask everyone to do the move three times but say the move each time (“Clap! Clap! Clap!”). Notice how it takes time to say it each time.</p>
<p><strong>3. Add a Loop: </strong> Now, let’s say, “Repeat this move 5 times.” Everyone should clap five times without needing you to say it again!</p>
<p><strong>Discuss the Loop Magic:</strong>See how much faster it is to say, “Repeat five times” instead of repeating each step? That’s how a loop works!
</p>
<p><strong>Why Use Loops?</strong>Loops make it easier to do things multiple times without repeating yourself. In a program, if you want a character to keep jumping until you say stop, you would use a loop. This way, you don’t have to write “jump” again and again. You just tell it to “keep jumping until I say stop.”
</p>
`,

          image: topic414, // Replace with actual image path
          imagePlacement: 'left', // Image on the left side// No image property added here
        },


        {
          sectionTitle: <span className="text-purple-600">Combining Loops with Algorithms</span>,
          // layout: 'two-images', // New layout type for two images
          //leftImage: io, // Replace with the actual path
          //
          // rightImage: op, // Replace with the actual path
          content: `<p><strong>Why Combine Loops with Algorithms?</strong> When we combine loops with algorithms, it makes our steps even more powerful! We can tell the computer to repeat certain steps without having to write them every time. It’s like saying, “Let’s keep following these instructions over and over.”
</p>
          <p><strong>For Example:</strong>Imagine you’re giving directions for setting the table, but you’re setting a table for 10 people. Instead of saying, “Put a plate, fork, and cup” 10 times, you can use a loop and say, “Repeat putting a plate, fork, and cup 10 times.” Combining loops with algorithms helps us get more done with fewer instructions.</p>
          `,
          image: topic416, // Replace with actual image path
          imagePlacement: 'left', // Image on the left side
        },

        {
          sectionTitle: <span className="text-purple-600">Review of Algorithms and Patterns
          </span>,
          // layout: 'two-images', // New layout type for two images
          //leftImage: io, // Replace with the actual path
          //
          // rightImage: op, // Replace with the actual path
          content: `<p><strong>Let’s Put It All Together!</strong> By now, you’ve learned that an algorithm is like a list of instructions that the computer follows, and patterns in those instructions help us understand what’s repeating. Complex algorithms have different choices, like going left or right, and loops help us repeat actions in the algorithm.</p>
          <p><strong>How Do We Use These Ideas?</strong>When we write a program, we’re often using all these things together. We find patterns to make our instructions simpler, we use loops to repeat actions, and we follow algorithms to get the job done!
</p>
          `,
          // image: op, // Replace with actual image path
          // imagePlacement: 'left', // Image on the left side
        },





        { sectionTitle: <span className="text-blue-600">Activity Session</span> },


        {
          sectionTitle: <span style={{ color: 'orange' }}>1.6 Create an Algorithm Using Patterns</span>,
          content: 'Students will create an algorithm that incorporates patterns in a task of their choice, such as getting ready for school. interact with pictures and devices to identify different types of computers.'
        }
        ,

        {
          sectionTitle: <span style={{ color: 'orange' }}>1.7 Unplugged Activity: Pattern Games
          </span>,
          content: ' Conduct a fun game where students create patterns using physical objects or movements.',
        },
        {
          sectionTitle: <span style={{ color: 'orange' }}>1.8 Write an Algorithm for a Simple Game </span>,
          content: 'Students will write a simple algorithm for a game they enjoy, focusing on the steps involved.',
        },
        {
          sectionTitle: <span style={{ color: 'orange' }}>1.9 Share Algorithms in Small Groups </span>,
          content: ' In small groups, students will share and discuss their algorithms with peers, providing feedback.',
        },
        {
          sectionTitle: <span style={{ color: 'orange' }}>1.10 Create a Pattern-Based Animation</span>,
          content: 'Using a block-based platform, students will create an animation that features repeating patterns.',
        },

      ]
    }, {
      id: 2,
      title: '2.Advanced Sprite Movements',
      // description: 'Learn about the foundational concepts of computers and technology.',
      details: [
        {
          sectionTitle: <span className="text-purple-600">2.1 Advanced Movement Commands for Sprites
          </span>,
          content: `<p> <strong>What’s a Sprite?</strong>A sprite is like a character or object in a computer program that you can control. Think of it like your main character in a game, which you can move around.</p>
          <p><strong>Advanced Movement Commands</strong>You already know simple commands like “move forward” or “turn.” Advanced movement commands let your sprite do even cooler things! For example:
</p>
<ul class="list-disc pl-6">
<li><strong>Glide:</strong> This lets your sprite smoothly move from one spot to another.
</li>
<li><strong>Jump:</strong>You can make your sprite “jump” a certain distance.
</li>
<li><strong>Change Direction:</strong>You can make your sprite face different directions (like left or right). These advanced movements help make your sprite move in exciting ways, like dancing or flying!
</li>
</ul>`,
          // image: computer, // Replace with actual image path
          // imagePlacement: 'right', // Image on the right side
        },
        {
          sectionTitle: <span className="text-purple-600">2.2 Adding Conditions: If-Then Statements
          </span>,
          content: `<p><strong>What’s an If-Then Statement?</strong> An if-then statement is like making a decision based on a rule. You set a condition (the “if” part), and if that condition is true, then an action happens (the “then” part).
</p>
<p>Example: Imagine a game where you collect points. An if-then statement could say, “If the player collects 10 points, then show a victory message!” If the player has less than 10 points, the message won’t show up. It’s a way to add choices or reactions to your program!</p>
<p><strong>Activity: If-Then Game Challenge! 🎉</strong></p>
<p><strong>1. Set Up the Game: </strong> We’ll play a game where you follow some if-then rules. I’ll give you a situation (the if) and an action to do if that situation is true (the then).
</p>
<p><strong>2. Rules to Follow: </strong></p>
<ul class="list-disc pl-6">
  <li>1.  If I say “Jump,” then you hop in place.</li>
  <li>2. If I say “Spin,” then you turn around once.</li>
  <li>3.  If I say “Clap,” then you clap your hands three times.</li>
  
</ul>
<p><strong>3. Play the Game: </strong>Let’s start! I’ll call out commands one by one, and you follow the if-then rules. But listen carefully—only do the action if the condition is true!</p>
<p><strong>Why Use If-Then? </strong> If-then statements help make your program interactive, meaning it responds to what’s happening. They let you control what happens based on different situations.
</p>

`,
          image: topic421, // Replace with actual image path
          imagePlacement: 'left', // Image on the left side
        },
        {
          sectionTitle: <span className="text-purple-600">2.3 Introduction to Events: What Triggers Actions?
          </span>,
          content: `<p><strong>What’s an Event?</strong> An event is something that happens and causes another thing to happen in response. It’s like pressing a button that starts a machine.
</p>
          <p><strong>How Events Work in Coding: </strong>In coding, an event could be clicking the green flag to start a game, pressing a key on the keyboard, or touching a sprite. These actions “trigger” other actions, like making a character move or a sound play.
</p>
          <p><strong>Examples of Events:</strong> </p>
          <ul class="list-disc pl-6"> 
         <li> <strong>When Green Flag Clicked:</strong> The game starts.</li>
<li> <strong>When Space Key Pressed:</strong>The sprite jumps.
</li>
<li> <strong>When Sprite Clicked:</strong> The sprite changes colour.</li>

.</ul>
Events are how we control when things happen in a program, and they make our projects fun and interactive!
`,

          image: topic422, // Replace with actual image path
          imagePlacement: 'left', // Image on the left side/ No image property added here
        },


        {
          sectionTitle: <span className="text-purple-600">2.4 Review of Advanced Commands
          </span>,
          // layout: 'two-images', // New layout type for two images
          //leftImage: io, // Replace with the actual path
          //
          // rightImage: op, // Replace with the actual path
          content: `<p><strong>Putting Advanced Commands Together:</strong> Now that you know about advanced movements, conditions, and events, you’re ready to use them all together! Here’s what you’ve learned:</p>
          <ul class="list-disc pl-6"> 
         <li> <strong>Advanced Movement Commands:</strong>  Make your sprite move in special ways.</li>
<li> <strong>If-Then Statements:</strong> Add rules so your sprite can react to different situations.
</li>
<li> <strong>Events:</strong>  Start actions based on triggers like clicking a sprite or pressing a key.</li>

.</ul>
<p><strong>How They All Work Together:</strong> Using these together makes your project feel like a real game or story. For example, you could create a game where:
</p>
<p>1. When the green flag is clicked, your sprite starts moving.</p>
<p>2. If it touches an obstacle, it stops and says, “Ouch!”
</p>
<p>3. If it reaches the goal, it says, “You win!”
</p>
          `,
          // image: io, // Replace with actual image path
          // imagePlacement: 'left', // Image on the left side
        },


        {
          sectionTitle: <span className="text-purple-600">2.5 Understanding the Logic Behind Coding
          </span>,
          // layout: 'two-images', // New layout type for two images
          //leftImage: io, // Replace with the actual path
          //
          // rightImage: op, // Replace with the actual path
          content: `<p><strong>What is Logic in Coding?</strong> Logic in coding is like a way of thinking that helps us decide what actions a computer should take. It’s all about making decisions, like if-then statements, and following rules that make sense.
</p>
          <p><strong>Why Logic is Important: </strong> Logic helps make sure your program does exactly what you want. Imagine you’re giving a friend directions to your house. If the directions are clear and make sense, your friend will find you easily. But if they don’t, your friend might get lost! The same is true in coding.
</p>
          <p><strong>Examples of Logic in Action:
</strong> </p>
          <ul class="list-disc pl-6"> 
         <li> If the score is 100, then say “Great job!”</li>
<li> If there’s a wall, then the sprite should turn around.
</li>
<li> If a key is pressed, then make the sprite move forward.
</li>

.</ul>
Logic is like giving clear, step-by-step instructions so your program works correctly every time!

          `,
          // image: op, // Replace with actual image path
          // imagePlacement: 'left', // Image on the left side
        },

        { sectionTitle: <span className="text-blue-600">Activity Session</span> },


        {
          sectionTitle: <span style={{ color: 'orange' }}>2.6  Create a Game with Advanced Sprite Movements
          </span>,
          content: ' Students will design a simple game that incorporates advanced sprite movements and conditional statements',
          // layout: 'two-images', // New layout type for two images
          //leftImage: io, // Replace with the actual path
          //
          // rightImage: op, // Replace with the actual path
          // content: `<b>Activity: </b>Arrange steps for tasks like getting dressed or making a sandwich.
          //   <ul class="list-disc pl-6">
          //   <b>Steps for Activity</b>
          //    <li><b>Introduce the Concept of Sequencing</b>
          //    </li>
          //    <ol>
          //    <li>Explain that sequencing means putting actions in the correct order to achieve a goal.</li>
          //    <li>Give an example, like tying shoelaces, where each step must happen in the right order.</li>
          //    </ol>
          //    <li><b>Explain the Sandwich Task</b></li>
          //     <ol>Show the students the materials or list of items involved in making a sandwich, such as:
          //     <li>Bread</li>
          //     <li>Butter or spread</li>
          //     <li>Filling (like cheese or vegetables)</li>
          //     <li>Knife for spreading</li></ol>
          //    <li><b>Discuss Each Step in Order</b></li>
          //    <ol>
          //     <li><b>Step 1:</b> Take two slices of bread.</li>
          //     <li><b>Step 2:</b> Spread butter or another spread on one or both slices of bread.</li>
          //     <li><b>Step 3:</b> Add the filling (e.g., cheese or veggies) on top of one of the slices.</li>
          //     <li><b>Step 4: </b>Place the second slice of bread on top to complete the sandwich.</li></ol>
          //    </ul>
          // `,
          // image: op, // Replace with actual image path
          // imagePlacement: 'left', // Image on the left side
        },
        {
          sectionTitle: <span style={{ color: 'orange' }}>2.7  Use Conditions to Control Sprite Actions</span>,
          // layout: 'two-images', // New layout type for two images
          //leftImage: io, // Replace with the actual path
          content: ' Students will modify their games to include conditional statements that control sprite behaviour based on user input.',
          // rightImage: op, // Replace with the actual path
          // content: `<b>Activity: </b>Build a simple sequence using cards or blocks for a specific task.
          //   <ul class="list-disc pl-6">
          //   <b>Steps for Activity</b>
          //    <li><b>Explain the Concept of an Algorithm</b>
          //    </li>
          //    <ol>
          //    <li>Define an algorithm as a set of step-by-step instructions to complete a task.</li>
          //    <li>Give examples of everyday algorithms, like a recipe for cooking or the instructions for tying shoelaces.</li>
          //    </ol>
          //    <li><b>Introduce the Task: Getting Ready for School</b></li>
          //     <ol>1.Explain that students will create an algorithm for getting ready in the morning.
          //     2.List the typical steps involved:
          //     <li>Wake up</li>
          //     <li>Brush teeth</li>
          //     <li>Eat breakfast</li>
          //     <li>Pack school bag</li>
          //     <li>Put on shoes</li>
          //     </ol>
          //    <li><b>Create Instruction Cards or Blocks</b></li>
          //    <ol>
          //     <li>Write each step on a separate card or use blocks with the steps written on them.</li>
          //     <li>Shuffle the cards/blocks to allow students to arrange them in the correct order.</li>
          //     </ol>
          //     <li><b>Arrange the Steps in the Correct Sequence</b></li>
          //    <ol>
          //     <li>Ask students to organize the steps into a logical order.</li>
          //     <li>Once they’ve arranged the steps, review the sequence together and discuss why each step comes in that particular order.</li>
          //     </ol>
          //      <li><b>Practice Creating Algorithms for Other Tasks</b></li>
          //    <ol>
          //     <li>Give students different tasks to build algorithms for, such as:</li>
          //     <ul><b>Making a Bowl of Cereal:</b>
          //     <li>* Get a bowl, pour cereal, add milk, use spoon to eat.</li></ul>
          //     <ul><b>Planting a Seed:</b>
          //     <li>* Dig a hole, plant the seed, cover with soil, water the plant.</li></ul>
          //     <li>Have students create an algorithm for each task using their cards or blocks.</li>
          //     </ol>
          //    </ul>
          // `,
          // image: op, // Replace with actual image path
          // imagePlacement: 'left', // Image on the left side
        },
        {
          sectionTitle: <span style={{ color: 'orange' }}>2.8  Add Sound and Effects to Your Game
          </span>,
          // layout: 'two-images', // New layout type for two images
          //leftImage: io, // Replace with the actual path
          content: ' Teach students how to add sound effects and animations to their games to make them more engaging.',
          // rightImage: op, // Replace with the actual path
          // content: `<b>Activity: </b>Follow verbal or visual directions to reach a destination in a maze.
          //   <ul class="list-disc pl-6">
          //   <b>Steps for Activity</b>
          //    <li><b>Introduce the Concept of Following Directions</b>
          //    </li>
          //    <ol>
          //    <li>Explain that directions tell us how to get from one place to another.</li>
          //    <li>Emphasize that following directions accurately is essential for reaching a goal, whether in a game, real life, or coding.</li>
          //    </ol>
          //    <li><b>Set Up the Maze</b></li>
          //     <ol>
          //     <li>Draw or print a simple maze on paper or create a large maze on the floor using tape.</li>
          //     <li>Mark the <b>start</b> and <b>finish</b> points clearly.</li>
          //     <li>Optionally, add obstacles or alternate paths to make the maze more challenging.</li>
          //     </ol>
          //    <li><b>Provide Verbal or Visual Directions</b></li>
          //    <ol>Choose one of the following methods:
          //     <li><b>Verbal Directions:</b> A partner or the instructor gives step-by-step directions out loud (e.g., “Move forward two steps,” “Turn left,” “Move forward one step”).</li>
          //     <li><b>Visual Directions:</b> Provide students with a printed list of directions or arrows showing the path to follow.</li>
          //     Allow students to attempt navigating the maze based on these instructions.</ol>
          //     <li><b>Have Students Follow the Directions</b></li>
          //     <ol>
          //     <li>If using a paper maze, students can trace their path with a pencil or finger.</li>
          //     <li>For a floor maze, students can walk through it step-by-step, following the directions precisely.</li>
          //     </ol>
          //    </ul>
          // `,
          // image: op, // Replace with actual image path
          // imagePlacement: 'left', // Image on the left side
        },
        {
          sectionTitle: <span style={{ color: 'orange' }}>2.9  Present Your Game to the Class
          </span>,
          // layout: 'two-images', // New layout type for two images
          //leftImage: io, // Replace with the actual path
          content: 'Each student will present their completed game to the class, explaining their design choices and coding process.',
          // rightImage: op, // Replace with the actual path
          // content: `<b>Activity: </b>Role-play tasks with steps, like tying shoes or setting the table.
          //   <ul class="list-disc pl-6">
          //   <b>Steps for Activity</b>
          //    <li><b>Introduce the Concept of Everday Algorithms</b>
          //    </li>
          //    <ol>
          //    <li>Explain that an algorithm is simply a sequence of steps to achieve a specific outcome.</li>
          //    <li>Give examples of everyday algorithms, such as instructions for making a sandwich or brushing teeth.</li>
          //    </ol>
          //    <li><b>Choose a Task to Act Out</b></li>
          //     <ol>Select a task that has clear, sequential steps and is familiar to students. Suggested tasks:
          //     <li><b>Setting the Table:</b></li>
          //     <li>Step 1: Place the placemat on the table.</li>
          //     <li>Step 2: Put a plate in the center of the placemat.</li>
          //     <li>Step 3: Place a fork to the left of the plate.</li>
          //     <li>Step 4: Place a knife and spoon to the right of the plate.</li>
          //     <li>Step 5: Put a cup or glass above the plate.</il></ol>
          //    <li><b>Role-Play the Task</b></li>
          //    <ol>
          //     <li>Assign each step to a student or group of students, or have a single student act out the entire task</li>
          //     <li>As students perform each step, narrate or discuss the purpose of each step in achieving the goal.</li></ol>
          //    </ul>
          // `,
          // image: op, // Replace with actual image path
          // imagePlacement: 'left', // Image on the left side
        },
        {
          sectionTitle: <span style={{ color: 'orange' }}>2.10 Collaborate on a Group Game Project
          </span>,
          // layout: 'two-images', // New layout type for two images
          //leftImage: io, // Replace with the actual path
          //
          // rightImage: op, // Replace with the actual path
          content: ` In small groups, students will collaborate to create a larger game that incorporates elements from all members’ projects.
  
            
          `,
          // image: op, // Replace with actual image path
          // imagePlacement: 'left', // Image on the left side
        },

      ]
    }, {
      id: 3,
      title: '3.Introduction to Interactive Storytelling',
      //description: '',
      details: [
        {
          sectionTitle: <span className="text-purple-600"> Introduction to Interactive Storytelling</span>,
          content: `<p><strong>What Does Interactive Mean?</strong> Interactive means that the audience, or the person watching or playing, can make choices that affect what happens next. Instead of just watching a story, they get to be a part of it!</p>
          <p><strong>What is Interactive Storytelling?</strong> Interactive storytelling is when you create a story that changes based on the choices the reader or player makes. It’s like a “choose your own adventure” book, where you get to decide what the characters do. If you make a choice, it could change the ending or how the characters feel.</p>
          <p><strong>What is a Character?</strong> A character is a person, animal, or even a talking object in your story. Characters are who the story is about. They can have names, personalities, and things they like or dislike.
</p>
<p><strong>Example: The Hungry Bunny Adventure 🐰🥕</strong></p>`,

        },
        {
          sectionTitle: <span className="text-purple-600">3.1 What is Interactive Storytelling?</span>,
          content: `Interactive storytelling is a fun way to tell stories where the audience gets to make choices that affect how the story unfolds. It’s like being the director of your own adventure! In “The Hungry Bunny Adventure,” readers can help Bella the Bunny decide what to do, making the story more exciting and personal.
`,

        },
        {
          sectionTitle: <span className="text-purple-600">3.2 Designing Characters and Settings
          </span>,
          content: `

<ul class="list-disc pl-6">
  <li><strong>Characters:</strong> </li>
  <ol><strong>1. Bella :</strong>A curious and hungry little bunny who loves carrots and has a brave heart.
</ol>
  <ol><strong>2. The Scarecrow:</strong> A friendly character who helps Bella when she’s brave enough to approach him.
</ol>
  <li><strong>Settings:</strong> </li>
  <ol><strong>1. Meadow:</strong>A sunny and vibrant place where Bella starts her adventure.</ol>
  <ol><strong>2. Forest</strong> A lush area with bushes where Bella finds berries.</ol>
  <ol><strong>3. Garden</strong> A colourful garden filled with flowers and carrots, home to the friendly scarecrow.
</ol>
  
</ul>`,
          //   image: parts, // Replace with actual image path
          // imagePlacement: 'left', // Image on the left side
        },
        {
          sectionTitle: <span className="text-purple-600">3.3 Using Events and Choices in Stories</span>,
          content: `In the adventure, there are key events where Bella has to make choices:
          <ul class="list-disc pl-6"> 
       <li><strong>First Event:</strong>Bella encounters two paths </li>
  <ol><strong>1. Choice A: Go to the Forest Path</strong> (finds berries, no carrots).</ol>
  <ol><strong>2. Choice B: Go to the Garden Path</strong>(finds carrots and a scarecrow).
</ol>
  <li><strong>Second Event:</strong>Bella faces the scarecrow. </li>
  <ol><strong>1. Choice A: Go near the scarecrow</strong> (receives a big carrot for being brave).</ol>
  <ol><strong>2. Choice B: Dig quickly and run away</strong> (finds carrots and escapes).</ol>
  
.</ul>
These choices change the direction of the story and help engage the reader in the adventure!

`,

          image: topic431, // Replace with actual image path
          imagePlacement: 'left', // Image on the left sideimage property added here
        },


        {
          sectionTitle: <span className="text-purple-600">3.4 Writing a Simple Story Outline</span>,
          // layout: 'two-images', // New layout type for two images
          //leftImage: io, // Replace with the actual path
          //
          // rightImage: op, // Replace with the actual path
          content: `Here’s how you can outline <strong>“The Hungry Bunny Adventure”</strong>:

            <p><strong>1. Introduction: </strong> Introduce Bella, the hungry bunny, in the sunny meadow.</p>
            <p><strong>2. First Choice: </strong>  Bella must choose between the forest path and the garden path.
</p>
<p><strong>3. First Outcome: </strong></p>
<ul class="list-disc pl-6">
  <li><strong>If Forest Path:</strong> Bella finds berries but no carrots and decides to explore again.</li>
  <li><strong>If Garden Path:</strong> Bella finds the garden with carrots and a scarecrow.</li></ul>
<p><strong>4. Second Choice:</strong>Bella decides whether to approach the scarecrow or dig for carrots quickly.</p>
<p><strong>5. Second Outcome: </strong></p>
<ul class="list-disc pl-6">
  <li><strong>If Near the Scarecrow:</strong>  Bella receives a big carrot for her bravery.</li>
  <li><strong>If Dig and Run:</strong> Bella successfully finds carrots and enjoys them safely.</li></ul>
<p><strong>6. Conclusion:</strong>Bella learns valuable lessons from her choices and enjoys a feast of carrots.
</p>
          `,
          // image: io, // Replace with actual image path
          // imagePlacement: 'left', // Image on the left side
        },

        {
          sectionTitle: <span className="text-purple-600">3.6 Review of Storytelling Elements
          </span>,
          // layout: 'two-images', // New layout type for two images
          //leftImage: io, // Replace with the actual path
          //
          // rightImage: op, // Replace with the actual path
          content: `
            <ul class="list-disc pl-6">
             <li><strong>Characters:</strong> Bella (brave bunny), the friendly scarecrow.</li>
             <li><strong>Settings:</strong> Meadow, forest, and garden.</li>
             <li><strong>Plot:</strong>Bella’s journey to find food and the choices she makes along the way.
</li>
             <li><strong>Events:</strong>Encountering paths, meeting the scarecrow, and making choices that change the story.
</li>
             <li><strong>Choices:</strong> Engaging the audience to help Bella decide her fate.</li>
             </ul>
         <strong>Ending:</strong>No matter the choice, Bella finds her carrots and happily enjoys her meal under a shady tree.
             `,
          image: topic432, // Replace with actual image path
          imagePlacement: 'left', // Image on the left side
        },




        { sectionTitle: <span className="text-blue-600">Activity Session</span> },


        {
          sectionTitle: <span style={{ color: 'orange' }}>3.6 Create Your Characters and Settings for the Story </span>,
          content: 'Students will design characters and backgrounds for their interactive stories.'
        }
        ,

        {
          sectionTitle: <span style={{ color: 'orange' }}>3.7 Start Coding Your Interactive Story
          </span>,
          content: 'Students will begin coding the basic structure of their interactive stories using events and commands.',
        },
        {
          sectionTitle: <span style={{ color: 'orange' }}>3.8 Add Choices and Branching Paths to Your Story
          </span>,
          content: 'Students will implement choices that lead to different story outcomes based on user interactions.',
        },
        {
          sectionTitle: <span style={{ color: 'orange' }}>3.9 Test and Refine Your Interactive Story</span>,
          content: 'Students will playtest each other’s stories and provide feedback for improvement.',
        },
        {
          sectionTitle: <span style={{ color: 'orange' }}>3.10 Present Your Interactive Story to the Class</span>,
          content: 'Each student will present their interactive story, demonstrating its features and engaging the audience.',
        },

      ]
    }, {
      id: 4,
      title: '4. Introduction to Simple Games',
      // description: 'Learn about the foundational concepts of computers and technology.',
      details: [
        {
          sectionTitle: <span className="text-purple-600"> 4. Introduction to Simple Games  </span>,
          content: `<p><strong>What Makes Games Enjoyable?</strong>Think about your favourite games. What makes you want to play them? A game is fun if it makes you excited, keeps you interested, and sometimes even makes you think.
</p>`},
        {
          sectionTitle: <span className="text-purple-600"> 4.1 What Makes a Game Fun?  </span>,
          content: `<p><strong>Elements of a Fun Game:</strong></p>
          <ul class="list-disc pl-6"> 
         <li> <strong>Challenges:</strong>Games are fun when they have challenges to overcome. It could be jumping over obstacles, solving puzzles, or winning a race.
</li>
<li> <strong>Rewards:</strong>Rewards like points, coins, or unlocking new levels make games more exciting.</li>
<li> <strong>Surprises:</strong>Surprises in games, like hidden items or special powers, keep the player interested.
</li>
<li> <strong>Choices:</strong>Games that let you make choices—like picking characters, paths, or moves—make you feel like you’re part of the game.
</li>
<p>Fun games are challenging, rewarding, surprising, and let players make choices. These things keep players coming back for more!
</ul>`,
          // image: computer, // Replace with actual image path
          // imagePlacement: 'right', // Image on the right side
        },
        {
          sectionTitle: <span className="text-purple-600"> 4.2 Game Design Basics</span>,
          content: `<p><strong>What is Game Design? </strong>Game design is all about planning and creating the way a game looks, feels, and plays. It’s like building a fun world for people to explore.</p>
          <p><strong>Basic Steps in Game Design:</strong></p>
          <p><strong>1. Story and Goal:</strong> What is the story of your game? What does the player need to do to win?
</p>
<p><strong>2. Characters:</strong> Who will the player be? Will there be other characters, like friends or enemies?
</p>
<p><strong>3. Setting:</strong> Where does the game take place? It could be in a forest, a city, or even outer space!
</p>
<p><strong>4. Rules:</strong>Every game has rules to make it fair and challenging. Think about how the player can move, how they score points, and what they need to avoid.</p>
These basics help you design a game that is fun, challenging, and easy to understand.
`,

        },
        {
          sectionTitle: <span className="text-purple-600">4.3 Creating Game Characters and Environments</span>,
          content: `<p><strong>Game Characters:</strong> Game characters are the heroes, villains, or creatures in the game. The player controls the main character, but there might be other characters that help or challenge them.</p>
          <p><strong>Designing Characters:</strong> When designing characters, think about:</p>
          <ul class="list-disc pl-6"> 
         <li> <strong>Looks:</strong>How does the character look? What’s their color, size, and outfit?
</li>
<li> <strong>Abilities:</strong>Can they jump high, run fast, or use special tools?
</li>
<li> <strong>Personality:</strong> Is the character brave, silly, or mysterious?
</li>
.</ul>
<p><strong>Game Environments:</strong> The environment is the world where the game takes place. It sets the mood and adds excitement to the game.
</p>
<p><strong>Designing the Environment:</strong> </p>
          <ul class="list-disc pl-6"> 
         <li> <strong>Style:</strong> Is the environment bright and colorful, or dark and spooky?</li>
<li> <strong>Details:</strong> Are there hills, rivers, trees, or buildings? Adding these details makes the world feel real.
</li>
<li> <strong>Challenges:</strong> Are there parts of the environment the character needs to avoid, like water, lava, or tricky paths?
</li>
.</ul>
Characters and environments bring a game to life and make it more fun to play!

`,

          // No image property added here
        },


        {
          sectionTitle: <span className="text-purple-600">4.4 Introduction to Game Mechanics
          </span>,
          // layout: 'two-images', // New layout type for two images
          //leftImage: io, // Replace with the actual path
          //
          // rightImage: op, // Replace with the actual path
          content: `<p><strong>What Are Game Mechanics? </strong>Game mechanics are the actions and rules that make the game work. They decide how the player moves, scores points, and interacts with the game world.
 </p>
 <p><strong>Examples of Game Mechanics:</strong></p>
          <ul class="list-disc pl-6"> 
         <li> <strong>Movement Mechanics:</strong>  How does the character move? Can they jump, swim, or fly?</li>
<li> <strong>Scoring Mechanics:</strong>How do players earn points or rewards? Points can come from collecting items, reaching the end of a level, or defeating an enemy.</li>
<li> <strong>Level Mechanics:</strong>  Levels are like chapters in a game. As players move through levels, the game gets harder, introducing new challenges and rewards.</li>
<li> <strong>Health or Lives:</strong>   Games often have lives or health points. Each time the player makes a mistake, they might lose a life or health points.
</li>
.</ul>
Game mechanics are the rules and actions that keep the game fun, challenging, and fair!

          `,

        },

        {
          sectionTitle: <span className="text-purple-600">4.5 Review of Game Design Concepts
          </span>,
          // layout: 'two-images', // New layout type for two images
          //leftImage: io, // Replace with the actual path
          //
          // rightImage: op, // Replace with the actual path
          content: `
            <p><strong>Putting It All Together:</strong>Let’s review the key parts of designing a game:
</p>
          <ul class="list-disc pl-6"> 
         <li> <strong>Fun Elements:</strong>  Challenges, rewards, surprises, and choices make games fun.</li>
<li> <strong>Design Basics:</strong> Story, characters, setting, and rules create a solid game plan.
</li>
<li> <strong>Characters and Environments:</strong>  These are the “who” and “where” of the game, bringing it to life.
</li>
<li> <strong>Game Mechanics:</strong>   These are the actions and rules that control how the game is played.
</li>
.</ul>
<strong>Why They All Matter:</strong> Each part of game design adds something important. Together, they create a game that’s not only fun to play but also feels like an exciting adventure. With these concepts, you’re ready to start building your own amazing game!

          `,

        },




        { sectionTitle: <span className="text-blue-600">Activity Session</span> },


        {
          sectionTitle: <span style={{ color: 'orange' }}>4.6 Design Your Game Concept</span>,
          content: ' Students will brainstorm and outline their game concepts, including objectives and mechanics. '
        }
        ,

        {
          sectionTitle: <span style={{ color: 'orange' }}>4.7  Start Coding Your Game
          </span>,
          content: '     Students will begin coding the basic mechanics of their games using block-based programming.',
        },
        {
          sectionTitle: <span style={{ color: 'orange' }}>4.8  Test and Improve Your Game Mechanics
          </span>,
          // layout: 'two-images', // New layout type for two images
          //leftImage: io, // Replace with the actual path
          //
          // rightImage: op, // Replace with the actual path
          content: `Students will playtest their games and make necessary adjustments based on feedback.
`
          // <b>Activity: </b>Create a movement pattern for a sprite using loops.
          //   <ul class="list-disc pl-6">
          //   <b>Step-by-Step Instructions:</b>
          //    <li><b>1. Choose a Sprite and Background</b>
          //    </li>
          //    <ol>
          //    <li>Have students select a sprite to animate, such as a character or a simple shape.</li>
          //    <li>Encourage them to pick a clear, contrasting background to make the movement pattern visible.</li>
          //    </ol>
          //    <li><b>2. Introduction to Direction Changes</b></li>
          //     <ol>
          //     <li>o  Direction Blocks: Introduce blocks like "turn [15] degrees" and "point in direction [ ]" to help them control the direction of the sprite’s movement.</li>
          //     </ol>
          //    <li><b>3. Create a Simple Pattern Using Loops</b></li>
          //    <ol>Square Pattern (Example):
          //     <li><b>Explain how moving in a square requires moving forward and then turning 90 degrees.</li>
          //     <li><b>Walk them through the code:</b> Use a "repeat [4]" block.<li>

          //     <li>Inside the loop, add "move [50] steps" and "turn [90] degrees".</li>
          //     <li>This code will move the sprite in a square pattern</li>


          //     </ol>
          //    </ul>
          ,
          // image: op, // Replace with actual image path
          // imagePlacement: 'upper', // Image on the left side
        },
        {
          sectionTitle: <span style={{ color: 'orange' }}>4.9 Add Visuals and Sounds to Your Game
          </span>,
          content: `Teach students how to enhance their games with graphics and sound effects.`
          // <b>Activity: </b>Create a movement pattern for a sprite using loops.
          // <ul class="list-disc pl-6">
          // <b>Step-by-Step Instructions:</b>
          //  <li><b>1. Choose a Sprite and Background</b>
          //  </li>
          //  <ol>
          //  <li>Have students select a sprite to animate, such as a character or a simple shape.</li>
          //  <li>Encourage them to pick a clear, contrasting background to make the movement pattern visible.</li>
          //  </ol>
          //  <li><b>2. Introduction to Direction Changes</b></li>
          //   <ol>
          //   <li>o  Direction Blocks: Introduce blocks like "turn [15] degrees" and "point in direction [ ]" to help them control the direction of the sprite’s movement.</li>
          //   </ol>
          //  <li><b>3. Create a Simple Pattern Using Loops</b></li>
          //  <ol>Square Pattern (Example):
          //   <li><b>Explain how moving in a square requires moving forward and then turning 90 degrees.</li>
          //   <li><b>Walk them through the code:</b> Use a "repeat [4]" block.<li>

          //   <li>Inside the loop, add "move [50] steps" and "turn [90] degrees".</li>
          //   <li>This code will move the sprite in a square pattern</li>


          //   </ol>
          //  </ul>
          ,
        },
        {
          sectionTitle: <span style={{ color: 'orange' }}>4.10 Present Your Game to the Class
          </span>,
          content: `Each student will present their finished game, explaining its design and mechanics.`
          //   `<b>Activity: </b>Create a movement pattern for a sprite using loops.
          //   <ul class="list-disc pl-6">
          //   <b>Step-by-Step Instructions:</b>
          //    <li><b>1. Choose a Sprite and Background</b>
          //    </li>
          //    <ol>
          //    <li>Have students select a sprite to animate, such as a character or a simple shape.</li>
          //    <li>Encourage them to pick a clear, contrasting background to make the movement pattern visible.</li>
          //    </ol>
          //    <li><b>2. Introduction to Direction Changes</b></li>
          //     <ol>
          //     <li>o  Direction Blocks: Introduce blocks like "turn [15] degrees" and "point in direction [ ]" to help them control the direction of the sprite’s movement.</li>
          //     </ol>
          //    <li><b>3. Create a Simple Pattern Using Loops</b></li>
          //    <ol>Square Pattern (Example):
          //     <li><b>Explain how moving in a square requires moving forward and then turning 90 degrees.</li>
          //     <li><b>Walk them through the code:</b> Use a "repeat [4]" block.<li>

          //     <li>Inside the loop, add "move [50] steps" and "turn [90] degrees".</li>
          //     <li>This code will move the sprite in a square pattern</li>


          //     </ol>
          //    </ul>
          // `,
        },

      ]
    }, {
      id: 5,
      title: '5. Introduction to the Pen Extension',
      // description: 'Learn about the foundational concepts of computers and technology.',
      details: [
        {
          sectionTitle: <span className="text-purple-600"> Introduction to the Pen Extension</span>,
          content: `<p>The <strong>Pen Extension</strong> in Scratch is like giving your sprite a magic pen to draw with! When you add the Pen Extension, you unlock special blocks that allow your sprite to leave trails, draw shapes, or even create colourful designs on the screen as it moves.</p>
          `,
          image: topic4551, // Replace with actual image path
          imagePlacement: 'right', // Image on the right side
        },
        {
          sectionTitle: <span className="text-purple-600">Getting Started with thePen</span>,
          content: `To use the Pen Extension, you need to add it to your project. Once added, you’ll see new blocks that let you control the pen’s actions.


<ul class="list-disc pl-6">
  <li><strong>Main Pen Blocks:</strong> </li>
  <ol><strong>Pen Down:</strong> This block makes the pen touch the screen and start drawing wherever the sprite goes.</ol>
  <ol><strong>Pen Up:</strong>: This block lifts the pen, so the sprite can move without drawing.</ol>
  <ol><strong>Set Pen Colour:</strong> Choose any colour you like for your drawings!
</ol>
  <ol><strong>Set Pen Size:</strong> This changes how thick or thin your pen line is.
</ol>
    <li><strong>Using thePen to  Draw Shapes:</strong> </li>
    <ol>With the Pen Extension, you can make your sprite move in shapes like circles, squares, or even zig-zags! For example, if you want to draw a square, you can move the sprite forward and turn it four times.
</ol>
  <li><strong>Clearing theScreen:</strong> </li>
  <ol>The Clear block lets you erase everything your sprite has drawn. This is useful when you want to start a new drawing!
</ol>
  
</ul>`,
        },
        {
          sectionTitle: <span className="text-purple-600">Why Use the Pen Extension?</span>,
          content: `The Pen Extension is a fun way to explore creativity and learn coding concepts like movement and loops. You can use it to:

          <ul class="list-disc pl-6"> 
         <li> Create patterns</li>
<li>Draw shapes</li>
<li> Make animated designs</li>
</ul>
With the Pen Extension, coding becomes like art class your sprite is your brush, and the screen is your canvas!
`,

          // No image property added here
        },


        {
          sectionTitle: <span className="text-purple-600">Drawing a circle:</span>,
          // layout: 'two-images', // New layout type for two images
          //leftImage: io, // Replace with the actual path
          //
          // rightImage: op, // Replace with the actual path
          content: `
           using a circle project in Scratch is about exploring its functionality, interacting with it, and potentially modifying it to suit your own interests and creative ideas.
            <p>1. open the scratch and create a new project.</p>
            <p>2. Let’s add some fun tools! Click the “Add extension” icon at the bottom left corner and choose the “pen” extension. This extension adds the drawing capability in the scratch.</p>
            <p>3. Find the “Events” palette and drag when green flag clicked block onto your scripting area.</p>
            <p>4. Let’s set up our drawing pen. Drag the set pen size to () block from the “pen” and set the size to 5.
</p>
            <p>5. We want colourful circle, don’t we? Drag the change the pen colour by () block from “pen” and choose a colour and number 15 to make it colourful.

</p>
            <p>6. Now, add “Erase all” block from “pen” to make sure our stage is clean before drawing.
</p>
            <p>7. Time to put our pen to paper! Drag the pen down the “pen” extension.
</p>
            <p>8. Get repeat the “control” palette and put the number 36 on it.

</p>
            <p>9. Move our sprite with the “move () steps” block from palette. Let’s make it move 10 steps.
</p>
            <p>10. To create the circle points, we need to turn our sprite at the right angle. Find “turn clockwise () degrees”. Set it for 10 degrees.

</p>
            <p>11. Once our circle is complete, we lift our pen up with the “pen up” block from “pen” extension to stop drawing.
</p>
            <p>12. Don’t forget to save your circle project.</p>
            <p>13. If u want add some surprise, we will use the “pick random () to ()” block from the “operators” palette in the x and y blocks. Set x-value between -120 to 80. And y-value between -80 and 120 to see the cat drawing the circle at random places.
</p>
            <p>14. Click the green flag and watch the cat draw the circle. </p>
            <p>15. Here we used sprite as cat, but you will use “pencil” sprite.

</p>
          `,
          image: topic4552, // Replace with actual image path
          imagePlacement: 'left', // Image on the left side
        },
      ]
    }, {
      id: 6,
      title: 'Lab Activities',
      // description: 'Learn about the foundational concepts of computers and technology.',
      details: [
        {
          sectionTitle: <span className="text-purple-600">Moving cat to random & mouse direction</span>,
          content: `In this activity, you are diving into the exciting world of coding with scratch. We will create two interactive scripts featuring cat, where cat will react to different actions: pressing the space key and mouse clicks. Get ready to bring your coding skills into action!
          <p>Let’s make a script to make cat glide to random positions whenever the space key is pressed. Follow the steps outlined below:
</p>
          <ul class="list-disc pl-6">
          <li>1.Open scratch and create a new file.</li>
  <li>2. Now create the script as follows:</li>
  <ol>2.1 Add “if () then” block from the control palette.
</ol>
  <ol>2.2  insert a “key () pressed?” block in the hexagonal slot of the if block and choose the space key  from drop-down menu.</ol>
  <ol>2.3  Add a “glide () secs to x: () y: ()” block from “Motion” palette under the if arm. You will specify random coordinates for x and y soon.</ol>
  <ol> 2.4. Enclose the “if () then” block inside a “Forever” block to make the script run continuously.</ol>
   
    <ol>2.5. Start your script by adding a when flag clicked block above the forever block.</ol>
  <li>3. To test your script, click the green flag and press the space key to see cat glid random position on stage.</li>
  <li>4. When space key pressed, our cat sprite glide random position.</li>
  For Mouse direction you try in other code your own.

</ul>

          `,
          image: topic4553, // Replace with actual image path
          imagePlacement: 'left', // Image on the right side
        },
      ]
    },
    {
      id: 7,
      title: 'LAB ACTIVITY 5 GEOGRAPHY With Stem Verse',
      // description: 'Learn about the foundational concepts of computers and technology.',
      details: [
        {
          //sectionTitle:<span className="text-purple-600"> PDF</span>,
          // content: `<iframe src="Seasons_in_india.pdf" width="100%" height="600px" />`
          content: `<embed src="Seasons_in_india.pdf#toolbar=0" type="application/pdf" width="100%" height="600px" />`
          // image: computer, // Replace with actual image path

          // imagePlacement: 'right', // Image on the right side
        },
      ]
    },],
    5: [{
      id: 1,
      title: '1. Introduction to Debugging and Project Management',
      // description: 'Learn about the foundational concepts of computers and technology.',
      details: [
        {
          sectionTitle: <span className="text-purple-600">  1.1 Understanding Debugging
          </span>,
          content: `<p><b>What’s Debugging?</b> Imagine you’re writing a story, and you accidentally spell some words wrong or forget to add some details. When you go back and fix those mistakes, you’re “editing.” In coding, we have something similar called <b>debugging</b>. Debugging is when you go through your code to find and fix any mistakes (called <b>bugs</b>) that keep your program from working right.</p>
<p><b>Why Do We Debug? </b>Sometimes, bugs can make your game stop, make characters behave weirdly, or keep things from looking the way you want. Debugging helps you make sure everything works just the way you planned!
</p>
<p><b>Example: </b>Let’s say you made a game where your character should jump when you press the space bar, but it doesn’t work. Debugging helps you find out why it’s not working and fix it.</p>`,
          image: debug, // Replace with actual image path
          imagePlacement: 'left', // Image on the right side
        },
        {
          sectionTitle: <span className="text-purple-600">1.2  Common Errors and How to Fix Them</span>,
          content: `What Are Errors? Errors are like little “uh-ohs” in your code. They’re the things that don’t work as expected. Here are some common errors:
    <ul class="list-disc pl-6">
    <li><strong>Syntax Error: </strong> This happens if you type something wrong, like forgetting a “)” at the end of a line. It’s like forgetting a period at the end of a sentence.</li>
    <li><strong>Logic Error: </strong>This is when your code runs but doesn’t do what you wanted. Maybe you wanted your character to go up, but it goes sideways instead. That’s a logic error.</li>
    <li><strong>Runtime Error: </strong>This type of error happens when your program is running. It’s like when you’re watching a movie and the screen suddenly freezes.</li>
    </ul>
    <ul><b>How Do We Fix Errors?</b>
    <li>Check your code line by line.</li>
<li>Think about what each line should do and compare it to what’s actually happening.</li>
<li>	Use “comments” to write little notes to yourself, which can help you keep track of what each part does.
</li>
   </ul>
   <b> Example: </b>If your character won’t move, double-check that you typed everything correctly and that you’re using the right commands.`,
          image: fixerror, // Replace with actual image path
          imagePlacement: 'left', // Image on the left side
        },
        {
          sectionTitle: <span className="text-purple-600">1.3 Project Management Basics
          </span>,
          content: `<p>What is Project Management? When you have a big project, like a class assignment or building a game, you need a plan to get it done. Project management is all about planning, organizing, and making sure you finish your project on time. It helps you stay on track and not feel overwhelmed.</p>
          <ul class="list-disc pl-6"> 
          <b>Steps in Project Management:</b>
         <li> <strong>Plan:</strong>  Decide what you want to do and how long you think it will take.</li>
    <li> <strong> Organize: </strong>Break down the project into smaller tasks. For example, “draw the character,” “add the background,” “write the code.”</li>
    <li> <strong>Do:</strong> Start working on each task one by one.</li>
    <li><b>	Check:</b> Test to make sure each part works.</li>
<li><b>Finish:</b> Put everything together, and review your final project.</li>
<p><b>Example:</b> Imagine making a maze game. First, you’ll plan by deciding how big the maze should be. Then, you’ll work on tasks like drawing the maze, coding the movement, and testing the game.</p>
    </ul>`,

          // No image property added here
        },


        {
          sectionTitle: <span className="text-purple-600">1.4 Documenting Your Code</span>,
          // layout: 'two-images', // New layout type for two images
          //leftImage: io, // Replace with the actual path
          //
          // rightImage: op, // Replace with the actual path
          content: `
            <p><b>What Does “Documenting” Mean? </b>Documenting is like writing down what each part of your code does so that if you or someone else looks at it later, they understand it. Think of it as leaving notes or labels that explain your code. These notes are called <b>comments.</b></p>
            <ul class="list-disc pl-6">
            <b>Why Document Your Code?</b>
<li>	It helps you remember what each part of the code does.</li>
<li>If someone else wants to use your code, they can understand it more easily.</li>
<li>It helps you spot mistakes more quickly.<li>
<p><b>How to Add Comments: </b>In Stemverse, you can add comments by right-clicking and selecting “add comment.” In other coding languages, you can use special symbols (like “#”) to add comments.</p>
</ul>
          `,
          image: yourcode, // Replace with actual image path
          imagePlacement: 'left', // Image on the left side
        },

        {
          sectionTitle: <span className="text-purple-600">1.5 Review of Debugging and Project Management
          </span>,
          // layout: 'two-images', // New layout type for two images
          //leftImage: io, // Replace with the actual path
          //
          // rightImage: op, // Replace with the actual path
          content: `<p><b>Why Review These Skills?</b> Learning to debug, manage projects, and document code is like learning the “tools of the trade” for being a good coder. When you’re working on bigger projects, these skills will help you stay organized, solve problems, and finish your work on time.</p>



            <ul class="list-disc pl-6">Let’s Recap:
            <li>Debugging helps you find and fix mistakes.</li>
            <li>Knowing common errors helps you quickly find solutions.</li>
            <li>	Project management helps you plan, organize, and complete big tasks without feeling overwhelmed.</li>
<li>Documenting leaves helpful notes in your code so you and others can understand it easily.</li>

            </ul>
          `,
          // image: op, // Replace with actual image path
          // imagePlacement: 'left', // Image on the left side
        },





        { sectionTitle: <span className="text-blue-600">Activity Session</span> },


        {
          sectionTitle: <span style={{ color: 'orange' }}>1.6  Debugging Exercise</span>,
          content: 'Provide students with buggy code samples. They will work in pairs to identify and fix the errors.'
        }
        ,

        {
          sectionTitle: <span style={{ color: 'orange' }}>1.7 Plan a Simple Coding Project
          </span>,
          content: 'Students will choose a project idea and create a project plan that includes goals, steps, and timelines.',
        },
        {
          sectionTitle: <span style={{ color: 'orange' }}>1.8 Implement Your Project Plan</span>,
          content: ': Students will begin coding their projects based on their plans, focusing on implementing the first few steps.',
        },
        {
          sectionTitle: <span style={{ color: 'orange' }}>1.9 Document Your Code as You Go
          </span>,
          content: ' Encourage students to add comments and document their code as they work on their projects.',
        },
        {
          sectionTitle: <span style={{ color: 'orange' }}>1.10 Peer Review of Projects </span>,
          content: 'In small groups, students will present their projects and provide feedback to one another, focusing on debugging and documentation.',
        },

      ]
    }, {
      id: 2,
      title: '2..Creating Interactive Stories',
      // description: 'Learn about the foundational concepts of computers and technology.',
      details: [
        {
          sectionTitle: <span className="text-purple-600">  Introduction to Storytelling in advanced
          </span>,
          content: `<p><b>Advanced Storytelling: Creating an Engaging Plot:Let’s Start with a Story!</b></p>
          <p>Imagine a young explorer named <b>Kai</b>. Kai dreams of finding the <b>Lost Treasure of the Golden Mountains!</b> But to reach it, Kai has to overcome many challenges and make smart choices along the way.</p> 
 `,
          image: storytelling,// Replace with actual image path
          imagePlacement: 'right', // Image on the right side
        },
        {
          sectionTitle: <span style={{ color: 'orange' }}>Step 1: Introducing the Plot – The Roadmap of the Story</span>,
          // layout: 'two-images', // New layout type for two images
          //leftImage: io, // Replace with the actual path
          //
          // rightImage: op, // Replace with the actual path
          content: `<p>A <b>plot</b> is like a roadmap that shows where a story begins, what challenges it faces, and how it ends. Every story starts somewhere, has exciting things in the middle, and comes to a satisfying end.</p><p>Let’s create Kai’s journey together! First, think about <b>where</b> he might start and<b> what kinds of challenges</b> he could face.</p>
           <p><b>Question for Students: </b>"Where do you think Kai’s adventure should begin? A dark forest, an ancient temple, or maybe a misty cave?"</p>
          <b> Teachers Activity:</b>
<p>Encourage students to share ideas about what would make an exciting starting </p>
          
          `,
          image: roadmapstorie, // Replace with actual image path
          imagePlacement: 'left', // Image on the left side
        },
        {
          sectionTitle: <span style={{ color: 'orange' }}>Step 2: Why Is the Plot Important?</span>,
          // layout: 'two-images', // New layout type for two images
          //leftImage: io, // Replace with the actual path
          //
          // rightImage: op, // Replace with the actual path
          content: `
<p>A plot makes a story more interesting! Without a plot, a story would just be random events. Imagine Kai is exploring the jungle, but there’s no treasure to find or challenge to face. That wouldn't be very exciting, would it?</p>
<p>The plot keeps things moving, making sure every event is connected and exciting. In Kai’s adventure, each part of the plot adds something special: maybe he meets a helpful guide, or he has to solve a puzzle to get closer to the treasure. This keeps us curious about what will happen next!</p>
<p>Interactive Activity: Let students suggest obstacles that could keep Kai from the treasure. Each student could suggest something different, like a river to cross or a riddle to solve, and the teacher could build on those ideas to show how they fit into a plot.</p>
          
          `,
          image: plotimport, // Replace with actual image path
          imagePlacement: 'left', // Image on the left side
        },
        {
          sectionTitle: <span style={{ color: 'orange' }}>Step 3: Kai’s First Challenge</span>,
          // layout: 'two-images', // New layout type for two images
          //leftImage: io, // Replace with the actual path
          //
          // rightImage: op, // Replace with the actual path
          content: `
<p>Let’s imagine that Kai has reached the river of fog. He needs to find a way across, but there’s no bridge, and the water is too fast to swim.</p>
<ul><b>Choices in the Plot:</b>
<li>Build a raft and paddle across.</li>
<li>Look for a hidden path through the forest.</li>
<li>Call out to see if anyone nearby can help.
</li>
</ul>
<b>Activity: </b>Have students vote on which option Kai should choose, then discuss what could happen next. For each choice, think about how it could lead Kai closer to the treasure or bring him a new challenge. This shows students that each plot point is like a step toward the story’s goal.
          
          `,
          // image: op, // Replace with actual image path
          // imagePlacement: 'left', // Image on the left side
        },
        {
          sectionTitle: <span style={{ color: 'orange' }}>Step 4: Twists and Surprises</span>,
          // layout: 'two-images', // New layout type for two images
          //leftImage: io, // Replace with the actual path
          //
          // rightImage: op, // Replace with the actual path
          content: `
<p>Sometimes, a good plot has <b>twists</b> – something unexpected that changes the story! For Kai, maybe he finds a map to the treasure, but there’s a trick: the map shows two routes, and only one of them is safe</p>
<p>Ask students: "How can we add a surprise to Kai’s story?" This could be something like a new character joining Kai, or a clue that suddenly points in a different direction.</p>
<p>Twists keep the audience excited and wondering, “What will happen next?”</p>
          
          `,
          image: twist, // Replace with actual image path
          imagePlacement: 'left', // Image on the left side
        },
        {
          sectionTitle: <span style={{ color: 'orange' }}>Step 5: Reaching the Goal</span>,
          // layout: 'two-images', // New layout type for two images
          //leftImage: io, // Replace with the actual path
          //
          // rightImage: op, // Replace with the actual path
          content: `
<p>Finally, every plot has an ending – it’s where all the pieces of the story come together. Will Kai find the treasure? Or maybe he’ll find something even better – like a new friend, or a lesson about bravery.</p>
<p><b>Wrap-Up Activity:</b> Let the class come up with different possible endings for Kai’s story. Some students might say he finds the treasure, while others might think he gets a special gift from a wise elder.</p>
<p><b>Final Thought for Kids: </b>Think of every story as an adventure where each part of the plot is another step forward. By building a good plot, you’re making sure that everyone who reads your story has as much fun as Kai did on his adventure!</p>
          `,
          image: reach_goal, // Replace with actual image path
          imagePlacement: 'left', // Image on the left side
        },
        {
          sectionTitle: <span className="text-purple-600">Designing Characters and Settings </span>,
          content: `<p><b>Designing Complex Characters and Detailed Settings<b><p>
<p><b>Building Deeper Characters </b>Characters have feelings, goals, and personalities. In advanced storytelling, think about your characters' motivations (why they do what they do) and how they change throughout the story. Give them strengths and weaknesses that make them feel more real.</p>
<p><b>Example:</b> If Kai, the explorer, is in a dense jungle, describe it in detail: "The jungle was filled with the smell of wet earth, the sound of chirping insects, and towering trees that blocked out most of the sunlight."</p>

    `,
          //   image: parts, // Replace with actual image path
          // imagePlacement: 'left', // Image on the left side
        },
        {
          sectionTitle: <span className="text-purple-600"> Using Events to Control Story Progression </span>,
          content: `<p>Imagine you’re watching a story where every time you do something, like press a button or make a choice, the story changes or moves forward. That’s the magic of using events in storytelling!</p>
<p><b>What are Events?</b></p>
<p>Events are things that happen to start, stop, or change something in a story or game. In a story, an event could be pressing a button, clicking a character, or reaching a specific part of the scene. When an event happens, it triggers an action that moves the story forward, like making a character speak or changing the scene.</p>
<p><b>Why Use Events in Stories?</b></p>
<p>Events make stories exciting and interactive! They allow you, the storyteller, to add different parts that only happen when something specific occurs. With events, you’re in control of the story's flow and timing.</p>
<p><b>Examples to Understand Events</b></p>
<ul class="list-disc pl-6">
<li><b>Example 1: A Greeting Event</b>
<p>	Imagine there’s a character named Mia the Cat. You want Mia to say “Hello!” when someone clicks on her. Here, the event is “clicking on Mia.”</p>
<p>	When the event happens (click), it triggers Mia’s action (saying “Hello!”).</p></li>
<li><b>Example 2: Moving to the Next Part of the Story</b>
<p>Let’s say you’re telling a story where a puppy, Max, is looking for his bone. The first part shows him searching in the garden.</p>
<p>Event: When you press the “Next” button, Max moves to the next scene, which is the backyard.</p>
<p>Action: The scene changes to the backyard, and the story progresses.</p>
<li><b>Example 3: Making a Choice in the Story</b>
<p>If the character reaches a fork in the road, the story can change depending on what the player decides.</p>
<p>Event: Choose to go left or right.</p>
<p>Action: If you choose left, the character finds a magical tree. If you choose right, they meet a friendly squirrel.</p></li></ul>
</b>Why Events Are Cool for Storytelling</b> 
<p>Events allow your audience to control parts of the story. They make the experience more personal and exciting, as the audience feels like they’re part of the story, making things happen along the way.</p>
<ul class="list-disc pl-6"><b>Class Activity: Creating an Event-Controlled Story</b>
<li><b>1.	Start with a Simple Story Idea:</b> Think of a short story where a character has to do something or make a choice.</li>
<li><b>2.	Add Events:</b> Decide on the points in the story where something will happen. It could be clicking on a character to make them talk or pressing a button to make a new scene appear.</li>
<li><b>3.	Test the Events: </b>Try out each event to see if it works as expected. Do the right actions happen when the event is triggered?</li>
</ul>
`,

          // No image property added here
        },


        {
          sectionTitle: <span className="text-purple-600">2.4 Building a Story Flowchart</span>,
          // layout: 'two-images', // New layout type for two images
          //leftImage: io, // Replace with the actual path
          //
          // rightImage: op, // Replace with the actual path
          content: `
           <p><b> What’s a Story Flowchart? </b>I flowcharts become a bit more complex. They don’t just map out a straight line; they show choices that branch out and reconnect. Each path can lead to a unique ending, making it like a “choose-your-own-adventure.”<b></p>
           <p><b>Adding Different Endings</b> In advanced storytelling, the choices can lead to different story endings. A flowchart can help keep track of each possible path and outcome, so the story stays organized and exciting.</p> 
           <ul class="list-disc pl-6">
            <b>Example Flowchart:</b>
             <li>1.	Start – Kai begins his journey to find the treasure.</li>
             <li>2.	Choice – Kai meets a river. Should he:
             <ul>	Build a raft: Leads to a shortcut but a risky journey.</ul>
             <ul>Walk around: Safer but takes longer.</ul></li>
             <li>3.	If Raft: Kai might get stranded and need to find help.</li>
             <li>4.	If Walking: Kai meets a wise guide who offers advice.</li>
             <li>5.	End – Kai either finds the treasure or learns a valuable lesson from the adventure.</li>
                    
             </ul>
          `,
          image: flowchat, // Replace with actual image path
          imagePlacement: 'left', // Image on the left side
        },


        {
          sectionTitle: <span className="text-purple-600">2.5 Review of Storytelling Elements
          </span>,
          // layout: 'two-images', // New layout type for two images
          //leftImage: io, // Replace with the actual path
          //
          // rightImage: op, // Replace with the actual path
          content: `Reviewing Advanced Storytelling Elements
            <ul class="list-disc pl-6"><b>Let’s Review!</b>
            <li><b>Plot:</b> The main sequence of events, with twists and challenges that keep the reader engaged.</li>
             <li><b>Characters: </b>Add depth by giving them motivations, strengths, and weaknesses. Characters should feel real and relatable.</li>
             <li><b>Settings: </b>Detailed places that bring the story to life with descriptive language.</li>
             <li><b>Events and Choices:</b> Dynamic parts of the story where readers can make decisions that affect the outcome.</li>
             <li><b>Flowchart:</b> A visual map showing different choices and endings for a complex, interactive story.</li>
             <p><b>Why These Elements Matter</b> These elements make stories interesting, unpredictable, and fun. They let readers feel like they’re part of the adventure and encourage creativity in planning how the story flows.</p>
             </ul>
          `,
          image: op, // Replace with actual image path
          imagePlacement: 'left', // Image on the left side
        },

        { sectionTitle: <span className="text-blue-600">Activity Session</span> },


        {
          sectionTitle: <span style={{ color: 'orange' }}>2.6	 Create Your Characters and Settings</span>,
          // layout: 'two-images', // New layout type for two images
          //leftImage: io, // Replace with the actual path
          //
          // rightImage: op, // Replace with the actual path
          content: `Students will design their characters and backgrounds for their interactive stories
              `,
          // image: op, // Replace with actual image path
          // imagePlacement: 'left', // Image on the left side
        },
        {
          sectionTitle: <span style={{ color: 'orange' }}>2.7	Build the Framework for Your Story
          </span>,
          // layout: 'two-images', // New layout type for two images
          //leftImage: io, // Replace with the actual path
          //
          // rightImage: op, // Replace with the actual path
          content: `
                 Students will start coding the basic structure of their stories using events and commands
              `,
          // image: op, // Replace with actual image path
          // imagePlacement: 'left', // Image on the left side
        },
        {
          sectionTitle: <span style={{ color: 'orange' }}>2.8	Add Interactive Elements to Your Story
          </span>,
          // layout: 'two-images', // New layout type for two images
          //leftImage: io, // Replace with the actual path
          //
          // rightImage: op, // Replace with the actual path
          content: `Teach students to incorporate user choices and branching paths into their stories.
              `,
          // image: op, // Replace with actual image path
          // imagePlacement: 'left', // Image on the left side
        },
        {
          sectionTitle: <span style={{ color: 'orange' }}>2.9	Test and Refine Your Interactive Story</span>,
          // layout: 'two-images', // New layout type for two images
          //leftImage: io, // Replace with the actual path
          //
          // rightImage: op, // Replace with the actual path
          content: `Students will playtest each other’s stories and provide feedback for improvement.
              `,
          // image: op, // Replace with actual image path
          // imagePlacement: 'left', // Image on the left side
        },
        {
          sectionTitle: <span style={{ color: 'orange' }}>2.10 Present Your Interactive Story
          </span>,
          // layout: 'two-images', // New layout type for two images
          //leftImage: io, // Replace with the actual path
          //
          // rightImage: op, // Replace with the actual path
          content: `Each student will present their interactive sto	ry to the class, demonstrating its features and engaging the audience.
           
          `,
          // image: op, // Replace with actual image path
          // imagePlacement: 'left', // Image on the left side
        },

      ]
    }, {
      id: 3,
      title: '3.Introduction to Animation and Games  ',
      // description: 'Learn about the foundational concepts of computers and technology.',
      details: [
        {
          sectionTitle: <span className="text-purple-600">3.1 Understanding Animation Basics </span>,
          content: `<p><b>What is Animation? </b>Animation is like bringing pictures or drawings to life! By showing a series of images really quickly, we can make it look like something is moving.</p>
          <p><b>Example:</b> Imagine flipping through a book where each page has a picture of a ball moving up and down. When you flip the pages quickly, it looks like the ball is bouncing!</p>
         <p><b> Activity: </b>Ask students to draw a simple object (like a bouncing ball) on a sticky note pad, with each page showing the ball in a slightly different place. When they flip the pages, they’ll see the animation come to life!</p>`,
          image: animation, // Replace with actual image path
          imagePlacement: 'left', // Image on the right side
        },
        {
          sectionTitle: <span className="text-purple-600">3.2  Creating Simple Animations</span>,
          content: `<b>Building Your First Animation:</b> Now that we know what animation is, let’s try making one! We can start with something simple, like a character waving or jumping up and down.
    
    <ul class="list-disc pl-6"><b>Step-by-Step:</b>
    <li><strong>Choose a Character:</strong> Let students pick a simple character, like a stick figure or an animal.</li>
    <li><strong>Draw the Motion Steps:</strong>  Draw small changes in each picture (like moving the arm up, then down) to create the movement.</li>
    <li><strong> Put It Together:</strong> When you play the pictures one after another, it will look like the character is moving.</li>
    <p style="color:blue;">In-Class Activity: Use a drawing program (or a simple animation tool like Scratch) to create a basic animation, guiding students to move a character up, down, or sideways.</p>
    </ul>
    <p><b>What is Game Design?</b> Game design is all about planning and creating games. This means thinking about the<b> rules, goals,</b> and <b>fun factor</b> of a game. Game designers come up with ideas for what players can do in the game and how to make it exciting!</p>
    `,
          //   image: parts, // Replace with actual image path
          // imagePlacement: 'left', // Image on the left side
        },
        {
          sectionTitle: <span className="text-purple-600">3.3 Game Design Basics:</span>,
          content: `
          <ul class="list-disc pl-6">  
         <li> <strong>Objective:</strong> What is the main goal? (For example, in a maze game, the goal is to reach the end.)</li>
    <li> <strong>Challenges:</strong>  What makes it tricky? (Like avoiding obstacles or solving puzzles.)
</li>
    <li> <strong>Rules: </strong>What can and can’t the player do?</li>
    </ul>
    <p><b>Example Activity:</b> Ask students to think of their favourite games and list what makes them fun. Encourage them to discuss the goals, challenges, and rules of each game.
</p>`,

          // No image property added here
        },


        {
          sectionTitle: <span className="text-purple-600"> 3.4 Building Game Characters and Environments
          </span>,
          // layout: 'two-images', // New layout type for two images
          //leftImage: io, // Replace with the actual path
          //
          // rightImage: op, // Replace with the actual path
          content: `
          <p><b>Designing Characters:</b> Characters are who the players control in the game. They can be animals, people, or even objects like bouncing balls!</p>
           <p><b> Activity:</b> Have each student draw or choose a character they’d like in a game. It could be a superhero, an animal, or a robot.</p>
<p><b>Creating Game Environments:</b> The environment is where the game takes place, like a jungle, space, or a beach.</p>
<p><b>Example Activity:</b> Ask students to imagine and draw their character’s world. If it’s a jungle game, they might draw trees, rocks, and rivers. If it’s a space game, they might add planets and stars.</p>
Combine the Character and Environment: Encourage students to place their character in the environment they designed, imagining how the character might move around or interact with objects there.</p>

          `,
          image: charecter_design, // Replace with actual image path
          imagePlacement: 'left', // Image on the left side
        },

        {
          sectionTitle: <span className="text-purple-600"> 3.5 Review of Animation and Game Design Concepts</span>,
          // layout: 'two-images', // New layout type for two images
          //leftImage: io, // Replace with the actual path
          //
          // rightImage: op, // Replace with the actual path
          content: `
            <ul class="list-disc pl-6"><b>Let’s Recap!</b>
             <li><strong>Animation</strong>is about making pictures move by showing them quickly in a sequence.</li>
            <li><b>	Game Design </b>includes setting up goals, challenges, and rules to make a game fun.</li>
             <li><b>Characters</b> are the heroes or objects in the game, while Environments are the worlds they explore.</li>
             </ul>
             <p><b>Fun Group Activity:</b> Split students into small groups to design a simple game idea together. One student could create a character, another can draw the environment, and together they can come up with the rules and objective. Afterward, they can share their ideas with the class!</p>
          `,
          // image: op, // Replace with actual image path
          // imagePlacement: 'left', // Image on the left side
        },





        { sectionTitle: <span className="text-blue-600">Activity Session</span> },


        {
          sectionTitle: <span style={{ color: 'orange' }}>3.6 Create a Simple Animation
          </span>,
          content: 'Students will work on creating a simple animation project, applying what they’ve learned about timing and movement'
        }
        ,

        {
          sectionTitle: <span style={{ color: 'orange' }}>3.7  Plan a Simple Game</span>,
          content: ' Students will outline their game ideas, including objectives, rules, and character designs.',
        },
        {
          sectionTitle: <span style={{ color: 'orange' }}>3.8 Code the Basic Mechanics of Your Game
          </span>,
          content: 'Students will begin coding the core mechanics of their games, focusing on player movement and interactions.',
        },
        {
          sectionTitle: <span style={{ color: 'orange' }}>3.9 Test and Debug Your Game
          </span>,
          content: 'Students will playtest their games, identify bugs, and debug their code collaboratively.',
        },
        {
          sectionTitle: <span style={{ color: 'orange' }}>3.10  Present Your Game to the Class
          </span>,
          content: 'Students will present their games, demonstrating gameplay and sharing their development experiences.',
        },

      ]
    }, {
      id: 4,
      title: '4. Advanced Storytelling with Multimedia',
      // description: 'Learn about the foundational concepts of computers and technology.',
      details: [
        {
          sectionTitle: <span className="text-purple-600"> 4.1 Using Multimedia in Storytelling
          </span>,
          content: `<p><b>What is Multimedia? </b>Multimedia is when we use different kinds of media—like pictures, sounds, and animations—to make a story more exciting.</p>
<p><b>Why Use Multimedia in Stories? </b>Adding multimedia makes stories more interesting and helps us experience the story with more of our senses. For example, a story with music, sound effects, and moving characters feels more alive than just words alone.
</p>`,
          // image: computer, // Replace with actual image path
          // imagePlacement: 'right', // Image on the right side
        },
        {
          sectionTitle: <span className="text-purple-600"> 4.2 Creating Audio Narration and Sound Effects
          </span>,
          content: `<p><b>What’s Audio Narration?</b> Audio narration is when someone reads the story aloud, which helps the audience follow along with the characters’ adventures.</p>
<p><b>What are Sound Effects?</b> Sound effects are sounds that match what’s happening in the story, like birds chirping in a forest scene or footsteps when a character is walking.  </p> 
<ul class="list-disc pl-6">Fun Activity:
<li><b>	Record Narration: </b>Students can record themselves narrating a short story.</li>
<li><b>	Add Sound Effects:</b> Use simple sound effects like clapping (for footsteps) or blowing into a microphone (for wind). This brings the story to life.</li>
 </ul>
`,
          //   image: parts, // Replace with actual image path
          // imagePlacement: 'left', // Image on the left side
        },
        {
          sectionTitle: <span className="text-purple-600">4.3 Animating Characters and Scenes</span>,
          content: `<p><b>What is Animation in Storytelling?</b> Animation is the magic of making characters and scenes move! By animating, we can show the characters doing things, like walking, talking, or even dancing.</p>`,

          // No image property added here
        },


        {
          sectionTitle: <span className="text-purple-600">4.4 Building Interactive Multimedia Stories</span>,
          // layout: 'two-images', // New layout type for two images
          //leftImage: io, // Replace with the actual path
          //
          // rightImage: op, // Replace with the actual path
          content: `
            <p><b>What’s an Interactive Story?</b> An interactive story lets the reader or viewer make choices that change what happens in the story. It’s like a “choose-your-own-adventure” story where you can decide what the character does next.</p>
<p><b>Example:</b> If the character is at a fork in the road, the viewer might choose to go left (leading to one part of the story) or go right (leading to a different part of the story).</p>
<ul class="list-disc pl-6"> 
<b>Class Activity:</b>
<li><b>Create a Story with Choices: </b>Ask students to come up with a simple story where the reader makes choices. For example, “If you want the character to explore the forest, go this way. If you want them to swim in the lake, go that way.”</li>
<li><b>Add Multimedia Elements: </b>Have students add sound effects, narration, and animation to make the different choices feel more exciting.</li>
</ul>    
          `,
          // image: io, // Replace with actual image path
          // imagePlacement: 'left', // Image on the left side
        },

        {
          sectionTitle: <span className="text-purple-600">4.5  Review of Multimedia Storytelling Concepts
          </span>,
          // layout: 'two-images', // New layout type for two images
          //leftImage: io, // Replace with the actual path
          //
          // rightImage: op, // Replace with the actual path
          content: `
    <ul class="list-disc pl-6"> <b>Let’s Recap:</b>
    <li><b>Multimedia</b> uses sound, animation, and interactivity to make stories more engaging.</li>
<li><b>Audio Narration and Sound Effects </b>make the story easier to follow and more exciting.</li>
<li><b>	Animating Characters and Scenes </b>helps the audience feel like they’re watching the story come to life.</li>
<li><b>Interactive Stories </b>let the audience participate and make choices that guide the story’s path.</li>
    </ul>
    <p style="background-color:Tomato;"><b>Final Group Project:</b> Divide students into small groups to create a mini multimedia story. Each group can choose a story and add narration, sound effects, animations, and even an interactive choice. Afterward, let each group present their multimedia story to the class!</p>

          `,
          // image: op, // Replace with actual image path
          // imagePlacement: 'left', // Image on the left side
        },





        { sectionTitle: <span className="text-blue-600">Activity Session</span> },


        {
          sectionTitle: <span style={{ color: 'orange' }}>4.6 Create a Multimedia Character Profile</span>,
          content: 'Students will create a character profile that includes visuals, audio narration, and animations.'
        }
        ,

        {
          sectionTitle: <span style={{ color: 'orange' }}>4.7	 Develop Your Interactive Multimedia Story </span>,
          content: '     Students will start coding their interactive multimedia stories, incorporating audio and animations.',
        },
        {
          sectionTitle: <span style={{ color: 'orange' }}>4.8	Test and Refine Your Multimedia Story</span>,
          // layout: 'two-images', // New layout type for two images
          //leftImage: io, // Replace with the actual path
          //
          // rightImage: op, // Replace with the actual path
          content: `
                   Students will playtest their multimedia stories, focusing on the integration of all elements.
                `,
          // image: op, // Replace with actual image path
          // imagePlacement: 'upper', // Image on the left side
        },
        {
          sectionTitle: <span style={{ color: 'orange' }}>4.9  Present Your Multimedia Story to the Class </span>,
          content: `Each student will present their multimedia story, showcasing its features and engaging the audience.
    `,
        },
        {
          sectionTitle: <span style={{ color: 'orange' }}>4.10 Reflect on the Learning Experience </span>,
          content: `Students will reflect on what they learned throughout the year, discussing skills gained and areas for improvement.
    `,
        },

      ]
    }, {
      id: 5,
      title: '5. Introduction to Text to speech(TTS)',
      // description: 'Learn about the foundational concepts of computers and technology.',
      details: [
        {
          // sectionTitle:<span className="text-purple-600"></span>,
          content: `<p>What is Text-to-Speech? Text-to-Speech, or TTS, is a technology that takes written words and converts them into spoken voice. It’s like having a virtual reader who can read words out loud! TTS is commonly used in apps, websites, and devices to help users hear information instead of reading it.</p>
          <p>ow Does It Work? TTS works by using special software that “reads” the text and then speaks it in a computerized or sometimes more human-like voice. It can read sentences, paragraphs, or even whole stories!</p>
          `,
          image: text_speech, // Replace with actual image path
          imagePlacement: 'right', // Image on the right side
        },
        {
          sectionTitle: <span className="text-purple-600"> Uses of Text-to-Speech for Students</span>,
          content: `Just like we have hands, eyes, and a brain, a computer has its own special parts. Each part does something important to make the computer work:
    
    <ul class="list-disc pl-6">
   <li><b> Listening to Written Content:</b> TTS can read books, articles, and instructions aloud. This is helpful if a student finds it easier to understand by listening rather than reading.</li>
<li><b>Improving Pronunciation:</b> By listening to words and sentences spoken correctly, students can learn how to pronounce difficult words and phrases better.</li>
<li><b>Reading Along:</b> TTS lets students follow along with the written text as it’s read aloud, helping them improve their reading skills.</li>
<li><b>Making Stories More Fun:</b> In interactive stories or games, TTS can make characters “speak,” which makes storytelling and digital projects more engaging and lively.</li>
<li><b>Accessibility:</b> TTS is very useful for people who have trouble seeing or reading text, making content accessible to everyone.</li>
    </ul>`,
          //   image: parts, // Replace with actual image path
          // imagePlacement: 'left', // Image on the left side
        },
      ]
    }, {
      id: 6,
      title: 'Lab Activities[1-3]',
      // description: 'Learn about the foundational concepts of computers and technology.',
      details: [

        {
          //sectionTitle: <span className="text-purple-600"></span>,
          //content: `<iframe src="LABACTIVITY5.pdf" width="100%" height="600px" />`
          content: `<embed src="LABACTIVITY5.pdf#toolbar=0" type="application/pdf" width="100%" height="600px" />`

        },

      ]
    },],
    6: [{
      id: 1,
      title: '1. Advanced Algorithms and Control Structures ',
      // description: 'Learn about the foundational concepts of computers and technology.',
      details: [
        {
          sectionTitle: <span className="text-purple-600">1.1  Reviewing Algorithms: Understanding Flowcharts
          </span>,
          content: `Imagine you’re planning a secret mission for a character, Alex the Explorer, who needs to find a hidden treasure. Each step in the plan is important and must be followed in order to reach the goal without getting lost.
         A flowchart is like a map that shows each step of Alex's journey. In this map:

          <ul class="list-disc pl-6">
  <li>The<strong>Start </strong>shape shows where the mission begins.
</li>
  <li><strong>Action </strong>  shapes tell Alex what to do, like “Move forward 10 steps.”
</li>
  <li><strong>Decision </strong> shapes give Alex a choice, like “Is there a wall?”
</li>
 
</ul>
For example, if Alex encounters a wall, the decision shape might direct him to turn left. Otherwise, he can keep moving forward. Flowcharts make complex missions easier by showing exactly what comes next.

          `,
          image: reach_goal, // Replace with actual image path
          imagePlacement: 'right', // Image on the right side
        },
        {
          sectionTitle: <span className="text-purple-600">1.2 Understanding Sequences: Order of Operations
          </span>,
          content: `Sequences are like following steps in a recipe. Imagine you’re programming a rocket to launch into space. Each action in the code needs to happen in the exact order, or else the launch could go very wrong.
<p>In this case, our sequence for the rocket might be:</p>
<p>1. Set the countdown timer.</p>
<p>2. Turn on the engines.</p>
<p>3. Launch the rocket.</p>
<p>4. Play a cheering sound for the crowd.</p>

If the actions happen out of order, like launching before the engines turn on, the rocket wouldn’t go anywhere. So, in coding, sequences make sure that everything happens in just the right order to reach our goal.`,
          image: topic61, // Replace with actual image path
          imagePlacement: 'left', // Image on the left side
        },
        {
          sectionTitle: <span className="text-purple-600">1.3 Conditional Statements: If-Else Logic</span>,
          content: `Conditional statements help computers make choices, just like we do. Imagine you’re coding an adventure where Mia the Mouse is exploring a maze to find a piece of cheese. Along the way, Mia has to make smart decisions based on what’s in front of her.
          <p>Here’s how the code might look:</p>
          <ul class="list-disc pl-6"> 
         <li> <strong>If </strong>Mia sees a wall in front, she should turn right to avoid it.</li>
<li> <strong>Else </strong> (if there’s no wall), Mia keeps moving forward.
</li>
.</ul>
The “If-Else” structure lets Mia react to her surroundings. She can decide which way to go depending on what she sees, allowing her to make choices based on the conditions around her. This makes her journey much smarter and helps her find the cheese faster!

`,
          image: topic612, // Replace with actual image path
          imagePlacement: 'right',
        },


        {
          sectionTitle: <span className="text-purple-600">1.4 Using Loops: Repeat Actions Efficiently</span>,
          // layout: 'two-images', // New layout type for two images
          //leftImage: io, // Replace with the actual path
          //
          // rightImage: op, // Replace with the actual path
          content: `
           Loops are like repeating patterns in a dance routine. Imagine you’re coding a guard to patrol back and forth along a castle wall until sunrise. Instead of telling the guard “walk forward” and “turn around” again and again, a loop can take care of the whole process.
          <p>Here’s how the loop works for the guard:</p>
            <ul class="list-disc pl-6">
             <li><strong>Repeat until sunrise:</strong>The guard walks along the wall, turns around, and walks back.</li>
             <li>The guard keeps repeating this route without stopping.</li>
             </ul>
             Loops save time by repeating actions automatically, making it easy to tell the guard to patrol without writing the same actions over and over.

          `,
          image: topic613, // Replace with actual image path
          imagePlacement: 'right',
        },

        {
          sectionTitle: <span className="text-purple-600">1.5 Review of Algorithms and Control Structures
          </span>,
          // layout: 'two-images', // New layout type for two images
          //leftImage: io, // Replace with the actual path
          //
          // rightImage: op, // Replace with the actual path
          content: `
          Control structures are like the building blocks that help us make smarter, more organized programs. They include:

            <ul class="list-disc pl-6">
             <li><strong>Sequences </strong> to keep actions in order.</li>
             <li><strong>Conditionals  </strong>to make choices depending on what’s happening.</li>
             <li><strong>Loops  </strong> to repeat actions without having to write them again.</li>
             </ul>
          `,

        },

        {
          sectionTitle: <span className="text-orange-600">The Adventure of Robo the Robot Chef</span>,

          content: `
           One sunny morning, Robo the Robot Chef was given a big task: he had to bake a cake for the Queen's birthday party! But Robo had a small problem — he needed to follow every step exactly to make the cake perfectly. So, his creator wrote him an algorithm to make sure every detail was right.

          `,
          image: topic614, // Replace with actual image path
          imagePlacement: 'right', // Image on the left side
        },
        {
          sectionTitle: <span className="text-orange-600">Step 1: Gathering Ingredients (Using Sequences)
          </span>,

          content: `
           Robo’s first instruction was to gather ingredients. He followed each step carefully, one after another:
          <p>1. Find the flour.</p>
          <p>2. Add sugar.</p>
          <p>3. Crack the eggs.</p>
          <p>4. Pour the milk.</p>
          If Robo didn’t follow these steps in the right sequence, he might put milk before the flour or crack the eggs too early. That would make a mess!

          `,
          image: topic615, // Replace with actual image path
          imagePlacement: 'right', // Image on the left side
        },
        {
          sectionTitle: <span className="text-orange-600">Step 2: Baking the Cake (Using Conditionals) </span>,

          content: `
         Now it was time to bake the cake. But Robo knew he couldn't start baking right away. He had a condition to check: If the oven was hot, he could start baking. Else he had to wait.
          <p>Robo looked at his checklist:</p>
            <ul class="list-disc pl-6">
             <li>“If the oven is hot, place the cake inside.”</li>
             <li>“Else, wait until it reaches the right temperature.”</li>
             </ul>
             The Queen’s cake needed to bake at just the right time. Robo waited patiently, and when the oven was hot, he carefully slid the cake inside.

          `,
          image: topic616, // Replace with actual image path
          imagePlacement: 'left', // Image on the left side

        },
        {
          sectionTitle: <span className="text-orange-600">Step 3: Keeping an Eye on the Cake (Using Loops)
          </span>,

          content: `
        The instructions told Robo to keep checking the cake every 5 minutes. But instead of writing “check the cake” 20 times, Robo’s creator used a loop! The loop kept repeating the same action so that Robo would check over and over until the cake was ready.
          <p>Here’s what Robo’s loop looked like:</p>
         
            <ul class="list-disc pl-6">
             <li><strong>Repeat until the timer goes off:</strong></li>
            <ol>Check the cake.</ol>
             </ul>
            Robo was so grateful for this loop! He only needed to follow a short command that would keep repeating, and that saved him a lot of extra steps.

          `,
        },
        {
          sectionTitle: <span className="text-orange-600">Final Step: Reviewing the Whole Recipe </span>,

          content: `
         <p>Robo’s instructions were a perfect mix of sequences, conditionals, and loops — the three superpowers of any good recipe! Robo understood how important it was to review each part of his recipe. These control structures helped him follow the steps in the right order, make decisions based on the oven’s temperature, and repeat checks on the cake until it was ready.</p>
         <p>When the Queen tasted the cake, she was thrilled! She crowned Robo the “Master Chef” of her kingdom. And Robo learned that with the right algorithm and control structures, he could bake anything perfectly every time!
</p>
          `,
          image: topic617, // Replace with actual image path
          imagePlacement: 'left', // Image on the left side

        },






        { sectionTitle: <span className="text-blue-600">Activity Session</span> },


        {
          sectionTitle: <span style={{ color: 'orange' }}>1.6 Create a Flowchart for a Simple Task</span>,
          content: 'Students will create a flowchart to outline the steps for a simple    task, such as making a sandwich.'
        }
        ,

        {
          sectionTitle: <span style={{ color: 'orange' }}>1.7  Write a Program Using If-Else Statements </span>,
          content: '  Students will write a program that uses if-else statements to create an interactive quiz.',
        },
        {
          sectionTitle: <span style={{ color: 'orange' }}>1.8 Design a Program with Loops
          </span>,
          content: ' Students will create a program that uses loops to perform repetitive tasks (e.g., counting).',
        },
        {
          sectionTitle: <span style={{ color: 'orange' }}>1.9  Combine Conditions and Loops in a Game
          </span>,
          content: '  Students will design a simple game that uses both conditions and loops.',
        },
        {
          sectionTitle: <span style={{ color: 'orange' }}>1.10 Present Flowcharts and Programs</span>,
          content: 'Students will present their flowcharts and programs to the class, explaining their processes.',
        },

      ]
    }, {
      id: 2,
      title: '2. Introduction to Functions and Events',
      // description: 'Learn about the foundational concepts of computers and technology.',
      details: [

        {
          sectionTitle: <span className="text-purple-600">Story: The Adventure of Felix and the Magical Functions</span>,
          content: `In the magical kingdom of CodeTopia, there lived a young inventor named Felix who loved creating robots. Felix had built a robot named Spark, who was great at helping with chores. 
          <p>But soon, Felix wanted to make Spark even more amazing by teaching him new tricks. To do this, Felix had to write down instructions for Spark in the form of functions.
</p>
<p>Felix started with some basic functions:</p>
<p><strong>1. Clean the Room: </strong>Spark would vacuum the floor and dust the furniture.</p>
<p><strong>2. Fetch Snacks: </strong> Spark would go to the kitchen and bring back snacks.</p>
Now, any time Felix needed Spark to do these tasks, he simply called the CleanTheRoom or FetchSnacks function, and Spark would follow the steps without Felix having to explain them again.
<p>One day, Felix had a brilliant idea! He thought, “What if Spark could do things only when I give him a special signal?” That’s when Felix learned about events. Felix set up a special button that he could press to make Spark dance and cheer him up.
</p><p>Now, whenever Felix pressed the dance button, Spark would call the Dance function and start busting some moves! Soon, Felix added even more events. He connected Spark’s “Clean the Room” function to an event that would trigger every morning at 9:00 AM.
</p>
<p>Now Spark was super efficient. Felix could focus on inventing new things, while Spark cleaned the room every morning and danced whenever Felix was in need of a little fun. Thanks to the power of functions and events, Spark and Felix became the most famous inventor duo in all of CodeTopia!
</p>
`,
          image: topic62, // Replace with actual image path
          imagePlacement: 'left', // Image on the left side

        },


        {
          sectionTitle: <span className="text-purple-600">2.1  Understanding Functions: Code Reusability</span>,
          content: `Functions are one of the coolest coding tricks that programmers have. Think of them as recipes or spells that you create and then can use over and over without rewriting the whole recipe each time.
          <p><strong>What are functions? </strong>They’re chunks of code that perform a specific task. Imagine you’re making a sandwich — the steps of getting the bread, adding ingredients, and putting it all together are like a recipe. When you write these steps as a function, you can reuse them whenever you need a sandwich!</p>
          <p> <strong>Why do we use functions?</strong> Imagine if every time you wanted a sandwich, you had to write out each individual step. It would get messy and take a long time. Functions keep your code neat and easy to use by letting you “call” the recipe anytime you need it.</p>
          <p><strong>Reusability:</strong> One of the main reasons we use functions is to avoid repeating ourselves. When we reuse code, it’s called code reusability. With functions, instead of rewriting everything, we just reuse the code by calling the function whenever we need it.
</p> `,
          image: topic621, // Replace with actual image path
          imagePlacement: 'left', // Image on the left side

        },
        {
          sectionTitle: <span className="text-purple-600">2.2 Creating and Calling Functions
          </span>,
          content: `Creating a function is like building a new tool that you can keep in your toolbox.


<ul class="list-disc pl-6">
  <li><strong>Creating a function: </strong>To make a function, you first define it, meaning you give it a name and a set of instructions. Let’s say we want a function that will draw a star. We might name it drawStar and then list the steps to make a star.
</li>
  <li><strong>Calling a function:</strong> After defining the function, you can call it whenever you need a star drawn. Calling a function means using it by name in your code to run all the steps it contains. Each time you call draw Star, the computer knows to follow the instructions inside that function and draw the star for you!
</li>

</ul>
Imagine you’re a wizard who’s created a spell. The spell is now saved in your spell book (your code) as a function. Whenever you need it, you don’t have to redo everything; you just say the spell’s name (call the function) and let it work its magic.
`,
          image: topic622, // Replace with actual image path
          imagePlacement: 'right', // Image on the left side
        },
        {
          sectionTitle: <span className="text-purple-600">2.3 Introduction to Events: What Triggers Actions?
          </span>,
          content: `Events are things that happen and can start or trigger an action in your code. They allow us to create programs that are interactive and respond to what the user does.
          <ul class="list-disc pl-6"> 
         <li> <strong>What are events?</strong> Events can be anything from clicking a button, pressing a key, or even something happening in the background. When you press the play button on a video, an event starts the video.</li>
<li> <strong>Why are events important?</strong> They help make coding interactive. Imagine playing a game where your character jumps every time you press the spacebar — that’s an event in action! Events let our code know when to start or stop doing things based on certain triggers.
</li>

.</ul>
Events can be used to make things happen automatically, or they can wait for you to click or press something. They help us control when and how code runs.

`,
          image: topic623, // Replace with actual image path
          imagePlacement: 'left', // Image on the left side
        },


        {
          sectionTitle: <span className="text-purple-600">2.4 Combining Functions with Events
          </span>,
          // layout: 'two-images', // New layout type for two images
          //leftImage: io, // Replace with the actual path
          //
          // rightImage: op, // Replace with the actual path
          content: `
           Combining functions and events is like mixing together the magic spell (function) with a command (event) that tells it when to activate.
            <ul class="list-disc pl-6">
             <li><strong>How it works:</strong> Say you have a function called makeRobotDance. If you want the robot to dance when the user presses the “D” key, you can use an event to trigger that function. In coding, you’d set up the function, then create an event that activates the function when “D” is pressed.
</li>
             <li><strong>Why it’s useful:</strong> Combining functions with events makes your code interactive and efficient. This combination means that you can control when each function is used, like only making the robot dance when you tell it to.
</li>
             
             </ul>
             This mix of functions and events allows your code to respond to the world around it and act based on different conditions. Imagine a smart home system that only turns on the lights when you walk into the room. It’s a combination of functions and events that makes this possible.

          `,
          image: topic624, // Replace with actual image path
          imagePlacement: 'left', // Image on the left side
        },


        {
          sectionTitle: <span className="text-purple-600">2.5 Review of Functions and Events
          </span>,
          // layout: 'two-images', // New layout type for two images
          //leftImage: io, // Replace with the actual path
          //
          // rightImage: op, // Replace with the actual path
          content: `Functions and events together make up a big part of interactive coding. Functions let us group actions into reusable chunks, while events let us decide when and how these chunks should be used.
            <ul class="list-disc pl-6">
             <li><strong>Functions and events in action:</strong> When you build programs, think of functions as the tools you create to get things done, and events as the conditions or signals that make those tools work when you need them.
</li>
             <li><strong>Putting it all together:</strong> Let’s say you’re coding a game with a character that can walk, jump, and pick up objects. Each action can be a separate function. Then, you can use events like key presses to control which function runs. When you press the arrow key, the walk function activates; when you press the spacebar, the jump function activates.
</li>
              </ul>
              The combination of functions and events gives your program structure and flexibility, helping you build complex, interactive projects.

          `,

        },

        { sectionTitle: <span className="text-blue-600">Activity Session</span> },


        {
          sectionTitle: <span style={{ color: 'orange' }}>2.6   Write a Program Using Functions</span>,
          // layout: 'two-images', // New layout type for two images
          //leftImage: io, // Replace with the actual path
          //
          // rightImage: op, // Replace with the actual path
          content: `Students will create a program that uses functions to perform specific tasks.`
          // <b>Activity: </b>Arrange steps for tasks like getting dressed or making a sandwich.
          //   <ul class="list-disc pl-6">
          //   <b>Steps for Activity</b>
          //    <li><b>Introduce the Concept of Sequencing</b>
          //    </li>
          //    <ol>
          //    <li>Explain that sequencing means putting actions in the correct order to achieve a goal.</li>
          //    <li>Give an example, like tying shoelaces, where each step must happen in the right order.</li>
          //    </ol>
          //    <li><b>Explain the Sandwich Task</b></li>
          //     <ol>Show the students the materials or list of items involved in making a sandwich, such as:
          //     <li>Bread</li>
          //     <li>Butter or spread</li>
          //     <li>Filling (like cheese or vegetables)</li>
          //     <li>Knife for spreading</li></ol>
          //    <li><b>Discuss Each Step in Order</b></li>
          //    <ol>
          //     <li><b>Step 1:</b> Take two slices of bread.</li>
          //     <li><b>Step 2:</b> Spread butter or another spread on one or both slices of bread.</li>
          //     <li><b>Step 3:</b> Add the filling (e.g., cheese or veggies) on top of one of the slices.</li>
          //     <li><b>Step 4: </b>Place the second slice of bread on top to complete the sandwich.</li></ol>
          //    </ul>
          ,
          // image: op, // Replace with actual image path
          // imagePlacement: 'left', // Image on the left side
        },
        {
          sectionTitle: <span style={{ color: 'orange' }}>2.7   Design an Interactive Story Using Events&Functions
          </span>,
          // layout: 'two-images', // New layout type for two images
          //leftImage: io, // Replace with the actual path
          //
          // rightImage: op, // Replace with the actual path
          content: `Students will design an interactive story that responds to user input through events.
`
          // <b>Activity: </b>Build a simple sequence using cards or blocks for a specific task.
          //   <ul class="list-disc pl-6">
          //   <b>Steps for Activity</b>
          //    <li><b>Explain the Concept of an Algorithm</b>
          //    </li>
          //    <ol>
          //    <li>Define an algorithm as a set of step-by-step instructions to complete a task.</li>
          //    <li>Give examples of everyday algorithms, like a recipe for cooking or the instructions for tying shoelaces.</li>
          //    </ol>
          //    <li><b>Introduce the Task: Getting Ready for School</b></li>
          //     <ol>1.Explain that students will create an algorithm for getting ready in the morning.
          //     2.List the typical steps involved:
          //     <li>Wake up</li>
          //     <li>Brush teeth</li>
          //     <li>Eat breakfast</li>
          //     <li>Pack school bag</li>
          //     <li>Put on shoes</li>
          //     </ol>
          //    <li><b>Create Instruction Cards or Blocks</b></li>
          //    <ol>
          //     <li>Write each step on a separate card or use blocks with the steps written on them.</li>
          //     <li>Shuffle the cards/blocks to allow students to arrange them in the correct order.</li>
          //     </ol>
          //     <li><b>Arrange the Steps in the Correct Sequence</b></li>
          //    <ol>
          //     <li>Ask students to organize the steps into a logical order.</li>
          //     <li>Once they’ve arranged the steps, review the sequence together and discuss why each step comes in that particular order.</li>
          //     </ol>
          //      <li><b>Practice Creating Algorithms for Other Tasks</b></li>
          //    <ol>
          //     <li>Give students different tasks to build algorithms for, such as:</li>
          //     <ul><b>Making a Bowl of Cereal:</b>
          //     <li>* Get a bowl, pour cereal, add milk, use spoon to eat.</li></ul>
          //     <ul><b>Planting a Seed:</b>
          //     <li>* Dig a hole, plant the seed, cover with soil, water the plant.</li></ul>
          //     <li>Have students create an algorithm for each task using their cards or blocks.</li>
          //     </ol>
          //    </ul>
          // `,
          // image: op, // Replace with actual image path
          // imagePlacement: 'left', // Image on the left side
        },
        {
          sectionTitle: <span style={{ color: 'orange' }}>2.8   Create a Mini Game with Functions and Events
          </span>,
          // layout: 'two-images', // New layout type for two images
          //leftImage: io, // Replace with the actual path
          //
          // rightImage: op, // Replace with the actual path
          content: `Students will design a mini-game that incorporates both functions and events.`
          // <b>Activity: </b>Follow verbal or visual directions to reach a destination in a maze.
          //   <ul class="list-disc pl-6">
          //   <b>Steps for Activity</b>
          //    <li><b>Introduce the Concept of Following Directions</b>
          //    </li>
          //    <ol>
          //    <li>Explain that directions tell us how to get from one place to another.</li>
          //    <li>Emphasize that following directions accurately is essential for reaching a goal, whether in a game, real life, or coding.</li>
          //    </ol>
          //    <li><b>Set Up the Maze</b></li>
          //     <ol>
          //     <li>Draw or print a simple maze on paper or create a large maze on the floor using tape.</li>
          //     <li>Mark the <b>start</b> and <b>finish</b> points clearly.</li>
          //     <li>Optionally, add obstacles or alternate paths to make the maze more challenging.</li>
          //     </ol>
          //    <li><b>Provide Verbal or Visual Directions</b></li>
          //    <ol>Choose one of the following methods:
          //     <li><b>Verbal Directions:</b> A partner or the instructor gives step-by-step directions out loud (e.g., “Move forward two steps,” “Turn left,” “Move forward one step”).</li>
          //     <li><b>Visual Directions:</b> Provide students with a printed list of directions or arrows showing the path to follow.</li>
          //     Allow students to attempt navigating the maze based on these instructions.</ol>
          //     <li><b>Have Students Follow the Directions</b></li>
          //     <ol>
          //     <li>If using a paper maze, students can trace their path with a pencil or finger.</li>
          //     <li>For a floor maze, students can walk through it step-by-step, following the directions precisely.</li>
          //     </ol>
          //    </ul>
          // `,
          // image: op, // Replace with actual image path
          // imagePlacement: 'left', // Image on the left side
        },
        {
          sectionTitle: <span style={{ color: 'orange' }}>2.9  Test and Refine Your Game</span>,
          // layout: 'two-images', // New layout type for two images
          //leftImage: io, // Replace with the actual path
          //
          // rightImage: op, // Replace with the actual path
          content: `Students will playtest their mini-games, focusing on improving functionality and user experience.`
          // <b>Activity: </b>Role-play tasks with steps, like tying shoes or setting the table.
          //   <ul class="list-disc pl-6">
          //   <b>Steps for Activity</b>
          //    <li><b>Introduce the Concept of Everday Algorithms</b>
          //    </li>
          //    <ol>
          //    <li>Explain that an algorithm is simply a sequence of steps to achieve a specific outcome.</li>
          //    <li>Give examples of everyday algorithms, such as instructions for making a sandwich or brushing teeth.</li>
          //    </ol>
          //    <li><b>Choose a Task to Act Out</b></li>
          //     <ol>Select a task that has clear, sequential steps and is familiar to students. Suggested tasks:
          //     <li><b>Setting the Table:</b></li>
          //     <li>Step 1: Place the placemat on the table.</li>
          //     <li>Step 2: Put a plate in the center of the placemat.</li>
          //     <li>Step 3: Place a fork to the left of the plate.</li>
          //     <li>Step 4: Place a knife and spoon to the right of the plate.</li>
          //     <li>Step 5: Put a cup or glass above the plate.</il></ol>
          //    <li><b>Role-Play the Task</b></li>
          //    <ol>
          //     <li>Assign each step to a student or group of students, or have a single student act out the entire task</li>
          //     <li>As students perform each step, narrate or discuss the purpose of each step in achieving the goal.</li></ol>
          //    </ul>
          // `,
          // image: op, // Replace with actual image path
          // imagePlacement: 'left', // Image on the left side
        },
        {
          sectionTitle: <span style={{ color: 'orange' }}>2.10 Present Your Mini Game</span>,
          // layout: 'two-images', // New layout type for two images
          //leftImage: io, // Replace with the actual path
          //
          // rightImage: op, // Replace with the actual path
          content: ` Each student will present their mini-game, highlighting the use    of functions and events.

  
            
          `,
          // image: op, // Replace with actual image path
          // imagePlacement: 'left', // Image on the left side
        },

      ]
    }, {
      id: 3,
      title: '3. Animation and Game Design Principles   ',
      // description: 'Learn about the foundational concepts of computers and technology.',
      details: [
        {
          sectionTitle: <span className="text-purple-600">3.1  Understanding Animation </span>,
          content: `Animation is the art of bringing images to life by creating the illusion of movement. When we animate, we take still images and make them seem like they are moving.
          <ul class="list-disc pl-6">
          <li><strong>What is animation? </strong>Think of animation like flipping through a flipbook. Each page shows a slightly different picture, and when you flip through them quickly, it looks like the pictures are moving! In digital animation, we create sequences of images that change over time to give the same effect.
</li>
          <li><strong>What are sprites?</strong> Sprites are images or graphics that represent objects or characters in your animation. Each sprite can have different costumes or looks for different actions, like walking or jumping.</li>
          <li><strong>How to create animations with sprites:</strong>In programming environments like Scratch, you can create animations using sprites by switching between different costumes. For example, if you have a sprite of a cat, you can create several costumes of the cat in different poses. When you quickly switch between these costumes, it makes the cat look like it’s running!
</li>
 <li><strong>Moving sprites:</strong> You can also control how sprites move across the screen. You can program them to glide, bounce, or move in a straight line, which adds more life to your animations.
</li>

          </ul>
          `,
          image: topic6311, // Replace with actual image path
          imagePlacement: 'left', // Image on the right side
        },
        {
          sectionTitle: <span className="text-purple-600">3.2 Creating Animations Using Sprites</span>,
          content: `started dancing, he sent out a message saying, “Let’s Dance!” Tweet and Whiskers heard the message and began to dance along with him. They all moved in perfect harmony, creating a joyful dance party!
<p>To make the dance party even livelier, Lily added fun sounds. Every time Benny jumped, she played a cheerful “boing” sound. When Tweet fluttered her wings, there was a soft “tweet-tweet.” Whiskers made a “meow” sound every time she landed. Together, the sounds and actions brought the dance party to life!
</p><p>As the show progressed, Lily decided to add special effects to highlight the exciting moments. Whenever Benny did a big jump, sparkles appeared around him, making it look magical! The audience gasped and laughed as they watched the colorful animation unfold.
</p><p>After hours of hard work, Lily finally finished her animation. She gathered her friends to watch it. As the characters danced, sang, and played together, everyone cheered. They loved the fun sounds, the colorful sprites, and how well Benny, Tweet, and Whiskers worked together through the magic of broadcasting!
</p><p>From that day on, Lily became known as the best animator in Animatia, inspiring everyone to create their own adventures through the magic of animation! And they all lived happily ever after, dancing and laughing, sharing their stories with the world.
</p>`,
          image: topic6322, // Replace with actual image path
          imagePlacement: 'left', // Image on the left side
        },
        {
          sectionTitle: <span className="text-purple-600">3.3 Using Broadcasts for Coordinated Actions</span>,
          content: `Broadcasts are a powerful tool for making multiple sprites work together in your animations.

          <ul class="list-disc pl-6"> 
         <li> <strong>What is broadcasting? </strong>  Broadcasting is like sending out a message to all your sprites. When you broadcast a message, any sprite that is listening for that message can react to it. It’s a way to coordinate actions without having to write a lot of complicated code.
</li>
<li> <strong>How to use broadcasts:</strong> Imagine you have two characters in your animation — a cat and a dog. If you want the dog to bark whenever the cat starts to dance, you can use a broadcast. When the cat starts dancing, it sends out a broadcast saying, “Let’s Dance!” The dog, which is waiting for that message, will hear it and start barking. This way, you can create synchronized actions that make your animation more dynamic and exciting!
</li>
</ul>`,

          // No image property added here
        },


        {
          sectionTitle: <span className="text-purple-600">3.4 Adding Sound and Effects to Animations
          </span>,

          content: `
            Sounds and effects enhance your animation, making it more immersive and engaging.

            <ul class="list-disc pl-6">
             <li><strong>Why add sound?</strong>  Sounds help tell the story. Whether it’s music playing in the background, characters talking, or sound effects like a crash or a cheer, sound brings your animation to life. Imagine watching a cartoon without any sound — it wouldn’t be nearly as fun!
</li>
          <li><strong>How to add sound: </strong> In Stem Verse, you can add sounds to specific actions. For example, you might want a character to laugh when they jump. You can program the jump action to also play a sound file of laughter. You can also add background music that plays throughout the animation, creating the right mood for your story.
</li> 
           <li><strong>Effects: </strong> You can also add visual effects, like making a character glow or changing colors. These effects can help highlight important moments in your animation, like a character scoring a goal in a game or winning a race!
</li>

             </ul>
          `,
          image: topic6323, // Replace with actual image path
          imagePlacement: 'left', // Image on the left side
        },

        {
          sectionTitle: <span className="text-purple-600">3.5 Review of Animation Concepts</span>,

          content: `Let’s take a moment to review what we’ve learned about animation!

            <ul class="list-disc pl-6">
             <li><strong> Animation Basics:</strong>We understood that animation is about creating the illusion of movement using a sequence of images or sprites.
</li>
           <li><strong>  Sprites:</strong> Sprites are the characters and objects in your animation that you can manipulate and bring to life through different costumes and movements.
</li>
           <li><strong>  Broadcasting:</strong> Broadcasting allows you to coordinate multiple sprites, enabling them to work together seamlessly when triggered by a message.
</li>
           <li><strong>Sound and Effects:</strong>Adding sound and effects enhances the storytelling aspect of your animation, making it more engaging for the audience.</li>
             </ul>
          `,

        },




        { sectionTitle: <span className="text-blue-600">Activity Session</span> },


        {
          sectionTitle: <span style={{ color: 'orange' }}>3.6 Create a Simple Animation Project</span>,
          content: ' Students will design and code a simple animation using sprites and movement commands'
        }
        ,

        {
          sectionTitle: <span style={{ color: 'orange' }}>3.7 Implement Broadcasts in Your Animation </span>,
          content: '  Students will enhance their animations by adding broadcasts to synchronize actions.',
        },
        {
          sectionTitle: <span style={{ color: 'orange' }}>3.8 Create Interactive Animated Stories</span>,
          content: ' Students will create an interactive story that incorporates animation and user input.',
        },
        {
          sectionTitle: <span style={{ color: 'orange' }}>3.9 Test and Improve Your Animations
          </span>,
          content: ' Students will playtest their animations and receive feedback for improvement.',
        },
        {
          sectionTitle: <span style={{ color: 'orange' }}>3.10 Present Your Animation Projects</span>,
          content: 'Each student will present their animation projects to the class.',
        },

      ]
    }, {
      id: 4,
      title: '4. Introduction to Variables & Advanced Game Design',
      // description: 'Learn about the foundational concepts of computers and technology.',
      details: [
        {
          sectionTitle: <span className="text-purple-600">4.1 Understanding Game Mechanics </span>,
          content: `Game mechanics are the rules and systems that make a game fun and engaging. They define how players interact with the game and what actions they can take.
          <ul class="list-disc pl-6"> 
         <li> <strong>Core Mechanics: </strong> These are the fundamental actions players can perform, such as jumping, running, collecting items, or shooting. In block coding, these mechanics can be implemented using simple blocks. For example, using a "when key pressed" block to make a character jump or move.
</li>
<li> <strong>Feedback Mechanisms:</strong> Good games provide feedback to players to show them the results of their actions. This can include sound effects, animations, or visual cues. For instance, when a player collects a coin, a sound effect can play, and the coin can disappear, providing clear feedback that the action was successful.
</li>
<li> <strong>Challenges and Rewards:</strong>Games often present challenges that players must overcome to earn rewards. This could be defeating enemies, solving puzzles, or completing levels. In block coding, you can create challenges by setting conditions using "if-else" statements to determine when a player succeeds or fails.
</li>
</ul>
          `,
          image: topic64, // Replace with actual image path
          imagePlacement: 'left', // Image on the right side
        },
        {
          sectionTitle: <span className="text-purple-600">4.2 Creating Levels and Challenges</span>,
          content: `Levels and challenges are essential for keeping players engaged and providing a sense of progression in a game.
          <ul class="list-disc pl-6"> 
         <li> <strong>Level Design: </strong>Each level should introduce new challenges or mechanics to keep the game interesting. When designing levels in Stem Verse, you can create different backdrops and adjust the layout of platforms, obstacles, or enemies.
</li>
<li> <strong>Challenge Elements:</strong> : Incorporating different types of challenges can add variety. For example, you can create a platforming challenge where the player must jump between moving platforms, or a puzzle where they must collect keys to unlock a door. Use blocks to define the conditions that must be met to complete each level, such as reaching a certain point or collecting a specific item.</li>
<li> <strong>Progression:</strong>As players complete levels, you can increase the difficulty. For example, introduce faster-moving enemies or additional obstacles in later levels. This can be managed using variables to track the player’s progress and adjust the challenges accordingly.
</li>
</ul>
          `,
          image: topic641, // Replace with actual image path
          imagePlacement: 'left', // Image on the left side
        },
        {
          sectionTitle: <span className="text-purple-600">4.3 Variables for Scoring and Lives</span>,
          content: `In cricket how to know about runs? In Tv we notice that change the run score by till end thematch.
         <p> In simple terms, a variable is a storage container that holds a value that can be modified or retrieved by your program. In Scratch, variables can be used to store data such as numbers, strings, or Boolean values, and can be accessed from any sprite in your project.
</p><p>What is a variable? In programming, a variable is a value that can change, depending on conditions or on information passed to the program. Typically, a program consists of instructions that tell the computer what to do and data that the program uses when it is running.
</p><p>In computer programming, a variable is an abstract storage location paired with an associated symbolic name, which contains some known or unknown quantity of data or object referred to as a value; or in simpler terms, a variable is a named container for a particular set of bits or type of data (like integer, float, ...).
</p>
          `,

          // No image property added here
        },


        {
          sectionTitle: <span className="text-purple-600">4.4 Adding Animation and Sound Effects to Games</span>,

          content: `Animation and sound effects enhance the gaming experience by making it more immersive and engaging.
          <ul class="list-disc pl-6">
          <li><strong>Animating Characters:</strong> In Stem Verse, you can animate characters using sprites and costume changes. For instance, when a character jumps, you can switch to a different costume that shows the character mid-jump. Use the “switch costume to [costume]” block to achieve this effect.
</li>
         <li><strong> Sound Effects:</strong> Sounds play a crucial role in games. You can add sound effects for actions like jumping, collecting items, or hitting enemies. Use the “play sound [sound] until done” block to play sounds when certain actions occur, providing auditory feedback to the player.
</li>
         <li><strong>Background Music:</strong> To create a more engaging atmosphere, you can also add background music that plays during gameplay. This can be accomplished using the “play sound [background music]” block at the beginning of the game or level.
 </li>
          `,

        },

        {
          sectionTitle: <span className="text-purple-600">4.5 Review of Game Design Principles
          </span>,
          // layout: 'two-images', // New layout type for two images
          //leftImage: io, // Replace with the actual path
          //
          // rightImage: op, // Replace with the actual path
          content: `
          <p>Once upon a time in the vibrant land of Gameville, a young game designer named Alex dreamed of creating an epic adventure game. Alex wanted players to explore different worlds filled with challenges and surprises.
</p>
          <p>To start, Alex learned about game mechanics. “I need to decide what my character can do!” Alex thought. After some brainstorming, Alex decided the hero, Leo the Lion, could jump over obstacles, collect gems, and fight off sneaky snakes. Using block coding, Alex programmed Leo to jump when the up arrow was pressed, making sure to add a sound effect for a satisfying “boing!”
        </p>
          <p>Next, Alex focused on creating levels. “Each level should be unique and offer new challenges!” Alex exclaimed. In the first level, Leo had to jump over logs and dodge cacti. In the second level, things got tricky; Leo had to navigate through a maze! To make it even more exciting, Alex added a timer to challenge players to complete the level quickly.
        </p>
          <p>To keep track of Leo’s progress, Alex created a variable for scores. Every time Leo collected a gem, his score increased, thanks to the “change [Score] by 10” block. Alex also set up a variable for lives. Leo started with three lives, and each time he got hit by a snake, he would lose one. “If Leo runs out of lives, it’s game over!” Alex chuckled, programming a fun animation for the game-over screen.
</p>
         <p> As Alex worked on the game, they realized the importance of animation and sound effects. Leo needed to look amazing while jumping! Alex switched Leo’s costumes to show him in mid-air. They added a catchy background music track to keep players energized while they played. And for each gem collected, a cheerful “ding!” rang out, letting players know they were doing great!
</p>
         <p> Finally, after weeks of hard work and creativity, Alex completed the game and invited friends to play. They all loved the exciting challenges, the smooth animations, and the fun sounds. They cheered as Leo dashed through levels, collecting gems and avoiding snakes. Alex felt proud, knowing they had successfully applied all the principles of game design to create a fun adventure that everyone enjoyed!
</p>
          <p>And from that day on, Alex continued to design games, sharing the joy of game mechanics, challenges, and creativity with the world!
</p>
          `,

        },

        //           {
        //             sectionTitle: <span className="text-purple-600">Understanding Buttons and Icons</span>,
        //             content: `When you look at a computer screen, you see lots of little pictures and buttons. These pictures are called <b>icons</b>, and they help you find and open different programs.
        //             <ul class="list-disc pl-6">
        //            <li><strong>Icons:</strong> Each icon is like a shortcut. If you want to open a game, you might click on a picture that looks like a joystick or a soccer ball. If you want to see the photos you took, you might click an icon that looks like a camera or a picture.</li>
        //            <li><strong>Buttons:</strong> Buttons are like switches. When you click a button, something happens. For example, if you’re watching a video, there’s a button to “pause” or “play.” If you’re drawing, there might be a button to change colors or erase something.</li>
        //            </ul>
        //            <p>Icons and buttons make it easy to tell the computer what you want to do. Just click, and the computer knows exactly what to show you or do next!</p>
        // `,
        // image: buttons, // Replace with actual image path
        // imagePlacement: 'right', // Image on the left side
        //           },



        { sectionTitle: <span className="text-blue-600">Activity Session</span> },


        {
          sectionTitle: <span style={{ color: 'orange' }}>4.6 Create a Basic Game Concept</span>,
          content: ' Students will brainstorm and outline the concept for their own game, including mechanics, levels, and challenges.'
        }
        ,

        {
          sectionTitle: <span style={{ color: 'orange' }}>4.7 Design Levels for Your Game
          </span>,
          content: '   Students will create different levels for their game based on their outlined concepts.',
        },
        {
          sectionTitle: <span style={{ color: 'orange' }}>4.8 Incorporate Scoring and Lives Using Variables
          </span>,
          // layout: 'two-images', // New layout type for two images
          //leftImage: io, // Replace with the actual path
          //
          // rightImage: op, // Replace with the actual path
          content: `Students will add scoring systems and lives to their games using variables.
`,
          // <b>Activity: </b>Create a movement pattern for a sprite using loops.
          //   <ul class="list-disc pl-6">
          //   <b>Step-by-Step Instructions:</b>
          //    <li><b>1. Choose a Sprite and Background</b>
          //    </li>
          //    <ol>
          //    <li>Have students select a sprite to animate, such as a character or a simple shape.</li>
          //    <li>Encourage them to pick a clear, contrasting background to make the movement pattern visible.</li>
          //    </ol>
          //    <li><b>2. Introduction to Direction Changes</b></li>
          //     <ol>
          //     <li>o  Direction Blocks: Introduce blocks like "turn [15] degrees" and "point in direction [ ]" to help them control the direction of the sprite’s movement.</li>
          //     </ol>
          //    <li><b>3. Create a Simple Pattern Using Loops</b></li>
          //    <ol>Square Pattern (Example):
          //     <li><b>Explain how moving in a square requires moving forward and then turning 90 degrees.</li>
          //     <li><b>Walk them through the code:</b> Use a "repeat [4]" block.<li>

          //     <li>Inside the loop, add "move [50] steps" and "turn [90] degrees".</li>
          //     <li>This code will move the sprite in a square pattern</li>


          //     </ol>
          //    </ul>
          // `
          // ,
          // image: op, // Replace with actual image path
          // imagePlacement: 'upper', // Image on the left side
        },
        {
          sectionTitle: <span style={{ color: 'orange' }}>4.9 Enhance Your Game with Animation and Sound
          </span>,
          content: ` Students will enhance their games by adding animations and sound effects to create a more engaging experience.`
          // <b>Activity: </b>Create a movement pattern for a sprite using loops.
          // <ul class="list-disc pl-6">
          // <b>Step-by-Step Instructions:</b>
          //  <li><b>1. Choose a Sprite and Background</b>
          //  </li>
          //  <ol>
          //  <li>Have students select a sprite to animate, such as a character or a simple shape.</li>
          //  <li>Encourage them to pick a clear, contrasting background to make the movement pattern visible.</li>
          //  </ol>
          //  <li><b>2. Introduction to Direction Changes</b></li>
          //   <ol>
          //   <li>o  Direction Blocks: Introduce blocks like "turn [15] degrees" and "point in direction [ ]" to help them control the direction of the sprite’s movement.</li>
          //   </ol>
          //  <li><b>3. Create a Simple Pattern Using Loops</b></li>
          //  <ol>Square Pattern (Example):
          //   <li><b>Explain how moving in a square requires moving forward and then turning 90 degrees.</li>
          //   <li><b>Walk them through the code:</b> Use a "repeat [4]" block.<li>

          //   <li>Inside the loop, add "move [50] steps" and "turn [90] degrees".</li>
          //   <li>This code will move the sprite in a square pattern</li>


          //   </ol>
          //  </ul>
          ,
        },
        {
          sectionTitle: <span style={{ color: 'orange' }}>4.10 Present Your Game Project
          </span>,
          content: `Each student will present their completed game to the class, explaining the mechanics, design choices, and user experience.`

        },

      ]
    }, {
      id: 5,
      title: '5. Music extension',
      // description: 'Learn about the foundational concepts of computers and technology.',
      details: [
        {
          //sectionTitle:<span className="text-purple-600"> What is a Computer ?</span>,
          //  content: `<iframe src="musicextension.pdf" width="100%" height="600px" />`
          content: `<embed src="musicextension.pdf#toolbar=0" type="application/pdf" width="100%" height="600px" />`
        },


      ]
    }, {
      id: 6,
      title: 'Speech recognition',
      // description: 'Learn about the foundational concepts of computers and technology.',
      details: [
        {
          // sectionTitle:<span className="text-purple-600"> What is a Computer ?</span>,
          //content: `<iframe src="speechrecogination.pdf" width="100%" height="600px" />`    
          content: `<embed src="speechrecogination.pdf#toolbar=0" type="application/pdf" width="100%" height="600px" />`
        },

      ]
    }, {
      id: 7,
      title: 'LAB Activity',
      // description: 'Learn about the foundational concepts of computers and technology.',
      details: [
        {
          //sectionTitle:<span className="text-purple-600"> What is a Computer ?</span>,
          //content: `<iframe src="LABACTIVITIE6.pdf" width="100%" height="600px" />`
          content: `<embed src="LABACTIVITIE6.pdf#toolbar=0" type="application/pdf" width="100%" height="600px" />`
        },






      ]
    }, {
      id: 8,
      title: 'Geography with StemVerse Robotics',
      // description: 'Learn about the foundational concepts of computers and technology.',
      details: [
        {
          //sectionTitle:<span className="text-purple-600"> </span>,
          //content: `<iframe src="Geography_with_StemVerse.pdf" width="100%" height="600px" />`
          content: `<embed src="Geography_with_StemVerse.pdf#toolbar=0" type="application/pdf" width="100%" height="600px" />`
        },

      ]
    },
    ],
    7: [{
      id: 1,
      title: '1. Advanced Programming Concepts',
      // description: 'Learn about the foundational concepts of computers and technology.',
      details: [
        {
          sectionTitle: <span className="text-purple-600">1.1 Review of Functions and Parameters
          </span>,
          content: `<strong>What Are Functions?</strong>
          <ul class="list-disc pl-6">
  <li>Explanation: </li>
  <ol>Start by explaining that a function is like a set of instructions we can create and name, so we can use it again whenever we want.</ol>
  <ol>In Scratch, functions are known as custom blocks. They allow us to write code once and then call it multiple times without rewriting it.</ol>
  <li>Example: </li>
  <ol>Imagine we want our sprite to "wave." Without functions, we’d have to keep repeating the code for waving each time we want the sprite to wave. But with a function, we just set up the waving code once and can reuse it whenever we want.</ol>
  </ul>
  <p><strong>Creating a Function in Scratch</strong></p>
  <p>1.	Open Scratch and have students pick a sprite to work with.</p>
  <p>2.	Go to My Blocks and click Make a Block to create a new function. Let’s call this function "Wave".</p>
  <p>3.	Under the new custom block, write the code for waving perhaps moving the arm up and down or rotating the sprite a few degrees back and forth.</p>
  
  `,
          image: topic71, // Replace with actual image path
          imagePlacement: 'right', // Image on the right side
        },
        {
          sectionTitle: <span className="text-purple-600">1.2 Introduction to Return Values
          </span>,
          content: `In programming, return values are like answers that a function or a block gives back after it completes its job. Imagine asking a friend, “How many candies are left in the jar?” They’ll count the candies and tell you the number that number is the return value.
  <p><strong>What is a Return Value?</strong></p>
  <p>A return value is what a function or block “returns” after it finishes doing something. It’s a piece of information (like a number, a word, or even true/false) that the function gives back to the rest of your code, so you can use it somewhere else in your project.</p>
  <p>For example, in block-based coding platforms like Scratch, many blocks provide return values:</p>
  <ul class="list-disc pl-6">
  <li><strong>Distance to Mouse Pointer: </strong> This block measures how far the sprite is from the mouse pointer. The distance it gives back (like 100 or 50) is the return value.</li>
  <li><strong>Pick Random: </strong> This has lots of buttons with letters (like A, B, C) and numbers (1, 2, 3). When you press these buttons, it’s like talking to the computer. You use it to type your name, write words, or do math.</li>
  <li><strong>Why Use Return Values?</strong> Return values are useful because they let us get important information from a block and then use that information somewhere else. Instead of guessing, your program can “ask” a block to calculate something, get an answer, and then use that answer.</li>
  </ul>`,
          //   image: parts, // Replace with actual image path
          // imagePlacement: 'left', // Image on the left side
        },
        {
          sectionTitle: <span className="text-purple-600">1.3 Events and Event Handling
          </span>,
          content: `When you want to use a computer, you do a few simple things:
          <ul class="list-disc pl-6"> 
         <li> Events are actions or occurrences that trigger specific responses in a program. In coding, events can be user actions like pressing a key, clicking a mouse, or touching the screen, as well as automated triggers like timers or system messages.</li>
         <li>Event handling is the process of defining what a program should do when an event occurs. It involves writing code blocks that "listen" for events and respond appropriately.</li>
  </ul>`,

          image: topic712, // Replace with actual image path
          imagePlacement: 'left', // Image on the left side
        },


        {
          sectionTitle: <span className="text-purple-600">1.4 Combining Functions and Events
          </span>,
          // layout: 'two-images', // New layout type for two images
          //leftImage: io, // Replace with the actual path
          //
          // rightImage: op, // Replace with the actual path
          content: `
            The computer has two types of parts: input devices and output devices.
            <ul class="list-disc pl-6">
             <li><strong>How Do Events and Functions Work Together? </strong> Events tell your program when something should happen, while functions tell it what should happen. By combining events and functions, we can create programs that respond to different actions smoothly.</li>
          `,
          image: topic713, // Replace with actual image path
          imagePlacement: 'right', // Image on the left side
        },

        {
          sectionTitle: <span className="text-purple-600">1.5 Review of Programming Concept
          </span>,
          // layout: 'two-images', // New layout type for two images
          //leftImage: io, // Replace with the actual path
          //
          // rightImage: op, // Replace with the actual path
          content: `
            <ul class="list-disc pl-6">
            <p>To wrap up, let’s review the main ideas:</p>
             <li><strong>Events and Event Handling: </strong> Events are actions that happen in the program (like key presses or clicks), and event handling is how we respond to these actions.</li>
             <li><strong>Functions:</strong>Functions are reusable pieces of code that perform specific tasks. They make our code easier to read and manage.</li>
             <li><strong>Combining Events and Functions:</strong>Using events to trigger functions lets us make interactive, dynamic programs where actions are controlled based on user input.</li>
           <p><strong>Lists:</strong></p>
           <li>•	In block-based coding, a list is a collection of items stored in a specific order. Lists allow programmers to store multiple pieces of information in one place, making it easy to manage and use data efficiently. You can think of a list as a digital organizer where each item has a position, or "index," starting from 1.</p>
           <p><strong>Uses of Lists in Block-Based Coding</strong></p>
           <p><strong>1.	Storing Multiple Values: </strong>Lists can hold multiple pieces of data, such as names, scores, or items in a game. This is helpful when you need to track multiple items without creating separate variables for each one.</p>
           <p><strong>2.	Easy Access and Modification: </strong>You can easily add, remove, or change items in a list. For example, if you have a list of players’ scores, you can update it as the game progresses.</p>
           <p><strong>3.	Looping Through Data: </strong>: Lists work well with loops, allowing you to perform actions on each item in the list. For example, in a game, you could check each item in a list of enemies and make them move one by one.</p>
           <p><strong>4.	Sorting and Organizing: </strong>Lists can help organize data in order, such as sorting items alphabetically or from highest to lowest score.</p>
  
             </ul>
          `,
          // image: op, // Replace with actual image path
          // imagePlacement: 'left', // Image on the left side
        },



        { sectionTitle: <span className="text-blue-600">Activity Session</span> },


        {
          sectionTitle: <span style={{ color: 'orange' }}>1.6 Create a Multi-Functional Program</span>,
          content: 'Description: Develop a program that uses multiple functions to perform tasks based on events (e.g., a simple calculator).'

        }
        ,

        {
          sectionTitle: <span style={{ color: 'orange' }}>1.7 Event-Driven Animation</span>,
          content: 'Description: Create an interactive animation where actions are triggered by user inputs.',
        },
        {
          sectionTitle: <span style={{ color: 'orange' }}>1.8 Debugging Program Issues
          </span>,
          content: 'Description: Teach students to debug their programs by identifying errors related to functions and events.',
        },
        {
          sectionTitle: <span style={{ color: 'orange' }}>1.9 Peer Review of Programs
          </span>,
          content: 'Description: Conduct a peer review session where students present their programs and give feedback.',
        },
        {
          sectionTitle: <span style={{ color: 'orange' }}>1.10  Finalize and Share Programs </span>,
          content: 'Description: Present finalized programs, emphasizing the use of functions and events.',
        },

      ]
    }, {
      id: 2,
      title: '2. Creative Storytelling through Animation ',
      // description: 'Learn about the foundational concepts of computers and technology.',
      details: [
        {
          sectionTitle: <span className="text-purple-600">2.1 Elements of Storytelling</span>,
          content: `<p><strong>What are the Elements of Storytelling?</strong>Storytelling is made up of several key elements that help bring a story to life. These elements guide the flow of the story and make it interesting for the audience.</p>
          <p><srrong>Key Elements:</strong></p>
          <p><strong>1.	Characters:</strong>These are the people, animals, or things that the story is about. Characters can be the hero, villain, or supporting roles.</p>
          <p><srong>2.	Setting: </strong>This is the time and place where the story happens. It helps the audience understand the world in which the characters live.</p>
          <p><strong>3.	Plot: </strong>The plot is the sequence of events in the story. It includes the introduction, rising action, climax, falling action, and resolution.</p>
          <p><strong>4.	Conflict: </strong>The problem or challenge the character faces, which needs to be resolved by the end of the story.</p>
          <p><strong>5.	Resolution: </strong>This is how the conflict is solved or how the story ends.</p>
          <p><strong>In Block-Based Coding: </strong> When creating an interactive story, these elements can be controlled using blocks for character actions, changing backgrounds (for setting), and triggering events that move the plot forward.</p>
          <p><strong>Example: </strong> In a story about a dragon, the dragon (character) might face a challenge (conflict), like needing to find treasure (plot), in a mystical forest (setting). The story progresses with events, like the dragon meeting a wizard (event) that helps solve the problem (resolution).</p>
  
          `,
          //     image: computer, // Replace with actual image path
          //     imagePlacement: 'right', // Image on the right side
        },
        {
          sectionTitle: <span className="text-purple-600"> Creating Characters and Backgrounds</span>,
          content: `<strong>What Does It Mean to Create Characters and Backgrounds?</strong>Characters and backgrounds are essential in bringing your story to life. Characters are your story's main figures, while the background sets the scene and shows where the action is taking place.
          <p></strong>Creating Characters:</strong></p>
  
  <ul class="list-disc pl-6">
  <li><strong>Designing Characters: </strong>In block-based coding platforms like Scratch, you can design your characters using the built-in sprite editor or import images.</li>
  <li><strong>Character Movement:</strong> You can make characters move using coding blocks like “move steps,” “go to x, y,” or “glide.”</li>
  <li><strong>Character Costumes: <strong>To make characters look like they’re moving, you can switch between different costumes or frames of animation.</li>
  <p><strong>Creating Backgrounds:</strong></p>
  <li><strong>Scenic Backgrounds: </strong>You can design or choose backgrounds from the library that fit the setting of your story.</li>
  <li><strong>Changing Backgrounds: </strong>Use blocks like "switch backdrop to" to change the scenery at different points in your story (e.g., from a forest to a castle).</li>
  <p><strong>Activity for Class:</strong></p>
  <li>Design a character (like a superhero or animal).</li>
  <li>Create a simple background (like a forest or city).</li>
  <li>Let students animate their characters, such as having them walk or fly across the screen.</li>
  </ul>
  Each step is part of the algorithm for brushing teeth! In the same way, computers follow algorithms to do things, like solving math problems or playing a song. It’s like a set of instructions or directions to get a job done, and computers need us to tell them each step.
  `,
          //   image: parts, // Replace with actual image path
          // imagePlacement: 'left', // Image on the left side
        },
        {
          sectionTitle: <span className="text-purple-600">Scripting a Story</span>,
          content: `<strong>What is Scripting a Story? </strong>Scripting in storytelling means writing down what happens in the story, but in code. This is how we tell the computer exactly what to do at each moment in the story—when to move characters, change backgrounds, or trigger sounds.
          <p><strong>How to Script:</strong></p>
          <ul class="list-disc pl-6"> 
  <li> Using Code Blocks: In block-based coding, you use blocks to script actions. For example, “when green flag clicked” can be the starting point, followed by blocks that animate the character, change backgrounds, or say something.</li>
  <li> Dialogues and Narration: Use blocks like “say [message] for [time]” to make characters speak and convey the story’s dialogue.</li>
  <li> Sequencing Events: Organize the blocks in a way that tells the story step by step. Add waiting times and sounds to enhance the storytelling experience.</li>
  <p></strong>Activity for Class:</strong> Create a short interactive story where students script a character to move, speak, and interact with the environment. For example, a character could move to a location and then say, "Hello! Welcome to my world!"</p>
  .</ul>
  Just like you follow directions to find your way, computers need directions to know where to go or what to do next. When we give directions to a computer, we’re guiding it, step by step, to get to the final goal!
  `,

          // No image property added here
        },


        {
          sectionTitle: <span className="text-purple-600">Animating Characters
          </span>,
          // layout: 'two-images', // New layout type for two images
          //leftImage: io, // Replace with the actual path
          //
          // rightImage: op, // Replace with the actual path
          content: `
           <strong> What Does It Mean to Animate Characters?</strong> Animation is when you make characters move or change to make them look like they are alive. Animation brings your story to life by showing actions like walking, running, talking, and jumping.
           <p><strong>Animating with Block-Based Coding:</strong></p>
            <ul class="list-disc pl-6">
             <li><strong>Costume Changes: </strong>In block-based coding, you can animate a character by switching between different costumes (for walking, jumping, etc.).</li>
             <li><strong>Movement: </strong>Use blocks like “move [steps]” or “glide to [x, y]” to make characters move around the screen.</li>
             <li><strong>Talking and Expressions: </strong>You can animate characters by making them talk or change facial expressions with the “say [message]” block or using facial expression costumes.</li>
             Here’s a fun story about making a sandwich to help kids understand the steps as part of a “sandwich-making algorithm”:
          
             </ul>
          `,
          // image: io, // Replace with actual image path
          // imagePlacement: 'left', // Image on the left side
        },
        {
          sectionTitle: <span className="text-purple-600">2.5 Review of Storytelling Concepts</span>,
          content: `<strong>Summary of Key Concepts:</strong>
          <ul class="list-disc pl-6"> 
      
             <li><strong>Characters: </strong> The heroes and villains that drive the story.</li>
             <li><strong>Setting: </strong>The world in which the story takes place.</li>
             <li><strong>Plot: </strong>The sequence of events that make up the story.</li>
             <li><strong>Conflict and Resolution: </strong>The problem the character faces and how they solve it.</li>
             <li><strong>Animating Characters: </strong>Making characters move and change to bring them to life.</li>
             <li><strong>Scripting the Story: </strong>Writing the code to control the flow of the story.</li>
             `
          // No image property added here
        },



        { sectionTitle: <span className="text-blue-600">Activity Session</span> },


        {
          sectionTitle: <span style={{ color: 'orange' }}>2.6	Create Your Animated Story</span>,
          // layout: 'two-images', // New layout type for two images
          //leftImage: io, // Replace with the actual path
          //
          // rightImage: op, // Replace with the actual path
          content: `Description: Develop an animated story using characters, backgrounds, and scripts.`

          // <ul class="list-disc pl-6">
          //   <b>Steps for Activity</b>
          //    <li><b>Introduce the Concept of Sequencing</b>
          //    </li>
          //    <ol>
          //    <li>Explain that sequencing means putting actions in the correct order to achieve a goal.</li>
          //    <li>Give an example, like tying shoelaces, where each step must happen in the right order.</li>
          //    </ol>
          //    <li><b>Explain the Sandwich Task</b></li>
          //     <ol>Show the students the materials or list of items involved in making a sandwich, such as:
          //     <li>Bread</li>
          //     <li>Butter or spread</li>
          //     <li>Filling (like cheese or vegetables)</li>
          //     <li>Knife for spreading</li></ol>
          //    <li><b>Discuss Each Step in Order</b></li>
          //    <ol>
          //     <li><b>Step 1:</b> Take two slices of bread.</li>
          //     <li><b>Step 2:</b> Spread butter or another spread on one or both slices of bread.</li>
          //     <li><b>Step 3:</b> Add the filling (e.g., cheese or veggies) on top of one of the slices.</li>
          //     <li><b>Step 4: </b>Place the second slice of bread on top to complete the sandwich.</li></ol>
          //    </ul>
          ,
          // image: op, // Replace with actual image path
          // imagePlacement: 'left', // Image on the left side
        },
        {
          sectionTitle: <span style={{ color: 'orange' }}>2.7	Add Sound and Narration</span>,
          // layout: 'two-images', // New layout type for two images
          //leftImage: io, // Replace with the actual path
          //
          // rightImage: op, // Replace with the actual path
          content: `Description: Teach students to add sound effects and narrations to their animations.`
          // <b>Activity: </b>Build a simple sequence using cards or blocks for a specific task.
          //   <ul class="list-disc pl-6">
          //   <b>Steps for Activity</b>
          //    <li><b>Explain the Concept of an Algorithm</b>
          //    </li>
          //    <ol>
          //    <li>Define an algorithm as a set of step-by-step instructions to complete a task.</li>
          //    <li>Give examples of everyday algorithms, like a recipe for cooking or the instructions for tying shoelaces.</li>
          //    </ol>
          //    <li><b>Introduce the Task: Getting Ready for School</b></li>
          //     <ol>1.Explain that students will create an algorithm for getting ready in the morning.
          //     2.List the typical steps involved:
          //     <li>Wake up</li>
          //     <li>Brush teeth</li>
          //     <li>Eat breakfast</li>
          //     <li>Pack school bag</li>
          //     <li>Put on shoes</li>
          //     </ol>
          //    <li><b>Create Instruction Cards or Blocks</b></li>
          //    <ol>
          //     <li>Write each step on a separate card or use blocks with the steps written on them.</li>
          //     <li>Shuffle the cards/blocks to allow students to arrange them in the correct order.</li>
          //     </ol>
          //     <li><b>Arrange the Steps in the Correct Sequence</b></li>
          //    <ol>
          //     <li>Ask students to organize the steps into a logical order.</li>
          //     <li>Once they’ve arranged the steps, review the sequence together and discuss why each step comes in that particular order.</li>
          //     </ol>
          //      <li><b>Practice Creating Algorithms for Other Tasks</b></li>
          //    <ol>
          //     <li>Give students different tasks to build algorithms for, such as:</li>
          //     <ul><b>Making a Bowl of Cereal:</b>
          //     <li>* Get a bowl, pour cereal, add milk, use spoon to eat.</li></ul>
          //     <ul><b>Planting a Seed:</b>
          //     <li>* Dig a hole, plant the seed, cover with soil, water the plant.</li></ul>
          //     <li>Have students create an algorithm for each task using their cards or blocks.</li>
          //     </ol>
          //    </ul>
          ,
          // image: op, // Replace with actual image path
          // imagePlacement: 'left', // Image on the left side
        },
        {
          sectionTitle: <span style={{ color: 'orange' }}>2.8	Peer Review of Story Animations
          </span>,
          // layout: 'two-images', // New layout type for two images
          //leftImage: io, // Replace with the actual path
          //
          // rightImage: op, // Replace with the actual path
          content: `Description: Present animations to peers for feedback on storytelling and animation techniques.`
          // <b>Activity: </b>Follow verbal or visual directions to reach a destination in a maze.
          //   <ul class="list-disc pl-6">
          //   <b>Steps for Activity</b>
          //    <li><b>Introduce the Concept of Following Directions</b>
          //    </li>
          //    <ol>
          //    <li>Explain that directions tell us how to get from one place to another.</li>
          //    <li>Emphasize that following directions accurately is essential for reaching a goal, whether in a game, real life, or coding.</li>
          //    </ol>
          //    <li><b>Set Up the Maze</b></li>
          //     <ol>
          //     <li>Draw or print a simple maze on paper or create a large maze on the floor using tape.</li>
          //     <li>Mark the <b>start</b> and <b>finish</b> points clearly.</li>
          //     <li>Optionally, add obstacles or alternate paths to make the maze more challenging.</li>
          //     </ol>
          //    <li><b>Provide Verbal or Visual Directions</b></li>
          //    <ol>Choose one of the following methods:
          //     <li><b>Verbal Directions:</b> A partner or the instructor gives step-by-step directions out loud (e.g., “Move forward two steps,” “Turn left,” “Move forward one step”).</li>
          //     <li><b>Visual Directions:</b> Provide students with a printed list of directions or arrows showing the path to follow.</li>
          //     Allow students to attempt navigating the maze based on these instructions.</ol>
          //     <li><b>Have Students Follow the Directions</b></li>
          //     <ol>
          //     <li>If using a paper maze, students can trace their path with a pencil or finger.</li>
          //     <li>For a floor maze, students can walk through it step-by-step, following the directions precisely.</li>
          //     </ol>
          //    </ul>
          ,
          // image: op, // Replace with actual image path
          // imagePlacement: 'left', // Image on the left side
        },
        {
          sectionTitle: <span style={{ color: 'orange' }}>2.9	Refine and Finalize Animations
          </span>,
          // layout: 'two-images', // New layout type for two images
          //leftImage: io, // Replace with the actual path
          //
          // rightImage: op, // Replace with the actual path
          content: `Description: Allow time for students to refine their animations based on feedback.`
          // <b>Activity: </b>Role-play tasks with steps, like tying shoes or setting the table.
          //   <ul class="list-disc pl-6">
          //   <b>Steps for Activity</b>
          //    <li><b>Introduce the Concept of Everday Algorithms</b>
          //    </li>
          //    <ol>
          //    <li>Explain that an algorithm is simply a sequence of steps to achieve a specific outcome.</li>
          //    <li>Give examples of everyday algorithms, such as instructions for making a sandwich or brushing teeth.</li>
          //    </ol>
          //    <li><b>Choose a Task to Act Out</b></li>
          //     <ol>Select a task that has clear, sequential steps and is familiar to students. Suggested tasks:
          //     <li><b>Setting the Table:</b></li>
          //     <li>Step 1: Place the placemat on the table.</li>
          //     <li>Step 2: Put a plate in the center of the placemat.</li>
          //     <li>Step 3: Place a fork to the left of the plate.</li>
          //     <li>Step 4: Place a knife and spoon to the right of the plate.</li>
          //     <li>Step 5: Put a cup or glass above the plate.</il></ol>
          //    <li><b>Role-Play the Task</b></li>
          //    <ol>
          //     <li>Assign each step to a student or group of students, or have a single student act out the entire task</li>
          //     <li>As students perform each step, narrate or discuss the purpose of each step in achieving the goal.</li></ol>
          //    </ul>
          ,
          // image: op, // Replace with actual image path
          // imagePlacement: 'left', // Image on the left side
        },
        {
          sectionTitle: <span style={{ color: 'orange' }}>2.10 Showcase Your Animated Story
          </span>,
          // layout: 'two-images', // New layout type for two images
          //leftImage: io, // Replace with the actual path
          //
          // rightImage: op, // Replace with the actual path
          content: `Description: Host a showcase event where students present their completed animations.`
          // <b>Activity: </b>Plan a simple sequence for an activity, like drawing a shape or playing a game.(By students)


          ,
          // image: op, // Replace with actual image path
          // imagePlacement: 'left', // Image on the left side
        },

      ]
    }, {
      id: 3,
      title: '3.Exploring Game Design  ',
      // description: 'Learn about the foundational concepts of computers and technology.',
      details: [
        {
          sectionTitle: <span className="text-purple-600">3.1 Principles of Game Design
          </span>,
          content: `<p><strong>What Are the Principles of Game Design?</strong></p>
<p>Game design refers to the process of creating the content, mechanics, and systems that make up a game. The principles of game design are the fundamental rules and concepts that guide how a game is created and played.</p>
<p><strong>Key Principles:</strong></p>
<ol class="list-decimal pl-6">
<li><strong>Player Experience:</strong> Games should be fun, challenging, and rewarding. Designers focus on making sure players have an enjoyable experience by giving them the right amount of challenge and feedback.
</li><li><strong>Balance:</strong> This means making sure no part of the game is too easy or too hard. Every level should gradually increase in difficulty without frustrating the player.
</li><li><strong>Interaction:</strong> Good games encourage interaction, whether through puzzles, obstacles, or enemies. The player should be able to control the game and make decisions that affect the outcome.
</li><li><strong>Feedback:</strong> Games give players feedback (through sounds, visual effects, or scoring) to show how well they are doing.
</li><li><strong>Rewards and Challenges:</strong> Rewards motivate players to keep playing, while challenges keep them engaged. Combining these two elements keeps the player interested in progressing through the game
</li></ol>`,
        },
        {
          sectionTitle: <span className="text-purple-600">3.2 Creating Game Assets
          </span>,
          content: `<p><strong>What Are Game Assets?</strong></p>
<p>Game assets are all the elements needed to make a game look, sound, and feel real. These include characters, backgrounds, sounds, and animations. Without assets, a game wouldn’t have any visuals or sound.
</p>
<p><strong>Types of Game Assets:</strong></p>
<ol class="list-decimal pl-6">
<li><strong>Sprites:</strong> These are the characters, objects, and enemies in the game.</li>
<li><strong>Backgrounds:</strong> These set the scene for the game levels, like a forest or a cityscape.</li>
<li><strong>Sounds:</strong> Sound effects (like jumping, shooting, or explosions) and music (background tracks) enhance the game experience.</li>
<li><strong>Animations:</strong> Making characters move, jump, or perform actions to make them feel alive.
</li>
<li><strong>Props/Objects:</strong> Items like keys, coins, and power-ups that the player can collect.
</li></ol>
<p><strong>In Block-Based Coding:</strong></p>
 <ul class="list-disc pl-6"> 
 <li>Students can create or use existing assets in Scratch (like selecting a sprite from the library or designing their own).
</li>
 <li>Students can animate their sprites using costumes and change backgrounds by switching between them.
</li>
 </ul>
`,

        },
        {
          sectionTitle: <span className="text-purple-600">3.3 Game Mechanics and Logic
          </span>,
          content: ` <p><strong>What Are Game Mechanics?
</strong></p>
          <p>Game mechanics are the rules and systems that control how the game works. These include movement, actions, and interactions that define how players play the game.</p>
          <p><strong>Common Game Mechanics:</strong></p>
          <ol class="list-decimal pl-6">
          <li><strong>Movement:</strong> How the player moves through the game. This could be through arrow keys, mouse movement, or touch.
</li>
          <li><strong>Collisions:</strong>  When characters or objects bump into each other, something happens (like the player losing a life or picking up a coin).</li>
         <li><strong>Score:</strong> e: The player earns points by completing tasks or overcoming obstacles.
</li>
         
          <li><strong>Lives/Health:</strong>Players may have a certain number of lives or health points that decrease when they make mistakes or face challenges.
 </li>
         </ol>
         <p><strong>Game Logic:</strong> Game logic is the set of instructions that makes things happen in the game. It tells the game how to respond when a player does something (like moving or collecting an item). In block-based coding, students use logic blocks like "if" statements to control the game’s behavior.
</p>
          <p><strong>In Block-Based Coding:</strong></p>
           <ul class="list-disc pl-6"> 
           <li>Students use blocks to create interactions, like a player earning a point when they collect an object or losing a life when they collide with an enemy.
 </li>
           <li>Use logic blocks like "if on edge bounce" or "if touching [object]" to create game behavior.
 </li>
           </ul>`,

          // No image property added here
        },


        {
          sectionTitle: <span className="text-purple-600">3.4 Level Design Basics
          </span>,
          // layout: 'two-images', // New layout type for two images
          //leftImage: io, // Replace with the actual path
          //
          // rightImage: op, // Replace with the actual path
          content: `
           What Is Level Design?
          <p>Level design is the process of creating the different stages or levels that a player will experience in the game. It involves setting challenges, designing obstacles, and creating environments that help tell the story of the game.
</p>
           <p>Key Considerations in Level Design:
</p>
             <ol class="list-decimal pl-6">
          <li>Difficulty Curve: Start easy and gradually increase difficulty as the player progresses. This keeps the game exciting but not overwhelming.
</li>

          <li>Balance: Design levels that have a good mix of challenges. Some levels should be about solving puzzles, while others are about quick thinking or timing.
</li>
         <li>Layout: How the player navigates through the level. Should the player move from left to right, explore a maze, or avoid enemies?
</li>

         </ol>
          `,

        },

        {
          sectionTitle: <span className="text-purple-600">3.5 Review of Game Design Concepts</span>,
          // layout: 'two-images', // New layout type for two images
          //leftImage: io, // Replace with the actual path
          //
          // rightImage: op, // Replace with the actual path
          content: `<strong>Review of What We Learned:</strong>
            <ul class="list-disc pl-6">
             <li><strong>Game Design Principles:</strong> Understand the basics of what makes a good game, including balance, feedback, and rewards.
</li>
<li><strong>Creating Game Assets:</strong>  Design and use characters, backgrounds, and sounds to enhance the game’s look and feel.
</li>
<li><strong>Game Mechanics and Logic:</strong>  Implement the rules that control how the game works (movement, score, lives).</li>
<li><strong>Level Design:</strong> Plan and create levels that challenge the player in fun and interesting ways.
</li>

             </ul>
          `,

        },





        { sectionTitle: <span className="text-blue-600">Activity Session</span> },


        {
          sectionTitle: <span style={{ color: 'orange' }}>3.6 Create a Simple Game Prototype
          </span>,
          content: 'Develop a basic game prototype incorporating learned concepts (e.g., a plat former).'
        }
        ,

        {
          sectionTitle: <span style={{ color: 'orange' }}>3.7 Implement Game Logic</span>,
          content: ' Add mechanics and logic to the game, using functions and event handling.',
        },
        {
          sectionTitle: <span style={{ color: 'orange' }}>3.8Testing and Feedback </span>,
          content: 'Conduct testing sessions for the game, gathering feedback for improvement.',
        },
        {
          sectionTitle: <span style={{ color: 'orange' }}>3.9 Group Collaboration: Game Jam
          </span>,
          content: '  Collaborate in groups to create a game in a limited time frame (Game Jam).',
        },
        {
          sectionTitle: <span style={{ color: 'orange' }}>3.10 Present Your Game
          </span>,
          content: ' Share completed games with the class, explaining design choices and programming logic.',
        },

      ]
    }, {
      id: 4,
      title: '4.  Introduction to Computational Thinking ',
      // description: 'Learn about the foundational concepts of computers and technology.',
      details: [
        {
          sectionTitle: <span className="text-purple-600"> 4.1 Understanding Algorithms
          </span>,
          content: `<strong>What is an Algorithm?</strong> 
          <p>An algorithm is a step-by-step set of instructions to solve a problem or complete a task. It’s like a recipe for doing something. In coding, algorithms help us tell the computer exactly what to do in a logical and organized way.
</p>
        <p><strong>Example of an Algorithm:</strong></p>
        <p>Imagine you want to make a cup of tea. Your algorithm might look like this:
</p>
        <ol class="list-decimal pl-6">
        <li>Boil water.</li>
       <li>Place tea bag in cup.</li>
        <li>Pour hot water into cup.</li>
       <li>Let it steep for a few minutes.</li>
       <li>Remove tea bag and enjoy!</li>
</ol>
          <p><strong>In Block-Based Coding:</strong></p>
          <ul class="list-disc pl-6">
         <li>Algorithms in coding are like blocks that tell the computer in which order to do things. For example, you could create an algorithm that makes a sprite move across the screen or makes an animation play in a sequence.
</li>
          </ul>`,

        },
        {
          sectionTitle: <span className="text-purple-600"> 4.2 Creating Flowcharts</span>,
          content: `<strong>What is a Flowchart?</strong>
          <p>A flowchart is a diagram that shows the steps of an algorithm or process. It uses different shapes to represent different types of actions (like decisions, actions, or steps in a process) and arrows to show the flow of the process.
          </p>
          <ul class="list-disc pl-6">
          <li><strong>Oval:</strong>Start or end of the process.</li>
          <li><strong>Rectangle:</strong> A process or action.</li>
          <li><strong>Diamond:</strong>A decision point (yes/no or true/false).</li>
          <li><strong>Arrow:</strong> Shows the flow of the process.</li>
          </ul>
          <p><strong>Example of a Flowchart:</strong></p>
          <ul class="list-disc pl-6">
          <li>If you wanted to create a flowchart for making tea, it might look like this:</li>
          <ol>Start → Boil water → Is the water boiling? (Decision)</ol>
          <ol>Yes→ Place tea bag in cup → Pour hot water into cup → Let it steep for a few minutes → Remove tea bag → Enjoy!</ol>
          <ol> No → Wait for water to boil </ol>
          </ul>

         
`,
        },
        {
          sectionTitle: <span className="text-purple-600">4.3 Problem Decomposition</span>,
          content: `<strong>What is Problem Decomposition?</strong> 
          <p>Problem decomposition is breaking a big problem into smaller, more manageable parts. Instead of trying to solve a huge problem all at once, you divide it into smaller, easier-to-handle tasks.
</p>
        <p><strong>Example of Problem Decomposition:</strong></p>
        <p>If you want to build a game, it might seem overwhelming, but by breaking it down:</p>
        <ol class="list-decimal pl-6">
        <li>Design the character</li>
       <li>Create the obstacles.</li>
        <li>Add the movement controls.</li>
       <li>Set the rules for winning and losing.</li>
</ol>
`,

          // No image property added here
        },


        {
          sectionTitle: <span className="text-purple-600">4.4 Debugging Techniques
          </span>,

          content: `<strong>What is Debugging?</strong> 
          <p>Debugging is the process of finding and fixing mistakes or errors in your code. When something goes wrong in a program, you use debugging to identify the issue and fix it, ensuring your code runs as expected.
</p>
        <p><strong>Common Debugging Techniques:</strong></p>
        <ol class="list-decimal pl-6">
        <li><strong>Read your code carefully:</strong> Check for simple mistakes, like missing blocks or incorrect values.
</li>
       <li><strong> Use print statements:</strong> In coding, use blocks like “say” or “think” to see what’s happening at different points in your code.
</li>
        <li><strong>Test small parts:</strong> Break your program into smaller sections and test each one to find where the problem lies.
</li>
       <li><strong> Use the "fix and run" method: </strong>If you find an issue, fix it and test your code again to see if it works.
</li>
</ol>
          <p><strong>In Block-Based Coding:</strong></p>
          <ul class="list-disc pl-6">
         <li>Scratch has helpful features like “Error messages” and “Test mode” that help students find where things are going wrong and correct them.
</li>
          </ul>
          `,
          // image: io, // Replace with actual image path
          // imagePlacement: 'left', // Image on the left side
        },

        {
          sectionTitle: <span className="text-purple-600">4.5 Review of Computational Thinking Concepts
          </span>,

          content: `
           <strong>Reviewing What We Learned:</strong>
<ul class="list-disc pl-6"> 
         <li> <strong>Algorithms</strong>A sequence of steps to solve a problem.
</li>
<li> <strong>Flowcharts</strong>Diagrams that help us plan our algorithms.
</li>
<li> <strong>Problem Decomposition</strong> Breaking a big problem into smaller, manageable tasks.
</li>
<li> <strong>Debugging :</strong> Finding and fixing errors in our code to make sure it works.
</li>
</ul>
          `,

        },

        //          



        { sectionTitle: <span className="text-blue-600">Activity Session</span> },


        {
          sectionTitle: <span style={{ color: 'orange' }}>4.6 Create an Algorithm for a Game
          </span>,
          content: ' Develop an algorithm for a simple game, using flowcharts to represent the logic.'
        }
        ,

        {
          sectionTitle: <span style={{ color: 'orange' }}>4.7 Implement and Test Algorithms
          </span>,
          content: '  Program the game based on the developed algorithm and test its functionality.',
        },
        {
          sectionTitle: <span style={{ color: 'orange' }}>4.8 Peer Review and Debugging Session
          </span>,
          content: ' Conduct peer reviews focusing on debugging and improving each other’s code.',
        },
        {
          sectionTitle: <span style={{ color: 'orange' }}>4.9  Create a Debugging Guide

          </span>,
          content: ' Create a guide for common debugging issues encountered in projects.',
        },
        {
          sectionTitle: <span style={{ color: 'orange' }}>4.10 Reflection on Computational Thinking

          </span>,
          content: 'Reflect on the importance of computational thinking in programming and everyday problem-solving.',
        },

      ]
    }, {
      id: 6,
      title: 'AI & ML - NLP ',
      // description: 'Learn about the foundational concepts of computers and technology.',
      details: [
        {
          sectionTitle: <span className="text-purple-600"></span>,
          // content: `<iframe src="Natural_Language_Processing.pdf" width="100%" height="600px" />`
          content: `<embed src="Natural_Language_Processing.pdf#toolbar=0" type="application/pdf" width="100%" height="600px" />`
        },


      ]
    }, {
      id: 7,
      title: 'Hand pose',
      // description: 'Learn about the foundational concepts of computers and technology.',
      details: [
        {
          sectionTitle: <span className="text-purple-600"> </span>,
          //content: `<iframe src="Hand_Pose_Extension.pdf" width="100%" height="600px" />`
          content: `<embed src="Hand_Pose_Extension.pdf#toolbar=0" type="application/pdf" width="100%" height="600px" />`
        },


      ]
    }, {
      id: 8,
      title: 'Mit app inventor',
      // description: 'Learn about the foundational concepts of computers and technology.',
      details: [
        {
          sectionTitle: <span className="text-purple-600"> </span>,
          //content: `<iframe src="MIT_App_Inventor.pdf" width="100%" height="600px" />`
          content: `<embed src="MIT_App_Inventor.pdf#toolbar=0" type="application/pdf" width="100%" height="600px" />`
        },



      ]
    }, {
      id: 9,
      title: 'Lab Activity[1-4]',
      // description: 'Learn about the foundational concepts of computers and technology.',
      details: [
        {
          sectionTitle: <span className="text-purple-600"> </span>,
          content: `<embed src="Lab_Activity[1-4].pdf#toolbar=0" type="application/pdf" width="100%" height="600px" />`

        },



      ]
    },
    ],
    8: [{
      id: 1,
      title: 'PROJECT 1',
      // description: 'Learn about the foundational concepts of computers and technology.',
      details: [
        {
          sectionTitle: <span className="text-purple-600"> COMING SOON...</span>,
          content: ``
        },


      ]
    },],
    9: [{
      id: 1,
      title: 'PROJECT 1',
      // description: 'Learn about the foundational concepts of computers and technology.',
      details: [
        {
          sectionTitle: <span className="text-purple-600"> COMING SOON....</span>,
          content: ``
        },

      ]
    },],
    10: [{
      id: 1,
      title: 'PROJECT 1',
      // description: 'Learn about the foundational concepts of computers and technology.',
      details: [
        {
          sectionTitle: <span className="text-purple-600"> COMING SOON</span>,
          content: ``
        },

      ]
    },],
  };

  const handleClassClick = (classIndex) => {
    setCurrentStep(classIndex); // Update the selected class
    
    // Get the first project of the selected class if it exists
    if (projects[classIndex] && projects[classIndex].length > 0) {
      setSelectedProject(projects[classIndex][0]); // Set the first project as selected
    } else {
      setSelectedProject(null);
    }
  };

  // Set initial project when component mounts
  useEffect(() => {
    // Get the first project of class 1 if it exists
    if (projects[1] && projects[1].length > 0) {
      setSelectedProject(projects[1][0]);
    }
  }, []); // Empty dependency array means this runs once on mount

  const handleProjectClick = (project) => {
    setSelectedProject(project); // Set the selected project to show details
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto bg-gradient-to-br from-blue-200 via-blue-200 to-blue-200" style={{ zIndex: 1000 }}>
      <button
        onClick={onClose}
        className="fixed right-4 top-4 bg-indigo-500 text-white px-3 py-1 rounded-full hover:bg-indigo-600 transition-colors z-50"
      >
        <X className="h-4 w-4" />
      </button>

      {/* Header with Class Buttons */}
      <div className="w-full bg-blue-500 p-4 mb-6">
        <div className="flex justify-center gap-4">
          {[...Array(10)].map((_, index) => (
            <button
              key={index}
              onClick={() => handleClassClick(index + 1)} // Set the class number
              className={`px-4 py-2 rounded-md ${currentStep === index + 1
                  ? 'bg-indigo-600 text-white'
                  : 'bg-blue-300 text-blue-800 hover:bg-blue-400'
                } transition-colors`}
            >
              {`Class ${index + 1}`}
            </button>
          ))}
        </div>
      </div>

      {/* Projects Section */}
      <div className="px-6 pb-6">
        <div className="bg-blue-50 rounded-lg p-6">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Projects Section</h2>

          {projects[currentStep] && projects[currentStep].length > 0 ? (
            <div className="flex gap-4 overflow-x-auto">
              {projects[currentStep].map((project) => (
                <div
                  key={project.id}
                  className="cursor-pointer w-60 bg-white rounded-lg shadow-md p-4 hover:bg-indigo-100"
                  onClick={() => handleProjectClick(project)}
                >
                  <h4 className="text-lg font-semibold">{project.title}</h4>
                  <p className="text-sm text-gray-500">{project.description}</p>
                </div>
              ))}
            </div>
          ) : (
            <p className="text-gray-700">No projects available for this class.</p>
          )}
        </div>
      </div>

      {/* Project Details Section */}
      {selectedProject && (
        <div className="w-full bg-blue-600 p-3 mb-3 rounded-lg">
          <h2 className="text-2xl font-semibold text-white text-center mb-4">
            {selectedProject.title}: Detailed Description
          </h2>

          {/* Detailed Layout */}
          <div>
            {selectedProject.details.map((item, index) => (
              <div
                key={index}
                className={`p-3 bg-white rounded-lg shadow-lg mb-3 ${item.image ? 'flex items-center gap-6' : ''
                  }`}
              >
                {/* Conditional Rendering for Image Placement */}
                {item.image && item.imagePlacement === 'left' && (
                  <div className="w-full md:w-1/4 flex justify-center">
                    <img
                      src={item.image}
                      alt={`${item.sectionTitle} illustration`}
                      className="w-full max-w-md rounded-lg shadow-md"
                    />
                  </div>
                )}

                {/* Center Image */}
                {item.image && item.imagePlacement === 'center' && (
                  <div className="w-full md:w-1/4 flex justify-center">
                    <img
                      src={item.image}
                      alt={`${item.sectionTitle} illustration`}
                      className="w-full h-full object-cover rounded-lg shadow-md"
                    />
                  </div>
                )}
                {/* Upper Image */}
                {item.image && item.imagePlacement === 'upper' && (
                  <div className="w-full md:w-1/4 flex justify-center">
                    <img
                      src={item.image}
                      alt={`${item.sectionTitle} illustration`}
                      className="w-full h-full object-cover rounded-lg shadow-md"
                    />
                  </div>
                )}

                {/* Text Content */}
                <div className={`${item.image ? 'w-full md:w-3/4' : ''}`}>
                  <h3 className="text-xl font-bold text-gray-800 mb-4">{item.sectionTitle}</h3>
                  <div
                    className="text-gray-600 leading-relaxed"
                    dangerouslySetInnerHTML={{ __html: item.content }}
                  ></div>
                </div>

                {/* Conditional Rendering for Image Placement (Right) */}
                {item.image && item.imagePlacement === 'right' && (
                  <div className="w-full md:w-1/4 flex justify-center">
                    <img
                      src={item.image}
                      alt={`${item.sectionTitle} illustration`}
                      className="w-full max-w-[200px]"
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}