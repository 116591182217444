import bindAll from 'lodash.bindall';
import React from 'react';
import PropTypes from 'prop-types';
import {defineMessages, intlShape, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import log from '../lib/log';
import sharedMessages from './shared-messages';

import {
    LoadingStates,
    getIsLoadingUpload,
    getIsShowingWithoutId,
    onLoadedProject,
    requestProjectUpload
} from '../reducers/project-state';
import {setProjectTitle} from '../reducers/project-title';
import {
    openLoadingProject,
    closeLoadingProject
} from '../reducers/modals';
import {
    closeFileMenu
} from '../reducers/menus';

const messages = defineMessages({
    loadError: {
        id: 'gui.projectLoader.loadError',
        defaultMessage: 'The project file that was selected failed to load.',
        description: 'An error that displays when a local project file fails to load.'
    }
});

/**
 * Higher Order Component to provide behavior for loading local project files into editor.
 * @param {React.Component} WrappedComponent the component to add project file loading functionality to
 * @returns {React.Component} WrappedComponent with project file loading functionality added
 *
 * <SBFileUploaderHOC>
 *     <WrappedComponent />
 * </SBFileUploaderHOC>
 */
const SBFileUploaderHOC = function (WrappedComponent) {
    class SBFileUploaderComponent extends React.Component {
        constructor (props) {
            super(props);
            bindAll(this, [
                'createFileObjects',
                'getProjectTitleFromFilename',
                'handleFinishedLoadingUpload',
                'handleStartSelectingFileUpload',
                'handleChange',
                'onload',
                'removeFileObjects',
                'checkUploadAllowed'
            ]);
        }
        componentDidUpdate (prevProps) {
            if (this.props.isLoadingUpload && !prevProps.isLoadingUpload) {
                this.handleFinishedLoadingUpload(); // cue step 5 below
            }
        }
        componentWillUnmount () {
            this.removeFileObjects();
        }
        // step 1: this is where the upload process begins
        handleStartSelectingFileUpload () {
            this.createFileObjects(); // go to step 2
        }
        // step 2: modified to load the file from a local path instead of opening a file explorer
        createFileObjects () {
            this.removeFileObjects();
            
            // Create fileReader
            this.fileReader = new FileReader();
            this.fileReader.onload = this.onload;
            
            // Remove existing input if any
            if (this.inputElement) {
                document.body.removeChild(this.inputElement);
            }
            
            // Create input element
            this.inputElement = document.createElement('input');
            this.inputElement.accept = '.sb,.sb2,.sb3';
            this.inputElement.style = 'display: none;';
            this.inputElement.type = 'file';
            this.inputElement.onchange = this.handleChange;
            document.body.appendChild(this.inputElement);
            
            // Trigger file selection
            this.inputElement.click();
        }

        // step 3: this method is no longer needed for manual file selection, but keeping it
        handleChange (e) {
            const thisFileInput = e.target;
            if (thisFileInput.files && thisFileInput.files[0]) {
                this.fileToUpload = thisFileInput.files[0];
                
                // Start reading the file immediately
                this.fileReader.readAsArrayBuffer(this.fileToUpload);

                // Only request project upload if we have a valid file
                const uploadAllowed = this.checkUploadAllowed();
                if (uploadAllowed) {
                    // Make sure we have a valid loading state before requesting upload
                    if (this.props.loadingState) {
                        this.props.requestProjectUpload(this.props.loadingState);
                    }
                } else {
                    this.removeFileObjects();
                }
                
                // Clean up the input element
                document.body.removeChild(this.inputElement);
                this.inputElement = null;
                
                this.props.closeFileMenu();
            }
        }

        // step 4 is below, in mapDispatchToProps

        // step 5: called from componentDidUpdate when project state shows
        // that project data has finished "uploading" into the browser
        handleFinishedLoadingUpload () {
            if (this.fileToUpload && this.fileReader) {
                // No need to re-read the file here, as it's already being read in createFileObjects
                // This block can be omitted or customized further if needed
            } else {
                this.props.cancelFileUpload(this.props.loadingState);
                this.removeFileObjects();
            }
        }

        // used in step 6 below
        getProjectTitleFromFilename (fileInputFilename) {
            if (!fileInputFilename) return '';
            const matches = fileInputFilename.match(/^(.*)\.sb[23]?$/);
            if (!matches) return '';
            return matches[1].substring(0, 100); // truncate project title to max 100 chars
        }

        // step 6: handler when file upload raw data is available in the reader
        onload () {
            if (this.fileReader) {
                this.props.onLoadingStarted();
                const filename = this.fileToUpload && this.fileToUpload.name;
                let loadingSuccess = false;

                // Add extension validation with proper checks
                this.props.vm.loadProject(this.fileReader.result)
                    .then(() => {
                        // Check if extensionManager exists before accessing
                        if (this.props.vm.runtime && this.props.vm.runtime.extensionManager) {
                            const loadedExtensions = this.props.vm.runtime.extensionManager._loadedExtensions;
                            console.log('Project loaded with extensions:', loadedExtensions);

                            // Safely check for missing extensions
                            if (loadedExtensions) {
                                const missingExtensions = Array.from(loadedExtensions.keys())
                                    .filter(ext => {
                                        try {
                                            return !this.props.vm.runtime.extensionManager.isExtensionLoaded(ext);
                                        } catch (e) {
                                            console.warn(`Error checking extension ${ext}:`, e);
                                            return false;
                                        }
                                    });

                                if (missingExtensions.length > 0) {
                                    console.warn('Missing extensions:', missingExtensions);
                                    // Instead of throwing error, just log warning
                                    console.warn(`Some extensions failed to load: ${missingExtensions.join(', ')}`);
                                }
                            }
                        } else {
                            console.warn('Extension manager not initialized');
                        }

                        if (filename) {
                            const uploadedProjectTitle = this.getProjectTitleFromFilename(filename);
                            this.props.onSetProjectTitle(uploadedProjectTitle);
                        }
                        loadingSuccess = true;
                    })
                    .catch(error => {
                        console.error('Project load failed:', {
                            error: error,
                            projectName: filename,
                            fileSize: this.fileToUpload ? this.fileToUpload.size : 0
                        });
                        alert(this.props.intl.formatMessage(messages.loadError));
                    })
                    .finally(() => {
                        this.props.onLoadingFinished(this.props.loadingState, loadingSuccess);
                        this.removeFileObjects();
                    });
            }
        }

        // step 7: clean up file objects from memory
        removeFileObjects () {
            if (this.fileReader) {
                this.fileReader = null;
            }
            if (this.fileToUpload) {
                this.fileToUpload = null;
            }
            if (this.inputElement && document.body.contains(this.inputElement)) {
                document.body.removeChild(this.inputElement);
                this.inputElement = null;
            }
        }

        // Add helper method to check if upload is allowed
        checkUploadAllowed() {
            const {
                intl,
                isShowingWithoutId,
                projectChanged,
                userOwnsProject
            } = this.props;

            if (userOwnsProject || (projectChanged && isShowingWithoutId)) {
                return confirm( // eslint-disable-line no-alert
                    intl.formatMessage(sharedMessages.replaceProjectWarning)
                );
            }
            return true;
        }

        render () {
            const {
                /* eslint-disable no-unused-vars */
                cancelFileUpload,
                closeFileMenu: closeFileMenuProp,
                isLoadingUpload,
                isShowingWithoutId,
                loadingState,
                onLoadingFinished,
                onLoadingStarted,
                onSetProjectTitle,
                projectChanged,
                requestProjectUpload: requestProjectUploadProp,
                userOwnsProject,
                /* eslint-enable no-unused-vars */
                ...componentProps
            } = this.props;
            return (
                <React.Fragment>
                    <WrappedComponent
                        onStartSelectingFileUpload={this.handleStartSelectingFileUpload}
                        {...componentProps}
                    />
                </React.Fragment>
            );
        }
    }

    SBFileUploaderComponent.propTypes = {
        canSave: PropTypes.bool,
        cancelFileUpload: PropTypes.func,
        closeFileMenu: PropTypes.func,
        intl: intlShape.isRequired,
        isLoadingUpload: PropTypes.bool,
        isShowingWithoutId: PropTypes.bool,
        loadingState: PropTypes.oneOf(LoadingStates),
        onLoadingFinished: PropTypes.func,
        onLoadingStarted: PropTypes.func,
        onSetProjectTitle: PropTypes.func,
        projectChanged: PropTypes.bool,
        requestProjectUpload: PropTypes.func,
        userOwnsProject: PropTypes.bool,
        vm: PropTypes.shape({
            loadProject: PropTypes.func
        })
    };

    const mapStateToProps = (state, ownProps) => {
        const loadingState = state.scratchGui.projectState.loadingState;
        const user = state.session && state.session.session && state.session.session.user;
        return {
            isLoadingUpload: getIsLoadingUpload(loadingState),
            isShowingWithoutId: getIsShowingWithoutId(loadingState),
            loadingState: loadingState,
            projectChanged: state.scratchGui.projectChanged,
            userOwnsProject: ownProps.authorUsername && user &&  
                (ownProps.authorUsername === user.username),
            vm: state.scratchGui.vm
        };
    };

    const mapDispatchToProps = (dispatch, ownProps) => ({      
        cancelFileUpload: loadingState => dispatch(onLoadedProject(loadingState, false, false)),
        closeFileMenu: () => dispatch(closeFileMenu()),
        onLoadingFinished: (loadingState, success) => {
            dispatch(onLoadedProject(loadingState, ownProps.canSave, success));
            dispatch(closeLoadingProject());
            dispatch(closeFileMenu());
        },
        onLoadingStarted: () => dispatch(openLoadingProject()),
        onSetProjectTitle: title => dispatch(setProjectTitle(title)),
        requestProjectUpload: loadingState => dispatch(requestProjectUpload(loadingState))
    });

    const mergeProps = (stateProps, dispatchProps, ownProps) => Object.assign(
        {}, stateProps, dispatchProps, ownProps
    );

    return injectIntl(connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps
    )(SBFileUploaderComponent));
};

export {
    SBFileUploaderHOC as default
};
