import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FolderIcon, TrashIcon, XIcon, LoaderIcon, PlusIcon } from 'lucide-react';
import apiClient from '../../utils/apiClient';

const EmptyState = ({ type }) => (
    <div className="flex flex-col items-center justify-center p-12 text-center">
        {type === 'projects' ? (
            <>
                <FolderIcon size={48} className="text-gray-400 mb-4" />
                <h3 className="text-lg font-semibold text-gray-600 mb-2">No Projects Yet</h3>
                <p className="text-gray-500">
                    Create your first project by clicking the "New Project" button above!
                </p>
            </>
        ) : (
            <>
                <TrashIcon size={48} className="text-gray-400 mb-4" />
                <h3 className="text-lg font-semibold text-gray-600 mb-2">Trash is Empty</h3>
                <p className="text-gray-500">
                    Projects you delete will appear here
                </p>
            </>
        )}
    </div>
);

const MyStuff = ({ onClose, onNewProject }) => {
    const [activeTab, setActiveTab] = useState('projects');
    const [projects, setProjects] = useState([]);
    const [trashedProjects, setTrashedProjects] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (activeTab === 'projects') {
            fetchProjects();
        } else {
            fetchTrashedProjects();
        }
    }, [activeTab]);

    const fetchProjects = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const userId = localStorage.getItem('userId');
            // const formData = new FormData();
            // formData.append('user_id', userId)
            console.log(userId)
            const response = await apiClient.get(`/projects/list`, {
                params: { user_id: userId },
            });
            setProjects(response.data.projects);
        } catch (error) {
            setError('Failed to load projects');
            console.error('Error fetching projects:', error);
            setProjects([]);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchTrashedProjects = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const userId = localStorage.getItem('userId');
            const response = await apiClient.get('/projects/trash', {
                params: { user_id: userId },
            });
            setTrashedProjects(response.data.trashedProjects);
        } catch (error) {
            setError('Failed to load trash');
            console.error('Error fetching trash:', error);
            setTrashedProjects([]);
        } finally {
            setIsLoading(false);
        }
    };

    const handleLoadProject = async (projectId) => {
        try {
            const userId = localStorage.getItem('userId');
            await apiClient.get(`/projects/${projectId}/load`, {
                params: { user_id: userId },
            });
            onClose(); // Close the modal after loading
        } catch (error) {
            setError('Failed to load project');
            console.error('Error loading project:', error);
        }
    };

    const handleMoveToTrash = async (projectId) => {
        try {
            const token = localStorage.getItem('accessToken');
            await apiClient.post(`/projects/${projectId}/trash`, null);
            fetchProjects(); // Refresh the projects list
        } catch (error) {
            setError('Failed to move project to trash');
            console.error('Error moving to trash:', error);
        }
    };

    const handleRestoreProject = async (projectId) => {
        try {
            const token = localStorage.getItem('accessToken');
            await apiClient.post(`/projects/${projectId}/restore`, null);
            fetchTrashedProjects(); // Refresh the trash list
        } catch (error) {
            setError('Failed to restore project');
            console.error('Error restoring project:', error);
        }
    };

    const handleDeletePermanently = async (projectId) => {
        if (window.confirm('Are you sure you want to delete this project permanently?')) {
            try {
                const token = localStorage.getItem('accessToken');
                await apiClient.delete(`/projects/${projectId}`);
                fetchTrashedProjects(); // Refresh the trash list
            } catch (error) {
                setError('Failed to delete project');
                console.error('Error deleting project:', error);
            }
        }
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white rounded-lg w-11/12 max-w-6xl h-5/6 overflow-hidden flex flex-col">
                {/* Header */}
                <div className="p-6 border-b border-gray-200 flex justify-between items-center">
                    <h1 className="text-2xl font-bold text-gray-800">My Projects</h1>
                    <div className="flex gap-4">
                        <button 
                            onClick={onNewProject}
                            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition-colors flex items-center gap-2"
                        >
                            <PlusIcon size={20} />
                            New Project
                        </button>
                        <button 
                            onClick={onClose}
                            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
                        >
                            <XIcon size={24} className="text-gray-600" />
                        </button>
                    </div>
                </div>

                {/* Tabs */}
                <div className="flex border-b border-gray-200">
                    <button
                        className={`flex items-center gap-2 px-6 py-3 ${
                            activeTab === 'projects' 
                                ? 'border-b-2 border-blue-500 text-blue-500' 
                                : 'text-gray-600'
                        }`}
                        onClick={() => setActiveTab('projects')}
                    >
                        <FolderIcon size={20} />
                        My Projects
                    </button>
                    <button
                        className={`flex items-center gap-2 px-6 py-3 ${
                            activeTab === 'trash' 
                                ? 'border-b-2 border-blue-500 text-blue-500' 
                                : 'text-gray-600'
                        }`}
                        onClick={() => setActiveTab('trash')}
                    >
                        <TrashIcon size={20} />
                        Trash
                    </button>
                </div>

                {/* Error message */}
                {error && (
                    <div className="p-4 bg-red-100 text-red-700 m-4 rounded">
                        {error}
                    </div>
                )}

                {/* Loading spinner */}
                {isLoading && (
                    <div className="flex justify-center items-center p-8">
                        <LoaderIcon size={32} className="animate-spin text-blue-500" />
                    </div>
                )}

                {/* Content */}
                <div className="flex-1 overflow-y-auto p-6">
                    {!isLoading && !error && (
                        activeTab === 'projects' ? (
                            projects.length === 0 ? (
                                <EmptyState type="projects" />
                            ) : (
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                                    {projects.map(project => (
                                        <div 
                                            key={project._id}
                                            className="border rounded-lg overflow-hidden hover:shadow-lg transition-shadow"
                                        >
                                            <div className="p-4">
                                                <h3 className="font-semibold text-lg mb-1">{project.title}</h3>
                                                <p className="text-sm text-gray-500 mb-4">
                                                    Last modified: {new Date(project.lastModified).toLocaleDateString()}
                                                </p>
                                                <div className="flex gap-2">
                                                    <button
                                                        onClick={() => handleLoadProject(project._id)}
                                                        className="flex-1 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors"
                                                    >
                                                        Open
                                                    </button>
                                                    <button
                                                        onClick={() => handleMoveToTrash(project._id)}
                                                        className="p-2 text-gray-500 hover:bg-gray-100 rounded"
                                                    >
                                                        <TrashIcon size={20} />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )
                        ) : (
                            trashedProjects.length === 0 ? (
                                <EmptyState type="trash" />
                            ) : (
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                                    {trashedProjects.map(project => (
                                        <div 
                                            key={project._id}
                                            className="border rounded-lg overflow-hidden hover:shadow-lg transition-shadow"
                                        >
                                            <div className="p-4">
                                                <h3 className="font-semibold text-lg mb-1">{project.title}</h3>
                                                <p className="text-sm text-gray-500 mb-4">
                                                    Deleted: {new Date(project.deletedAt).toLocaleDateString()}
                                                </p>
                                                <div className="flex gap-2">
                                                    <button
                                                        onClick={() => handleRestoreProject(project._id)}
                                                        className="flex-1 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition-colors"
                                                    >
                                                        Restore
                                                    </button>
                                                    <button
                                                        onClick={() => handleDeletePermanently(project._id)}
                                                        className="flex-1 bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition-colors"
                                                    >
                                                        Delete Forever
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )
                        )
                    )}
                </div>
            </div>
        </div>
    );
};

MyStuff.propTypes = {
    onClose: PropTypes.func.isRequired,
    onNewProject: PropTypes.func.isRequired
};

export default MyStuff; 