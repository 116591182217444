const getEditorSuggestions = (monaco) => [
    // Built-in Functions
    {
        label: 'print',
        kind: monaco.languages.CompletionItemKind.Function,
        insertText: 'print(${1})',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Print objects to the text stream',
        documentation: 'Print objects to the text stream file, separated by sep and followed by end.'
      },
      {
        label: 'input',
        kind: monaco.languages.CompletionItemKind.Function,
        insertText: 'input(${1})',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Read a string from standard input',
        documentation: 'Read a string from standard input.'
      },
      {
        label: 'len',
        kind: monaco.languages.CompletionItemKind.Function,
        insertText: 'len(${1})',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Return the length of an object',
        documentation: 'Return the length (the number of items) of an object.'
      },
      {
        label: 'if',
        kind: monaco.languages.CompletionItemKind.Keyword,
        insertText: 'if ${1:condition}:\n\t${2}',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'If statement',
        documentation: 'Execute a block of code if a condition is true.'
      },
      {
        label: 'elif',
        kind: monaco.languages.CompletionItemKind.Keyword,
        insertText: 'elif ${1:condition}:\n\t${2}',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Else If statement',
        documentation: 'Execute a block of code if a condition is true.'
      },
      {
        label: 'else',
        kind: monaco.languages.CompletionItemKind.Keyword,
        insertText: 'else:\n\t${1}',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Else statement',
        documentation: 'Execute a block of code if none of the conditions are true.'
      },
      {
        label: 'for',
        kind: monaco.languages.CompletionItemKind.Keyword,
        insertText: 'for ${1:item} in ${2:items}:\n\t${3}',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'For loop',
        documentation: 'Iterate over a sequence of items.'
      },
      {
        label: 'while',
        kind: monaco.languages.CompletionItemKind.Keyword,
        insertText: 'while ${1:condition}:\n\t${2}',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'While loop',
        documentation: 'Execute statements repeatedly as long as a condition is true.'
      },
      {
        label: 'def',
        kind: monaco.languages.CompletionItemKind.Snippet,
        insertText: 'def ${1:function_name}(${2:parameters}):\n\t${3:pass}',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Function definition',
        documentation: 'Define a new function.'
      },
      {
        label: 'import',
        kind: monaco.languages.CompletionItemKind.Keyword,
        insertText: 'import ${1:module}',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Import statement',
        documentation: 'Import a module.'
      },

    // Sprite Methods
    {
        label: 'move_sprite',
        kind: monaco.languages.CompletionItemKind.Method,
        insertText: 'move_sprite(${1:sprite_id}, ${2:x}, ${3:y})',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Move sprite to specific coordinates',
        documentation: 'Move a sprite to the specified x and y coordinates on the stage.'
    },
    {
        label: 'move_steps',
        kind: monaco.languages.CompletionItemKind.Method,
        insertText: 'move_steps(${1:sprite_id}, ${2:steps})',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Move sprite by steps',
        documentation: 'Move a sprite forward by the specified number of steps.'
    },
    {
        label: 'rotate_sprite',
        kind: monaco.languages.CompletionItemKind.Method,
        insertText: 'rotate_sprite(${1:sprite_id}, ${2:angle})',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Rotate sprite by angle',
        documentation: 'Rotate a sprite by the specified angle in degrees.'
    },
    {
        label: 'think_sprite',
        kind: monaco.languages.CompletionItemKind.Method,
        insertText: 'think_sprite(${1:sprite_id}, ${2:message}, ${3:time})',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Make sprite show think bubble',
        documentation: 'Make a sprite show a think bubble with the specified message for the given time in seconds.'
    },
    {
        label: 'next_costume',
        kind: monaco.languages.CompletionItemKind.Method,
        insertText: 'next_costume(${1:sprite_id})',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Switch to next costume',
        documentation: 'Switch the sprite to its next costume in the costume list.'
    },
    {
        label: 'say_sprite',
        kind: monaco.languages.CompletionItemKind.Method,
        insertText: 'say_sprite(${1:sprite_id}, ${2:"message"}, ${3:time})',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Make sprite say message',
        documentation: 'Make a sprite show a speech bubble with the specified message for the given time in seconds.'
    },
    {
        label: 'create_sprite',
        kind: monaco.languages.CompletionItemKind.Method,
        insertText: 'create_sprite(${1:"sprite_name"}, ${2:0}, ${3:0})',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Create a new sprite_id at coordinates',
        documentation: 'Create a new sprite with the given name at specified x,y coordinates.'
    },
    {
        label: 'bounce_on_edge',
        kind: monaco.languages.CompletionItemKind.Method,
        insertText: 'bounce_on_edge(${1:sprite_id})',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Make sprite bounce on edge',
        documentation: 'Make the sprite bounce when it hits the edge of the stage.'
    },
    {
        label: 'go_to_random_position',
        kind: monaco.languages.CompletionItemKind.Method,
        insertText: 'go_to_random_position(${1:sprite_id})',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Move sprite to random position',
        documentation: 'Move the sprite to a random position on the stage.'
    },
    {
        label: 'go_to_mouse',
        kind: monaco.languages.CompletionItemKind.Method,
        insertText: 'go_to_mouse(${1:sprite_id})',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Move sprite to mouse position',
        documentation: 'Move the sprite to the current mouse pointer position.'
    },
    {
        label: 'change_x_by',
        kind: monaco.languages.CompletionItemKind.Method,
        insertText: 'change_x_by(${1:sprite_id}, ${2:value})',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Change sprite X position',
        documentation: 'Change the X position of the sprite by the specified value.'
    },
    {
        label: 'change_y_by',
        kind: monaco.languages.CompletionItemKind.Method,
        insertText: 'change_y_by(${1:sprite_id}, ${2:value})',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Change sprite Y position',
        documentation: 'Change the Y position of the sprite by the specified value.'
    },
    {
        label: 'set_x',
        kind: monaco.languages.CompletionItemKind.Method,
        insertText: 'set_x(${1:sprite_id}, ${2:value})',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Set sprite X position',
        documentation: 'Set the X position of the sprite to the specified value.'
    },
    {
        label: 'set_y',
        kind: monaco.languages.CompletionItemKind.Method,
        insertText: 'set_y(${1:sprite_id}, ${2:value})',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Set sprite Y position',
        documentation: 'Set the Y position of the sprite to the specified value.'
    },
    {
        label: 'change_size',
        kind: monaco.languages.CompletionItemKind.Method,
        insertText: 'change_size(${1:sprite_id}, ${2:value})',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Change sprite size',
        documentation: 'Change the size of the sprite by the specified percentage.'
    },
    {
        label: 'set_size',
        kind: monaco.languages.CompletionItemKind.Method,
        insertText: 'set_size(${1:sprite_id}, ${2:value})',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Set sprite size',
        documentation: 'Set the size of the sprite to the specified percentage.'
    },
    {
        label: 'hide',
        kind: monaco.languages.CompletionItemKind.Method,
        insertText: 'hide(${1:sprite_id})',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Hide sprite',
        documentation: 'Make the sprite invisible.'
    },
    {
        label: 'show',
        kind: monaco.languages.CompletionItemKind.Method,
        insertText: 'show(${1:sprite_id})',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Show sprite',
        documentation: 'Make the sprite visible.'
    },
    {
        label: 'glide_to',
        kind: monaco.languages.CompletionItemKind.Method,
        insertText: 'glide_to(${1:sprite_id}, ${2:x}, ${3:y}, ${4:secs})',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Glide sprite to position over time',
        documentation: 'Move sprite smoothly to specified x,y coordinates over given seconds.'
    },
    {
        label: 'set_rotation_style',
        kind: monaco.languages.CompletionItemKind.Method,
        insertText: 'set_rotation_style(${1:sprite_id}, ${2:style})',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Set sprite rotation style',
        documentation: `Set how the sprite rotates. Available styles:
- ROTATION_ALL_AROUND: Sprite can rotate in any direction
- ROTATION_LEFT_RIGHT: Sprite only faces left or right 
- ROTATION_DONT_ROTATE: Sprite never rotates`
    },
    {
        label: 'switch_costume',
        kind: monaco.languages.CompletionItemKind.Method,
        insertText: 'switch_costume(${1:sprite_id}, ${2:costume})',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Switch sprite costume',
        documentation: 'Switch the sprite to a specific costume by name or number.'
    },
    {
        label: 'switch_backdrop',
        kind: monaco.languages.CompletionItemKind.Method,
        insertText: 'switch_backdrop(${1:backdrop})',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Switch stage backdrop',
        documentation: 'Switch the stage backdrop to a specific backdrop by name or number.'
    },
    {
        label: 'next_backdrop',
        kind: monaco.languages.CompletionItemKind.Method,
        insertText: 'next_backdrop()',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Switch to next backdrop',
        documentation: 'Switch the stage to its next backdrop in the backdrop list.'
    },
    {
        label: 'change_effect',
        kind: monaco.languages.CompletionItemKind.Method,
        insertText: 'change_effect(${1:sprite_id}, ${2:effect}, ${3:change})',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Change sprite visual effect',
        documentation: `Change a visual effect on the sprite. Available effects:
- COLOR: Changes sprite's color (0-200)
- FISHEYE: Distorts sprite with fisheye effect
- WHIRL: Whirls the sprite
- PIXELATE: Pixelates the sprite
- MOSAIC: Creates a mosaic effect
- BRIGHTNESS: Changes sprite brightness
- GHOST: Makes sprite semi-transparent`
    },
    {
        label: 'set_effect',
        kind: monaco.languages.CompletionItemKind.Method,
        insertText: 'set_effect(${1:sprite_id}, ${2:effect}, ${3:value})',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Set sprite visual effect',
        documentation: `Set a visual effect value for the sprite. Available effects:
- COLOR: Sets sprite's color (0-200)
- FISHEYE: Sets fisheye distortion level
- WHIRL: Sets whirl amount
- PIXELATE: Sets pixelation level
- MOSAIC: Sets mosaic effect level
- BRIGHTNESS: Sets sprite brightness
- GHOST: Sets transparency level (0-100)`
    },
    {
        label: 'clear_effects',
        kind: monaco.languages.CompletionItemKind.Method,
        insertText: 'clear_effects(${1:sprite_id})',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Clear all visual effects',
        documentation: 'Removes all visual effects from the sprite.'
    },
    {
        label: 'go_to_front',
        kind: monaco.languages.CompletionItemKind.Method,
        insertText: 'go_to_front(${1:sprite_id})',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Move sprite to front layer',
        documentation: 'Bring the sprite to the front layer, above all other sprites.'
    },
    {
        label: 'go_to_back',
        kind: monaco.languages.CompletionItemKind.Method,
        insertText: 'go_to_back(${1:sprite_id})',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Move sprite to back layer',
        documentation: 'Send the sprite to the back layer, behind all other sprites.'
    },
    {
        label: 'change_layer_by',
        kind: monaco.languages.CompletionItemKind.Method,
        insertText: 'change_layer_by(${1:sprite_id}, ${2:layers})',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Change sprite layer position',
        documentation: 'Move sprite forward or backward by the specified number of layers. Use positive numbers to bring forward, negative to send backward.'
    },
    {
        label: 'is_touching',
        kind: monaco.languages.CompletionItemKind.Method,
        insertText: 'is_touching(${1:sprite_id}, ${2:target})',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Check if sprite is touching another sprite',
        documentation: 'Returns True if the sprite is touching the specified target sprite.'
    },
    {
        label: 'is_touching_color',
        kind: monaco.languages.CompletionItemKind.Method,
        insertText: 'is_touching_color(${1:sprite_id}, ${2:color})',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Check if sprite is touching a color',
        documentation: 'Returns True if the sprite is touching the specified color. Color can be a hex value or color name.'
    },
    {
        label: 'is_color_touching_color',
        kind: monaco.languages.CompletionItemKind.Method,
        insertText: 'is_color_touching_color(${1:sprite_id}, ${2:color1}, ${3:color2})',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Check if two colors are touching',
        documentation: 'Returns True if color1 on the sprite is touching color2. Colors can be hex values or color names.'
    },
    {
        label: 'ask_sprite',
        kind: monaco.languages.CompletionItemKind.Method,
        insertText: 'ask_sprite(${1:sprite_id}, ${2:"question"})',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Ask a question and wait for answer',
        documentation: 'Makes the sprite ask a question and waits for user input. Returns the answer as a string.'
    },
    {
        label: 'distance_to',
        kind: monaco.languages.CompletionItemKind.Method,
        insertText: 'distance_to(${1:sprite_id}, ${2:target})',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Get distance to another sprite',
        documentation: 'Returns the distance in pixels from this sprite to the target sprite.'
    },
    {
        label: 'get_mouse_x',
        kind: monaco.languages.CompletionItemKind.Method,
        insertText: 'get_mouse_x(${1:sprite_id})',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Get mouse X coordinate',
        documentation: 'Returns the current X coordinate of the mouse pointer on the stage.'
    },
    {
        label: 'get_mouse_y',
        kind: monaco.languages.CompletionItemKind.Method,
        insertText: 'get_mouse_y(${1:sprite_id})',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Get mouse Y coordinate',
        documentation: 'Returns the current Y coordinate of the mouse pointer on the stage.'
    },
    {
        label: 'play_sound',
        kind: monaco.languages.CompletionItemKind.Method,
        insertText: 'play_sound(${1:sprite_id}, ${2:sound_name})',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Start playing a sound',
        documentation: 'Play a sound file and immediately continue with next instruction.'
    },
    {
        label: 'play_sound_until_done',
        kind: monaco.languages.CompletionItemKind.Method,
        insertText: 'play_sound_until_done(${1:sprite_id}, ${2:sound_name})',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Play sound and wait',
        documentation: 'Play a sound file and wait until it finishes before continuing.'
    },
    {
        label: 'stop_all_sounds',
        kind: monaco.languages.CompletionItemKind.Method,
        insertText: 'stop_all_sounds()',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Stop all sounds',
        documentation: 'Stop all currently playing sounds.'
    },
    {
        label: 'set_sound_effect',
        kind: monaco.languages.CompletionItemKind.Method,
        insertText: 'set_sound_effect(${1:sprite_id}, ${2:effect}, ${3:value})',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Set sound effect value',
        documentation: `Set a sound effect to specific value. Available effects:
- PITCH: Changes sound pitch (-360 to 360)
- PAN: Left/right stereo pan (-100 to 100)
- ECHO: Echo effect (0 to 100)
- REVERB: Reverberation (0 to 100)
- DISTORTION: Sound distortion (0 to 100)
- ROBOT: Robotic effect (0 to 100)`
    },
    {
        label: 'change_sound_effect',
        kind: monaco.languages.CompletionItemKind.Method,
        insertText: 'change_sound_effect(${1:sprite_id}, ${2:effect}, ${3:change})',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Change sound effect',
        documentation: `Change a sound effect by relative amount. Available effects:
- PITCH: Changes sound pitch (-360 to 360)
- PAN: Left/right stereo pan (-100 to 100)
- ECHO: Echo effect (0 to 100)
- REVERB: Reverberation (0 to 100)
- DISTORTION: Sound distortion (0 to 100)
- ROBOT: Robotic effect (0 to 100)`
    },
    {
        label: 'clear_sound_effects',
        kind: monaco.languages.CompletionItemKind.Method,
        insertText: 'clear_sound_effects(${1:sprite_id})',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Clear sound effects',
        documentation: 'Remove all sound effects from the sprite.'
    },
    {
        label: 'set_volume',
        kind: monaco.languages.CompletionItemKind.Method,
        insertText: 'set_volume(${1:sprite_id}, ${2:volume})',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Set sound volume',
        documentation: 'Set the volume for sprite sounds (0-100%).'
    },
    {
        label: 'change_volume',
        kind: monaco.languages.CompletionItemKind.Method,
        insertText: 'change_volume(${1:sprite_id}, ${2:change})',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Change sound volume',
        documentation: 'Change the volume for sprite sounds by relative amount.'
    },
    {
        label: 'get_volume',
        kind: monaco.languages.CompletionItemKind.Method,
        insertText: 'get_volume(${1:sprite_id})',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Get sound volume',
        documentation: 'Returns the current volume for sprite sounds (0-100%).'
    },
    {
        label: 'is_key_pressed',
        kind: monaco.languages.CompletionItemKind.Method,
        insertText: 'is_key_pressed(${1:sprite_id}, ${2:key})',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Check if a key is pressed',
        documentation: `Returns True if the specified key is pressed. Available keys:
- space, up arrow, down arrow, right arrow, left arrow
- a-z, 0-9
- enter, shift, ctrl, alt, esc`
    },
    {
        label: 'days_since_2000',
        kind: monaco.languages.CompletionItemKind.Method,
        insertText: 'days_since_2000(${1:sprite_id})',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Get days since year 2000',
        documentation: 'Returns the number of days that have passed since January 1, 2000.'
    },
    {
        label: 'get_current',
        kind: monaco.languages.CompletionItemKind.Method,
        insertText: 'get_current(${1:sprite_id}, ${2:menu_option})',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Get current time value',
        documentation: `Get current time value. Available options:
- YEAR: Current year
- MONTH: Current month (1-12)  
- DATE: Current date (1-31)
- DAYOFWEEK: Current day of week (1-7)
- HOUR: Current hour (0-23)
- MINUTE: Current minute (0-59)
- SECOND: Current second (0-59)`
    },
    {
        label: 'get_timer',
        kind: monaco.languages.CompletionItemKind.Method,
        insertText: 'get_timer(${1:sprite_id})',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Get timer value',
        documentation: 'Returns the value of the timer in seconds.'
    },
    {
        label: 'reset_timer',
        kind: monaco.languages.CompletionItemKind.Method,
        insertText: 'reset_timer(${1:sprite_id})',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Reset timer',
        documentation: 'Reset the timer to zero.'
    }
];

export default getEditorSuggestions;