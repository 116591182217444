import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Unity, useUnityContext } from "react-unity-webgl";
import { AnimatePresence, motion } from 'framer-motion';
import { X } from 'lucide-react';
import Draggable from 'react-draggable';

const ComponentPreview = ({ component, image, isVisible, position, containerWidth }) => {
  // Calculate adjusted position to keep preview within bounds
  const adjustPosition = (rawPosition) => {
    // Convert percentage to pixels
    const pixelPosition = (rawPosition * containerWidth) / 100;
    const previewWidth = 120; // Approximate width of preview box
    const halfPreviewWidth = previewWidth / 2;

    // Adjust if too close to left edge
    if (pixelPosition < halfPreviewWidth) {
      return `${(halfPreviewWidth / containerWidth) * 100}%`;
    }
    // Adjust if too close to right edge
    if (pixelPosition > containerWidth - halfPreviewWidth) {
      return `${((containerWidth - halfPreviewWidth) / containerWidth) * 100}%`;
    }
    return `${rawPosition}%`;
  };

  // Calculate arrow position relative to the preview box
  const getArrowPosition = (rawPosition) => {
    const pixelPosition = (rawPosition * containerWidth) / 100;
    const previewWidth = 120;
    const halfPreviewWidth = previewWidth / 2;

    if (pixelPosition < halfPreviewWidth) {
      const offset = ((pixelPosition / halfPreviewWidth) * 50) - 50;
      return `calc(50% + ${offset}px)`;
    }
    if (pixelPosition > containerWidth - halfPreviewWidth) {
      const offset = (((containerWidth - pixelPosition) / halfPreviewWidth) * 50) - 50;
      return `calc(50% - ${offset}px)`;
    }
    return '50%';
  };

  const adjustedPosition = adjustPosition(position);
  const arrowPosition = getArrowPosition(position);

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 20 }}
          className="absolute bg-gray-200 rounded-lg shadow-lg p-2 z-50 w-[120px]"
          style={{
            left: adjustedPosition,
            transform: 'translateX(-50%)',
            top: '20px',
          }}
        >
          <div className="relative">
            {/* Arrow pointing up */}
            <div
              className="absolute w-4 h-4 bg-white transform rotate-45 -top-5 border-t border-l border-gray-400"
              style={{
                left: arrowPosition,
                marginLeft: '-8px',
              }}
            />
            <img
              src={image}
              alt={component}
              className="w-16 h-16 object-contain mx-auto"
            />
            <div className="text-sm font-medium text-center mt-1 truncate">
              {component}
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
// Error handling wrapper for Unity context
const useUnityContextWithErrorHandling = (config) => {
  useEffect(() => {
    // Store references to original functions
    const handlers = {
      originalError: console.error,
      originalDefine: window.define
    };

    // Suppress the specific RequireJS error
    console.error = (...args) => {
      const errorMessage = args.join(' ');
      if (
        !errorMessage.includes('Can only have one anonymous define call per script file') &&
        !errorMessage.includes('Script error.') &&
        !errorMessage.includes('handleError')
      ) {
        handlers.originalError.apply(console, args);
      }
    };

    // Handle RequireJS define conflicts
    if (window.define && window.define.amd) {
      window.define = (...args) => {
        try {
          return handlers.originalDefine.apply(this, args);
        } catch (error) {
          if (!error.message.includes('Can only have one anonymous define call per script file')) {
            throw error;
          }
          // Return undefined instead of rethrowing the error
          return undefined;
        }
      };
      // Preserve AMD flag
      window.define.amd = handlers.originalDefine.amd;
    }

    return () => {
      // Restore original functions
      console.error = handlers.originalError;
      if (window.define !== handlers.originalDefine) {
        window.define = handlers.originalDefine;
      }
    };
  }, []);

  return useUnityContext(config);
};

// Rest of your components remain the same...
const CarGame = () => {
  const unityContext = useUnityContextWithErrorHandling({
    loaderUrl: "/Build/Pictures.loader.js",
    dataUrl: "/Build/Pictures.data/webgl.data",
    frameworkUrl: "/Build/Pictures.framework.js/build.framework.js",
    codeUrl: "/Build/Pictures.wasm/build.wasm",
  });

  return <UnityGameWrapper {...unityContext} gameMode="car" />;
};

const ArmGame = () => {
  const unityContext = useUnityContextWithErrorHandling({
    loaderUrl: "/Build/roboarm_game.loader.js",
    dataUrl: "/Build/roboarm_game.data/webgl.data",
    frameworkUrl: "/Build/roboarm_game.framework.js/build.framework.js",
    codeUrl: "/Build/roboarm_game.wasm/build.wasm",
  });

  return <UnityGameWrapper {...unityContext} gameMode="arm" />;
};

const SpiderRobotGame = () => {
  const unityContext = useUnityContextWithErrorHandling({
    loaderUrl: "/Build/robotic_spider.loader.js",
    dataUrl: "/Build/robotic_spider.data/webgl.data",
    frameworkUrl: "/Build/robotic_spider.framework.js/build.framework.js",
    codeUrl: "/Build/robotic_spider.wasm/build.wasm",
  });

  return <UnityGameWrapper {...unityContext} gameMode="spider" />;
};

const HumanRoboGame = () => {
  const unityContext = useUnityContextWithErrorHandling({
    loaderUrl: "/Build/human_robo.loader.js",
    dataUrl: "/Build/human_robo.data/webgl.data",
    frameworkUrl: "/Build/human_robo.framework.js/build.framework.js",
    codeUrl: "/Build/human_robo.wasm/build.wasm",
  });

  return <UnityGameWrapper {...unityContext} gameMode="robo" />;
};

const WMRGame = () => {
  const unityContext = useUnityContextWithErrorHandling({
    loaderUrl: "/Build/wmr_assamble_v4.loader.js",
    dataUrl: "/Build/wmr_assamble_v4.data/webgl.data",
    frameworkUrl: "/Build/wmr_assamble_v4.framework.js/build.framework.js",
    codeUrl: "/Build/wmr_assamble_v4.wasm/build.wasm",
  });

  return <UnityGameWrapper {...unityContext} gameMode="wmr" />;
};

const UnityGameWrapper = ({ unityProvider, isLoaded, loadingProgression, requestFullscreen, gameMode = 'car' }) => {
  const progress = Math.round(loadingProgression * 100);
  const [componentProgress, setComponentProgress] = useState(0);
  const [currentComponent, setCurrentComponent] = useState("");
  const [componentProgresses, setComponentProgresses] = useState({});
  const [previewComponent, setPreviewComponent] = useState(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const progressBarsRef = useRef(null);
  const previewTimeoutRef = useRef(null);
  const mounted = useRef(true);

  // Add mounted ref to handle cleanup
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
      // Clear any pending timeouts
      if (previewTimeoutRef.current) {
        clearTimeout(previewTimeoutRef.current);
      }
      // Remove the global handler
      delete window.handleProgressUpdate;
    };
  }, []);

  // Safe setState functions
  const safeSetComponentProgress = useCallback((value) => {
    if (mounted.current) {
      setComponentProgress(value);
    }
  }, []);

  const safeSetCurrentComponent = useCallback((value) => {
    if (mounted.current) {
      setCurrentComponent(value);
    }
  }, []);

  const safeSetPreviewComponent = useCallback((value) => {
    if (mounted.current) {
      setPreviewComponent(value);
    }
  }, []);

  // Initialize component progresses
  useEffect(() => {
    if (mounted.current) {
      const initialProgresses = {};
      Object.values(componentMapping).forEach(component => {
        initialProgresses[component] = 0;
      });
      setComponentProgresses(initialProgresses);
      safeSetCurrentComponent("");
      safeSetComponentProgress(0);
    }
  }, [gameMode, safeSetCurrentComponent, safeSetComponentProgress]);

  // Update global progress handler
  useEffect(() => {
    window.handleProgressUpdate = (newProgress) => {
      safeSetComponentProgress(newProgress);
    };

    return () => {
      delete window.handleProgressUpdate;
    };
  }, [safeSetComponentProgress]);

  // Component visibility logging
  useEffect(() => {
    const originalConsoleLog = console.log;
    console.log = function (...args) {
      const message = args.join(' ');
      if (message.startsWith('Showing Component:')) {
        const rawComponentName = message.split(':')[1].trim();
        const mappedName = mapComponentName(rawComponentName);
        if (mappedName && mounted.current) {
          safeSetCurrentComponent(mappedName);
        }
      }
      originalConsoleLog.apply(console, args);
    };

    return () => {
      console.log = originalConsoleLog;
    };
  }, [gameMode, safeSetCurrentComponent]);

  const showNextComponentPreview = useCallback((components, currentIndex) => {
    if (!mounted.current) return;

    const nextIndex = currentIndex + 1;
    if (nextIndex < components.length) {
      const nextComponent = components[nextIndex];
      safeSetPreviewComponent({
        name: nextComponent,
        position: ((nextIndex + 1) / components.length) * 100,
      });

      if (previewTimeoutRef.current) {
        clearTimeout(previewTimeoutRef.current);
      }
      previewTimeoutRef.current = setTimeout(() => {
        if (mounted.current) {
          safeSetPreviewComponent(null);
        }
      }, 2000);
    }
  }, [safeSetPreviewComponent]);

  useEffect(() => {
    const uniqueComponents = [...new Set(Object.values(componentMapping))];
    const currentIndex = uniqueComponents.indexOf(currentComponent);

    if (currentIndex !== -1) {
      showNextComponentPreview(uniqueComponents, currentIndex);
    }

    return () => {
      if (previewTimeoutRef.current) {
        clearTimeout(previewTimeoutRef.current);
      }
    };
  }, [currentComponent, showNextComponentPreview]);

  const componentImages = {
    'Top mounting plate': '/images/top_mounting_plate.png',
    'Left side chassis': '/images/left_side_chassis.png',
    'Right side chassis': '/images/right_side_chassis.png',
    'Rear left Motor': '/images/motor.png',
    'Front left Motor': '/images/motor.png',
    'Rear right Motor': '/images/motor.png',
    'Front right Motor': '/images/motor.png',
    'Rear left wheel': '/images/wheel.png',
    'Front left wheel': '/images/wheel.png',
    'Rear right wheel': '/images/wheel.png',
    'Front right wheel': '/images/wheel.png',
    'Car Back': '/images/car_front_back.png',
    'Car Front': '/images/car_front_back.png',
    'WMR Base': '/images/lower_body.png',
    'Chassis link 1': '/images/chassis_link.png',
    'Chassis link 2': '/images/chassis_link.png',
    'Chassis link 3': '/images/chassis_link.png',
    'Chassis link 4': '/images/chassis_link.png',
    'Chassis link 5': '/images/chassis_link.png',
    'Chassis link 6': '/images/chassis_link.png',
    'Motor clamp 1': '/images/bo_clamo.png',
    'Motor clamp 2': '/images/bo_clamo.png',
    'Motor clamp 3': '/images/bo_clamo.png',
    'Motor clamp 4': '/images/bo_clamo.png',
    'BO motor 1': '/images/dc_gear_motor.png',
    'BO motor 2': '/images/dc_gear_motor.png',
    'BO motor 3': '/images/dc_gear_motor.png',
    'BO motor 4': '/images/dc_gear_motor.png',
    'Upper body': '/images/upper_body.png',
    'Stemverase brain': '/images/brain.png',
    'Wheel 1': '/images/wheel_wmr.png',
    'Wheel 2': '/images/wheel_wmr.png',
    'Wheel 3': '/images/wheel_wmr.png',
    'Wheel 4': '/images/wheel_wmr.png',
    'Arm base': '/images/arm_base.png',
    'Shoulder': '/images/shoulder.png',
    'Elbow link 1': '/images/elbow_link_1.png',
    'Elbow link 2': '/images/elbow_link_2.png',
    'Wrist link 1': '/images/wrist_link_1.png',
    'Wrist link 2': '/images/wrist_link_2.png',
    'Gripper gear 1': '/images/gripper_gear_1.png',
    'Gripper gear 2': '/images/gripper_gear_2.png',
    'End effector': '/images/end_effector.png',
    'Coxa 1': '/images/coxa.png',
    'Coxa 2': '/images/coxa.png',
    'Coxa 3': '/images/coxa.png',
    'Coxa 4': '/images/coxa.png',
    'Fimure 1': '/images/fimure.png',
    'Fimure 2': '/images/fimure.png',
    'Fimure 3': '/images/fimure.png',
    'Fimure 4': '/images/fimure.png',
    'Tibia 1': '/images/tibia.png',
    'Tibia 2': '/images/tibia.png',
    'Tibia 3': '/images/tibia.png',
    'Tibia 4': '/images/tibia.png',
    'Top plate': '/images/top_plate.png',
    'Robo Back': '/images/robo_back.png',
    'Robo Chest': '/images/robo_chest.png',
    'Left leg': '/images/robo_leg.png',
    'Right leg': '/images/robo_leg.png',
    'Left hand': '/images/left_hand.png',
    'Right hand': '/images/right_hand.png',
    'Head': '/images/head.png',
  };
  const carComponents = {
    'l_r_1side_default': 'Left side chassis',
    'l_r_2side_default': 'Right side chassis',
    'wheel gear motor_1_default': 'Front right Motor',
    'wheel_1_default': 'Front right wheel',
    'wheel gear motor_2_default': 'Rear right Motor',
    'wheel_2_default': 'Rear right wheel',
    'wheel gear motor_3_default': 'Rear left Motor',
    'wheel_3_default': 'Rear left wheel',
    'wheel gear motor_4_default': 'Front left Motor',
    'wheel_4_default': 'Front left wheel',
    'side_default': 'Car Back',
    'side.1_default': 'Car Front',
    'upper_default': 'Top mounting plate'
  };
  const armComponents = {
    'base': 'Arm base',
    'cover': 'Shoulder',
    'link_1': 'Elbow link 1',
    'link_2': 'Elbow link 2',
    'link_3': 'Wrist link 1',
    'link_4': 'Wrist link 2',
    'grip_1': 'Gripper gear 1',
    'grip_2': 'Gripper gear 2',
    'grip_3': 'End effector',
    'grip_4': 'End effector',
  };
  const spiderComponents = {
    'cocsa_1': 'Coxa 1',
    'cocsa_2': 'Coxa 2',
    'cocsa_3': 'Coxa 3',
    'cocsa_4': 'Coxa 4',
    'fimure_1': 'Fimure 1',
    'fimure_2': 'Fimure 2',
    'fimure_3': 'Fimure 3',
    'fimure_4': 'Fimure 4',
    'tibia_1': 'Tibia 1',
    'tibia_2': 'Tibia 2',
    'tibia_3': 'Tibia 3',
    'tibia_4': 'Tibia 4',
    'top': 'Top plate',
  };
  const roboComponents = {
    'back_body': 'Robo Back',
    'main_body': 'Robo Chest',
    'left_leg': 'Left leg',
    'right_leg': 'Right leg',
    'left_hand': 'Left hand',
    'right_hand': 'Right hand',
    'robo_head': 'Head',
  };
  const wmrComponents = {
    'lower_body': 'WMR Base',
    'chassis_link1': 'Chassis link 1',
    'chassis_link2': 'Chassis link 2',
    'chassis_link3': 'Chassis link 3',
    'chassis_link4': 'Chassis link 4',
    'chassis_link5': 'Chassis link 5',
    'chassis_link6': 'Chassis link 6',
    'bo clamo 1': 'Motor clamp 1',
    'bo clamo 2': 'Motor clamp 2',
    'bo clamo 3': 'Motor clamp 3',
    'bo clamo 4': 'Motor clamp 4',
    'dc_gear_motor_single_shaft1': 'BO motor 1',
    'dc_gear_motor_single_shaft2': 'BO motor 2',
    'dc_gear_motor_single_shaft3': 'BO motor 3',
    'dc_gear_motor_single_shaft4': 'BO motor 4',
    'wheel_d65x25d1': 'Wheel 1',
    'wheel_d65x25d2': 'Wheel 2',
    'wheel_d65x25d3': 'Wheel 3',
    'wheel_d65x25d4': 'Wheel 4',
    'upper_body': 'Upper body',
    'brain': 'Stemverase brain'
  };
  // Use appropriate component mapping based on game mode
  const componentMapping = gameMode === 'car'
    ? carComponents
    : gameMode === 'arm'
      ? armComponents
      : gameMode === 'spider'
        ? spiderComponents
        : gameMode === 'wmr'
          ? wmrComponents
          : roboComponents;


  const mapComponentName = (rawName) => {
    const lowerName = rawName.toLowerCase();
    for (const [key, value] of Object.entries(componentMapping)) {
      if (lowerName.includes(key)) {
        return value;
      }
    }
    return null;
  };

  // Initialize component progresses
  useEffect(() => {
    const initialProgresses = {};
    Object.values(componentMapping).forEach(component => {
      initialProgresses[component] = 0;
    });
    setComponentProgresses(initialProgresses);
    setCurrentComponent("");
    setComponentProgress(0);
  }, [gameMode]);

  window.handleProgressUpdate = (newProgress) => {
    setComponentProgress(newProgress);
  };

  // Component visibility logging
  useEffect(() => {
    const originalConsoleLog = console.log;
    console.log = function (...args) {
      const message = args.join(' ');
      if (message.startsWith('Showing Component:')) {
        const rawComponentName = message.split(':')[1].trim();
        const mappedName = mapComponentName(rawComponentName);
        if (mappedName) {
          setCurrentComponent(mappedName);
        }
      }
      originalConsoleLog.apply(console, args);
    };

    return () => {
      console.log = originalConsoleLog;
    };
  }, [gameMode]);
  const carDescriptions = {
    'Top mounting plate': "This part holds the controllers and sensors",
    'Left side chassis': "This is a car body part that helps the car to hold its left side motors",
    'Right side chassis': "This is a car body part that helps the car to hold its right side motors",
    'Rear left Motor': "These are a 12v centre shaft DC gear motor having 60 RPM rotation speed",
    'Front left Motor': "These are a 12v centre shaft DC gear motor having 60 RPM rotation speed",
    'Rear right Motor': "These are a 12v centre shaft DC gear motor having 60 RPM rotation speed",
    'Front right Motor': "These are a 12v centre shaft DC gear motor having 60 RPM rotation speed",
    'Rear left wheel': "These are centre shaft 100mm wheels",
    'Front left wheel': "These are centre shaft 100mm wheels",
    'Rear right wheel': "These are centre shaft 100mm wheels",
    'Front right wheel': "These are centre shaft 100mm wheels",
    'Car Back': "These parts help to hold the structure of the car",
    'Car Front': "These parts help to hold the structure of the car",
  };
  const armDescriptions = {
    'Arm base': "This is the reference body of the 6 DOF arm",
    'Shoulder': "This is the shoulder link connected to the base through a vertical joint which can rotate between -90° to 90°",
    'Elbow link 1': "This is the first elbow link connected to the shoulder link through a horizontal joint which can rotate between -90° to 90°",
    'Elbow link 2': "This is the second elbow link connected to the first elbow link through a horizontal joint which can rotate between -90° to 90°",
    'Wrist link 1': "This is the first wrist link connected to the second elbow link through a vertical joint which can rotate between -90° to 90°",
    'Wrist link 2': "This is the second wrist link connected to the first wrist link through a horizontal joint which can rotate between -90° to 90°",
    'Gripper gear 1': "This is the first gripper gear connected to the second wrist link through a horizontal joint which can rotate between 110° to 150°",
    'Gripper gear 2': "This is the second gripper gear connected to the second wrist link through a horizontal joint which can rotate between 110° to 150°",
    'End effector': "This is the end effector connected to the gripper gear through a horizontal joint which can rotate between 110° to 150°",
  };
  const spiderDescriptions = {
    'Coxa 1': "This is a structural component of the robotic leg, providing rotational movement.",
    'Coxa 2': "This is a structural component of the robotic leg, providing rotational movement.",
    'Coxa 3': "This is a structural component of the robotic leg, providing rotational movement.",
    'Coxa 4': "This is a structural component of the robotic leg, providing rotational movement.",
    'Fimure 1': "This is a robotic leg component that connects to the tibia and supports movement.",
    'Fimure 2': "This is a robotic leg component that connects to the tibia and supports movement.",
    'Fimure 3': "This is a robotic leg component that connects to the tibia and supports movement.",
    'Fimure 4': "This is a robotic leg component that connects to the tibia and supports movement.",
    'Tibia 1': "This part of the robotic leg works in conjunction with the femur and coxa for stable movement.",
    'Tibia 2': "This part of the robotic leg works in conjunction with the femur and coxa for stable movement.",
    'Tibia 3': "This part of the robotic leg works in conjunction with the femur and coxa for stable movement.",
    'Tibia 4': "This part of the robotic leg works in conjunction with the femur and coxa for stable movement.",
    'Top plate': "This plate helps hold and secure various components of the robot.",
  };
  const roboDescriptions = {
    'Robo Back': "This is the back structure of the robotic body.",
    'Robo Chest': "This is the main body structure for the robot, housing essential components.",
    'Left leg': "This component supports the left leg functionality of the robot.",
    'Right leg': "This component supports the right leg functionality of the robot.",
    'Left hand': "This is the left hand part of the robot, capable of grip and release.",
    'Right hand': "This is the right hand part of the robot, capable of grip and release.",
    'Head': "This is the robot's head, often containing sensors and cameras."
  };
  const wmrDescriptions = {
    'WMR Base': "The foundation component that serves as the base structure for the WMR robot",
    'Chassis link 1': "Structural link component that connects various parts of the chassis",
    'Chassis link 2': "Structural link component that connects various parts of the chassis",
    'Chassis link 3': "Structural link component that connects various parts of the chassis",
    'Chassis link 4': "Structural link component that connects various parts of the chassis",
    'Chassis link 5': "Structural link component that connects various parts of the chassis",
    'Chassis link 6': "Structural link component that connects various parts of the chassis",
    'Motor clamp 1': "Clamp component used to secure the BO motor in place",
    'Motor clamp 2': "Clamp component used to secure the BO motor in place",
    'Motor clamp 3': "Clamp component used to secure the BO motor in place",
    'Motor clamp 4': "Clamp component used to secure the BO motor in place",
    'BO motor 1': "30 rpm BO motor for providing movement and control",
    'BO motor 2': "30 rpm BO motor for providing movement and control",
    'BO motor 3': "30 rpm BO motor for providing movement and control",
    'BO motor 4': "30 rpm BO motor for providing movement and control",
    'Wheel 1': "High-traction wheel for precise movement and control of the WMR robot",
    'Wheel 2': "High-traction wheel for precise movement and control of the WMR robot",
    'Wheel 3': "High-traction wheel for precise movement and control of the WMR robot",
    'Wheel 4': "High-traction wheel for precise movement and control of the WMR robot",
    'Upper body': "Top structural component of the WMR robot",
    'Stemverase brain': "Central control unit that manages the robot's operations"
  };

  const componentDescriptions = gameMode === 'car'
    ? carDescriptions
    : gameMode === 'arm'
      ? armDescriptions
      : gameMode === 'spider'
        ? spiderDescriptions
        : gameMode === 'wmr'
          ? wmrDescriptions
          : roboDescriptions;
  const getComponentDescription = (component) => {
    return componentDescriptions[component] || "Building in progress...";
  };

  const getComponentImage = (component) => {
    return componentImages[component] || '/images/default_component.png';
  };

  useEffect(() => {
    const uniqueComponents = [...new Set(Object.values(componentMapping))];
    const currentIndex = uniqueComponents.indexOf(currentComponent);

    if (currentIndex !== -1) {
      showNextComponentPreview(uniqueComponents, currentIndex);
    }
  }, [currentComponent]);

  const renderProgressBars = () => {
    const uniqueComponents = [...new Set(Object.values(componentMapping))];
    const totalComponents = uniqueComponents.length;
    const barWidth = `calc((100% - ${(totalComponents - 1) * 0.5}rem) / ${totalComponents})`;
    const currentIndex = uniqueComponents.indexOf(currentComponent);

    return (
      <div className="w-full space-y-4">
        {/* Overall progress */}
        <div>
          {!isLoaded ? (
            <div className="w-full h-2 bg-gray-300 rounded-lg border border-gray-200 overflow-hidden">
              <div
                className="h-full bg-purple-800 transition-all duration-300 ease-in-out rounded-lg"
                style={{ width: `${progress}%` }}
              />
            </div>
          ) : null}
        </div>

        {/* Component-specific progress bars */}
        {isLoaded && (
          <div className="relative pb-2" ref={progressBarsRef}>
            <div className="flex gap-2">
              {uniqueComponents.map((component, index) => {
                const isActive = currentComponent === component;
                const isPrevious = index < currentIndex;
                const fillPercentage = componentProgress;
                const isLastComponent = index === uniqueComponents.length - 1;
                const shouldBeFilled = isPrevious ||
                  (isLastComponent && fillPercentage === 100);

                return (
                  <div
                    key={index}
                    className="relative"
                    style={{ width: barWidth }}
                  >
                    {/* Progress bar */}
                    <div
                      className={`h-2 rounded-lg border overflow-hidden transition-all duration-300
                      ${isActive ? 'ring-2 ring-blue-400 ring-offset-2' : 'border-gray-200'}
                    `}
                    >
                      <div className="absolute inset-0 bg-gray-300" />
                      <div
                        className={`absolute inset-0 transition-all duration-700 ease-in-out
                        ${shouldBeFilled
                            ? 'bg-gradient-to-r from-purple-600 to-purple-800'
                            : isActive
                          }
                      `}
                        style={{
                          width: `${shouldBeFilled ? 100 : fillPercentage}%`,
                        }}
                      />

                      {isActive && !shouldBeFilled && (
                        <div
                          className="absolute inset-0 bg-gradient-to-r from-transparent via-white to-transparent opacity-30 animate-shine"
                          style={{
                            backgroundSize: '200% 100%',
                            animation: 'shine 2s infinite'
                          }}
                        />
                      )}

                      {shouldBeFilled && (
                        <div
                          className="absolute inset-0 bg-gradient-to-r from-transparent via-purple-300 to-transparent opacity-0 animate-flash"
                        />
                      )}
                    </div>

                    {/* Preview tooltip */}
                    {previewComponent && previewComponent.name === component && (
                      <ComponentPreview
                        component={component}
                        image={getComponentImage(component)}
                        isVisible={true}
                        position={(index + 1) / totalComponents * 100}
                        containerWidth={containerWidth}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="w-full flex flex-col items-center relative">
      <div className="w-11/12 mx-auto text-center pb-1 mb-2">
        {(!isLoaded || isLoaded) && (
          <div>
            {renderProgressBars()}
            {currentComponent && (
              <div className="mt-2 flex items-center justify-center">
              </div>
            )}
          </div>
        )}
      </div>

      <div className="w-full max-w-[1200px] max-h-[540px] aspect-[2/1] relative">
        <Unity
          unityProvider={unityProvider}
          style={{
            visibility: isLoaded ? "visible" : "hidden",
            width: '100%',
            height: '100%'
          }}
        />
      </div>

      <div className="mt-2 text-lg font-semibold">
        {componentProgress === 100
          ? "Building Completed"
          : currentComponent && `Current Component: ${currentComponent}`}
      </div>
      <div className="mt-1 text-md">
        {componentProgress === 100
          ? ""
          : getComponentDescription(currentComponent)}
      </div>
    </div>
  );
};

const UnityGame = ({ onClose }) => {
  const [gameType, setGameType] = useState('car');

  const handleGameSwitch = useCallback((event) => {
    setGameType(event.target.value);
  }, []);

  return (
    <div>
      {/* Modal Overlay */}
      {gameType && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
        >
          {/* Draggable Modal Content */}
          <Draggable handle=".drag-handle">
            <div
              className="bg-white rounded-lg p-8 w-full max-w-[90%] sm:max-w-[850px] max-h-[90%] overflow-y-auto relative"
              onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
            >
              {/* Minimal Drag Handle positioned at top edge */}
              <div className="drag-handle cursor-move bg-gray-300 h-2 rounded-t-lg w-full absolute top-0 left-0"></div>

              {/* Close Button */}
              <button
                onClick={onClose}
                className="absolute top-2 right-2 text-gray-500 hover:text-red-500"
                aria-label="Close game"
              >
                <X className="h-7 w-7 stroke-2 text-gray-700 hover:text-purple-600" />
              </button>

              <div className="flex items-center mx-5 mt-6 flex-col sm:flex-row">
                <label htmlFor="gameSelect" className="mr-3 text-lg font-bold mb-2 sm:mb-0">
                  Choose Robo:
                </label>
                <select
                  id="gameSelect"
                  value={gameType || ''}
                  onChange={handleGameSwitch}
                  className="px-3 py-2 text-base border-2 border-orange-500 rounded-md w-full sm:w-auto"
                >
                  <option value="">Select a Game</option>
                  <option value="car">Build Car-Bot</option>
                  <option value="wmr">Build WMR-Bot</option>
                  <option value="arm">Build Robo-Arm</option>
                  <option value="spider">Build Robo-Spider</option>
                  <option value="humanRobo">Build Human-Robo</option>
                </select>
              </div>

              {/* Add WMR condition */}
              <div className="mt-5">
                {gameType === 'car' && <CarGame />}
                {gameType === 'arm' && <ArmGame />}
                {gameType === 'spider' && <SpiderRobotGame />}
                {gameType === 'humanRobo' && <HumanRoboGame />}
                {gameType === 'wmr' && <WMRGame />}
              </div>
            </div>
          </Draggable>
        </div>
      )}
    </div>
  );
};

export const openbuildRoboModal = () => {
  const modalRoot = document.createElement('div');
  document.body.appendChild(modalRoot);

  const handleClose = () => {
    ReactDOM.unmountComponentAtNode(modalRoot);
    document.body.removeChild(modalRoot);
  };

  ReactDOM.render(<UnityGame onClose={handleClose} />, modalRoot);
};

export default UnityGame;