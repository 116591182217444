/*
NOTE: this file only temporarily resides in scratch-gui.
Nearly identical code appears in scratch-www, and the two should
eventually be consolidated.
*/

import React, { useState } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MenuBarMenu from './menu-bar-menu.jsx';
import { MenuSection } from '../menu/menu.jsx';
import MenuItemContainer from '../../containers/menu-item.jsx';
import UserAvatar from './user-avatar.jsx';
import MyStuff from '../my-stuff/my-stuff.jsx';
import dropdownCaret from './dropdown-caret.svg';

import styles from './account-nav.css';

const AccountNavComponent = ({
    className,
    classroomId,
    isEducator,
    isOpen,
    isRtl,
    isStudent,
    menuBarMenuClassName,
    onClick,
    onClose,
    onLogOut,
    profileUrl,
    thumbnailUrl,
    username
}) => {
    const [isMyStuffOpen, setIsMyStuffOpen] = useState(false);

    const handleMyStuffClick = () => {
        setIsMyStuffOpen(true);
        onClose();
    };

    const handleLogout = () => {
        // Clear local storage
        localStorage.removeItem('accessToken');
        localStorage.removeItem('userId');
        
        // Call the passed onLogOut function
        if (onLogOut) {
            onLogOut();
        }
        
        // Redirect to home or login page
        window.location.href = '/';
    };

    return (
        <React.Fragment>
            <div
                className={classNames(
                    styles.userInfo,
                    className
                )}
                onClick={onClick} // Changed from onMouseUp to onClick
            >
                {thumbnailUrl ? (
                    <UserAvatar
                        className={styles.avatar}
                        imageUrl={thumbnailUrl}
                    />
                ) : null}
                <span className={styles.profileName}>
                    {username}
                </span>
                <div className={styles.dropdownCaretPosition}>
                    <img
                        className={styles.dropdownCaretIcon}
                        src={dropdownCaret}
                    />
                </div>
            </div>
            <MenuBarMenu
                className={menuBarMenuClassName}
                open={isOpen}
                place={isRtl ? 'right' : 'left'}
                onRequestClose={onClose}
            >
                <MenuItemContainer href={profileUrl}>
                    <FormattedMessage
                        defaultMessage="Profile"
                        description="Text to link to my user profile, in the account navigation menu"
                        id="gui.accountMenu.profile"
                    />
                </MenuItemContainer>
                <MenuItemContainer onClick={handleMyStuffClick}>
                    <FormattedMessage
                        defaultMessage="My Projects"
                        description="Text to open my projects modal"
                        id="gui.accountMenu.myProjects"
                    />
                </MenuItemContainer>
                {isEducator ? (
                    <MenuItemContainer href="/educators/classes/">
                        <FormattedMessage
                            defaultMessage="My Classes"
                            description="Text to link to my classes (if I am a teacher), in the account navigation menu"
                            id="gui.accountMenu.myClasses"
                        />
                    </MenuItemContainer>
                ) : null}
                {isStudent ? (
                    <MenuItemContainer href={`/classes/${classroomId}/`}>
                        <FormattedMessage
                            defaultMessage="My Class"
                            description="Text to link to my class (if I am a student), in the account navigation menu"
                            id="gui.accountMenu.myClass"
                        />
                    </MenuItemContainer>
                ) : null}
                <MenuItemContainer href="/accounts/settings/">
                    <FormattedMessage
                        defaultMessage="Account settings"
                        description="Text to link to my account settings, in the account navigation menu"
                        id="gui.accountMenu.accountSettings"
                    />
                </MenuItemContainer>
                <MenuSection>
                    <MenuItemContainer onClick={handleLogout}>
                        <FormattedMessage
                            defaultMessage="Sign out"
                            description="Text to link to sign out, in the account navigation menu"
                            id="gui.accountMenu.signOut"
                        />
                    </MenuItemContainer>
                </MenuSection>
            </MenuBarMenu>

            {isMyStuffOpen && (
                <MyStuff onClose={() => setIsMyStuffOpen(false)} />
            )}
        </React.Fragment>
    );
};

// Add mapStateToProps and mapDispatchToProps
const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
    onLogOut: () => {
        dispatch({ type: 'LOGOUT' }); // Add this action type to your Redux store
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountNavComponent);
