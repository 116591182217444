const BlockType = require('../../extension-support/block-type');
const ArgumentType = require('../../extension-support/argument-type');
const formatMessage = require('format-message');
const axios = require('axios');
const codeGeneration = require('../codeGeneration');

let setupCode = '';
let defineCode = '';
let loopCode = '';
let bodyCode = '';
let initializedPins = new Set(); // To track initialized pins
let definedFunctions = new Set();
let definedVariable = new Set();

class Scratch3SensorsBlocks {
    constructor(runtime) {
        this.runtime = runtime;
    }

    getInfo() {
        return {
            id: 'sensors',
            name: 'Sensors',
            color1: '#18b3d6',

            blocks: [
                {
                    opcode: 'disSensor',
                    blockType: BlockType.COMMAND,
                    text: 'Connect distance sensor',
                    func: 'disSensor' // Link to the function
                },
                {
                    opcode: 'acvtDissensor',
                    blockType: BlockType.COMMAND,
                    text: formatMessage({
                        id: 'sensor.acvtDissensor',
                        default: 'Check distane and store the value in [varname]',
                        description: 'Check the distance'
                    }),
                    arguments: {
                        varname: {
                            type: ArgumentType.STRING,
                            defaultValue: 'name_your_variable'
                        }
                    }
                },
                {
                    opcode: 'checkdistance',
                    blockType: BlockType.BOOLEAN,
                    text: formatMessage({
                        id: 'sensor.disCheck',
                        default: 'distance of [varname] [operator] [number]',
                        description: 'Check Varaible'
                    }),
                    arguments: {
                        varname: {
                            type: ArgumentType.STRING,
                            defaultValue: 'name_your_variable'
                        },
                        operator: {
                            type: ArgumentType.STRING,
                            menu: 'operatorMenu',
                            defaultValue: '=='
                        },
                        number: {
                            type: ArgumentType.STRING,
                            defaultValue: '0'
                        }
                    }
                },
                {
                    opcode: 'cntobsensor',
                    blockType: BlockType.COMMAND,
                    text: formatMessage({
                        id: 'sensor.cntabsensor',
                        default: 'Connect obstacle sensor',
                        description: 'connect to obstacle sensor'
                    })
                },
                {
                    opcode: 'acvtIrsensor',
                    blockType: BlockType.COMMAND,
                    text: formatMessage({
                        id: 'sensor.acvtIrsensor',
                        default: 'Check obstacle and store the value in [varname]',
                        description: 'Check the obstacles'
                    }),
                    arguments: {
                        varname: {
                            type: ArgumentType.STRING,
                            defaultValue: 'name_your_variable'
                        }
                    }
                },
                {
                    opcode: 'checkobs',
                    blockType: BlockType.BOOLEAN,
                    text: formatMessage({
                        id: 'sensor.checkobs',
                        default: ' presence of obstacle in [varname] == [number]',
                        description: 'Check Varaible'
                    }),
                    arguments: {
                        varname: {
                            type: ArgumentType.STRING,
                            defaultValue: 'name_your_variable'
                        },
                        number: {
                            type: ArgumentType.STRING,
                            menu: 'yesnoMenu',
                            defaultValue: 'yes'
                        }
                    }
                },
                {
                    opcode: 'cntFiresensor',
                    blockType: BlockType.COMMAND,
                    text: formatMessage({
                        id: 'sensor.cntFiresensor',
                        default: 'Connect fire sensor',
                        description: 'connect to fire sensor'
                    })
                },
                {
                    opcode: 'acvtFiresensor',
                    blockType: BlockType.COMMAND,
                    text: formatMessage({
                        id: 'sensor.acvtFirersensor',
                        default: 'Check for fire and store the value in [varname]',
                        description: 'Check for fire'
                    }),
                    arguments: {
                        varname: {
                            type: ArgumentType.STRING,
                            defaultValue: 'name_your_variable'
                        }
                    }
                },
                {
                    opcode: 'checkfire',
                    blockType: BlockType.BOOLEAN,
                    text: formatMessage({
                        id: 'sensor.checkfire',
                        default: ' presence of fire in [varname] == [number]',
                        description: 'Check Varaible'
                    }),
                    arguments: {
                        varname: {
                            type: ArgumentType.STRING,
                            defaultValue: 'name_your_variable'
                        },
                        number: {
                            type: ArgumentType.STRING,
                            menu: 'yesnoMenu',
                            defaultValue: 'yes'
                        }
                    }
                },
            ],
            menus: {
                operatorMenu: {
                    items: ['==', '>', '<', '<=', '>=', '!=']
                },
                yesnoMenu: {
                    items: ['yes','no']
                }
            }
        };
    }

    // Function to handle distance sensor initialization
    disSensor() {
        // Initialize the pins in setup code if not already done
        if (!initializedPins.has('trigdisPin')) {
            defineCode += '#define trigPin 26\n';
            setupCode += 'pinMode(trigPin, OUTPUT);\n';
            initializedPins.add('trigdisPin');
        }
        if (!initializedPins.has('echoPin')) {
            defineCode += '#define echoPin 25\n';
            setupCode += 'pinMode(echoPin, INPUT);\n';
            initializedPins.add('echoPin');
        }
        this.accumulateCode();
        // Optionally, reset the setup code for subsequent blocks
        this.resetCode();
    }

    acvtDissensor(args) {
        const varname = args.varname || 'distanceValue';

        // Add the distance check function to the bodyCode
        if (!definedFunctions.has('distance_check')) {
            bodyCode += `
                float distance_check() {
                float duration, distance;
                digitalWrite(trigPin, LOW);
                delayMicroseconds(2);
                digitalWrite(trigPin, HIGH);
                delayMicroseconds(10);
                digitalWrite(trigPin, LOW);

                duration = pulseIn(echoPin, HIGH);
                distance = (duration * 0.0343) / 2;
                delay(100);
                return distance;
                }\n`;
        }
        definedFunctions.add('distance_check')
        // Add the call to distance_check in the loop code, using the variable name provided in args
        if (!definedVariable.has(varname)) {
            loopCode += `float ${varname} = distance_check();\n`;
        }
        definedVariable.add(varname)
        this.accumulateCode();
        // Optionally, reset the setup code for subsequent blocks
        this.resetCode();
    }
    checkdistance(block){
        const variable = block.varname;
        const number = block.number;
        const operator = block.operator;
    
        // Generate the condition for the if block
        return `${variable} ${operator} ${number}`;
    }
    cntobsensor() {
        // Initialize the pins in setup code if not already done
        if (!initializedPins.has('trigirPin')) {
            defineCode += '#define ir 14\n';
            setupCode += 'pinMode(ir, INPUT);\n';
            initializedPins.add('trigirPin');
        }
        this.accumulateCode();
        // Optionally, reset the setup code for subsequent blocks
        this.resetCode();
    }
    acvtIrsensor(args) {
        const varname = args.varname;
        // Add the call to distance_check in the loop code, using the variable name provided in args
        if (!definedVariable.has(varname)) {
            defineCode += `int ${varname};`
            loopCode += `${varname} = digitalRead(ir);\n`;
        }
        definedVariable.add(varname)
        this.accumulateCode();
        // Optionally, reset the setup code for subsequent blocks
        this.resetCode();
    }
    checkobs(block) {
        const variable = block.varname;
        let number = block.number;
        if (number === "no") {
            number = 1;
        } else if (number === "yes") {
            number = 0;
        }
    
        // Generate the condition for the if block
        return `${variable} == ${number}`;
    }
    cntFiresensor() {
        // Initialize the pins in setup code if not already done
        if (!initializedPins.has('trigirPin')) {
            defineCode += '#define _fire_ 32\n';
            setupCode += 'pinMode(_fire_, INPUT);\n';
            initializedPins.add('trigirPin');
        }
        this.accumulateCode();
        // Optionally, reset the setup code for subsequent blocks
        this.resetCode();
    }
    acvtFiresensor(args) {
        const varname = args.varname;
        // Add the call to distance_check in the loop code, using the variable name provided in args
        if (!definedVariable.has(varname)) {
            defineCode += `int ${varname};`
            loopCode += `${varname} = digitalRead(_fire_);\n`;
        }
        definedVariable.add(varname)
        this.accumulateCode();
        // Optionally, reset the setup code for subsequent blocks
        this.resetCode();
    }
    checkfire(block) {
        const variable = block.varname;
        let number = block.number;
        if (number === "no") {
            number = 1;
        } else if (number === "yes") {
            number = 0;
        }
    
        // Generate the condition for the if block
        return `${variable} == ${number}`;
    }
    
    accumulateCode() {
        codeGeneration.accumulateCode(defineCode, bodyCode, setupCode, loopCode);
    }
    // Method to reset code variables if needed
    resetCode() {
        setupCode = '';
        loopCode = '';
        defineCode = '';
        bodyCode = '';
    }
    full_reset() {
        setupCode = '';
        loopCode = '';
        defineCode = '';
        bodyCode = '';
        initializedPins.clear();
        definedFunctions.clear();
        definedVariable.clear();
    }
}

module.exports = Scratch3SensorsBlocks;
