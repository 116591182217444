const ArgumentType = require('../../extension-support/argument-type');
const BlockType = require('../../extension-support/block-type');
const formatMessage = require('format-message');
const codeGeneration = require('../codeGeneration');
let setupCode = '';
let loopCode = '';
let defineCode = '';
let bodyCode = '';
let codeContext = 'setup';
const definedFunctions = new Set();
const definedServos = new Set();

class Scratch3RoboticSpiderBlocks {
  constructor(runtime) {
    this.runtime = runtime;
    codeContext = localStorage.getItem('codeContext');
  }

  getInfo() {
    return {
      id: 'spider',
      name: formatMessage({
        id: 'spider.categoryName',
        default: 'Robotic Spider',
        description: 'Extension to control a robotic spider based on Arduino.'
      }),
      blocks: [
        {
          opcode: 'connectToSpider',
          blockType: BlockType.COMMAND,
          text: formatMessage({
            id: 'spider.connectToSpider',
            default: 'Connect to Robotic Spider',
            description: 'Connnect to robotic spider.'
          }),
          func: 'connectToSpider'
        },
        {
          opcode: 'initializeServo',
          blockType: BlockType.COMMAND,
          text: formatMessage({
            id: 'spider.initializeServo',
            default: 'Define intial values',
            description: 'Initialize servos in Spider.'
          }),
          func: 'initializeServo'
        },
        {
          opcode: 'setServo',
          blockType: BlockType.COMMAND,
          text: formatMessage({
            id: 'spider.setServo',
            default: 'Connect the legs',
            description: 'Attach a spider part to the specified pin.'
          }),
          func: 'setServo'
        },
        {
          opcode: 'moveleg',
          blockType: BlockType.COMMAND,
          text: formatMessage({
            id: 'spider.moveleg',
            default: 'Move coxa by [degree1] fimure by [degree2] tibia by [degree3] of [leg_num]',
            description: 'Attach a servo to the specified pin.'
          }),
          arguments: {
            degree1: {
              type: ArgumentType.ANGLE,
              defaultValue: 0
            },
            degree2: {
              type: ArgumentType.ANGLE,
              defaultValue: 0
            },
            degree3: {
              type: ArgumentType.ANGLE,
              defaultValue: 0
            },
            leg_num: {
              type: ArgumentType.STRING,
              menu: 'legnumMenu',
              defaultValue: 'leg 1'
            }
          },
        },
        {
          opcode: 'moveAxis',
          blockType: BlockType.COMMAND,
          text: formatMessage({
            id: 'spider.moveAxis',
            default: 'Move spider in [axis] axis',
            description: 'Move the axis by the specified degree.'
          }),
          arguments: {
            axis: {
              type: ArgumentType.STRING,
              menu: 'axisMenu',
              defaultValue: 'X'
            }
          },
        },
        {
          opcode: 'moveForward',
          blockType: BlockType.COMMAND,
          text: formatMessage({
            id: 'spider.moveForward',
            default: 'Move spider forward by [steps] steps',
            description: 'Move the spider forward.'
          }),
          arguments: {
            steps: {
              type: ArgumentType.STRING,
              defaultValue: 1
            },
            // direction: {
            //   type: ArgumentType.STRING,
            //   menu: 'directionMenu',
            //   defaultValue: 'forward'
            // }
          },
        },
        {
          opcode: 'moveLeftRight',
          blockType: BlockType.COMMAND,
          text: formatMessage({
            id: 'spider.moveLeftRight',
            default: 'Move spider [side] by [steps] steps',
            description: 'Move the spider left or right.'
          }),
          arguments: {
            steps: {
              type: ArgumentType.STRING,
              defaultValue: 1
            },
            side: {
              type: ArgumentType.STRING,
              menu: 'sideMenu',
              defaultValue: 'left'
            }
          },
        },
        {
          opcode: 'moveToHome',
          blockType: BlockType.COMMAND,
          text: formatMessage({
            id: 'spider.moveToHome',
            default: 'Move spider to home position',
            description: 'moves the spider to home position.'
          }),
          func: 'moveToHome'
        },
      ],
      menus: {
        partsMenu: {
          items: ['cocsa', 'fimure', 'tibia']
        },
        pinMenu: {
          items: ['2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13']
        },
        legnumMenu: {
          items: ['leg 1', 'leg 2', 'leg 3', 'leg 4']
        },
        axisMenu: {
          items: ['X', 'Y', 'Z']
        },
        directionMenu: {
          items: ['forward', 'backward']
        },
        sideMenu: {
          items: ['left', 'right']
        },
        chanlenumMenu: {
          items: ['0', '1', '2', '4', '5', '6', '8', '9', '10', '12', '13', '14']
        }
      }
    };
  }


  resetCode() {
    setupCode = '';
    loopCode = '';
    defineCode = '';
    bodyCode = '';
  }

  full_reset() {
    setupCode = '';
    loopCode = '';
    defineCode = '';
    bodyCode = '';
    definedServos.clear();
    definedFunctions.clear();
    codeGeneration.resetCode();
    console.log('Code has been reset.');
  }

  accumulateCode() {
    codeGeneration.accumulateCode(defineCode, bodyCode, setupCode, loopCode);
  }

  connectToSpider() {
    if (!definedServos.has('initcontroller')) {
      setupCode += `pwm.begin();
  pwm.setPWMFreq(60);`
      definedServos.add('initcontroller')
    }
    this.accumulateCode();
    this.resetCode();
  }

  initializeServo() {
    if (!definedServos.has('initval')) {
      defineCode += `#define SERVOMIN_puls 170
#define SERVOMAX_puls 600
#define SERVOMIN_angle 0
#define SERVOMAX_angle 180`;
      definedServos.add('initval');
    }
    this.accumulateCode();
    this.resetCode();
  }

  setServo() {
    // Create the define statement
    const codetoadd = `#define leg1_cocsa 0
#define leg1_fimure 1
#define leg1_tibia 2

#define leg2_cocsa 4
#define leg2_fimure 5
#define leg2_tibia 6

#define leg3_cocsa 8
#define leg3_fimure 9
#define leg3_tibia 10

#define leg4_cocsa 12
#define leg4_fimure 13
#define leg4_tibia 14

uint16_t angleToPulse(int angle) {
  return map(angle, SERVOMIN_angle, SERVOMAX_angle, SERVOMIN_puls, SERVOMAX_puls);
}`;

    if (!definedServos.has('legconnect')) {
      defineCode += codetoadd;

      // Add to the set of defined servos to track what's been configured
      definedServos.add('legconnect');
    }

    this.accumulateCode();
    this.resetCode();
  }
  moveleg(args) {

  }
  moveAxis(args) {
    const axis = args.axis;
    console.log(axis);
  }
  moveForward(args) {
    const steps = args.steps;
    const codetoadd = `void init_for(){
  pwm.setPWM(leg1_cocsa, 0, angleToPulse(90));
  pwm.setPWM(leg2_cocsa, 0, angleToPulse(80));
  }


void move_f(){
    pwm.setPWM(leg1_cocsa, 0, angleToPulse(120));
    pwm.setPWM(leg1_fimure, 0, angleToPulse(80));
    delay(100);
    pwm.setPWM(leg1_fimure, 0, angleToPulse(120));
    pwm.setPWM(leg1_tibia, 0, angleToPulse(85));
    delay(100);
    pwm.setPWM(leg1_cocsa, 0, angleToPulse(105));
    pwm.setPWM(leg1_fimure, 0, angleToPulse(100));
    pwm.setPWM(leg1_tibia, 0, angleToPulse(45));
    
    pwm.setPWM(leg2_cocsa, 0, angleToPulse(65));
    
    pwm.setPWM(leg3_cocsa, 0, angleToPulse(135));
    pwm.setPWM(leg3_fimure, 0, angleToPulse(70));
    pwm.setPWM(leg3_tibia, 0, angleToPulse(75));
    
    pwm.setPWM(leg4_cocsa, 0, angleToPulse(85));
    delay(100);
    pwm.setPWM(leg3_cocsa, 0, angleToPulse(100));
    pwm.setPWM(leg3_fimure, 0, angleToPulse(110));
    pwm.setPWM(leg3_tibia, 0, angleToPulse(45));
    delay(100);
    pwm.setPWM(leg3_fimure, 0, angleToPulse(90));
    
    pwm.setPWM(leg4_cocsa, 0, angleToPulse(55));
    pwm.setPWM(leg4_fimure, 0, angleToPulse(90));
    pwm.setPWM(leg4_tibia, 0, angleToPulse(75));
    delay(100);
    pwm.setPWM(leg4_fimure, 0, angleToPulse(70));
    delay(100);
    pwm.setPWM(leg1_cocsa, 0, angleToPulse(90));
    
    pwm.setPWM(leg2_cocsa, 0, angleToPulse(50));
    pwm.setPWM(leg2_fimure, 0, angleToPulse(110));
    pwm.setPWM(leg2_tibia, 0, angleToPulse(75));
    
    pwm.setPWM(leg3_cocsa, 0, angleToPulse(120));
    
    pwm.setPWM(leg4_cocsa, 0, angleToPulse(70));
    pwm.setPWM(leg4_fimure, 0, angleToPulse(70));
    pwm.setPWM(leg4_tibia, 0, angleToPulse(45));
    delay(100);
    pwm.setPWM(leg2_cocsa, 0, angleToPulse(80));
    pwm.setPWM(leg2_fimure, 0, angleToPulse(80));
    delay(100);
    pwm.setPWM(leg2_tibia, 0, angleToPulse(45));
    pwm.setPWM(leg2_fimure, 0, angleToPulse(100));
  }
    
 void move_for_steps(int steps) {
  init_for();
    delay(100);
    for (int i = 0; i < steps; i++) {
        move_f(); // Execute one full step forward
        delay(100); // Delay between steps to ensure smooth motion
    }
}`
    if (!definedFunctions.has('forward')) {
      bodyCode += codetoadd;
      definedFunctions.add('forward')
    }


    setupCode += ` move_for_steps(${steps});`;
    this.accumulateCode();
    this.resetCode();
  }
  moveLeftRight(args) {
    const steps = args.steps;
    const side = args.side;
    console.log(steps, side);
  }

  moveToHome() {
    const codetoadd = `void ho_me(){
  pwm.setPWM(leg1_cocsa, 0, angleToPulse(120));
  pwm.setPWM(leg1_fimure, 0, angleToPulse(100));
  pwm.setPWM(leg1_tibia, 0, angleToPulse(45));
  
  pwm.setPWM(leg2_cocsa, 0, angleToPulse(70));
  pwm.setPWM(leg2_fimure, 0, angleToPulse(100));
  pwm.setPWM(leg2_tibia, 0, angleToPulse(45));
  
  pwm.setPWM(leg3_cocsa, 0, angleToPulse(120));
  pwm.setPWM(leg3_fimure, 0, angleToPulse(90));
  pwm.setPWM(leg3_tibia, 0, angleToPulse(45));
  
  pwm.setPWM(leg4_cocsa, 0, angleToPulse(70));
  pwm.setPWM(leg4_fimure, 0, angleToPulse(70));
  pwm.setPWM(leg4_tibia, 0, angleToPulse(45));
  }`;

    if (!definedFunctions.has('ho_me')) {
      bodyCode += codetoadd;
      definedFunctions.add('ho_me');
    }
    if (localStorage.getItem('codeContext') === 'setup') {
      setupCode += ` ho_me();`;
    } else if (localStorage.getItem('codeContext') === 'loop') {
      loopCode += ` ho_me();`;
    }
    this.accumulateCode();
    this.resetCode();
  }
}

module.exports = Scratch3RoboticSpiderBlocks;