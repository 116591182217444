const ArgumentType = require('../../extension-support/argument-type');
const BlockType = require('../../extension-support/block-type');
const formatMessage = require('format-message');
const axios = require('axios');
const codeGeneration = require('../codeGeneration');

let setupCode = '';
let defineCode = '';
let loopCode = '';
let bodyCode = '';
let initializedPins = new Set(); // To track initialized pins
let definedFunctions = new Set();
let definedVariable = new Set();

class Scratch3ActuatorsBlocks {
    constructor(runtime) {
        this.runtime = runtime;
    }

    getInfo() {
        return {
            id: 'actuators',
            color1: '#cd950c',
            name: formatMessage({
                id: 'actuators.categoryName',
                default: 'Actuators',
                description: 'Actuators extension category'
            }),
            blocks: [
                {
                    opcode: 'cntBuzzer',
                    blockType: BlockType.COMMAND,
                    text: formatMessage({
                        id: 'actuators.cntBuzzer',
                        default: 'Connect buzzer',
                        description: 'connect to buzzer'
                    })
                },
                {
                    opcode: 'buzzerState',
                    blockType: BlockType.COMMAND,
                    text: formatMessage({
                        id: 'actuators.buzzerState',
                        default: 'Turn [state] the buzzer',
                        description: 'set buzzer state'
                    }),
                    arguments: {
                        state: {
                            type: ArgumentType.STRING,
                            menu: 'stateMenu',
                            defaultValue: 'on'
                        },
                    }
                },
                {
                    opcode: 'contMotor',
                    blockType: BlockType.COMMAND,
                    text: formatMessage({
                        id: 'actuators.defMotor',
                        default: 'Connect motor [motor]',
                        description: 'connect Variable'
                    }),
                    arguments: {
                        motor: {
                            type: ArgumentType.STRING,
                            menu: 'motorMenu',
                            defaultValue: '1'
                        }
                    }
                },
                {
                    opcode: 'rotateMotor',
                    blockType: BlockType.COMMAND,
                    text: formatMessage({
                        id: 'actuators.rotateMotor',
                        default: 'rotate motor [motor] in [direction]',
                        description: 'rotate motor'
                    }),
                    arguments: {
                        motor: {
                            type: ArgumentType.STRING,
                            menu: 'motorMenu',
                            defaultValue: '1'
                        },
                        direction: {
                            type: ArgumentType.STRING,
                            menu: 'directionMenu',
                            defaultvalue: 'clockwise'
                        }
                    }
                },
                {
                    opcode: 'atchServo',
                    blockType: BlockType.COMMAND,
                    text: formatMessage({
                        id: 'actuators.atchServo',
                        default: 'attach the servo',
                        description: 'connect servo'
                    })
                },
                {
                    opcode: 'rotateServo',
                    blockType: BlockType.COMMAND,
                    text: formatMessage({
                        id: 'actuators.rotateServo',
                        default: 'rotate servo motor by [degree] degree',
                        description: 'rotate motor'
                    }),
                    arguments: {
                        degree: {
                            type: ArgumentType.ANGLE,
                            defaultvalue: '0'
                        }
                    }
                },
            ],
            menus: {
                stateMenu: {
                    items: ['on', 'off']
                },
                motorMenu: {
                    items: ['1', '2','3','4']
                },
                directionMenu: {
                    items: ['clockwise', 'anti_clockwise']
                }
            }
        };
    }
    buzzerState(args) {
        let state = args.state
        if (state === 'on') {
            state = 'HIGH'
        }
        else {
            state = 'LOW'
        }
        if (localStorage.getItem('codeContext') === 'setup') {
            setupCode += `digitalWrite(buz,${state});`;
        }
        else if (localStorage.getItem('codeContext') === 'loop') {
            loopCode += `digitalWrite(buz,${state});`;
        }
        this.accumulateCode();
        this.resetCode();
    }
    cntBuzzer() {
        // Initialize the pins in setup code if not already done
        if (!initializedPins.has('trigirPin')) {
            defineCode += '#define buz 13\n';
            setupCode += 'pinMode(buz, OUTPUT);\n';
            initializedPins.add('trigirPin');
        }
        this.accumulateCode();
        // Optionally, reset the setup code for subsequent blocks
        this.resetCode();
    }
    contMotor(args) {
        const motor = args.motor
        if (motor === '1') {
            if (!definedVariable.has('motor1')) {
                defineCode += `#define m1a 16
                #define m1b 17`;
                setupCode += `pinMode(m1a, OUTPUT);
                pinMode(m1b, OUTPUT);`;
            }
            definedVariable.add('motor1');
        }
        if (motor === '2') {
            if (!definedVariable.has('motor2')) {
                defineCode += `#define m2a 19
                #define m2b 18`;
                setupCode += `pinMode(m2a, OUTPUT);
                pinMode(m2b, OUTPUT);`;
            }
            definedVariable.add('motor2');
        }
        if (motor === '3') {
            if (!definedVariable.has('motor3')) {
                defineCode += `#define m3a 0
                #define m3b 2`;
                setupCode += `pinMode(m3a, OUTPUT);
                pinMode(m3b, OUTPUT);`;
            }
            definedVariable.add('motor3');
        }
        if (motor === '4') {
            if (!definedVariable.has('motor4')) {
                defineCode += `#define m4a 5
                #define m4b 4`;
                setupCode += `pinMode(m4a, OUTPUT);
                pinMode(m4b, OUTPUT);`;
            }
            definedVariable.add('motor4');
        }
        if (!definedVariable.has('enablemotor')) {
            defineCode += `\n#define e1 27
            #define e2 23`;
            setupCode += `\npinMode(e1, OUTPUT);
            pinMode(e2, OUTPUT);`;
        }
        definedVariable.add('enablemotor');
        this.accumulateCode();
        this.resetCode();
    }
    rotateMotor(args) {
        const motor = args.motor;
        const direction = args.direction;
        let codeToAdd = '';
        let motorPinA = '';
        let motorPinB = '';

        // Define the function name based on motor and direction
        const functionName = `${direction}_${motor}`;
        if (motor === '1'){
            motorPinA ='m1a';
            motorPinB ='m1b';
        }
        else if (motor === '2'){
            motorPinA ='m2a';
            motorPinB ='m2b';
        }
        else if (motor === '3'){
            motorPinA ='m3a';
            motorPinB ='m3b';
        }
        else {
            motorPinA ='m4a';
            motorPinB ='m4b';
        }

        const pinStateA = direction === 'clockwise' ? 'HIGH' : 'LOW';
        const pinStateB = direction === 'clockwise' ? 'LOW' : 'HIGH';

        // Check if the function has already been defined
        if (!definedFunctions.has(functionName)) {
            bodyCode += `
            void ${functionName}() {
                digitalWrite(${motorPinA}, ${pinStateA});
                digitalWrite(${motorPinB}, ${pinStateB});
            }\n`;
            definedFunctions.add(functionName);
        }

        // Add the function call to the relevant code section based on context
        codeToAdd = `${functionName}();\n`;

        if (localStorage.getItem('codeContext') === 'setup') {
            setupCode += codeToAdd;
        } else if (localStorage.getItem('codeContext') === 'loop') {
            loopCode += codeToAdd;
        }

        this.accumulateCode();
        this.resetCode();
    }
    atchServo() {
        if (!definedVariable.has('setservo')) {
            defineCode += `#include <Servo.h>
            Servo servo;`;
        }
        definedVariable.add('setservo');
        if (!initializedPins.has('servopin')) {
            setupCode += ` servo.attach(12);`;
        }
        initializedPins.add('servopin');
        this.accumulateCode();
        this.resetCode();
    }
    rotateServo(args) {
        const degree = args.degree;
        const MIN_ANGLE = 0;    // Define your minimum angle
        const MAX_ANGLE = 180;
        let codeToAdd = '';
        if (degree < MIN_ANGLE || degree > MAX_ANGLE) {
            // console.error;
            return (`Enter the angle between ${MIN_ANGLE} and ${MAX_ANGLE}`); // Exit the function if the angle is out of range
          }
        // Add the function call to the relevant code section based on context
        codeToAdd = `servo.write(${degree});\n`;

        if (localStorage.getItem('codeContext') === 'setup') {
            setupCode += codeToAdd;
        } else if (localStorage.getItem('codeContext') === 'loop') {
            loopCode += codeToAdd;
        }

        this.accumulateCode();
        this.resetCode();
    }
    accumulateCode() {
        codeGeneration.accumulateCode(defineCode, bodyCode, setupCode, loopCode);
    }
    // Method to reset code variables if needed
    resetCode() {
        setupCode = '';
        loopCode = '';
        defineCode = '';
        bodyCode = '';
    }
    full_reset() {
        setupCode = '';
        loopCode = '';
        defineCode = '';
        bodyCode = '';
        initializedPins.clear();
        definedFunctions.clear();
        definedVariable.clear();
    }
}

module.exports = Scratch3ActuatorsBlocks;
