const ArgumentType = require('../../extension-support/argument-type');
const BlockType = require('../../extension-support/block-type');
const formatMessage = require('format-message');
const codeGeneration = require('../codeGeneration');

let setupCode = '';
let loopCode = '';
let defineCode = '';
let bodyCode = '';
let codeContext = 'setup';
let definedMotors = new Set();
let definedFunctions = new Set();

class Scratch3ArduinoRoboticCarBlocks {
    constructor(runtime) {
        this.runtime = runtime;
        codeContext = localStorage.getItem('codeContext');
    }

    getInfo() {
        return {
            id: 'car',
            color1: '#97ad17',
            name: formatMessage({
                id: 'car.categoryName',
                default: 'Robotic Car',
                description: 'Arduino Robotic Car extension category'
            }),
            blocks: [
                {
                    opcode: 'defineMotor',
                    blockType: BlockType.COMMAND,
                    text: formatMessage({
                        id: 'car.defineMotor',
                        default: 'define [motor]',
                        description: 'Define a motor with two pins and configure them as input or output'
                    }),
                    arguments: {
                        motor: {
                            type: ArgumentType.STRING,
                            menu: 'motorMenu',
                            defaultValue: 'motor1'
                        },
                    }
                },
                {
                    opcode: 'moveDirection',
                    blockType: BlockType.COMMAND,
                    text: formatMessage({
                        id: 'car.moveDirection',
                        default: 'move towards [direction] direction for [time] milliseconds with [speed] speed',
                        description: 'Move the robotic car in the specified direction for the given time'
                    }),
                    arguments: {
                        direction: {
                            type: ArgumentType.STRING,
                            menu: 'directionMenu',
                            defaultValue: 'forward'
                        },
                        time: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 1000
                        },
                        speed: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 150
                        }
                    }
                },
                {
                    opcode: 'turnDirection',
                    blockType: BlockType.COMMAND,
                    text: formatMessage({
                        id: 'car.turnDirection',
                        default: 'turn towards [direction] direction for [time] milliseconds with [speed] speed',
                        description: 'Turn the robotic car in the specified direction for the given time'
                    }),
                    arguments: {
                        direction: {
                            type: ArgumentType.STRING,
                            menu: 'turnDirectionMenu',
                            defaultValue: 'left'
                        },
                        time: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 1000
                        },
                        speed: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 150
                        }
                    }
                },
                {
                    opcode: 'rotateAngle',
                    blockType: BlockType.COMMAND,
                    text: formatMessage({
                        id: 'car.clockanticlock',
                        default: 'Rotate the car in [clockanticlock] direction by [degree] degrees',
                        description: 'Turn the robotic car in the specified direction for the given time'
                    }),
                    arguments: {
                        clockanticlock: {
                            type: ArgumentType.STRING,
                            menu: 'clockanticlockMenu',
                            defaultValue: 'clockwise'
                        },
                        degree: {
                            type: ArgumentType.ANGLE,
                            defaultValue: 0
                        }
                    }
                },
                {
                    opcode: 'drawCircle',
                    blockType: BlockType.COMMAND,
                    text: formatMessage({
                        id: 'car.drawCircle',
                        default: 'draw a [size] size circle',
                        description: 'Turn the robotic car in the specified direction for the given time'
                    }),
                    arguments: {
                        size: {
                            type: ArgumentType.STRING,
                            menu: 'sizeMenu',
                            defaultValue: 'small'
                        }
                    }
                },
                {
                    opcode: 'stopMovement',
                    blockType: BlockType.COMMAND,
                    text: formatMessage({
                        id: 'car.stopMovement',
                        default: 'Stop moving for [time] milliseconds',
                        description: 'Stop all motors for the specified time'
                    }),
                    arguments: {
                        time: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 1000
                        }
                    }
                },
                {
                    opcode: 'moveTowardsDirection',
                    blockType: BlockType.COMMAND,
                    text: 'Move towards [direction] direction with [speed] speed',
                    arguments: {
                        direction: {
                            type: ArgumentType.STRING,
                            menu: 'directionMenu',
                            defaultValue: 'forward'
                        },
                        speed: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 150
                        }
                    }
                },
                {
                    opcode: 'turnTowardsDirection',
                    blockType: BlockType.COMMAND,
                    text: 'Turn towards [direction] direction with [speed] speed',
                    arguments: {
                        direction: {
                            type: ArgumentType.STRING,
                            menu: 'turnDirectionMenu',
                            defaultValue: 'left'
                        },
                        speed: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 150
                        }
                    }
                },
                {
                    opcode: 'stopMoving',
                    blockType: BlockType.COMMAND,
                    text: formatMessage({
                        id: 'car.stopMoving',
                        default: 'stop moving',
                        description: 'Stop all motors'
                    })
                }
            ],
            menus: {
                digitalPortMenu: {
                    items: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13']
                },
                motorMenu: {
                    items: ['motor1', 'motor2', 'motor3', 'motor4']
                },
                directionMenu: {
                    items: ['forward', 'backward']
                },
                turnDirectionMenu: {
                    items: ['left', 'right']
                },
                clockanticlockMenu: {
                    items: ['clockwise', 'anticlockwise']
                },
                sizeMenu: {
                    items: ['small', 'medium', 'large']
                }
            }
        };
    }
    accumulateCode() {
        codeGeneration.accumulateCode(defineCode, bodyCode, setupCode, loopCode);
    }
    resetCode() {
        setupCode = '';
        loopCode = '';
        defineCode = '';
        bodyCode = '';
    }
    full_reset() {
        setupCode = '';
        loopCode = '';
        defineCode = '';
        bodyCode = '';
        definedMotors.clear();
        definedFunctions.clear();
    }
    defineMotor(args) {
        const motor = args.motor;

        if (definedMotors.has(motor)) {
            return;
        }

        let motorPrefix;
        switch (motor) {
            case 'motor1':
                motorPrefix = 'M1';
                break;
            case 'motor2':
                motorPrefix = 'M2';
                break;
            case 'motor3':
                motorPrefix = 'M3';
                break;
            case 'motor4':
                motorPrefix = 'M4';
                break;
            default:
                console.error('Invalid motor selection.');
                return;
        }
        definedMotors.add(motor);
        this.accumulateCode();
        this.resetCode();
    }
    async moveDirection(args) {
        const direction = args.direction;
        const time = args.time;
        const speed = args.speed;
        let codeToAdd = '';

        if (direction === 'forward') {
            if (!definedFunctions.has('movefor_time')) {
                bodyCode += `
                void movefor_time(int time, int speed) {
                    ${definedMotors.has('motor1') ? 'digitalWrite(m1a, HIGH);digitalWrite(m1b,LOW);' : ''}
                    ${definedMotors.has('motor2') ? 'digitalWrite(m2a, HIGH);digitalWrite(m2b,LOW);' : ''}
                    ${definedMotors.has('motor3') ? 'digitalWrite(m3a, HIGH);digitalWrite(m3b,LOW);' : ''}
                    ${definedMotors.has('motor4') ? 'digitalWrite(m4a, HIGH);digitalWrite(m4b,LOW);' : ''}
                    analogWrite(e1,speed);
  analogWrite(e2,speed);
                    delay(time);
                     analogWrite(e1,0);
  analogWrite(e2,0);
  digitalWrite(m1a, LOW);digitalWrite(m1b,LOW);
                }\n`;
                definedFunctions.add('movefor_time');
            }
            codeToAdd = `movefor_time(${time},${speed});\n`;

            if (typeof window.moveForward === 'function') {
                for (let i = 0; i < time / 100; i++) {
                    window.moveForward();
                    await new Promise(resolve => setTimeout(resolve, 100)); // Add delay of 100ms
                }
                window.stopForward(); // Stop moving after the loop completes
            } else {
                console.error('moveForward function is not available');
            }
        } else if (direction === 'backward') {
            if (!definedFunctions.has('moveback_time')) {
                bodyCode += `
                void moveback_time(int time, int speed) {
                    ${definedMotors.has('motor1') ? 'digitalWrite(m1a, LOW);digitalWrite(m1b,HIGH);' : ''}
                    ${definedMotors.has('motor2') ? 'digitalWrite(m2a, LOW);digitalWrite(m2b,HIGH);' : ''}
                    ${definedMotors.has('motor3') ? ' digitalWrite(m3a, LOW);digitalWrite(m3b,HIGH);' : ''}
                    ${definedMotors.has('motor4') ? 'digitalWrite(m4a, LOW);digitalWrite(m4b,HIGH);' : ''}
                   analogWrite(e1,speed);
  analogWrite(e2,speed);
                    delay(time);
                     analogWrite(e1,0);
  analogWrite(e2,0);
  digitalWrite(m1a, LOW);digitalWrite(m1b,LOW);
                }\n`;
                definedFunctions.add('moveback_time');
            }
            codeToAdd = `moveback_time(${time},${speed});\n`;

            if (typeof window.moveBackward === 'function') {
                for (let i = 0; i < time / 100; i++) {
                    window.moveBackward();
                    await new Promise(resolve => setTimeout(resolve, 100)); // Add delay of 100ms
                }
                window.stopBackward(); // Stop moving after the loop completes
            } else {
                console.error('moveBackward function is not available');
            }
        }

        if (localStorage.getItem('codeContext') === 'setup') {
            setupCode += codeToAdd;
        } else if (localStorage.getItem('codeContext') === 'loop') {
            loopCode += codeToAdd;
        }
        this.accumulateCode();
        this.resetCode();
    }
    async turnDirection(args) {
        const direction = args.direction;
        const time = args.time;
        const speed = args.speed;
        let codeToAdd = '';

        if (direction === 'left') {
            if (!definedFunctions.has('moveleft_time')) {
            bodyCode += `
            void moveleft_time(int time, int speed) {
                ${definedMotors.has('motor1') ? 'digitalWrite(m1a, HIGH);digitalWrite(m1b,LOW);' : ''}
                ${definedMotors.has('motor2') ? 'digitalWrite(m2a, HIGH);digitalWrite(m2b,LOW);' : ''}
                ${definedMotors.has('motor3') ? 'digitalWrite(m3a, LOW);digitalWrite(m3b,HIGH);' : ''}
                ${definedMotors.has('motor4') ? 'digitalWrite(m4a, LOW);digitalWrite(m4b,HIGH);' : ''}
                analogWrite(e1,speed);
  analogWrite(e2,speed);
                    delay(time);
                     analogWrite(e1,0);
  analogWrite(e2,0);
  digitalWrite(m1a, LOW);digitalWrite(m1b,LOW);
            }\n`;
            definedFunctions.add('moveleft_time');
            }
            codeToAdd = `moveleft_time(${time},${speed});\n`;

            if (typeof window.turnLeft === 'function') {
                for (let i = 0; i < time / 100; i++) {
                    window.turnLeft();
                    await new Promise(resolve => setTimeout(resolve, 100)); // Add delay of 100ms
                }
                window.stopLeft(); // Stop turning after the loop completes
            } else {
                console.error('turnLeft function is not available');
            }
        } else if (direction === 'right') {
            if (!definedFunctions.has('moveright_time')) {
            bodyCode += `
            void moveright_time(int time, int speed) {
                ${definedMotors.has('motor1') ? ' digitalWrite(m1a, LOW);digitalWrite(m1b,HIGH);' : ''}
                ${definedMotors.has('motor2') ? 'digitalWrite(m2a, LOW);digitalWrite(m2b,HIGH);' : ''}
                ${definedMotors.has('motor3') ? 'digitalWrite(m3a, HIGH);digitalWrite(m3b,LOW);' : ''}
                ${definedMotors.has('motor4') ? 'digitalWrite(m4a, HIGH);digitalWrite(m4b,LOW);' : ''}
                analogWrite(e1,speed);
  analogWrite(e2,speed);
                    delay(time);
                     analogWrite(e1,0);
  analogWrite(e2,0);
  digitalWrite(m1a, LOW);digitalWrite(m1b,LOW);
            }\n`;
            definedFunctions.add('moveright_time');
            }
            codeToAdd = `moveright_time(${time},${speed});\n`;

            if (typeof window.turnRight === 'function') {
                for (let i = 0; i < time / 100; i++) {
                    window.turnRight();
                    await new Promise(resolve => setTimeout(resolve, 100)); // Add delay of 100ms
                }
                window.stopRight(); // Stop turning after the loop completes
            } else {
                console.error('turnRight function is not available');
            }
        }

        if (localStorage.getItem('codeContext') === 'setup') {
            setupCode += codeToAdd;
        } else if (localStorage.getItem('codeContext') === 'loop') {
            loopCode += codeToAdd;
        }
        this.accumulateCode();
        this.resetCode();
    }
    async stopMovement(args) {
        const time = args.time;
        if (!definedFunctions.has('movestop_time')) {
            bodyCode += `
            void movestop_time(int time) {
                ${definedMotors.has('motor1') ? 'digitalWrite(m1a, LOW);digitalWrite(m1b,HIGH);' : ''}
                ${definedMotors.has('motor2') ? 'digitalWrite(m2a, LOW);digitalWrite(m2b,HIGH);' : ''}
                ${definedMotors.has('motor3') ? 'digitalWrite(m3a, HIGH);digitalWrite(m3b,LOW);' : ''}
                ${definedMotors.has('motor4') ? 'digitalWrite(m4a, HIGH);digitalWrite(m4b,LOW);' : ''}
                analogWrite(e1,0);
  analogWrite(e2,0);
                delay(time_);
            }\n`;
            definedFunctions.add('movestop_time');
        }
        if (typeof window.stopForward === 'function' || typeof window.stopBackward === 'function' || typeof window.stopRight === 'function' || typeof window.stopLeft === 'function') {
            for (let i = 0; i < time / 100; i++) {
                window.stopForward();
                window.stopBackward();
                window.stopRight();
                window.stopLeft();
                await new Promise(resolve => setTimeout(resolve, 100)); // Add delay of 100ms
            }
            // Stop moving after the loop completes
        } else {
            console.error('moveBackward function is not available');
        }
        const codeToAdd = `movestop_time(${time});\n`;
        if (localStorage.getItem('codeContext') === 'setup') {
            setupCode += codeToAdd;
        } else if (localStorage.getItem('codeContext') === 'loop') {
            loopCode += codeToAdd;
        }
        this.accumulateCode();
        this.resetCode();
    }
    moveTowardsDirection(args) {
        const direction = args.direction;
        const speed = args.speed;
        let codeToAdd = '';
        if (direction === 'forward') {
            if (!definedFunctions.has('movefor')) {
                bodyCode += `
                void movefor(int speed) {
                    ${definedMotors.has('motor1') ? 'digitalWrite(m1a, HIGH); digitalWrite(m1b,LOW);' : ''}
                    ${definedMotors.has('motor2') ? 'digitalWrite(m2a, HIGH); digitalWrite(m2b,LOW);' : ''}
                    ${definedMotors.has('motor3') ? 'digitalWrite(m3a, HIGH); digitalWrite(m3b,LOW);' : ''}
                    ${definedMotors.has('motor4') ? 'digitalWrite(m4a, HIGH); digitalWrite(m4b,LOW);' : ''}
                    analogWrite(e1,speed);
  analogWrite(e2,speed);
                }\n`;
                definedFunctions.add('movefor');
            }
            if (typeof window.moveForward === 'function') {
                window.moveForward();
            } else {
                console.error('moveForward function is not available');
            }
            codeToAdd = `movefor(${speed});\n`;
        } else if (direction === 'backward') {
            if (!definedFunctions.has('moveback')) {
                bodyCode += `
                void moveback(int speed) {
                    ${definedMotors.has('motor1') ? 'digitalWrite(m1a, LOW); digitalWrite(m1b,HIGH);' : ''}
                    ${definedMotors.has('motor2') ? 'digitalWrite(m2a, LOW); digitalWrite(m2b,HIGH);' : ''}
                    ${definedMotors.has('motor3') ? 'digitalWrite(m3a, LOW); digitalWrite(m3b,HIGH);' : ''}
                    ${definedMotors.has('motor4') ? 'digitalWrite(m4a, LOW); digitalWrite(m4b,HIGH);' : ''}
                    analogWrite(e1,speed);
  analogWrite(e2,speed);
                }\n`;
                definedFunctions.add('moveback');
            }
            codeToAdd = `moveback(${speed});\n`;
            if (typeof window.moveBackward === 'function') {
                window.moveBackward();
            } else {
                console.error('moveBackward function is not available');
            }
        }

        if (localStorage.getItem('codeContext') === 'setup') {
            setupCode += codeToAdd;
        } else if (localStorage.getItem('codeContext') === 'loop') {
            loopCode += codeToAdd;
        }
        this.accumulateCode();
        this.resetCode();
    }
    turnTowardsDirection(args) {
        const direction = args.direction;
        const speed = args.speed;
        let codeToAdd = '';

        if (direction === 'left') {
            if (!definedFunctions.has('moveleft')) {
                bodyCode += `
                void moveleft(int speed) {
                    ${definedMotors.has('motor1') ? 'digitalWrite(m1a, HIGH); digitalWrite(m1b,LOW);' : ''}
                    ${definedMotors.has('motor2') ? 'digitalWrite(m2a, HIGH); digitalWrite(m2b,LOW);' : ''}
                    ${definedMotors.has('motor3') ? 'digitalWrite(m3a, LOW); digitalWrite(m3b,HIGH);' : ''}
                    ${definedMotors.has('motor4') ? 'digitalWrite(m4a, LOW); digitalWrite(m4b,HIGH);' : ''}
                    analogWrite(e1,speed);
  analogWrite(e2,speed);
                }\n`;
                definedFunctions.add('moveleft');
            }
            if (typeof window.turnLeft === 'function') {
                window.turnLeft();
            } else {
                console.error('turnLeft function is not available');
            }
            codeToAdd = `moveleft(${speed});\n`;
        } else if (direction === 'right') {
            if (!definedFunctions.has('moveright')) {
                bodyCode += `
                void moveright(int speed) {
                    ${definedMotors.has('motor1') ? 'digitalWrite(m1a, LOW); digitalWrite(m1b,HIGH);' : ''}
                    ${definedMotors.has('motor2') ? 'digitalWrite(m2a, LOW); digitalWrite(m2b,HIGH);' : ''}
                    ${definedMotors.has('motor3') ? 'digitalWrite(m3a, HIGH); digitalWrite(m3b,LOW);' : ''}
                    ${definedMotors.has('motor4') ? 'digitalWrite(m4a, HIGH); digitalWrite(m4b,LOW);' : ''}
                    analogWrite(e1,speed);
  analogWrite(e2,speed);
                }\n`;
                definedFunctions.add('moveright');
            }
            if (typeof window.turnRight === 'function') {
                window.turnRight();
            } else {
                console.error('turnLeft function is not available');
            }
            codeToAdd = `moveright(${speed});\n`;
        }
        if (localStorage.getItem('codeContext') === 'setup') {
            setupCode += codeToAdd;
        } else if (localStorage.getItem('codeContext') === 'loop') {
            loopCode += codeToAdd;
        }
        this.accumulateCode();
        this.resetCode();
    }
    async stopMoving() {
        if (!definedFunctions.has('movestop')) {
            bodyCode += `
            void movestop() {
                ${definedMotors.has('motor1') ? 'digitalWrite(m1a, LOW); digitalWrite(m1b,LOW);' : ''}
                ${definedMotors.has('motor2') ? 'digitalWrite(m2a, LOW); digitalWrite(m2b,LOW);' : ''}
                ${definedMotors.has('motor3') ? 'digitalWrite(m3a, LOW); digitalWrite(m3b,LOW);' : ''}
                ${definedMotors.has('motor4') ? 'digitalWrite(m4a, LOW); digitalWrite(m4b,LOW);' : ''}
            analogWrite(e1,0);
  analogWrite(e2,0);
                }\n`;
            definedFunctions.add('movestop');
        }
        if (typeof window.stopForward === 'function' || typeof window.stopBackward === 'function' || typeof window.stopRight === 'function' || typeof window.stopLeft === 'function') {
            window.stopForward();
            window.stopBackward();
            window.stopRight();
            window.stopLeft();
        } else {
            console.error('moveForward function is not available');
        }
        const codeToAdd = `movestop();\n`;
        if (localStorage.getItem('codeContext') === 'setup') {
            setupCode += codeToAdd;
        } else if (localStorage.getItem('codeContext') === 'loop') {
            loopCode += codeToAdd;
        }
        this.accumulateCode();
        this.resetCode();
    }

    rotateAngle(args) {
        const clockanticlock = args.clockanticlock;
        const degree = args.degree;
        let codeToAdd = '';

        if (clockanticlock === 'clockwise') {
            if (!definedFunctions.has('rotateCW')) {
                bodyCode += `
                void rotateCW(float angle) {
                    int rotationTime = calculateRotationTime(angle);

                    ${definedMotors.has('motor1') ? 'digitalWrite(m1a, LOW); digitalWrite(m1b,HIGH);' : ''}
                    ${definedMotors.has('motor2') ? 'digitalWrite(m2a, LOW); digitalWrite(m2b,LOW);' : ''}
                    ${definedMotors.has('motor3') ? 'digitalWrite(m3a, LOW); digitalWrite(m3b,LOW);' : ''}
                    ${definedMotors.has('motor4') ? 'digitalWrite(m4a, LOW); digitalWrite(m4b,HIGH);' : ''}
                    analogWrite(e1, 180);
                    analogWrite(e2, 180);

                    delay(rotationTime);

                    analogWrite(e1, 0);
                    analogWrite(e2, 0);
                    ${definedMotors.has('motor1') ? 'digitalWrite(m1a, LOW); digitalWrite(m1b,LOW);' : ''}
                }\n`;
                definedFunctions.add('rotateCW');
            }
            codeToAdd = `rotateCW(${degree});\n`;
            
            // if (typeof window.turnRight === 'function') {
            //     window.turnRight();
            //     setTimeout(() => window.stopRight(), degree * 10); // Approximate time based on degree
            // } else {
            //     console.error('turnRight function is not available');
            // }
        } else if (clockanticlock === 'anticlockwise') {
            if (!definedFunctions.has('rotateCCW')) {
                bodyCode += `
                void rotateCCW(float angle) {
                    int rotationTime = calculateRotationTime(angle);

                    ${definedMotors.has('motor1') ? 'digitalWrite(m1a, LOW); digitalWrite(m1b,LOW);' : ''}
                    ${definedMotors.has('motor2') ? 'digitalWrite(m2a, LOW); digitalWrite(m2b,HIGH);' : ''}
                    ${definedMotors.has('motor3') ? 'digitalWrite(m3a, LOW); digitalWrite(m3b,HIGH);' : ''}
                    ${definedMotors.has('motor4') ? 'digitalWrite(m4a, LOW); digitalWrite(m4b,LOW);' : ''}
                    analogWrite(e1, 180);
                    analogWrite(e2, 180);

                    delay(rotationTime);

                    analogWrite(e1, 0);
                    analogWrite(e2, 0);
                    ${definedMotors.has('motor1') ? 'digitalWrite(m1a, LOW); digitalWrite(m1b,LOW);' : ''}
                }\n`;
                definedFunctions.add('rotateCCW');
            }
            codeToAdd = `rotateCCW(${degree});\n`;
            
            // if (typeof window.turnLeft === 'function') {
            //     window.turnLeft();
            //     setTimeout(() => window.stopLeft(), degree * 10); // Approximate time based on degree
            // } else {
            //     console.error('turnLeft function is not available');
            // }
        }

        if (localStorage.getItem('codeContext') === 'setup') {
            setupCode += codeToAdd;
        } else if (localStorage.getItem('codeContext') === 'loop') {
            loopCode += codeToAdd;
        }
        this.accumulateCode();
        this.resetCode();
    }
    drawCircle(args) {
        const size = args.size;
    }
}

module.exports = Scratch3ArduinoRoboticCarBlocks;
