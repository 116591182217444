const ArgumentType = require('../../extension-support/argument-type');
const BlockType = require('../../extension-support/block-type');
const formatMessage = require('format-message');
const codeGeneration = require('../codeGeneration');

let setupCode = '';
let loopCode = '';
let defineCode = '';
let bodycode = '';
let codeContext = 'setup';
const definedFunctions = new Set();
const definedServos = new Set(); // Keep track of defined servos
class Scratch3RoboticArmBlocks {
  constructor(runtime) {
    this.runtime = runtime;
    codeContext = localStorage.getItem('codeContext');
  }
  getInfo() {
    return {
      id: 'arm',
      color1: '#0b0ed9',
      name: formatMessage({
        id: 'arm.categoryName',
        default: 'Robotic Arm',
        description: 'Extension to control a robotic arm based on Arduino.'
      }),
      blocks: [
        {
          opcode: 'calibrate',
          blockType: BlockType.COMMAND,
          text: formatMessage({
            id: 'arm.calibrate',
            default: 'Calibrate servos',
            description: 'Move all servos to their calibrated positions.'
          }),
          func: 'calibrate'
        },
        {
          opcode: 'setInitialPosition',
          blockType: BlockType.COMMAND,
          text: formatMessage({
            id: 'arm.setInitialPosition',
            default: 'Set initial positions',
            description: 'Set the initial positions for all servos.'
          }),
          func: 'setInitialPosition'
        },
        {
          opcode: 'manualControl',
          blockType: BlockType.COMMAND,
          text: formatMessage({
            id: 'arm.manualControl',
            default: 'Control arm manually',
            description: 'Control the arm manually.'
          }),
          func: 'manualControl'
        },
        {
          opcode: 'setServo',
          blockType: BlockType.COMMAND,
          text: formatMessage({
            id: 'arm.setServo',
            default: 'Connect [base] joint to channel number [channelNo]',
            description: 'Attach a servo to the specified pin based on the base selected.'
          }),
          arguments: {
            base: {
              type: ArgumentType.STRING,
              menu: 'baseMenu',
              defaultValue: 'base'
            },
            channelNo: {
              type: ArgumentType.STRING,
              menu: 'channelMenu',
              defaultValue: '0'
            }
          },
          func: 'setServo'
        },
        {
          opcode: 'moveBase',
          blockType: BlockType.COMMAND,
          text: formatMessage({
            id: 'arm.moveBase',
            default: 'Move base to [angle] degrees with speed [speed]',
            description: 'Move the base servo to the specified angle with the given speed.'
          }),
          arguments: {
            angle: {
              type: "angle",
              defaultValue: 90
            },
            speed: {
              type: ArgumentType.NUMBER,
              defaultValue: 15
            }
          },
          func: 'moveBase'
        },
        {
          opcode: 'moveShoulder',
          blockType: BlockType.COMMAND,
          text: formatMessage({
            id: 'arm.moveShoulder',
            default: 'Move shoulder to [angle] degrees with speed [speed]',
            description: 'Move the shoulder servo to the specified angle with the given speed.'
          }),
          arguments: {
            angle: {
              type: "angle",
              defaultValue: 90
            },
            speed: {
              type: ArgumentType.NUMBER,
              defaultValue: 15
            }
          },
          func: 'moveShoulder'
        },
        {
          opcode: 'moveElbow',
          blockType: BlockType.COMMAND,
          text: formatMessage({
            id: 'arm.moveElbow',
            default: 'Move elbow to [angle] degrees with speed [speed]',
            description: 'Move the elbow servo to the specified angle with the given speed.'
          }),
          arguments: {
            angle: {
              type: "angle",
              defaultValue: 90
            },
            speed: {
              type: ArgumentType.NUMBER,
              defaultValue: 15
            }
          },
          func: 'moveElbow'
        },
        {
          opcode: 'moveWrist1',
          blockType: BlockType.COMMAND,
          text: formatMessage({
            id: 'arm.moveWrist1',
            default: 'Move wrist1 to [angle] degrees with speed [speed]',
            description: 'Move the wrist1 servo to the specified angle with the given speed.'
          }),
          arguments: {
            angle: {
              type: "angle",
              defaultValue: 90
            },
            speed: {
              type: ArgumentType.NUMBER,
              defaultValue: 15
            }
          },
          func: 'moveWrist1'
        },
        {
          opcode: 'moveWrist2',
          blockType: BlockType.COMMAND,
          text: formatMessage({
            id: 'arm.moveWrist2',
            default: 'Move wrist2 to [angle] degrees with speed [speed]',
            description: 'Move the wrist2 servo to the specified angle with the given speed.'
          }),
          arguments: {
            angle: {
              type: "angle",
              defaultValue: 90
            },
            speed: {
              type: ArgumentType.NUMBER,
              defaultValue: 15
            }
          },
          func: 'moveWrist2'
        },
        {
          opcode: 'moveGrip',
          blockType: BlockType.COMMAND,
          text: formatMessage({
            id: 'arm.moveGrip',
            default: 'Move grip to [angle] degrees with speed [speed]',
            description: 'Move the grip servo to the specified angle with the given speed.'
          }),
          arguments: {
            angle: {
              type: "angle",
              defaultValue: 60
            },
            speed: {
              type: ArgumentType.NUMBER,
              defaultValue: 15
            }
          },
          func: 'moveGrip'
        },
        {
          opcode: 'homePosition',
          blockType: BlockType.COMMAND,
          text: formatMessage({
            id: 'arm.homePosition',
            default: 'Move to home position',
            description: 'Move all servos to their home positions.'
          }),
          func: 'homePosition'
        },
        // {
        //   opcode: 'drawArc',
        //   blockType: BlockType.COMMAND,
        //   text: formatMessage({
        //     id: 'arm.drawArc',
        //     default: 'Draw an arc with radius [radius]',
        //     description: 'Draw an arc with the given radius.'
        //   }),
        //   arguments: {
        //     radius: {
        //       type: ArgumentType.NUMBER,
        //       defaultValue: 50
        //     },
        //   },
        //   func: 'drawArc'
        // },
        {
          opcode: 'drawShape',
          blockType: BlockType.COMMAND,
          text: formatMessage({
            id: 'arm.drawShape',
            default: 'Draw a [shape] ',
            description: 'Draw a circle with the given radius.'
          }),
          arguments: {
            shape: {
              type: ArgumentType.STRING,
              defaultValue: 'circle',
              menu: 'shapeMenu'
            },
          },
          func: 'drawShape'
        },
        {
          opcode: 'moveToPose',
          blockType: BlockType.COMMAND,
          text: formatMessage({
            id: 'arm.moveToPose',
            default: 'Move to pose [pose]',
            description: 'Move to the are to given pose'
          }),
          arguments: {
            pose: {
              type: ArgumentType.STRING,
              defaultValue: 'up',
              menu: 'poseMenu'
            },
          },
          func: 'moveToPose'
        },
      ],
      menus: {
        headerMenu: {
          items: ['Wire.h', 'Adafruit_PWMServoDriver.h', 'Servo.h']
        },
        baseMenu: {
          items: ['base', 'shoulder', 'elbow', 'wrist1', 'wrist2', 'grip']
        },
        pinMenu: {
          items: ['2', '3', '4', '5', '6', '7']
        },
        channelMenu: {
          items: ['0', '1', '2', '3', '4', '5']
        },
        contextMenu: {
          items: ['setup', 'loop']
        },
        shapeMenu: {
          items: ['circle', 'triangle']
        },
        poseMenu: {
          items: ['up', 'down', 'pick', 'place']
        }
      }
    };
  }
  resetCode() {
    // Reset all accumulated code and status
    setupCode = '';
    loopCode = '';
    defineCode = '';
    bodycode = '';
  }
  full_reset() {
    setupCode = '';
    loopCode = '';
    defineCode = '';
    bodycode = '';
    definedServos.clear(); // Clear the set of defined servos
    definedFunctions.clear();
    console.log('Arm Code has been reset.');
  }

  accumulateCode() {
    codeGeneration.accumulateCode(defineCode, bodycode, setupCode, loopCode);
  }
  setServo(args) {
    const base = args.base;
    const channelNo = args.channelNo;
    // Add the servo variable initialization to the setup code if not already present
    if (!definedServos.has(base)) {
      defineCode += `int ${base} = ${channelNo};\n`;
      definedServos.add(base);
    }

    this.accumulateCode();
    this.resetCode();
  }

  manualControl() {
    if (typeof window.sendPoseCommand === 'function') {
      window.sendPoseCommand("manual");
    }
  }

  moveBase(args) {
    const servoVar = 'servo_b';
    const MIN_ANGLE = 0;    // Define your minimum angle
    const MAX_ANGLE = 180;
    const angle = args.angle;
    const speed = args.speed;
    if (angle < MIN_ANGLE || angle > MAX_ANGLE) {
      // console.error;
      return (`Enter the angle between ${MIN_ANGLE} and ${MAX_ANGLE}`); // Exit the function if the angle is out of range
    }
    if (typeof window.moveArmJoint === 'function') {
      window.moveArmJoint('arm', angle);
    }
    // Generate the moveServo_b function
    if (!definedFunctions.has('moveServo_b')) {
      bodycode += `
          void moveServo_b(int endAngle, int delayTime) {
             if (base_pos < endAngle) {
    for (int angle = base_pos; angle <= endAngle; angle++) {
      pwm.setPWM(base, 0, angleToPulse(angle));
      delay(delayTime);
    }
  } else {
    for (int angle = base_pos; angle >= endAngle; angle--) {
      pwm.setPWM(base, 0, angleToPulse(angle));
      delay(delayTime);
    }
  }
  // Update the current position
  base_pos = endAngle;
          }
        `;
      definedFunctions.add('moveServo_b');
    }

    const codeToAdd = `moveServo_b(${angle}, ${speed});\n`;

    if (localStorage.getItem('codeContext') === 'setup') {
      setupCode += codeToAdd;
    } else if (localStorage.getItem('codeContext') === 'loop') {
      loopCode += codeToAdd;
    }
    this.accumulateCode();
    this.resetCode();
  }
  moveShoulder(args) {
    const servoVar = 'servo_s';
    const MIN_ANGLE = 0;    // Define your minimum angle
    const MAX_ANGLE = 180;
    const angle = args.angle;
    const speed = args.speed;
    if (angle < MIN_ANGLE || angle > MAX_ANGLE) {
      // console.error;
      return (`Enter the angle between ${MIN_ANGLE} and ${MAX_ANGLE}`); // Exit the function if the angle is out of range
    }
    if (typeof window.moveArmJoint === 'function') {
      window.moveArmJoint('shoulder', angle);
    }
    // Generate the moveServo_s function
    if (!definedFunctions.has('moveServo_s')) {
      bodycode += `
          void moveServo_s(int endAngle, int delayTime) {
             if (shoulder_pos < endAngle) {
    for (int angle = shoulder_pos; angle <= endAngle; angle++) {
      pwm.setPWM(shoulder, 0, angleToPulse(angle));
      delay(delayTime);
    }
  } else {
    for (int angle = shoulder_pos; angle >= endAngle; angle--) {
      pwm.setPWM(shoulder, 0, angleToPulse(angle));
      delay(delayTime);
    }
  }
  // Update the current position
  shoulder_pos = endAngle;
          }
        `;
      definedFunctions.add('moveServo_s');
    }
    const codeToAdd = `moveServo_s(${angle}, ${speed});\n`;

    if (localStorage.getItem('codeContext') === 'setup') {
      setupCode += codeToAdd;
    } else if (localStorage.getItem('codeContext') === 'loop') {
      loopCode += codeToAdd;
    }
    this.accumulateCode();
    this.resetCode();
  }
  moveElbow(args) {
    const MIN_ANGLE = 0;    // Define your minimum angle
    const MAX_ANGLE = 180;
    const angle = args.angle;
    const speed = args.speed;
    if (angle < MIN_ANGLE || angle > MAX_ANGLE) {
      // console.error;
      return (`Enter the angle between ${MIN_ANGLE} and ${MAX_ANGLE}`); // Exit the function if the angle is out of range
    }
    if (typeof window.moveArmJoint === 'function') {
      window.moveArmJoint('elbow', angle);
    }
    if (!definedFunctions.has('moveServo_e')) {
      bodycode += `
        void moveServo_e(int endAngle, int delayTime) {
       if (elbow_pos < endAngle) {
    for (int angle = elbow_pos; angle <= endAngle; angle++) {
      pwm.setPWM(elbow, 0, angleToPulse(angle));
      delay(delayTime);
    }
  } else {
    for (int angle = elbow_pos; angle >= endAngle; angle--) {
      pwm.setPWM(elbow, 0, angleToPulse(angle));
      delay(delayTime);
    }
  }
  // Update the current position
  elbow_pos = endAngle;
        }
      `;
      definedFunctions.add('moveServo_e');
    }
    const codeToAdd = `moveServo_e(${angle}, ${speed});\n`;

    if (localStorage.getItem('codeContext') === 'setup') {
      setupCode += codeToAdd;
    } else if (localStorage.getItem('codeContext') === 'loop') {
      loopCode += codeToAdd;
    }
    this.accumulateCode();
    this.resetCode();
  }
  moveWrist1(args) {
    const MIN_ANGLE = 0;    // Define your minimum angle
    const MAX_ANGLE = 180;
    const angle = args.angle;
    const speed = args.speed;
    if (angle < MIN_ANGLE || angle > MAX_ANGLE) {
      // console.error;
      return (`Enter the angle between ${MIN_ANGLE} and ${MAX_ANGLE}`); // Exit the function if the angle is out of range
    }
    if (typeof window.moveArmJoint === 'function') {
      window.moveArmJoint('wrist1', angle);
    }
    if (!definedFunctions.has('moveServo_w1')) {
      bodycode += `        
        void moveServo_w1(int endAngle, int delayTime) {
          if (wrist1_pos < endAngle) {
    for (int angle = wrist1_pos; angle <= endAngle; angle++) {
      pwm.setPWM(wrist1, 0, angleToPulse(angle));
      delay(delayTime);
    }
  } else {
    for (int angle = wrist1_pos; angle >= endAngle; angle--) {
      pwm.setPWM(wrist1, 0, angleToPulse(angle));
      delay(delayTime);
    }
  }
  // Update the current position
  wrist1_pos = endAngle;
        }
      `;
      definedFunctions.add('moveServo_w1');
    }
    const codeToAdd = `moveServo_w1(${angle}, ${speed});\n`;

    if (localStorage.getItem('codeContext') === 'setup') {
      setupCode += codeToAdd;
    } else if (localStorage.getItem('codeContext') === 'loop') {
      loopCode += codeToAdd;
    }
    this.accumulateCode();
    this.resetCode();
  }
  moveWrist2(args) {
    const MIN_ANGLE = 0;    // Define your minimum angle
    const MAX_ANGLE = 180;
    const angle = args.angle;
    const speed = args.speed;
    if (angle < MIN_ANGLE || angle > MAX_ANGLE) {
      // console.error;
      return (`Enter the angle between ${MIN_ANGLE} and ${MAX_ANGLE}`); // Exit the function if the angle is out of range
    }
    if (typeof window.moveArmJoint === 'function') {
      window.moveArmJoint('wrist2', angle);
    }
    if (!definedFunctions.has('moveServo_w2')) {
      bodycode += `
        void moveServo_w2(int endAngle, int delayTime) {
          if (wrist2_pos < endAngle) {
    for (int angle = wrist2_pos; angle <= endAngle; angle++) {
      pwm.setPWM(wrist2, 0, angleToPulse(angle));
      delay(delayTime);
    }
  } else {
    for (int angle = wrist2_pos; angle >= endAngle; angle--) {
      pwm.setPWM(wrist2, 0, angleToPulse(angle));
      delay(delayTime);
    }
  }
  // Update the current position
  wrist2_pos = endAngle;
        }
      `;
      definedFunctions.add('moveServo_w2');
    }
    const codeToAdd = `moveServo_w2(${angle}, ${speed});\n`;

    if (localStorage.getItem('codeContext') === 'setup') {
      setupCode += codeToAdd;
    } else if (localStorage.getItem('codeContext') === 'loop') {
      loopCode += codeToAdd;
    }
    this.accumulateCode();
    this.resetCode();
  }
  moveGrip(args) {
    const MIN_ANGLE = 60;    // Define your minimum angle
    const MAX_ANGLE = 120;  // Define your maximum angle
    const angle = args.angle;
    const speed = args.speed;

    // Check if the angle is within the valid range
    if (angle < MIN_ANGLE || angle > MAX_ANGLE) {
      // console.error;
      return (`Enter the angle between ${MIN_ANGLE} and ${MAX_ANGLE}`); // Exit the function if the angle is out of range
    }
    if (typeof window.moveArmJoint === 'function') {
      window.moveArmJoint('grip1', angle);
    }
    if (!definedFunctions.has('moveServo_grp')) {
      bodycode += `        
      void moveServo_grp(int endAngle, int delayTime) {
         if (grip_pos < endAngle) {
    for (int angle = grip_pos; angle <= endAngle; angle++) {
      pwm.setPWM(grip, 0, angleToPulse_grp(angle));
      delay(delayTime);
    }
  } else {
    for (int angle = grip_pos; angle >= endAngle; angle--) {
      pwm.setPWM(grip, 0, angleToPulse_grp(angle));
      delay(delayTime);
    }
  }
  // Update the current position
  grip_pos = endAngle;
      }
    `;
      definedFunctions.add('moveServo_grp');
    }
    const codeToAdd = `moveServo_grp(${angle}, ${speed});\n`;

    if (localStorage.getItem('codeContext') === 'setup') {
      setupCode += codeToAdd;
    } else if (localStorage.getItem('codeContext') === 'loop') {
      loopCode += codeToAdd;
    }
    this.accumulateCode();
    this.resetCode();
  }
  homePosition() {
    bodycode += `
        void home_pos() {
            ${definedServos.has('base') ? 'pwm.setPWM(base, 0, angleToPulse(base_pos));' : ''}
            ${definedServos.has('shoulder') ? 'pwm.setPWM(shoulder, 0, angleToPulse(shoulder_pos));' : ''}
            ${definedServos.has('elbow') ? 'pwm.setPWM(elbow, 0, angleToPulse(elbow_pos));' : ''}
            ${definedServos.has('wrist1') ? 'pwm.setPWM(wrist1, 0, angleToPulse(wrist1_pos));' : ''}
            ${definedServos.has('wrist2') ? 'pwm.setPWM(wrist2, 0, angleToPulse(wrist2_pos));' : ''}
            ${definedServos.has('grip') ? 'pwm.setPWM(grip, 0, angleToPulse(grip_pos));' : ''}
        }
    `;
    if (typeof window.sendPoseCommand === 'function') {
      window.sendPoseCommand("rest");
    }
    const codeToAdd = `
    home_pos();
    `;

    if (localStorage.getItem('codeContext') === 'setup') {
      setupCode += codeToAdd;
    } else if (localStorage.getItem('codeContext') === 'loop') {
      loopCode += codeToAdd;
    }
    this.accumulateCode();
    this.resetCode();
  }

  setInitialPosition() {
    // Define the initial positions if not already defined
    if (!definedServos.has('initial_positions')) {
      defineCode += `
            #define SERVOMIN_puls 170
#define SERVOMAX_puls 600
#define SERVOMIN_angle 5
#define SERVOMAX_angle 180

#define SERVOMIN_grp_angle 93
#define SERVOMAX_grp_angle 160
#define SERVOMIN_grp_puls 310
#define SERVOMAX_grp_puls 533

int base_pos = 90;
int shoulder_pos = 90;
int elbow_pos = 180;
int wrist1_pos = 90;
int wrist2_pos = 180;
int grip_pos = 60;

uint16_t angleToPulse(int angle) {
  return map(angle, SERVOMIN_angle, SERVOMAX_angle, SERVOMIN_puls, SERVOMAX_puls);
}

uint16_t angleToPulse_grp(int angle) {
  return map(angle, SERVOMIN_grp_angle, SERVOMAX_grp_angle, SERVOMIN_grp_puls, SERVOMAX_grp_puls);
}
          `;
      definedServos.add('initial_positions');  // Track that initial positions are defined
    }
    this.accumulateCode();
    this.resetCode();
  }
  calibrate() {
    // Check each servo and calibrate it if detected
    bodycode += `
        void calibrate_() {
            ${definedServos.has('base') ? ' pwm.setPWM(base, 0, angleToPulse(90));' : ''}
            ${definedServos.has('shoulder') ? 'pwm.setPWM(shoulder, 0, angleToPulse(90));' : ''}
            ${definedServos.has('elbow') ? ' pwm.setPWM(elbow, 0, angleToPulse(90));' : ''}
            ${definedServos.has('wrist1') ? ' pwm.setPWM(wrist1, 0, angleToPulse(90));' : ''}
            ${definedServos.has('wrist2') ? 'pwm.setPWM(wrist2, 0, angleToPulse(90));' : ''}
            ${definedServos.has('grip') ? 'pwm.setPWM(grip, 0, angleToPulse(90));' : ''}
        }
    `;
    setupCode += `
        calibrate_();
    `;
    this.accumulateCode();
    this.resetCode();
  }
  drawArc(args) {
    const radius = args.radius;
    console.log(radius);
  }
  drawShape(args) {
    const shape = args.shape;
    if (typeof window.sendPoseCommand === 'function') {
      window.sendPoseCommand(shape);
    }
  }
  moveToPose(args) {
    const pose = args.pose;
    if (typeof window.sendPoseCommand === 'function') {
      window.sendPoseCommand(pose);
    }
  }
}
module.exports = Scratch3RoboticArmBlocks;
